import React from 'react';
import { mergeWith } from 'lodash';
import noop from 'lodash/noop';

import useScreen from '@lyearn/core/hooks/useScreen';
import { useTranslation } from '@lyearn/i18n';
import { Close as IconClose } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import {
  BottomSheet,
  Box,
  DeprecatedIconButton,
  DeprecatedIconButtonProps,
  Dialog,
} from '@lyearn/ui';

const DEFAULT_DIALOG_CLASSES = {
  paper: 'relative transform-gpu text-text-primary bg-bg-primary',
};

const DEFAULT_BOTTOM_SHEET_CLASSES = {
  paperAnchorBottom: 'bg-transparent',
};

interface DialogComponentProps {
  children: React.ReactNode;
  onClose: () => void;
  open: boolean;
  classes?: React.ComponentProps<typeof Dialog>['classes'];
  bottomSheetClasses?: React.ComponentProps<typeof BottomSheet>['classes'];
  renderAs?: 'dialog' | 'bottomSheet';
  showCloseIcon?: boolean;
  bottomSheetContainerClassName?: string;
  closeButton?: {
    size?: DeprecatedIconButtonProps['size'];
    className?: string;
  };

  // dialog props
  dialogProps?: Omit<
    React.ComponentProps<typeof Dialog>,
    'classes' | 'open' | 'onClose' | 'TransitionComponent'
  >;
  TransitionComponent?: React.ComponentProps<typeof Dialog>['TransitionComponent'];

  // bottomsheet props
  isSwipeable?: boolean;
  enableContentPanningGesture?: boolean;
  onOpen?: () => void;
  maxContentHeight?: string | number;
  className?: string;
  root?: React.ReactNode;
}

function DialogComponent(props: DialogComponentProps) {
  const { isDesktop, isTablet } = useScreen();
  const { t } = useTranslation();
  const {
    children,
    classes,
    bottomSheetClasses = DEFAULT_BOTTOM_SHEET_CLASSES,
    onClose,
    open,
    bottomSheetContainerClassName,
    className,
    renderAs,
    showCloseIcon = true,
    closeButton,
    enableContentPanningGesture,
    onOpen = noop,
    maxContentHeight,
    TransitionComponent,
    dialogProps,
    root = null,
  } = props;

  const mergedClasses = React.useMemo(
    () => mergeWith({}, DEFAULT_DIALOG_CLASSES, classes, cn),
    [classes],
  );

  const RenderDialog = (
    <Dialog
      TransitionComponent={TransitionComponent}
      classes={mergedClasses}
      open={open}
      onClose={onClose}
      {...dialogProps}>
      {root}
      {showCloseIcon ? (
        <DeprecatedIconButton
          appearance="secondary"
          aria-label={t('Common:Actions.CloseDialog')}
          className={cn('absolute top-4 z-50 p-0 end-0', closeButton?.className)}
          size={closeButton?.size}
          variant="ghost"
          onClick={onClose}>
          <IconClose className="!h-26 !w-26" />
        </DeprecatedIconButton>
      ) : null}
      {children}
    </Dialog>
  );

  const RenderBottomSheet = (
    <BottomSheet
      className={className}
      classes={bottomSheetClasses}
      enableContentPanningGesture={enableContentPanningGesture}
      maxContentHeight={maxContentHeight}
      open={open}
      onClose={onClose}
      onOpen={onOpen}>
      <Box className={cn('bottom-sheet-content mt-12 mb-20', bottomSheetContainerClassName)}>
        {children}
      </Box>
    </BottomSheet>
  );

  if (renderAs === 'dialog') {
    return RenderDialog;
  } else if (renderAs === 'bottomSheet') {
    return RenderBottomSheet;
  } else if (isDesktop || isTablet) {
    return RenderDialog;
  }

  return RenderBottomSheet;
}

export default DialogComponent;
