import { OptimisticMutationResolver } from '@urql/exchange-graphcache';
import { keyBy } from 'lodash';

import { UserSurveyQuestionActionEnum } from '@/types/schema';

import {
  UserSurveyQuestionsEditorFragment,
  UserSurveyQuestionsEditorFragmentDoc,
} from '../fragments/generated/UserSurveyEditor';
import {
  ManageUserSurveyQuestionsMutation,
  ManageUserSurveyQuestionsMutationVariables,
} from '../mutations/generated/manageUserSurveyQuestions';

const manageUserSurveyQuestions: OptimisticMutationResolver<
  ManageUserSurveyQuestionsMutationVariables,
  ManageUserSurveyQuestionsMutation
> = (variables, cache, info) => {
  const input = variables.input;

  const questionsConfigs = input.questionConfigs;

  let returnValue: ManageUserSurveyQuestionsMutation = {
    manageUserSurveyQuestions: {
      __typename: 'ManageUserSurveyQuestionsPayload',
      userSurveyQuestions: [],
    },
  };

  if (!questionsConfigs) return returnValue;

  const isAllUdpateActions = questionsConfigs.every(
    (config) => config.action === UserSurveyQuestionActionEnum.Update,
  );

  if (!isAllUdpateActions) return returnValue;

  const userSurveyQuestionsConfigs = questionsConfigs
    .map((config) => {
      return cache.readFragment(UserSurveyQuestionsEditorFragmentDoc, {
        id: config.id,
      }) as UserSurveyQuestionsEditorFragment | null;
    })
    .filter((c): c is UserSurveyQuestionsEditorFragment => !!c);

  if (userSurveyQuestionsConfigs.length !== questionsConfigs.length) return returnValue;

  const keyByQuestionId = keyBy(userSurveyQuestionsConfigs, (c) => c.id);

  returnValue.manageUserSurveyQuestions = {
    __typename: 'ManageUserSurveyQuestionsPayload',
    userSurveyQuestions: questionsConfigs.map((inputConfig) => {
      const question = keyByQuestionId[inputConfig.id ?? ''];
      return {
        ...question,
        __typename: 'UserSurveyQuestionType',
        id: inputConfig.id ?? question?.id,
        order: inputConfig.order ?? question?.order,
        commentTitle: inputConfig.commentTitle ?? question?.commentTitle,
        conditionConfigs: inputConfig.conditionConfigs
          ? [
              {
                actions: inputConfig.conditionConfigs[0].actions.map((action) => ({
                  actionType: action.actionType,
                  properties: action.properties?.map((property) => ({
                    type: property.type,
                    values: property.values,
                    __typename: 'ConditionActionPropertyType',
                  })),
                  __typename: 'UserSurveyConditionActionConfig',
                })),
                conditions: inputConfig.conditionConfigs[0].conditions.map((condition) => ({
                  operator: condition.operator,
                  property: {
                    type: condition.property.type,
                    value: condition.property.value,
                    __typename: 'FilterPropertyType',
                  },
                  typeCast: condition.typeCast,
                  value: condition.value,
                  __typename: 'FilterD2Type',
                })),
                operator: inputConfig.conditionConfigs[0].operator,
              },
            ]
          : question?.conditionConfigs,
        conditional: inputConfig.conditional ?? question?.conditional,
        settings: {
          ...question?.settings,
          isOptional: inputConfig.settings?.isOptional ?? !!question?.settings?.isOptional,
          allowAttachmentsInResponse:
            inputConfig.settings?.allowAttachmentsInResponse ??
            question?.settings?.allowAttachmentsInResponse,
          allowComments: inputConfig.settings?.allowComments ?? !!question?.settings?.allowComments,
          isHidden: inputConfig.settings?.isHidden ?? question?.settings?.isHidden,
        },
      };
    }),
  };

  return returnValue;
};

export default manageUserSurveyQuestions;
