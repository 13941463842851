import React, { useRef } from 'react';

import useNavigation from '@/hooks/useNavigation';
import useParams from '@/hooks/useParams';
import { setLoginCookies } from '@/modules/auth/utils';

const AuthRedirect = ({ children }: { children: React.ReactNode }) => {
  const navigation = useNavigation();
  const loading = useRef(false);
  // only for allowMemoryCookie tokens will be available here. they are forwarded from _middleware.
  // for iframe tokens are set in-memory as they can't be set on middleware
  const { success, accessToken, refreshToken } = useParams() as Record<string, string>;

  if (!loading.current && success === 'true' && accessToken && refreshToken) {
    loading.current = true;
    setLoginCookies({ accessToken, refreshToken }).then(() => {
      navigation.replace('/setLoginCookies', { setFrom: true });
      loading.current = false;
    });
  }

  if (loading.current) return null;

  return <>{children}</>;
};

export default AuthRedirect;
