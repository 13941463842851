import { UpdateResolver } from '@urql/exchange-graphcache';

import { jsonStringify } from '@lyearn/core/utils/json';
import { AgendasQueryVariables } from '@/modules/1on1s/components/Template/graphql/queries/generated/agendas';
import { AgendaItemEntityTypeEnum } from '@/types/schema';

export function saveWorkspaceAgendaItemsUpdater(): UpdateResolver {
  return (result, queryArgs, cache, info) => {
    const listOperationName = 'agendaItems';

    cache
      .inspectFields('Query')
      .filter((q) => {
        return q.fieldName === listOperationName;
      })
      .forEach((query) => {
        const args = query.arguments as AgendasQueryVariables;
        const { data: argsData } = jsonStringify(args);
        if (argsData?.includes(AgendaItemEntityTypeEnum.Workspace)) {
          cache.invalidate('Query', query.fieldName, args);
        }
      });
  };
}
