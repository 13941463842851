import { UserFragment } from '@/graphql/fragments/generated/User';
import { UserStatusEnum } from '@/types/schema';

class UserEntity {
  static profilePicture(user: Pick<UserFragment, 'profilePicture'>) {
    return user.profilePicture || '';
  }

  // Static property 'name' conflicts with built-in property 'Function.name' :(
  static getName(user: Pick<UserFragment, 'name' | 'uniqueId'>) {
    // for reading in UI we use uniqueId, as preference is given to federationId of user which is used in hdfc since emailId is not used there
    return user.name ?? user.uniqueId;
  }

  static firstName(user: Pick<UserFragment, 'name'>) {
    return user.name ? user.name.split(' ')[0] : '';
  }

  static isDeactivated(status?: UserStatusEnum) {
    return status === UserStatusEnum.Archived;
  }
}

export default UserEntity;
