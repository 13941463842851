import React, { useCallback, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import difference from 'lodash/difference';
import reduce from 'lodash/reduce';
import { useClient } from 'urql';

import ErrorReporter, { isProd } from '@lyearn/core/utils/ErrorReporter';
import { PageError } from '@/components/Error';
import Loader from '@/components/Loader';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import { FeatureFlagType } from '@/types/schema';

import { fetchFeatureFlags } from './utils/fetchFeatureFlags';
import { FeatureFlagsContextProvider } from './index';
import { FeatureFlags } from './types';
import { evaluateFeatureFlag } from './utils';

interface FeatureFlagsProviderProps {
  children: React.ReactNode;
  preloadedFeatureFlags?: FeatureFlagType[];
}

const FeatureFlagsProvider = ({ children, preloadedFeatureFlags }: FeatureFlagsProviderProps) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagType[] | undefined>(
    preloadedFeatureFlags,
  );
  const client = useClient();
  const { id: userId } = useUserContext();

  const refetchFeatureFlags = useCallback(async () => {
    const updatedFeatureFlags = await fetchFeatureFlags(client);
    if (updatedFeatureFlags?.length) {
      setFeatureFlags(updatedFeatureFlags);
    }
  }, [client]);

  const { loading: fetching, error } = useAsync(
    () => (userId && !featureFlags?.length ? refetchFeatureFlags() : Promise.resolve()),
    [userId],
  );

  const flags: Record<FeatureFlags, boolean> = useMemo(() => {
    if (fetching || !featureFlags) {
      return {} as Record<FeatureFlags, boolean>;
    }

    if (Object.values(FeatureFlags).length !== featureFlags.length) {
      const errorMessage = `FeatureFlag not configured for [${difference(
        Object.values(FeatureFlags),
        featureFlags.map((flag) => flag.name),
      )}]`;

      if (isProd) {
        ErrorReporter.error(errorMessage);
      } else {
        throw new Error(errorMessage);
      }
    }

    return reduce(
      featureFlags,
      (acc, featureFlag) => ({ ...acc, [featureFlag.name]: evaluateFeatureFlag(featureFlag) }),
      {},
    ) as Record<FeatureFlags, boolean>;
  }, [featureFlags, fetching]);

  if (error) {
    if (isProd) {
      ErrorReporter.error(error);
    } else {
      return <PageError error={error} />;
    }
  }

  if (fetching) {
    return <Loader />;
  }

  return <FeatureFlagsContextProvider flags={flags}>{children}</FeatureFlagsContextProvider>;
};

export default FeatureFlagsProvider;
