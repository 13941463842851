import { AppConfig } from './index';

const isomorphicAppConfig = (
  appHost: string,
  uiConfig?: AppConfig['ui'],
  isLoggingEnabled: boolean = false,
): Promise<AppConfig> => {
  if (__IS_BROWSER__) {
    return Promise.resolve(window.__NEXT_DATA__.props.pageProps.appConfig);
  }

  return import('@/hooks/useAppConfig/fetchAppConfig').then(({ default: fetchAppConfig }) =>
    fetchAppConfig(appHost!, uiConfig, isLoggingEnabled),
  );
};

export default isomorphicAppConfig;
