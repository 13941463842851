import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  QuizEditorCacheUpdaterFragment,
  QuizEditorCacheUpdaterFragmentDoc,
} from '../fragments/generated/QuizEditorCacheUpdater';
import { QuizEditorQuizQuestionFragment } from '../fragments/generated/QuizEditorQuizQuestion';
import {
  QuizEditorCreateQuestionsMutation,
  QuizEditorCreateQuestionsMutationVariables,
} from '../mutations/generated/createQuestions';
import { getQuizEditorCacheKey } from './utils';

type Question = QuizEditorCacheUpdaterFragment['questions'][number];

const createQuestionsUpdater: UpdateResolver<
  QuizEditorCreateQuestionsMutation,
  QuizEditorCreateQuestionsMutationVariables
> = (result, args, cache, info) => {
  if (!args.data.quizId) {
    invalidateQuery(['questions'])(result, args, cache, info); // Use-case: Question Bank
    return;
  }

  const quiz = cache.readFragment<QuizEditorCacheUpdaterFragment>(
    QuizEditorCacheUpdaterFragmentDoc,
    { id: getQuizEditorCacheKey(args.data.quizId), __typename: 'QuizContentType' },
  );

  if (!quiz || !result?.createQuestions?.createdQuestions?.length) {
    invalidateQuery(['content'])(result, args, cache, info);
    return;
  }

  const quizQuestions = [
    ...quiz.quizQuestions,
    ...result.createQuestions.createdQuizQuestions.map<QuizEditorQuizQuestionFragment>(
      (quizQuestion) => ({ ...quizQuestion, __typename: 'QuizQuestionSchema' }),
    ),
  ];

  const questions = [
    ...quiz.questions,
    ...result.createQuestions.createdQuestions.map<Question>((question) => ({
      id: question.id,
      __typename: 'QuestionType',
    })),
  ];

  cache.writeFragment<QuizEditorCacheUpdaterFragment>(QuizEditorCacheUpdaterFragmentDoc, {
    __typename: 'QuizContentType',
    id: quiz.id,
    versionStatus: quiz.versionStatus,
    quizQuestions,
    questions,
  });
};

export default createQuestionsUpdater;
