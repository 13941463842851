export function jsonParse<T>(jsonString: string) {
  let data: T | undefined;
  let error;

  try {
    data = JSON.parse(jsonString);
  } catch (err) {
    error = err;
  }

  return { data, error };
}

export function jsonStringify<T>(json: T) {
  let data: string | undefined;
  let error;

  try {
    data = JSON.stringify(json);
  } catch (err) {
    error = err;
  }

  return { data, error };
}
