import { themeConfig } from '@lyearn/style';

const legacyDarkTheme = {
  palette: {
    'bg-primary': '#000000',
    'bg-secondary': '#111111',

    'interactive-primary': '#ffffff',
    'interactive-primary-hover': '#bfbfbf',
    'interactive-primary-active': '#999999',
    'interactive-secondary': '#333333',
    'interactive-secondary-hover': '#444444',
    'interactive-secondary-active': '#666666',
    'interactive-white': '#000000',
    'interactive-white-hover': '#333333',
    'interactive-white-active': '#444444',

    'link-external': '#4c98fb',
    'link-external-hover': '#93c5fd',
    'link-external-active': '#bfdbfe',

    focus: themeConfig.palette['text-secondary'],
    'focus-primary': '#ffffff',
    'focus-secondary': '#4c98fb',

    'text-paragraph': '#dbdbdb',
    'text-primary': '#ffffff',
    'text-secondary': '#999999',
    'text-placeholder': '#888888',
    'text-white': '#000000',
    'text-tertiary': '#444444',

    'bg-highlight': '#222222',
    'text-highlight': '#222222',

    'link-01': '#4c98fb',
    'icon-active': '#ffffff',
    'icon-primary': '#bfbfbf',
    'icon-placeholder': '#666666',
    'icon-white': '#000000',
    'icon-secondary': '#888888',

    field: '#333333',

    'bg-success-light': '#f0fdf4',
    'bg-success': '#28c870',
    'success-hover': '#24b264',
    'success-active': '#55dd92',
    'border-success': '#ccf5de',
    'border-success-dark': '#9debc0',
    'icon-success-light': '#55dd92',
    'icon-success': '#55DD92',
    'text-success': '#28C870',
    'text-success-dark': '#9debc0',

    'bg-error-light': '#fef2f2',
    'bg-error': '#f87171',
    'error-hover': '#ef4444',
    'error-active': '#f87171',
    'border-error': '#f2e2e2',
    'border-error-dark': '#fca5a5',
    'icon-error-light': '#f87171',
    'icon-error': '#ef4444',
    'text-error': '#f87171',
    'text-error-dark': '#fca5a5',

    's-warning-text-dark': '#fbbf24',
    'text-warning': '#f59e0b',
    'bg-warning': '#fbbf24',
    'border-warning': '#fef3c7',
    'border-warning-dark': '#fde68a',
    'bg-warning-light': '#fffbeb',
    'icon-warning-light': '#fbbf24',
    'icon-warning': '#f59e0b',

    'text-info': '#bfbfbf',
    'text-info-highlight': '#eaeaea',
    's-info-light': '#444444',
    'icon-info': '#888888',

    divider: '#333333',

    'border-dark': '#444444',
    border: '#333333',
  },
};

// theme was generated by chatgpt
const darkTheme = {
  palette: {
    'bg-primary': '#222222',
    'bg-secondary': '#303030',
    'bg-hover': '#404040',
    'bg-hover-light': '#424242',
    'bg-onclick': '#444444',
    'bg-onclick-dark': '#3a3a3a',
    'interactive-primary': '#ffffff',
    'interactive-primary-hover': '#eaeaea',
    'interactive-primary-active': '#bfbfbf',
    'interactive-secondary': '#303030',
    'interactive-secondary-hover': '#404040',
    'interactive-secondary-active': '#424242',
    'interactive-white': '#222222',
    'interactive-white-hover': '#303030',
    'interactive-white-active': '#3a3a3a',
    'interactive-blur': 'rgba(0, 0, 0, 0.48)',
    'interactive-blur-hover': 'rgba(0, 0, 0, 0.3)',
    'interactive-bg-hover': '#424242',
    'interactive-bg-active': '#404040',
    'interactive-border': '#404040',
    'interactive-text': '#ffffff',
    'interactive-text-hover': '#eaeaea',
    'interactive-text-active': '#bfbfbf',
    'link-external': '#4c98fb',
    'link-external-hover': '#3d81e6',
    'link-external-active': '#2e6ad1',
    'link-internal': '#cccccc',
    'link-internal-hover': '#aaaaaa',
    'link-internal-active': '#8e8e8e',
    focus: '#ffffff',
    'focus-primary': '#ffffff',
    'focus-secondary': '#4c98fb',
    'bg-accent': '#3d81e6',
    'bg-accent-light': '#dbeafe',
    'text-accent': '#3d81e6',
    'text-accent-dark': '#2e6ad1',
    'border-accent': '#93c5fd',
    'border-accent-dark': '#4983d2',
    'icon-accent': '#4c98fb',
    'icon-accent-light': '#93c5fd',
    'text-paragraph': '#cccccc',
    'text-primary': '#ffffff',
    'text-secondary': '#cccccc',
    'text-placeholder': '#c8c8c8',
    'text-white': '#222222',
    'bg-tertiary': '#303030',
    'text-tertiary': '#3a3a3a',
    'bg-highlight': '#ffffff',
    'text-highlight': '#000000',
    'link-01': '#ffffff',
    'link-white': '#000000',
    'link-white-hover': '#333333',
    'link-white-active': '#666666',
    'icon-active': '#ffffff',
    'icon-primary': '#999999',
    'icon-placeholder': '#666666',
    'icon-white': '#000000',
    'icon-secondary': '#cccccc',
    field: '#333333',
    'bg-success-light': '#f0fdf4',
    'bg-success': '#28c870',
    'success-hover': '#24b264',
    'success-active': '#55dd92',
    'border-success': '#ccf5de',
    'border-success-dark': '#9debc0',
    'icon-success-light': '#55dd92',
    'icon-success': '#55DD92',
    'text-success': '#28C870',
    'text-success-dark': '#9debc0',
    'bg-error-light': '#fef2f2',
    'bg-error': '#f87171',
    'error-hover': '#ef4444',
    'error-active': '#f87171',
    'border-error': '#f2e2e2',
    'border-error-dark': '#fca5a5',
    'icon-error-light': '#f87171',
    'icon-error': '#ef4444',
    'text-error': '#f87171',
    'text-error-dark': '#fca5a5',
    'text-warning': '#f59e0b',
    'text-warning-dark': '#d97706',
    'bg-warning': '#fbbf24',
    'border-warning': '#fef3c7',
    'border-warning-dark': '#fde68a',
    'bg-warning-light': '#fffbeb',
    'icon-warning-light': '#fbbf24',
    'icon-warning': '#f59e0b',
    'text-info': '#444444',
    'text-info-highlight': '#333333',
    's-info-light': '#333333',
    'icon-info': '#cccccc',
    'text-pink': '#bb2c55',
    'text-pink-dark': '#da5178',
    'icon-pink-light': '#ed698f',
    'icon-pink': '#ff8eaf',
    'bg-pink': '#ff8eaf',
    'bg-pink-light': '#fff0f4',
    'border-pink': '#ffadc5',
    'border-pink-dark': '#fedfe8',
    'text-cyan': '#6dc7e1',
    'text-cyan-dark': '#4db2d4',
    'icon-cyan-light': '#3f95c2',
    'icon-cyan': '#3076b1',
    'bg-cyan': '#6dc7e1',
    'bg-cyan-light': '#d1eff7',
    'border-cyan': '#3f95c2',
    'border-cyan-dark': '#e9faff',
    'text-purple': '#9096fb',
    'text-purple-dark': '#5956ec',
    'icon-purple-light': '#373da8',
    'icon-purple': '#7278f5',
    'bg-purple': '#9096fb',
    'bg-purple-light': '#dadbfc',
    'border-purple': '#5956ec',
    'border-purple-dark': '#ecedfd',
    'overlay-light': 'rgba(255,255,255,0.44)',
    overlay: 'rgba(255,255,255,0.65)',
    divider: '#333333',
    'border-dark': '#666666',
    border: '#333333',
    'border-03': '#282828',
    'border-medium': '#999999',
    disabled: 'rgba(255,255,255,0.5)',
    'disabled-light': 'rgba(64, 64, 64, 0.5)',
    transparent: 'transparent',
    'brand-linkedin': '#ffffff',
    'brand-linkedin-hover': 'rgba(0,0,0, 0.8)',
  },
  boxShadow: {
    'shadow-none': 'none',
    'shadow-01':
      '0px 0px 1px rgb(0, 0, 0 ,0.2), 0px 0px 2px rgb(0, 0, 0, 0.3), 0px 4px 8px rgba(0, 0, 0, 0.2)',
    'shadow-02':
      '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 10px 20px rgba(0, 0, 0, 0.2)',
    'shadow-03':
      '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 6px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.3)',
    'shadow-04':
      '0px 0px 1px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.2), 0px 16px 24px rgba(0, 0, 0, 0.2), 0px 24px 32px rgba(0, 0, 0, 0.2)',
    'shadow-05':
      '0px 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 6px 0px rgba(0, 0, 0, 0.1), 0px 10px 20px 0px rgba(0, 0, 0, 0.1)',
  },
  backgroundImage: {
    'gradient-01': 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 44.79%)',
  },
};

export default darkTheme;
