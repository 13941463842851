import { css, keyframes } from '@emotion/css';

// reference: https://codepen.io/FlorinPop17/pen/drJJzK
const pulse = (color: string, spread: number) => keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${color}b3;
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 ${spread}px ${color}00;
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${color}00;
  }
`;

export const pulseAnimation = (color: string, spread: number) => css`
  box-shadow: 0 0 0 0 ${color};
  animation: ${pulse(color, spread)} 2s infinite;
`;

export const aspectRatioBox = (aspectRatio: number) => {
  return css`
    padding-top: ${100 / aspectRatio}%;
  `;
};

export const buttonSizeStyles = (iconSize: number = 2.4, containerSize: number = 3.6) => css`
  height: ${containerSize}rem;
  width: ${containerSize}rem;

  svg {
    height: ${iconSize}rem;
    width: ${iconSize}rem;
  }
`;

export const svgSizeStyles = (size: number = 2.4) => css`
  svg {
    height: ${size}rem;
    width: ${size}rem;
  }
`;

export const resetPadding = css`
  padding: 0;
`;

export const fullHeightWidthForced = css`
  width: 100% !important;
  height: 100% !important;
`;

export const fullHeightWidth = css`
  width: 100%;
  height: 100%;
`;

export const listItemHoverStyles = css`
  box-shadow: 0px -1px 0px 0px var(--bg-primary);
  border-color: var(--bg-primary);
`;

export const grayScale = css`
  filter: grayscale(100%);
`;

export const emojiGrayScaleContainer = css`
  .emoji-mart-emoji {
    ${grayScale}
  }
`;

export const searchAnimation = css`
  &.search-enter {
    max-height: 0;
    opacity: 0;
  }

  &.search-enter-active {
    opacity: 1;
    max-height: 56px;
    transition:
      max-height 200ms ease,
      opacity 100ms ease 200ms;
  }

  &.search-enter-done {
    opacity: 1;
    max-height: 56px;
  }

  &.search-exit {
    overflow: hidden;
    max-height: 56px;
  }

  &.search-exit-active {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: all 300ms ease;
  }

  &.search-exit-done {
    max-height: 0;
    overflow: hidden;
    padding: 0;
  }
`;

export const bgColor = (color: string) => css`
  background-color: ${color};
`;

export const borderColor = (color: string) => css`
  border-color: ${color};
`;

export const textColor = (color: string) => css`
  color: ${color};
`;

export const setWidth = (width: number) => css`
  width: ${width}px;
`;

export const br12 = css`
  border-radius: 1.2rem;
`;

export const wFitContent = css`
  width: fit-content;
`;

export const overflowUnset = css`
  overflow: unset;
`;
