import { FeatureFlagType } from '@/types/schema';

import { FeatureFlags } from '../types';

const DEFAULT_FLAG_VALUE: Partial<Record<FeatureFlags, boolean>> = {
  [FeatureFlags.EnableSharedCourseDuplication]: true,
  [FeatureFlags.EnableReports]: true,
};

export const getDefaultValue = (flag: FeatureFlags) => DEFAULT_FLAG_VALUE[flag] ?? false;

export const evaluateFeatureFlag = (featureFlag: FeatureFlagType): boolean => {
  const { value } = featureFlag;

  return value === 'true';
};
