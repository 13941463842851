import { GraphQLError } from 'graphql';
import { CombinedError } from 'urql';

type ExtensionsType =
  | {
      [key: string]: any;
    }
  | undefined;

export const getErrorCode = (error?: CombinedError): string | undefined => {
  return (error?.graphQLErrors?.[0].extensions?.exception as any)?.errorCode as string | undefined;
};

export const checkForPredicateInError = (
  predicate: (extensions?: ExtensionsType) => boolean,
  error?: CombinedError,
): boolean => {
  return !!error?.graphQLErrors?.some((e) => predicate(e.extensions));
};

export const getErrorMessage = (graphQLErrors: GraphQLError[] | undefined) =>
  graphQLErrors?.[0].message;

export const isGraphQLError = (error?: CombinedError) =>
  !!getErrorMessage(error?.graphQLErrors) || !!getErrorCode(error);
