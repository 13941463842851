import React from 'react';
import { LayoutRectangle } from 'react-native';

import { Editor as EditorCore, setNodePerformanceAttrs } from '@lyearn/lygo-core';
import { ContentType } from '@/modules/corporate/modules/course/views/CourseBuilder/components/Sidebar/types';

export interface EditorProps
  extends Pick<
    React.ComponentProps<typeof EditorCore>,
    | 'onCreateEditorView'
    | 'onChange'
    | 'schema'
    | 'plugins'
    | 'nodePerformanceMap'
    | 'inputRules'
    | 'FooterComponent'
    | 'HeaderComponent'
    | 'onScroll'
    | 'yProvider'
  > {
  editable?: boolean;
  loading?: boolean;
  importing?: boolean;
  importingLabel?: string;
  jsonDoc?: ContentType;
  setNodePerformance?: (attrs: setNodePerformanceAttrs) => void;
  getNodePerformance?: (id: string) => { progress?: number };
  setNodeLayoutParams?: (nodeId: string, nodeType: string, layoutParams: LayoutRectangle) => void;
  LoaderComponent?: JSX.Element;
}

export enum Blocks {
  TITLE = 'ly__title',
  TEXT = 'text',
  PARAGRAPH = 'paragraph',
  COURSE = 'ly__course',
  LESSON = 'ly__lesson',
  MEETING = 'ly__meeting',
  ACTION_ITEM = 'ly__action_item',
  AGENDA = 'ly__agenda',
  NOTE = 'ly__note',
  LESSON_PAGE = 'ly__page',
  SESSION_PAGE = 'ly__page',
  REF = 'ly__ref',
  SECTION = 'ly__section',
  DESCRIPTION = 'ly__description',
  COVER = 'ly__lesson_cover',
  LESSON_COMMENTS = 'ly__lesson_comments',
  LESSON_ASSIGNEE = 'ly__lesson_assignee',
  LESSON_STATUS = 'ly__lesson_status',
  LESSON_NOTES = 'ly__lesson_notes',
}

export enum Features {
  COURSE = 'course',
  SESSION = 'session',
  ARTICLE = 'article',
  MEETING = 'meeting',
  OKR = 'okr',
}
