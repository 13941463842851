import { useEffect } from 'react';

//fires the passed callback at given time interval if the browser tab is in focus
const useWindowFocusInterval = (
  callback: () => void,
  intervalTime: number,
  callOnIntervalStart?: boolean,
) => {
  useEffect(() => {
    callOnIntervalStart && callback();
    let interval: ReturnType<typeof setInterval> | null = setInterval(callback, intervalTime);

    const handleFocus = () => {
      callOnIntervalStart && callback();
      if (!interval) {
        interval = setInterval(callback, intervalTime);
      }
    };

    const clearTimer = () => {
      if (interval) {
        clearInterval(interval);
        interval = null;
      }
    };

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', clearTimer);
    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', clearTimer);
      clearTimer();
    };
  }, [callOnIntervalStart, callback, intervalTime]);
};

export default useWindowFocusInterval;
