import React from 'react';

import { Typography } from '@lyearn/ui';

import { useUserInfo } from '../context';
import UserInfoTooltip from '../layouts/UserInfoTooltip';
import { NameProps } from '../types';

function Name({
  variant = 'body-short-02',
  color = 'text-text-paragraph',
  isUserInfoTooltipEnabled,
  ...rest
}: NameProps) {
  const { user } = useUserInfo();

  if (!user?.name) {
    return null;
  }

  return (
    <UserInfoTooltip isUserInfoTooltipEnabled={isUserInfoTooltipEnabled}>
      <Typography
        color={color}
        title={isUserInfoTooltipEnabled ? undefined : user.name}
        variant={variant}
        {...rest}>
        {user.name}
      </Typography>
    </UserInfoTooltip>
  );
}

export default React.memo(Name);
