import { Resolver } from '@urql/exchange-graphcache';

import { OrgRoleFromCacheQueryVariables } from '@/modules/org-hierarchy/modules/tracks/views/TrackDetails/graphql/queries/generated/trackFromCache';

export const orgRoleDetailsResolver: Resolver<any, OrgRoleFromCacheQueryVariables, any> = (
  _,
  args,
) => {
  return { __typename: 'OrgRoleType', id: args.filters.id };
};
