import { UpdateResolver } from '@urql/exchange-graphcache';

import {
  GetNotificationDocument,
  GetNotificationQuery,
  GetNotificationQueryVariables,
} from '@/components/Header/components/NotificationTray/graphql/queries/generated/getNotifications';
import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  BaseUserNotification,
  UserBellNotificationsSubscriptionPayload,
  UserBellNotificationsSubscriptionPayloadTypeEnum,
} from '@/types/schema';

import {
  GetNotificationCountDocument,
  GetNotificationCountQuery,
  GetNotificationCountQueryVariables,
} from '../../queries/generated/getNotificationCount';
import { addUserNotifications, updateUser, updateUserNotifications } from './utils';

export function getUserBellNotificationsUpdater(): UpdateResolver {
  return (result, args, cache) => {
    const response = result.userBellNotification as UserBellNotificationsSubscriptionPayload;
    const type = response.type as UserBellNotificationsSubscriptionPayloadTypeEnum;
    const userNotification = response.userNotification;
    const unreadNotificationsCount = response.unreadNotificationsCount;
    const unresolvedNotificationsCount = response.unresolvedNotificationsCount;

    inspectCache(cache, 'Query', 'user', (field) => {
      updateCache<GetNotificationCountQuery, GetNotificationCountQueryVariables>(
        cache,
        GetNotificationCountDocument,
        field.arguments as GetNotificationCountQueryVariables,
        (data) =>
          updateUser({
            data,
            unreadNotificationsCount,
            unresolvedNotificationsCount,
          }),
      );
    });

    switch (type) {
      case UserBellNotificationsSubscriptionPayloadTypeEnum.BellNotificationAdded:
        inspectCache(cache, 'Query', 'userNotifications', (field) => {
          updateCache<GetNotificationQuery, GetNotificationQueryVariables>(
            cache,
            GetNotificationDocument,
            field.arguments as GetNotificationQueryVariables,
            (data) =>
              addUserNotifications({
                data,
                userNotification: userNotification as BaseUserNotification,
              }),
          );
        });
        return;
      case UserBellNotificationsSubscriptionPayloadTypeEnum.AllNotificationsRead:
        inspectCache(cache, 'Query', 'userNotifications', (field) => {
          updateCache<GetNotificationQuery, GetNotificationQueryVariables>(
            cache,
            GetNotificationDocument,
            field.arguments as GetNotificationQueryVariables,
            (data) =>
              updateUserNotifications({
                data,
              }),
          );
        });
        return;
      default:
        return;
    }
  };
}
