import config from 'config';
import { BLOB, CSPDirectives, DATA, getCSP, SELF, UNSAFE_EVAL, UNSAFE_INLINE } from 'csp-header';
import { ServerResponse } from 'http';
import _mergeWith from 'lodash/mergeWith';

import { AppConfig } from '@/hooks/useAppConfig';

const environment = config.vercel_env ? 'preview' : config.build_env;
const REPORT_URI = `https://o879161.ingest.sentry.io/api/5889636/security/?sentry_key=9040c9f52daa4456a2833c70c3b3aedd&sentry_environment=${environment}&sentry_release=${config.app_version}`;

const DEFAULT_DIRECTIVES: Partial<CSPDirectives> = {
  'default-src': [SELF, BLOB, 'https://*.lyearn.com'],
  'script-src': [
    SELF,
    UNSAFE_EVAL,
    // unsafe-inline because react!
    UNSAFE_INLINE,
    'https://*.lyearn.com',
    'https://*.sprinklr.com',
    'https://d7jx2atmwmj9r.cloudfront.net',
    'www.google-analytics.com',
    'www.googletagmanager.com',
    'https://www.youtube.com',
    'https://maps.googleapis.com',
    'https://*.firebaseio.com',
    'https://cdn.segment.com',
    'cdn.rudderlabs.com',
    'unpkg.com',
    'https://*.openreplay.com',
  ],
  'script-src-elem': [
    SELF,
    UNSAFE_INLINE,
    DATA,
    'https://*.lyearn.com',
    'https://*.sprinklr.com',
    'https://d7jx2atmwmj9r.cloudfront.net',
    'https://*.firebaseio.com',
    'https://maps.googleapis.com',
    'https://unpkg.com',
    'www.youtube.com',
    'https://cdn.segment.com',
    'cdn.rudderlabs.com',
    'api.segment.io',
    'www.googletagmanager.com',
    'https://www.google-analytics.com',
  ],
  'connect-src': [
    SELF,
    BLOB,
    DATA,
    'https://d7jx2atmwmj9r.cloudfront.net',
    'https://firestore.googleapis.com',
    'https://maps.googleapis.com',
    'https://*.lyearn.com',
    '*.sprinklr.com',
    'https://*.sentry.io',
    'wss://*.lyearn.com',
    'wss://*.sprinklr.com',
    'wss://*.firebaseio.com',
    'https://videodelivery.net',
    'https://upload.videodelivery.net',
    'cloudflarestream.com',
    '*.cloudflarestream.com',
    '*.amazonaws.com',
    'www.google-analytics.com',
    'cdn.segment.com',
    'api.segment.io',
    'cdn.rudderlabs.com',
    'api.rudderlabs.com',
    'api.rudderstack.com',
    'https://images.unsplash.com',
    'https://www.dropbox.com',
    'https://*.openreplay.com',
    'https://noembed.com',
  ],
  // to allow embeds * is added
  'frame-src': ['*', DATA],
  'worker-src': [SELF, BLOB, 'https://*.lyearn.com', 'https://*.openreplay.com'],
  'object-src': [
    SELF,
    DATA,
    'https://*.lyearn.com',
    'https://lyearn-classroom-resources.s3.ap-south-1.amazonaws.com',
  ],
  // allow images from everywhere
  'img-src': ['*', SELF, BLOB, DATA, 'android-webview-video-poster:'],
  'style-src': [SELF, UNSAFE_INLINE, DATA, 'https://*.lyearn.com', 'https://fonts.googleapis.com'],
  'media-src': [
    SELF,
    BLOB,
    DATA,
    'https://*.lyearn.com',
    'https://d7jx2atmwmj9r.cloudfront.net',
    '*.amazonaws.com',
    '*.sprinklr.com',
    'videodelivery.net',
    'cloudflarestream.com',
    '*.cloudflarestream.com',
    'https://www.dropbox.com',
    'https://dl.dropboxusercontent.com',
  ],
  'font-src': [
    SELF,
    DATA,
    'https://*.lyearn.com',
    'https://fonts.gstatic.com',
    'https://unpkg.com',
    'https://lyearn-classroom-resources.s3.ap-south-1.amazonaws.com',
  ],
  'frame-ancestors': [SELF, 'https://*.lyearn.com', '*.sprinklr.com'],
};

const getCSPValues = (cspConfig: AppConfig['csp']) =>
  getCSP({
    directives: _mergeWith({}, DEFAULT_DIRECTIVES, cspConfig, (src1, src2) => src1?.concat(src2)),
    reportUri: REPORT_URI,
  });

const securityHeaders = [
  {
    key: 'Strict-Transport-Security',
    value: 'max-age=31536000; includeSubDomains; preload',
  },
  {
    key: 'X-XSS-Protection',
    value: '1; mode=block',
  },
  {
    key: 'X-Content-Type-Options',
    value: 'nosniff',
  },
  {
    key: 'Referrer-Policy',
    value: 'strict-origin-when-cross-origin',
  },
  {
    key: 'Expect-CT',
    value: `report-uri=${REPORT_URI}`,
  },
];

export default function setSecurityHeader(res: ServerResponse, appConfig: AppConfig) {
  securityHeaders.forEach(({ key, value }) => {
    res.setHeader(key, value);
  });

  res.setHeader(
    appConfig.site.enforceCSP ? 'Content-Security-Policy' : 'Content-Security-Policy-Report-Only',
    getCSPValues(appConfig.csp),
  );
}
