import React, { useMemo } from 'react';
import { useAsync } from 'react-use';
import config from 'config';

import UppyContext from '@lyearn/shared/hooks/useUppy/UppyContext';
import currentUser from '@/helper/currentUser';
import { request } from '@/helper/request';
import { useAppConfig } from '@/hooks/useAppConfig';

const getUppyLib = async () => {
  const getUppyInstance = await import('@lyearn/shared/hooks/useUppy/getUppyInstance');

  return { getUppyInstance };
};

export const useUppyLazyGetInstance = () => {
  const { value, loading } = useAsync(getUppyLib, []);

  return { getUppyInstance: value?.getUppyInstance.default, isReady: !loading };
};

const UppyProvider: React.FC<React.PropsWithChildren<{ userId?: string }>> = ({
  children,
  userId,
}) => {
  const { site: siteConfig } = useAppConfig();
  const { getUppyInstance, isReady } = useUppyLazyGetInstance();

  // reinitialize uppy on user refetch
  const uppyInstance = useMemo(
    () => {
      return isReady
        ? {
            loading: false,
            Uppy: getUppyInstance!(
              config.uppy_companion_url,
              {
                getAccessToken: currentUser._aT,
                disabledMultipartUpload: siteConfig.disabledMultipartUpload,
              },
              request,
            ),
          }
        : { loading: true, Uppy: null };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userId, getUppyInstance],
  );

  return <UppyContext.Provider value={uppyInstance}>{children}</UppyContext.Provider>;
};

export default UppyProvider;
