import { UserFragment } from '@/graphql/fragments/generated/User';
import { Maybe } from '@/types/schema';

import { UserTooltipInfoFragment } from '../graphql/fragment/generated/UserTooltipInfo';
import {
  useUserTooltipFromCacheQuery,
  useUserTooltipQuery,
} from '../graphql/queries/generated/userTooltip';

export type UserDetailsType =
  | {
      isPartialData: false;
      user?: Maybe<UserTooltipInfoFragment>;
    }
  | {
      isPartialData: true;
      user?: Maybe<UserFragment>;
    };

type ReturnTypeUseUserTooltip = {
  userDetails: UserDetailsType;
  error: boolean;
};

const useUserTooltip = ({ userId }: { userId?: string }): ReturnTypeUseUserTooltip => {
  const [{ data: cachedUser }] = useUserTooltipFromCacheQuery({
    variables: {
      filters: {
        userId,
      },
    },
    requestPolicy: 'cache-only',
    pause: !userId,
  });

  const [{ fetching, data, error }] = useUserTooltipQuery({
    variables: {
      filters: {
        userId,
      },
    },
    pause: !userId,
  });

  const fetchedUser = data?.user;
  const isPartialData = fetching && !!cachedUser?.user;

  return {
    userDetails: isPartialData
      ? { isPartialData, user: cachedUser?.user }
      : { isPartialData, user: fetchedUser },
    error: !!error,
  };
};

export default useUserTooltip;
