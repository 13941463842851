import { TabValues } from '@/modules/1on1s/views/1on1Editor/types';

const adminRoutes = {};

const learnerRoutes = {
  oneOnOnes: () => '/1on1s',
  oneOnOneDetail: (id: string) => `/1on1s/${id}`,
  oneOnOneMeetingDetail: (id: string, meetingInstanceId: string) =>
    `/1on1s/${id}/${meetingInstanceId}`,
  oneOnOneMeetingDetailTab: (id: string, meetingInstanceId: string, tabId: TabValues) =>
    `/1on1s/${id}/${meetingInstanceId}/${tabId}`,
};

export { adminRoutes, learnerRoutes };
export default learnerRoutes;
