import React from 'react';

import ErrorReporter, { isProd } from '@lyearn/core/utils/ErrorReporter';
import { inIframe } from '@lyearn/core/utils/iframe';
import AnalyticsTriggers from '@/components/Analytics/AnalyticsTriggers';
import HelpMenuProvider from '@/components/HelpMenu';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import { useAppConfig } from '@/hooks/useAppConfig';
import useLastActiveAt from '@/hooks/useLastActiveAt';
import useTrackPlatformVisit from '@/hooks/useTrackPlatformVisit';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import useMasqueradingUser from '@/modules/learner/views/LearnerProfile/hooks/useMasqueradingUser';

import SdkEvents from './SdkEvents';

let reportedIframeError = false;

const Bootstrap: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useUserContext();
  const appConfig = useAppConfig();
  const { [FeatureFlags.OnlyAllowInIframe]: OnlyAllowInIframe } = useFeatureFlags();

  useMasqueradingUser();
  useLastActiveAt(user);
  useTrackPlatformVisit(user);

  // Don't render if the appConfig only permits the app to render inside an iframe
  if (OnlyAllowInIframe && !inIframe()) {
    const ERROR_MESSAGE =
      'Attempt to load outside an iframe. The app configuration mandates it to open inside an iframe.';
    if (isProd && appConfig.site.appUrl) {
      // Only report the error once and not on every render
      !reportedIframeError && ErrorReporter.error(ERROR_MESSAGE);
      reportedIframeError = true;
      window.location.href = appConfig.site.appUrl;
    } else {
      throw new Error(ERROR_MESSAGE);
    }
  }

  return (
    <>
      <SdkEvents />
      <HelpMenuProvider>
        <AnalyticsTriggers />
        {children}
      </HelpMenuProvider>
    </>
  );
};

export default Bootstrap;
