import { Cache, FieldInfo, UpdateResolver } from '@urql/exchange-graphcache';
import { DocumentNode, TypedQueryDocumentNode } from 'graphql';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import set from 'lodash/set';

import { MutationResponse } from '@/types/schema';

export const invalidateFragment =
  (operationKey: string, typename: string, idKey: string): UpdateResolver =>
  (result, args, cache) => {
    if ((result?.[operationKey] as MutationResponse).success) {
      cache.invalidate({
        __typename: typename,
        id: (args?.data as any)?.[idKey],
      });
    }
  };

export const invalidateQuery =
  (listOperationName: string | string[]): UpdateResolver =>
  (result, args, cache) => {
    cache
      .inspectFields('Query')
      .filter((q) => {
        if (isArray(listOperationName)) return listOperationName.includes(q.fieldName);

        return q.fieldName === listOperationName;
      })
      .forEach((query) => cache.invalidate('Query', query.fieldName, query.arguments!));
  };

export function inspectCache(
  cache: Cache,
  entity: string,
  fieldName: string,
  callback: (field: FieldInfo) => void,
) {
  cache
    .inspectFields(entity)
    .filter((field) => field.fieldName === fieldName)
    .map(callback);
}

export function updateCache<T, Q>(
  cache: Cache,
  query: string | DocumentNode | TypedQueryDocumentNode<T, Q>,
  variables: Q,
  updater: (data: T | null) => T | null,
) {
  cache.updateQuery<T, Q>({ query, variables }, updater);
}

export function deleteMutation(
  edgeKey: string,
  mutationResultEdgeKey: string,
  queryArgDeleteKey: string,
  Document: DocumentNode,
): UpdateResolver {
  return (result, queryArgs, cache) => {
    const isDeleted = (get(result, mutationResultEdgeKey) as MutationResponse)?.success;
    if (!isDeleted) {
      return;
    }

    inspectCache(cache, 'Query', edgeKey, (field) => {
      const args = field.arguments;
      updateCache(cache, Document, args, (data) => {
        if (get(data, edgeKey)?.edges) {
          set(
            data as any,
            `${edgeKey}.edges`,
            get(data, edgeKey).edges.filter(
              (edge: any) => edge.node.id !== get(queryArgs.data, queryArgDeleteKey),
            ),
          );
        }

        return data;
      });
    });
  };
}
