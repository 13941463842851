import React, { useEffect } from 'react';

import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import useNavigation from '@/hooks/useNavigation';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import { onBoardCompleted } from '@/modules/onboarding/helper';
import { UserStatusEnum } from '@/types/schema';

import { useAppConfig } from '../../hooks/useAppConfig';
import routes from '../../pages/corporate/routes';

const OnBoardingFlow: React.FC<React.PropsWithChildren> = ({ children }) => {
  const navigation = useNavigation();
  const user = useUserContext();
  const { [FeatureFlags.EnableOnboarding]: showOnBoarding } = useFeatureFlags();
  const { site } = useAppConfig();

  //searchParam return string or null
  const shouldBoarding = showOnBoarding !== false;
  const emailNotVerified = user?.status === UserStatusEnum.EmailNotVerified;

  useEffect(() => {
    if (site.academySetupCompleted === false && navigation.pathname !== routes.AcademySetup()) {
      navigation.replace(routes.AcademySetup());
    }

    if (emailNotVerified && user.id && navigation.pathname !== '/verify-email') {
      navigation.push('/verify-email', { setFrom: true, appendQuery: false });
    }

    if (
      shouldBoarding &&
      !emailNotVerified &&
      user.id &&
      !onBoardCompleted(user) &&
      navigation.pathname !== '/about-yourself'
    ) {
      navigation.push('/about-yourself', { setFrom: true, appendQuery: false });
    }
  }, [emailNotVerified, navigation, shouldBoarding, site.academySetupCompleted, user]);

  return <>{children}</>;
};

export default OnBoardingFlow;
