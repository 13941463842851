interface StopPropagationEventType {
  stopPropagation: () => void;
}

export const stopEventPropagation = (event: StopPropagationEventType) => {
  event.stopPropagation();
};

interface PreventDefaultEventType {
  preventDefault: () => void;
}

export const eventPreventDefault = (event: PreventDefaultEventType) => {
  event.preventDefault();
};
