const registerPolyfills = () => {
  // Required for old somos mobile browser compatibility
  // Ref: https://lyearn.sentry.io/issues/4210881281/?project=5889636
  // Modified from TC39 at proposal polyfill: https://github.com/tc39/proposal-relative-indexing-method#polyfill
  if (!Array.prototype.at) {
    Array.prototype.at = function at(n) {
      let i = Math.trunc(n) || 0;

      if (i < 0) i += this.length;

      if (i < 0 || i >= this.length) return undefined;

      return this[i];
    };
  }

  // fixes an issue where requestAnimationFrame was injected in web despite reanimated polyfill
  if (global.requestAnimationFrame == null) {
    global.requestAnimationFrame = (cb: any) => cb();
  }

  (async () => {
    if (typeof window !== 'undefined' && !('ResizeObserver' in window)) {
      // Loads polyfill asynchronously, only if required.
      const resizeModule = await import('@juggle/resize-observer');
      // @ts-ignore
      window.ResizeObserver = resizeModule.ResizeObserver;
    }
  })();
};

export default registerPolyfills;
