import { AppConfig } from '@/hooks/useAppConfig';

export const getCSSVars = (
  fonts: Record<string, { style: { fontFamily: string } }>,
  themeConfig: AppConfig['theme'],
) => {
  let rootCSSVars = Object.entries(fonts)
    .map(([key, font]) => `${key}: ${font.style.fontFamily}`)
    .join(';');

  rootCSSVars += `;
  --font-primary: ${`var(${themeConfig?.fontVariant?.primary?.name || '--font-inter'})`};
  --font-secondary: ${`var(${themeConfig?.fontVariant?.secondary?.name || '--font-poppins'})`};
  `;

  return rootCSSVars;
};
