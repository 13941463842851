import { useEffect, useRef } from 'react';

import currentUser from '@/helper/currentUser';

const useMasqueradingUser = () => {
  const userIdRef = useRef(currentUser.id());

  useEffect(() => {
    if (!userIdRef.current) {
      return;
    }
    window.addEventListener('focus', () => {
      if (userIdRef.current !== currentUser.id()) {
        window.location.reload();
      }
    });

    return () => {
      window.removeEventListener('focus', currentUser.id);
    };
  }, []);
};

export default useMasqueradingUser;
