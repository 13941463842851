import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const ANNOUNCEMENTS_PARENT_ROUTE = '/announcements';
const ADMIN_ANNOUNCEMENTS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${ANNOUNCEMENTS_PARENT_ROUTE}`;

const announcementAdminRoutes = {
  AnnouncementManager: () => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}`,
  AnnouncementCreator: () => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}/create`,
  AnnouncementDetails: (id: string) => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}/${id}/details`,
  AnnouncementReports: (id: string) => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}/${id}/reports`,
  AnnouncementEditor: (id: string) => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}/${id}/edit`,
  AnnouncementDuplicator: (id: string) => `${ADMIN_ANNOUNCEMENTS_PARENT_ROUTE}/${id}/duplicate`,
};

export default announcementAdminRoutes;
