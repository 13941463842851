export enum EventAction {
  View = 'VIEW',
  Edit = 'EDIT',
  EditRecurringEvent = 'Edit_RECURRING_EVENT',
  LearnerCancel = 'LEARNER_CANCEL',
  Duplicate = 'DUPLICATE',
  LearnerDuplicate = 'LEARNER_DUPLICATE',
  Cancel = 'CANCEL',
  ADD_TO_CALENDAR = 'ADD_TO_CALENDAR',
  ADD_TO_CALENDAR_GOOGLE = 'ADD_TO_CALENDAR_GOOGLE',
  ADD_TO_CALENDAR_OUTLOOK = 'ADD_TO_CALENDAR_OUTLOOK',
  Reschedule = 'RESCHEDULE',
  RescheduleSlot = 'RESCHEDULE_SLOT',
  Delete = 'DELETE',
}

export enum ActionDialog {
  Archive = 'ARCHIVE',
  Share = 'SHARE',
  Reschedule = 'RESCHEDULE',
  Delete = 'DELETE',
}

export enum DialogActions {
  Archive = 'ARCHIVE`',
  Close = 'CLOSE',
  Delete = 'DELETE',
}

export enum SessionTabs {
  Upcoming = 'upcoming',
  Finished = 'finished',
  Recurring = 'recurring',
}
