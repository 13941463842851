import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/quiz';
const ADMIN_QUIZ_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const quizAdminRoutes = {
  QuizCreator: () => `${ADMIN_QUIZ_PARENT_ROUTE}/create`,
  QuizEditor: (contentId: string) => `${ADMIN_QUIZ_PARENT_ROUTE}/${contentId}/edit`,
  AdminQuizDetails: (contentId: string) => `${ADMIN_QUIZ_PARENT_ROUTE}/${contentId}/details`,
  QuizPreview: (contentId: string) => `${ADMIN_QUIZ_PARENT_ROUTE}/${contentId}/preview`,
  QuizPreviewDevices: (contentId: string) =>
    `${ADMIN_QUIZ_PARENT_ROUTE}/${contentId}/preview-devices`,
  AdminQuizReports: (contentId: string) => `${ADMIN_QUIZ_PARENT_ROUTE}/${contentId}/reports`,
};

const quizLearnerRoutes = {
  QuizDetails: (contentId: string) => `${PARENT_ROUTE}/${contentId}/details`,
};

const routes = {
  ...quizAdminRoutes,
  ...quizLearnerRoutes,
};

export const QUIZ_ADMIN_ROUTE_KEYS = {
  QuizCreator: 'QuizCreator',
  QuizEditor: 'QuizEditor',
  AdminQuizDetails: 'AdminQuizDetails',
};

export const QUIZ_LEARNER_ROUTE_KEYS = {
  QuizDetails: 'QuizDetails',
};

export const QUIZ_ROUTE_KEYS = {
  ...QUIZ_ADMIN_ROUTE_KEYS,
  ...QUIZ_LEARNER_ROUTE_KEYS,
};

export default routes;
