import React, { useEffect } from 'react';

import { useChangeLanguage } from '@lyearn/i18n';
import OnBoardingFlow from '@/components/OnBoardingFlow';
import PageLayout from '@/components/PageLayout';
import useNavigation from '@/hooks/useNavigation';
import useNotification from '@/hooks/useNotification';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import useJobByCreator from '@/modules/corporate/modules/admin/hooks/useJobByCreator';
import authRoutes from '@/pages/auth/routes';

const AppRoutes: React.FC<React.PropsWithChildren> = ({ children }) => {
  const user = useUserContext();
  const navigation = useNavigation();
  useJobByCreator();
  useNotification();
  useChangeLanguage(user.meta?.locale);
  useEffect(() => {
    if (!user.id) {
      navigation.push(authRoutes.Login(), { setFrom: true, appendQuery: false });
    }
  }, [navigation, user.id]);

  if (!user.id) {
    return null;
  }

  return (
    <OnBoardingFlow>
      <PageLayout>{children}</PageLayout>
    </OnBoardingFlow>
  );
};

export default AppRoutes;
