import { requestPolicyExchange as urqlRequestPolicyExchange } from '@urql/exchange-request-policy';

import { getOperationName } from '@/utils/urql';

export const CACHE_ONLY_OPERATION_NAMES_SET = new Set<string>([
  'okrDetailsFromCache',
  'taskDetailsFromCache',
  'userTooltipFromCache',
  'skillFromCache',
  'orgRoleFromCache',
  'metricDetailsFromCache',
]);

export const requestPolicyExchange = () =>
  urqlRequestPolicyExchange({
    shouldUpgrade: (operation) =>
      !CACHE_ONLY_OPERATION_NAMES_SET.has(getOperationName(operation.query) ?? ''),
  });
