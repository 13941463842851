import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import { UserFragment, UserFragmentDoc } from '@/graphql/fragments/generated/User';
import { OkrTypeEnum } from '@/types/schema';

import {
  KeyResultOkrDetailsFragment,
  KeyResultOkrDetailsFragmentDoc,
  ObjectiveOkrDetailsFragment,
  ObjectiveOkrDetailsFragmentDoc,
} from '../../views/OKRDetails/graphql/fragments/generated/OkrDetails';
import {
  UpdateOkrDetailsMutation,
  UpdateOkrDetailsMutationVariables,
} from '../mutations/generated/updateOkrDetails';

const updateOKRDetails: OptimisticMutationResolver = (variables, cache, info) => {
  const data = (variables as UpdateOkrDetailsMutationVariables).data;
  const { okrId, ownerIds, dueDateConfig } = data;
  const okr = (cache.readFragment(ObjectiveOkrDetailsFragmentDoc, { id: okrId }) ||
    cache.readFragment(KeyResultOkrDetailsFragmentDoc, { id: okrId })) as
    | ObjectiveOkrDetailsFragment
    | KeyResultOkrDetailsFragment;

  if (!okr || (!ownerIds && !dueDateConfig)) {
    return null;
  }

  let newAssignedUser = okr.owners?.[0] as UserFragment | null;
  if (ownerIds?.set.length) {
    newAssignedUser = cache.readFragment(UserFragmentDoc, {
      id: ownerIds.set[0],
    }) as UserFragment | null;
  }

  const returnValue: UpdateOkrDetailsMutation['updateOKRDetails'] = {
    okr: {
      ...okr,
      owners: newAssignedUser ? [{ ...newAssignedUser, __typename: 'UserType' }] : [],
      dueDate: dueDateConfig?.dueDate ? new Date(dueDateConfig.dueDate).toUTCString() : okr.dueDate,
      __typename: okr.type === OkrTypeEnum.Objective ? 'ObjectiveType' : 'KeyResultType',
    },
    __typename: 'OKRPayload',
  };

  return returnValue;
};

export default updateOKRDetails;
