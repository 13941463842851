import { Cache } from '@urql/exchange-graphcache';

import { setIn } from '@lyearn/core/utils/immutable';
import { isAccessible } from '@/components/AccessControl';
import { PostFragment } from '@/features/messages/graphql/fragments/generated/Post';
import {
  CommunityChannelFragment,
  CommunityChannelFragmentDoc,
} from '@/modules/corporate/modules/community/views/CommunityDetails/graphql/fragments/generated/CommunityChannel';
import { ChannelActionsEnum, ChannelSubjectsEnum } from '@/types/schema';

interface Params {
  currentUserId: string;
  cache: Cache;
  post: PostFragment;
}

export const getCommunityChannelFragment = (cache: Cache, id: string) => {
  const channel = cache.readFragment(CommunityChannelFragmentDoc, { id });
  return channel ? (channel as CommunityChannelFragment) : null;
};

interface CanIgnoreDeletedPostParams {
  channel: CommunityChannelFragment;
  post: PostFragment;
  currentUserId: string;
}

export const canIgnoreDeletedPost = (params: CanIgnoreDeletedPostParams) => {
  const { channel, post, currentUserId } = params;
  const canSeeHiddenPosts = isAccessible(
    channel.userPermissions,
    ChannelActionsEnum.Hide,
    ChannelSubjectsEnum.Post,
  );

  const isCreatedByCurrentUser = post.createdById === currentUserId;

  return !canSeeHiddenPosts && post.hiddenBy && !isCreatedByCurrentUser;
};

const setUnreadCount = (cache: Cache, channel: CommunityChannelFragment, unreadCount: number) => {
  const updatedChannel = setIn(channel, ['channelUser', 'unreadCount'], unreadCount);
  cache.writeFragment(CommunityChannelFragmentDoc, updatedChannel);
};

export const updateUnreadCountOnPostCreate = (params: Params) => {
  const { cache, post, currentUserId } = params;
  const channel = getCommunityChannelFragment(cache, post.currentWorkspaceChannelId);

  if (!channel?.channelUser) {
    return;
  }

  const isCreatedByCurrentUser = post.createdById === currentUserId;
  const unreadCount = channel.channelUser.unreadCount || 0;

  // don't update unread count if the message is created by current user
  // and user has no unread count
  if (isCreatedByCurrentUser && !unreadCount) {
    return;
  }

  setUnreadCount(cache, channel, unreadCount + 1);
};

export const updateUnreadCountOnPostHideOrUnhide = (params: Params) => {
  const { cache, post } = params;
  const channel = getCommunityChannelFragment(cache, post.currentWorkspaceChannelId);

  if (!channel?.channelUser) {
    return;
  }

  const postCreatedAt = new Date(post.createdAt);
  const lastViewedAt = new Date(channel.channelUser.lastViewedAt);

  if (postCreatedAt < lastViewedAt) {
    return;
  }

  const isPostHidden = Boolean(post.hiddenBy);
  const unreadCount = (channel.channelUser.unreadCount || 0) + (isPostHidden ? -1 : 1);

  setUnreadCount(cache, channel, unreadCount);
};

export const updateUnreadCountOnPostDelete = (params: Params) => {
  const { cache, post, currentUserId } = params;
  const channel = getCommunityChannelFragment(cache, post.currentWorkspaceChannelId);

  if (!channel?.channelUser?.unreadCount) {
    return;
  }

  if (canIgnoreDeletedPost({ channel, post, currentUserId })) {
    return;
  }

  const postCreatedAt = new Date(post.createdAt);
  const lastViewedAt = new Date(channel.channelUser.lastViewedAt);
  const unreadCount = channel.channelUser.unreadCount;

  if (postCreatedAt > lastViewedAt) {
    setUnreadCount(cache, channel, unreadCount - 1);
  }
};
