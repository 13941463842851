import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import { UpdateFileMutation } from '../mutations/generated/updateFile';

export function updateFileMutation(): UpdateResolver {
  return (result, queryArgs, cache, info) => {
    const file = (result as UpdateFileMutation).updateFile?.file;

    if (!file) {
      invalidateQuery('files')(result, queryArgs, cache, info);
    }
  };
}
