import React, { useEffect, useState } from 'react';
import { SkipNavContent } from '@reach/skip-nav';

import { inIframe } from '@lyearn/core/utils/iframe';
import { Box } from '@lyearn/ui';
import { Header } from '@/components/Header';
import Loader from '@/components/Loader';
import user from '@/helper/currentUser';
import MasqueradingHeadline from '@/modules/learner/views/LearnerProfile/components/MasqueradingHeadline';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';

import DocumentTitle from '../DocumentTitle';
import BottomNavigation from './components/BottomNavigation';
import KeyboardAvoidance from './components/KeyboardAvoidance';
import PageLayoutContextProvider from './context';
import { PageLayoutType } from './types';

export const DEFAULT_VALUE: PageLayoutType = {
  showHeader: true,
  isDisabled: false,
  LeftNode: undefined,
  RightNode: undefined,
  SubHeader: undefined,
  showNavigationTabs: undefined,
  showBottomNav: false,
  showShadow: false,
  showBreadcrumbs: false,
  pageId: undefined,
  module: undefined,
};

const PageLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isAdminRoute = useAdminRoute();
  const [headerProps, setHeaderProps] = React.useState<PageLayoutType>(
    isAdminRoute
      ? {
          ...DEFAULT_VALUE,
          showHeader: false,
        }
      : DEFAULT_VALUE,
  );
  const { showHeader, showNavigationTabs, showBottomNav, bottomNavFixed, ...restHeaderProps } =
    headerProps;
  const isMasquerading = user.isMasquerading();
  const [hideHeaderFromIFrame, setHideHeaderFromIFrame] = useState(false);

  useEffect(() => {
    // Check if the prop is passed from the iframe
    if (inIframe()) {
      const queryParams = new URLSearchParams(window.location.search);
      const hideHeader = queryParams.get('hideHeader');
      if (hideHeader === 'true') {
        setHideHeaderFromIFrame(true);
      }
    }
  }, []);

  return (
    <PageLayoutContextProvider setHeaderProps={setHeaderProps}>
      <KeyboardAvoidance>
        {isMasquerading ? <MasqueradingHeadline /> : null}
        <DocumentTitle title={headerProps.title} />
        {showHeader && !hideHeaderFromIFrame ? (
          <Header {...restHeaderProps} showNavigationTabs={showNavigationTabs ?? !isAdminRoute} />
        ) : null}
        <React.Suspense fallback={<Loader />}>
          {isAdminRoute ? (
            children
          ) : (
            <>
              <SkipNavContent />
              <Box className="flex flex-1 flex-col xs:bg-bg-primary" component="main">
                {children}
              </Box>
            </>
          )}
        </React.Suspense>
        {showBottomNav ? (
          <React.Suspense fallback={null}>
            <BottomNavigation bottomNavFixed={bottomNavFixed} />
          </React.Suspense>
        ) : null}
      </KeyboardAvoidance>
    </PageLayoutContextProvider>
  );
};

export default PageLayout;
