import React, { useMemo } from 'react';

import { AccessControl as AccessControlType, Permissions as PermissionType } from './interface';

class ManageControl {
  _isSuperUser: boolean;

  set isSuperUser(isSuperUser: boolean) {
    // TODO:routes remove set on classroom
    this._isSuperUser = isSuperUser;
  }
  get isSuperUser() {
    return this._isSuperUser;
  }
}

export const manageControls = new ManageControl();

export const isAccessible = <T, Q>(
  permissions: NonNullable<AccessControlType<T, Q>['permissions']>,
  action: T,
  subject: Q,
): boolean => {
  return (
    manageControls.isSuperUser ||
    permissions.some(
      (permission: PermissionType<T, Q>) =>
        permission.action === action && permission.subject === subject,
    )
  );
};

export const useAccessControl = <T, Q>(
  permissions: AccessControlType<T, Q>['permissions'] | undefined | null,
  action: T,
  subject: Q,
): boolean => {
  return useMemo(() => {
    if (manageControls.isSuperUser) {
      return true;
    }
    if (!permissions || !action || !subject) {
      return false;
    }
    return isAccessible(permissions, action, subject);
  }, [action, permissions, subject]);
};

const AccessControl = <T, Q>(props: AccessControlType<T, Q> & { children: React.ReactNode }) => {
  const { action, subject, permissions, children } = props;

  const canAccess = useAccessControl(permissions, action, subject);
  return canAccess ? <>{children}</> : null;
};

export default React.memo(AccessControl);
