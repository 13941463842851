import { useCallback, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import useLocalStorage from '@lyearn/core/hooks/useLocalStorage';
import routes from '@/pages/corporate/routes';

import useNavigation from '../useNavigation';

export type AppThemesTypes = 'light' | 'dark' | 'system';

const getThemeFromPreferColorScheme = () => {
  if (typeof window === 'undefined') {
    return 'light';
  }
  const { matchMedia } = window;
  if (matchMedia) {
    const darkModeMediaQuery = matchMedia('(prefers-color-scheme: dark)');
    if (darkModeMediaQuery.matches) {
      return 'dark';
    }
  }

  return 'light';
};

const systemMode = getThemeFromPreferColorScheme();

// these pages where using dark mode already, so there is a compatibility issue
// todo: @iamsaumya make sessions compatible with the new theme
const bailoutPages = [
  routes.LiveSession('[eventId]'),
  routes.RecordingEvent('[eventId]'),
  routes.StreamingEvent('[eventId]'),
];

export const getAppliedTheme = () => {
  if (typeof window === 'undefined') return 'light';
  const metaElement = document.querySelector('meta[name="color-scheme"]');

  return (metaElement?.getAttribute('content') || 'light') as 'light' | 'dark';
};

const useAppColorScheme = ({ isDarkModeAllowed }: { isDarkModeAllowed?: boolean }) => {
  const navigation = useNavigation();

  const [appUITheme, setAppUITheme] = useLocalStorage<AppThemesTypes>(
    'theme',
    isDarkModeAllowed ? 'system' : 'light',
  );

  const onThemeChange = useCallback(() => {
    if (appUITheme === 'system') {
      // switch to the opposite of the system theme
      setAppUITheme(systemMode === 'light' ? 'dark' : 'light');

      return;
    } else {
      // switch to the opposite of the current theme
      setAppUITheme(appUITheme === 'light' ? 'dark' : 'light');

      return;
    }
  }, [appUITheme, setAppUITheme]);

  useHotkeys('meta+alt+t', onThemeChange, [onThemeChange]);

  const appliedTheme = bailoutPages.includes(navigation.pathname)
    ? 'light'
    : appUITheme === 'system'
    ? systemMode
    : appUITheme;

  useEffect(() => {
    if (appliedTheme === 'dark') {
      document.documentElement.classList.add('darkMode');
      // added for tailwind
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('darkMode');
      document.documentElement.classList.remove('dark');
    }
  }, [appliedTheme]);

  return { appliedTheme };
};

export default useAppColorScheme;
