import { css } from '@emotion/css';

import { cn } from '@lyearn/style';

export const containerStyles = css`
  width: 280px;
  max-height: 220px;
  height: max-content;
`;

const contentStyles = css`
  width: 280px;
  max-width: unset;
  max-height: 220px;
  min-height: 150px;
  height: max-content;
`;

export const TOOLTIP_CLASSES = {
  tooltip: cn('rounded-16 p-0', contentStyles),
};
