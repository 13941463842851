import {
  ActionPlanResolvedOwner_UserType_Fragment,
  ActionPlanResolvedOwnerFragment,
} from '../graphql/fragments/generated/ActionPlanResolvedOwner';

const PERFORMANCE_REVIEW = 'performanceReview';
const USER_SURVEY = 'survey';

class ActionPlanEntity {
  static doesOwnerExist(
    resolvedOwner?: ActionPlanResolvedOwnerFragment | null | undefined,
  ): resolvedOwner is ActionPlanResolvedOwnerFragment {
    return !!resolvedOwner;
  }

  static isOwnerUser(
    resolvedOwner?: ActionPlanResolvedOwnerFragment | null | undefined,
  ): resolvedOwner is ActionPlanResolvedOwner_UserType_Fragment {
    return resolvedOwner?.__typename === 'UserType';
  }

  static assertOwnerIsUser(
    resolvedOwner?: ActionPlanResolvedOwnerFragment | null | undefined,
  ): asserts resolvedOwner is ActionPlanResolvedOwner_UserType_Fragment {
    if (!ActionPlanEntity.doesOwnerExist(resolvedOwner)) {
      throw new Error('Action plan owner does not exist');
    }
    if (!ActionPlanEntity.isOwnerUser(resolvedOwner)) {
      throw new Error('Action plan owner is not of type users');
    }
  }

  static getOwnerUser(resolvedOwner?: ActionPlanResolvedOwnerFragment | null | undefined) {
    if (ActionPlanEntity.isOwnerUser(resolvedOwner)) {
      return resolvedOwner;
    }

    return null;
  }

  static getFirstParentUserSurveyId(parentEntities: string[]) {
    const parentEntity = parentEntities.find((parentEntity) =>
      parentEntity.startsWith(USER_SURVEY),
    );

    if (!parentEntity) {
      throw new Error('User survey id not found in parent entities');
    }

    return parentEntity.split(':')[1];
  }

  static getFirstParentPerformanceReviewId(parentEntities: string[]) {
    const parentEntity = parentEntities.find((parentEntity) =>
      parentEntity.startsWith(PERFORMANCE_REVIEW),
    );

    if (!parentEntity) {
      throw new Error('Performance review id not found in parent entities');
    }

    return parentEntity.split(':')[1];
  }

  static getParentEntityFromUserSurveyId(userSurveyId: string) {
    return `${USER_SURVEY}:${userSurveyId}`;
  }

  static getParentEntityFromPerformanceReviewId(userSurveyId: string) {
    return `${PERFORMANCE_REVIEW}:${userSurveyId}`;
  }

  static isSomeParentEntityPerformanceReview(parentEntities: string[]) {
    return parentEntities.some((parentEntity) => parentEntity.startsWith(PERFORMANCE_REVIEW));
  }
}

export default ActionPlanEntity;
