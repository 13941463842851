import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import { jsonStringify } from '@lyearn/core/utils/json';
import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  MeetingInstanceDocument,
  MeetingInstanceQuery,
  MeetingInstanceQueryVariables,
} from '@/modules/1on1s/graphql/queries/generated/meetingInstance';
import { ActionItemsQueryVariables } from '@/modules/tasks/features/actionItems/graphql/queries/generated/actionItems';
import { AgendaItemSubscriptionOperationEnum, AgendaItemSubscriptionPayload } from '@/types/schema';

import { AgendaItemFragment } from '../../fragment/generated/AgendaItem';

const handleAgendaItemUpdate = (cache: Cache, agendaItem?: AgendaItemFragment | null) => {
  if (!agendaItem) return;

  inspectCache(cache, 'Query', 'meetingInstance', (field) => {
    const args = field.arguments as MeetingInstanceQueryVariables;
    updateCache<MeetingInstanceQuery, MeetingInstanceQueryVariables>(
      cache,
      MeetingInstanceDocument,
      args,
      (data) => {
        const meetingInstance = data?.meetingInstance?.meetingInstance;
        if (!meetingInstance) return data;

        const agendaItems = meetingInstance?.agendaItems;
        if (!agendaItems) return data;

        const updatedAgendaItems = agendaItems.map((item) => {
          if (item.id === agendaItem.id) {
            return {
              ...item,
              ...agendaItem,
            };
          }

          return item;
        });

        const updatedMeetingInstanceQuery: MeetingInstanceQuery = {
          ...data,
          meetingInstance: {
            ...data.meetingInstance,
            meetingInstance: {
              ...meetingInstance,
              agendaItems: updatedAgendaItems,
            },
          },
        };

        return updatedMeetingInstanceQuery;
      },
    );
  });
};

export function getAgendaItemUpdater(): UpdateResolver {
  return (result, args, cache, info) => {
    const actionItemsPayload = result.agendaItems as AgendaItemSubscriptionPayload;
    const operationType = actionItemsPayload.operation;
    const agendaItem = actionItemsPayload.agendaItem;
    const meetingInstanceId = agendaItem.entity.split(':')[1];
    const listOperationName = 'actionItems';

    const invalidateActionItemsFetchedForMeetingInstance = () => {
      cache
        .inspectFields('Query')
        .filter((q) => {
          return q.fieldName === listOperationName;
        })
        .forEach((query) => {
          const args = query.arguments as ActionItemsQueryVariables;
          const { data: argsData } = jsonStringify(args);
          if (argsData?.includes(meetingInstanceId)) {
            cache.invalidate('Query', query.fieldName, args);
          }
        });
    };

    switch (operationType) {
      case AgendaItemSubscriptionOperationEnum.AgendaItemUpdated: {
        handleAgendaItemUpdate(cache, agendaItem as AgendaItemFragment);
        break;
      }
      case AgendaItemSubscriptionOperationEnum.ActionItemAdded: {
        invalidateActionItemsFetchedForMeetingInstance();
        break;
      }
      case AgendaItemSubscriptionOperationEnum.ActionItemRemoved: {
        invalidateActionItemsFetchedForMeetingInstance();
        break;
      }
      default:
        return;
    }
  };
}
