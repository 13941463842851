import { OptimisticMutationResolver } from '@urql/exchange-graphcache';
import { keyBy } from 'lodash';

import { InputMaybe, Scalars } from '@/types/schema';

import { AgendaItemFragment, AgendaItemFragmentDoc } from '../fragment/generated/AgendaItem';
import {
  UpdateAgendaItemMutation,
  UpdateAgendaItemMutationVariables,
} from '../mutations/generated/updateAgendaItem';

const updateList = (
  currentIds?: string[] | null,
  addIds?: InputMaybe<Array<Scalars['String']['input']>>,
  pullIds?: InputMaybe<Array<Scalars['String']['input']>>,
) => {
  const presentIds = currentIds ?? [];
  let newIds: string[] = [];
  if (addIds) {
    newIds = [...presentIds, ...addIds];
  } else if (pullIds) {
    const keyedById = keyBy(pullIds, (id) => id);
    newIds = presentIds.filter((id) => !keyedById[id]);
  }
  return newIds;
};

const updateAgendaItem: OptimisticMutationResolver = (variables, cache, info) => {
  const data = (variables as UpdateAgendaItemMutationVariables).data;
  const { agendaItemId, ...rest } = data;
  const agendaItem = cache.readFragment(AgendaItemFragmentDoc, {
    id: agendaItemId,
  }) as AgendaItemFragment | null;

  if (!agendaItem) {
    return null;
  }

  const returnValue: UpdateAgendaItemMutation['updateAgendaItem'] = {
    __typename: 'AgendaItemType',
    ...agendaItem,
    id: agendaItem.id,
    title: rest.title ?? agendaItem.title,
    instruction: {
      __typename: 'InstructionType',
      medias: [],
      text: rest.instruction?.text ?? agendaItem?.instruction?.text ?? '',
    },
    attachments:
      agendaItem.attachments?.map((attachment) => ({
        __typename: 'EntityMediaType',
        ...attachment,
      })) ?? [],
    isChecked: rest.isChecked ?? agendaItem.isChecked,
    subType: rest.subType ?? agendaItem.subType,
    updatedAt: new Date().toISOString(),
  };

  if (rest.activityMetricIds) {
    returnValue.activityMetricIds = updateList(
      agendaItem.activityMetricIds,
      rest.activityMetricIds.add,
      rest.activityMetricIds.pull,
    );
  }

  if (rest.mindsetIds) {
    returnValue.mindsetIds = updateList(
      agendaItem.mindsetIds,
      rest.mindsetIds.add,
      rest.mindsetIds.pull,
    );
  }

  if (rest.skillIds) {
    returnValue.skillIds = updateList(agendaItem.skillIds, rest.skillIds.add, rest.skillIds.pull);
  }

  return returnValue;
};

export default updateAgendaItem;
