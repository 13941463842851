export enum ReportsTab {
  Summary = 'summary',
  Insights = 'insights',
  Reviewees = 'reviewees',
  Questions = 'questions',
  SkillGroups = 'skill-groups',
  Mindsets = 'mindsets',
  FlaggedItems = 'flagged-items',
  Cadence = 'cadence',
  ActionPlan = 'action-plan',
}
