// libs
import { UpdatesConfig } from '@urql/exchange-graphcache';

import { getUserBellNotificationsUpdater } from '@/components/Header/components/NotificationButton/graphql/subscription/updater/userBellNotificationsSubscription';
// updaters
import { getChannelPostsUpdater } from '@/features/messages/graphql/subscriptions/updaters/ChannelPostsUpdater';
import { getAgendaItemUpdater } from '@/modules/1on1s/components/Agendas/graphql/subscription/updater/getAgendaItemUpdater';
import { getMeetingInstanceUpdater } from '@/modules/1on1s/graphql/subscription/updater/getMeetingInstanceUpdater';
import { getMeetingUpdater } from '@/modules/1on1s/graphql/subscription/updater/getMeetingUpdater';
import { getPerformanceUpdater } from '@/modules/corporate/graphql/subscription/updater/contentPerformance';
import { getOKRJobUpdater } from '@/modules/goals/modules/okr/views/OKRDetails/graphql/subscription/updater/getOKRJobUpdater';
import { getActionItemUpdater } from '@/modules/tasks/features/actionItems/graphql/subscription/updater/getActionItemUpdater';

import { invalidateQuery } from './cacheHelpers';

const cacheSubscription: UpdatesConfig['Subscription'] = {
  channelPosts: getChannelPostsUpdater(),
  syncPerformance: getPerformanceUpdater(),
  events: invalidateQuery('contentPerformances'),
  meetingInstances: getMeetingInstanceUpdater(),
  meetings: getMeetingUpdater(),
  actionItems: getActionItemUpdater(),
  agendaItems: getAgendaItemUpdater(),
  userBellNotification: getUserBellNotificationsUpdater(),
  job: getOKRJobUpdater(),
};
export default cacheSubscription;
