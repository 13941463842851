import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import { QuizQuestionActionEnum } from '@/types/schema';

import {
  QuizEditorCacheUpdaterFragment,
  QuizEditorCacheUpdaterFragmentDoc,
} from '../fragments/generated/QuizEditorCacheUpdater';
import {
  QuizEditorRemoveQuestionsMutation,
  QuizEditorRemoveQuestionsMutationVariables,
} from '../mutations/generated/removeQuestions';
import { getQuizEditorCacheKey } from './utils';

const addRemoveQuizQuestionsUpdater: UpdateResolver<
  QuizEditorRemoveQuestionsMutation,
  QuizEditorRemoveQuestionsMutationVariables
> = (result, args, cache, info) => {
  const quiz = cache.readFragment<QuizEditorCacheUpdaterFragment>(
    QuizEditorCacheUpdaterFragmentDoc,
    { id: getQuizEditorCacheKey(args.data.quizId), __typename: 'QuizContentType' },
  );

  if (!quiz || !result.addRemoveQuizQuestions?.success) {
    invalidateQuery(['content'])(result, args, cache, info);
    return;
  }

  let questions = quiz.questions;
  let quizQuestions = quiz.quizQuestions;

  args.data.questionConfigs.forEach((questionConfig) => {
    if (questionConfig.action === QuizQuestionActionEnum.Remove) {
      questions = questions.filter((q) => q.id !== questionConfig.questionId);
      quizQuestions = quizQuestions.filter((q) => q.questionId !== questionConfig.questionId);
    }
  });

  if (questions.length === quiz.questions.length) {
    return;
  }

  cache.writeFragment<QuizEditorCacheUpdaterFragment>(QuizEditorCacheUpdaterFragmentDoc, {
    __typename: 'QuizContentType',
    id: quiz.id,
    versionStatus: quiz.versionStatus,
    quizQuestions,
    questions,
  });
};

export default addRemoveQuizQuestionsUpdater;
