import { UpdateResolver } from '@urql/exchange-graphcache';
import includes from 'lodash/includes';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import { CreateCategoryMutationVariables } from '@/modules/corporate/modules/discover/views/Category/graphql/mutations/generated/createCategory';
import {
  CategoriesDocument,
  CategoriesQuery,
  CategoriesQueryVariables,
} from '@/modules/corporate/modules/discover/views/Category/graphql/queries/generated/categories';
import { FilePurposeEnum } from '@/types/schema';

import { CreateFileMutation } from '../mutations/generated/createFile';
import { FilesDocument, FilesQuery, FilesQueryVariables } from '../queries/generated/files';

export function createFileMutation(): UpdateResolver {
  return (result, _queryArgs, cache) => {
    const file = (result as CreateFileMutation).createFile?.file;
    if (!file) {
      return;
    }

    inspectCache(cache, 'Query', 'files', (field) => {
      const args = field.arguments as FilesQueryVariables;
      if (args.filters?.systemGenerated) {
        return;
      }

      // Update cache for categories
      if (args.filters?.purpose?.includes(FilePurposeEnum.LibraryCategory)) {
        const queryArgs = _queryArgs as CreateCategoryMutationVariables;
        updateCache<CategoriesQuery, CategoriesQueryVariables>(
          cache,
          CategoriesDocument,
          // @ts-ignore
          args,
          (data) => {
            if (
              data?.categories?.edges &&
              !data.categories.edges.find((edge) => edge.node.id === file.id)
            ) {
              data.categories.edges.push({
                node: file,
                __typename: 'FileEdge',
              });
            }

            const parent = queryArgs.data.parentId
              ? data?.categories?.edges?.find(({ node }) => node.id === queryArgs.data.parentId)
                  ?.node
              : null;

            if (parent) {
              if (parent.childrenIds && !includes(parent.childrenIds, file.id)) {
                parent?.childrenIds.push(file.id);
              } else {
                parent.childrenIds = [file.id];
              }
            } else if (
              data?.rootCategory?.edges?.length &&
              !includes(data.rootCategory.edges[0].node.childrenIds, file.id)
            ) {
              data.rootCategory.edges[0].node.childrenIds?.push(file.id);
            }

            return data;
          },
        );
      } else {
        // update cache for files
        updateCache<FilesQuery, FilesQueryVariables>(cache, FilesDocument, args, (data) => {
          if (
            data?.files?.edges &&
            !data?.files?.edges?.find((edge) => edge?.node?.id === file.id)
          ) {
            data?.files?.edges.push({
              node: file,
              __typename: 'FileEdge',
            });
          }
          return data;
        });
      }
    });
  };
}
