import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/staff-roles';
const ADMIN_STAFF_ROLE_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const staffRoleAdminRoutes = {
  StaffRoleCreator: () => `${ADMIN_STAFF_ROLE_PARENT_ROUTE}/create`,
  StaffRoleManager: () => `${ADMIN_STAFF_ROLE_PARENT_ROUTE}`,
  StaffRoleEditor: (staffRoleId: string) => `${ADMIN_STAFF_ROLE_PARENT_ROUTE}/${staffRoleId}/edit`,
  StaffRoleDetails: (staffRoleId: string) =>
    `${ADMIN_STAFF_ROLE_PARENT_ROUTE}/${staffRoleId}/details`,
  StaffRoleDuplicator: (staffRoleId: string) =>
    `${ADMIN_STAFF_ROLE_PARENT_ROUTE}/${staffRoleId}/duplicate`,
};

const staffRoleLearnerRoutes = {};

const routes = {
  ...staffRoleAdminRoutes,
  ...staffRoleLearnerRoutes,
};

export const STAFF_ROLE_ADMIN_ROUTE_KEYS = {
  StaffRoleCreator: 'StaffRoleCreator',
  StaffRoleDetails: 'StaffRoleDetails',
  StaffRoleManager: 'StaffRoleManager',
  StaffRoleEditor: 'StaffRoleEditor',
  StaffRoleDuplicator: 'StaffRoleDuplicator',
};

export const STAFF_ROLE_LEARNER_ROUTE_KEYS = {};

export const STAFF_ROLE_ROUTE_KEYS = {
  ...STAFF_ROLE_ADMIN_ROUTE_KEYS,
  ...STAFF_ROLE_LEARNER_ROUTE_KEYS,
};

export default routes;
