import { Resolver } from '@urql/exchange-graphcache';

import { UserTooltipFromCacheQueryVariables } from '../queries/generated/userTooltip';

export const userDetailsResolver: Resolver<any, UserTooltipFromCacheQueryVariables, any> = (
  parent,
  args,
) => {
  return { __typename: 'UserType', id: args.filters.userId };
};
