import { useCallback, useState } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';

import { FetchingStatus } from '../../interfaces';
import { request } from '../request';

/* interface UseRestProps {
  endpoint: string;
  fetchOptions: any;
  extraOptions: any;
  callback?: Function;
  responseType: 'json' | 'text';
} */

/* const stringifyBody = (body: string | object | undefined) =>
  body && typeof body !== 'string' ? JSON.stringify(body) : body; */

const FETCH_API_OPTIONS = {
  method: 'POST',
  headers: { 'content-type': 'application/json; charset=utf-8' },
};

/*  Use this Hook to fetch data from Rest APIs
    Arguments
    endpoint -> rest API endpoing, if you provide only a /path, it will add prefix the default backend URL
    fetchOptions -> options to pass to `fetch`, for example, headers, method etc
*/
function useRest(
  endpoint: string,
  fetchOptions: object = FETCH_API_OPTIONS,
): [FetchingStatus, object | null, object | null, Function] {
  const [status, setStatus] = useState(FetchingStatus.NotStarted);
  const [data, setData] = useState(null);
  const [error, setError] = useState<Error | null>(null);

  const execute = useCallback(
    async (requestFetchOptions?: object) => {
      setStatus(FetchingStatus.Fetching);
      let dataResponse;
      const newFetchOptions = { ...fetchOptions, ...requestFetchOptions };
      try {
        const response = await request(endpoint, newFetchOptions);
        dataResponse = await response.json();
        if (dataResponse.error) {
          setError(dataResponse.error);
        }
        setData(dataResponse);
        setStatus(FetchingStatus.Done);
        return dataResponse;
      } catch (error) {
        setError(error as Error);
        setStatus(FetchingStatus.Done);
        ErrorReporter.error(error);
        console.error('Error in useRest:', error);
        return null;
      }
    },
    [endpoint, fetchOptions],
  );

  return [status, error, data, execute];
}

export default useRest;
