import {
  AToZOutline,
  Calendar2Outline,
  CheckmarkCircle2Outline,
  ClockOutline,
  Edit2Outline,
  ExternalLinkOutline,
  Flag2Outline,
  FlagFill,
  ListOutline,
  PeopleOutline,
  PersonOutline,
  SocialJira,
  TogglelistOutline,
} from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Stack } from '@lyearn/ui';
import Emoji from '@/components/Emoji';
import { IconSettingsInput, IconTypeEnum } from '@/types/schema';
import { IconPropsType } from '@/utils/icon';

export type IconType = (props: IconPropsType & { progress?: number | null }) => JSX.Element;

interface IconSettingsProps {
  Icon: IconType;
  iconSettings: IconSettingsInput;
  size?: number;
  className?: string;
  progress?: number | null;
}

const MAPPED_ICON: Partial<Record<string, IconType>> = {
  CheckmarkCircle2Outline: CheckmarkCircle2Outline,
  PeopleOutline: PeopleOutline,
  ListOutline: ListOutline,
  Edit2Outline: Edit2Outline,
  Flag2Outline: Flag2Outline,
  Calendar2Outline: Calendar2Outline,
  PersonOutline: PersonOutline,
  ClockOutline: ClockOutline,
  ExternalLinkOutline: ExternalLinkOutline,
  ToggleListOutline: TogglelistOutline,
  SocialJira: SocialJira,
  AToZOutline: AToZOutline,
};

export const IconSettings = (props: IconSettingsProps) => {
  const { Icon, iconSettings, size = 20, className, progress } = props;
  const { type, value } = iconSettings;

  if (type === IconTypeEnum.Color) {
    return (
      <Icon
        className={cn('flex-shrink-0', className)}
        color={value}
        height={size}
        progress={progress}
        width={size}
      />
    );
  }

  if (type === IconTypeEnum.Icon) {
    const IconComp = MAPPED_ICON[value] || Icon;

    return (
      <IconComp
        className={cn('flex-shrink-0', className)}
        color={value}
        height={size}
        width={size}
      />
    );
  }

  return <Emoji class={className} emoji={value} size={size} />;
};

const ListItemIconSettings = (props: IconSettingsProps) => {
  const { className, ...rest } = props;

  return (
    <Stack
      alignItems="center"
      className={cn(
        'h-48 w-48 flex-shrink-0 rounded-full border border-border bg-bg-primary',
        className,
      )}
      justifyContent="center">
      <IconSettings {...rest} />
    </Stack>
  );
};

const getIconSettingsComponents = (Icon: typeof FlagFill) => {
  const IconSettingsComponent = (props: Omit<IconSettingsProps, 'Icon'>) => (
    <IconSettings Icon={Icon} {...props} />
  );

  const ListItemIconSettingsComponent = (props: Omit<IconSettingsProps, 'Icon'>) => (
    <ListItemIconSettings Icon={Icon} {...props} />
  );

  return [IconSettingsComponent, ListItemIconSettingsComponent] as const;
};

export default getIconSettingsComponents;
