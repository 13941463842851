import { useCallback } from 'react';

import { inIframe } from '@lyearn/core/utils/iframe';

import useNavigation from '../useNavigation';

export default function useLinking() {
  const navigation = useNavigation();
  const openLink = useCallback(
    async (url?: string) => {
      if (!url) {
        return;
      }

      if (navigation && inIframe()) {
        return navigation?.push(url, { appendQuery: false });
      }

      return window.open(url);
    },
    [navigation],
  );
  return { openLink };
}
