import { Cache, ResolveInfo, UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  SkillDetailsFragment,
  SkillDetailsFragmentDoc,
} from '@/modules/skill-matrix/graphql/fragment/generated/SkillDetails';
import {
  EditSkillGroupInTrackInput,
  UpdateTrackMetricsInput,
  UpdateTrackMindsetsInput,
  UpdateTrackSkillGroupsInput,
  UpdateTrackSkillsInput,
} from '@/types/schema';

import {
  UpdateTrackDetailsMutation,
  UpdateTrackDetailsMutationVariables,
} from '../mutations/generated/updateTrackDetails';
import {
  MetricTrackDetailsFragment,
  MetricTrackDetailsFragmentDoc,
  TrackDetailsActivitiesFragment,
  TrackDetailsActivitiesFragmentDoc,
} from '../queries/generated/trackDetailsActivities';
import {
  TrackDetailsMindsetFragment,
  TrackDetailsMindsetFragmentDoc,
} from '../queries/generated/trackDetailsMindsets';
import {
  SkillGroupTrackDetailsFragment,
  TrackDetailsSkillsFragment,
  TrackDetailsSkillsFragmentDoc,
} from '../queries/generated/trackDetailsSkills';

export const removeMindsetsFromTrack = ({
  mindsetsIdsToRemove,
  track,
  cache,
}: {
  mindsetsIdsToRemove: string[];
  cache: Cache;
  track: TrackDetailsMindsetFragment;
}) => {
  if (track.mindsetIds) {
    track.mindsetIds = track.mindsetIds.filter((id) => !mindsetsIdsToRemove.includes(id));
  }

  if (track.mindsets) {
    track.mindsets = track.mindsets.filter((mindset) => !mindsetsIdsToRemove.includes(mindset.id));
  }

  if (track.orgRoles) {
    track.orgRoles = track.orgRoles.map((role) => {
      if (role.mindsetIds) {
        role.mindsetIds = role.mindsetIds.filter(
          (mindsetId) => !mindsetsIdsToRemove.includes(mindsetId),
        );
      }
      return role;
    });
  }
  cache.writeFragment(TrackDetailsMindsetFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const addMindsetsToTrack = ({
  mindsetsToAdd,
  track,
  cache,
}: {
  mindsetsToAdd: SkillDetailsFragment[];
  cache: Cache;
  track: TrackDetailsMindsetFragment;
}) => {
  const mindsetsIdsToAdd = mindsetsToAdd.map((mindset) => mindset.id);

  if (track.mindsetIds) {
    track.mindsetIds = [...track.mindsetIds, ...mindsetsIdsToAdd];
  }

  if (track.orgRoles) {
    track.orgRoles = track.orgRoles.map((role) => {
      if (role.mindsetIds) {
        role.mindsetIds = [...role.mindsetIds, ...mindsetsIdsToAdd];
      }
      return role;
    });
  }

  if (track.mindsets) {
    track.mindsets = [
      ...track.mindsets,
      ...mindsetsToAdd.map<SkillDetailsFragment>((mindset) => ({
        ...mindset,
        __typename: 'SkillType',
      })),
    ] as SkillDetailsFragment[];
  }

  cache.writeFragment(TrackDetailsMindsetFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const updateMindsetsInsideTrack = ({
  track,
  mindsets,
  cache,
  result,
}: {
  mindsets: UpdateTrackMindsetsInput;
  cache: Cache;
  result: UpdateTrackDetailsMutation;
  track: TrackDetailsMindsetFragment;
}) => {
  if (mindsets?.remove?.length) {
    const idsToRemove = mindsets.remove;
    removeMindsetsFromTrack({ track, mindsetsIdsToRemove: idsToRemove, cache });
  } else if (mindsets?.add?.length) {
    const idsToAdd = mindsets.add;
    const mindsetsToAdd = idsToAdd.map((id) =>
      cache.readFragment(SkillDetailsFragmentDoc, { id }),
    ) as SkillDetailsFragment[];
    addMindsetsToTrack({ track, mindsetsToAdd, cache });
  } else if (mindsets?.toCreateMindsets?.length) {
    const createdMindsets = result.updateTrack.createdMindsets ?? [];
    addMindsetsToTrack({ track, mindsetsToAdd: createdMindsets, cache });
  }
};

export const addMetricToTrack = ({
  metricToAdd,
  track,
  cache,
}: {
  metricToAdd: MetricTrackDetailsFragment[];
  cache: Cache;
  track: TrackDetailsActivitiesFragment;
}) => {
  const idsToAdd = metricToAdd.map((metric) => metric.id);

  if (track.metricIds) {
    track.metricIds = [...track.metricIds, ...idsToAdd];
  }

  if (track.metrics) {
    track.metrics = [
      ...track.metrics,
      ...metricToAdd.map<MetricTrackDetailsFragment>((metric) => ({
        ...metric,
        __typename: 'MetricSchema',
      })),
    ];
  }

  cache.writeFragment(TrackDetailsActivitiesFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const updateMetricInsideTrack = ({
  metrics,
  cache,
  track,
  result,
  args,
  info,
}: {
  metrics: UpdateTrackMetricsInput;
  cache: Cache;
  result: UpdateTrackDetailsMutation;
  track: TrackDetailsActivitiesFragment;
  args: UpdateTrackDetailsMutationVariables;
  info: ResolveInfo;
}) => {
  if (metrics?.remove?.length) {
    invalidateQuery('track')(result, args, cache, info);
  } else if (metrics?.add?.length) {
    const idsToAdd = metrics.add;
    const metricToAdd = idsToAdd.map((id) =>
      cache.readFragment(MetricTrackDetailsFragmentDoc, { id }),
    ) as MetricTrackDetailsFragment[];
    addMetricToTrack({ metricToAdd, track, cache });
  } else if (metrics?.toCreateMetrics?.length) {
    const metricToAdd = result.updateTrack.createdMetrics ?? [];
    addMetricToTrack({ metricToAdd, track, cache });
  }
};

export const addSkillsInsideTrack = ({
  skillsToAdd,
  track,
  cache,
  skillGroupId,
}: {
  skillsToAdd: SkillDetailsFragment[];
  cache: Cache;
  track: TrackDetailsSkillsFragment;
  skillGroupId: string;
}) => {
  const skillIds = skillsToAdd.map((skill) => skill.id);

  if (track.skillMatrix) {
    track.skillMatrix.skillGroups = track.skillMatrix?.skillGroups?.map((group) => {
      if (group._id === skillGroupId) {
        return {
          ...group,
          skills: [...(group.skills ?? []), ...skillsToAdd],
          __typename: 'SkillGroupType',
        };
      }
      return group;
    });
  }

  if (track.orgRoles) {
    track.orgRoles.forEach((role) => {
      skillIds.forEach(
        (_skillId) =>
          role.skillMatrixConfig?.skillMasteryConfigs.push({
            __typename: 'SkillMasteryConfig',
            skillId: _skillId,
            expectedScore: 1,
          }),
      );
    });
  }

  cache.writeFragment(TrackDetailsSkillsFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const removeSkillsFromTrack = ({
  track,
  skillIdsToRemove,
  cache,
  skillGroupId,
}: {
  track: TrackDetailsSkillsFragment;
  skillIdsToRemove: string[];
  cache: Cache;
  skillGroupId: string;
}) => {
  if (track.skillMatrix) {
    track.skillMatrix.skillGroups = track.skillMatrix?.skillGroups?.map((group) => {
      if (group._id === skillGroupId) {
        return {
          ...group,
          skills: group.skills?.filter((skill) => !skillIdsToRemove.includes(skill.id)),
          __typename: 'SkillGroupType',
        };
      }
      return group;
    });
  }

  cache.writeFragment(TrackDetailsSkillsFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const updateSkillsInsideTrack = ({
  skills,
  cache,
  track,
  result,
}: {
  skills: UpdateTrackSkillsInput;
  cache: Cache;
  result: UpdateTrackDetailsMutation;
  track: TrackDetailsSkillsFragment;
}) => {
  if (skills?.add?.length) {
    skills.add.map(({ skillGroupId, skillIds }) => {
      const skillsToAdd = skillIds
        .map((id) => cache.readFragment(SkillDetailsFragmentDoc, { id }))
        .map((skill) => ({ ...skill, __typename: 'SkillType' })) as SkillDetailsFragment[];

      addSkillsInsideTrack({ skillGroupId, skillsToAdd, track, cache });
    });
  } else if (skills?.remove?.length) {
    skills.remove.map(({ skillGroupId, skillIds }) => {
      removeSkillsFromTrack({ skillIdsToRemove: skillIds, track, cache, skillGroupId });
    });
  } else if (skills?.toCreateSkills?.length) {
    const payloadCreatedSkill = skills.toCreateSkills?.[0];
    const createdSkill = result.updateTrack.createdSkills?.[0];
    if (!payloadCreatedSkill || !createdSkill) {
      return;
    }
    const skillGroupId = payloadCreatedSkill.skillGroupId;
    addSkillsInsideTrack({ skillGroupId, track, cache, skillsToAdd: [createdSkill] });
  }
};

export const addSkillGroupsToTrack = ({
  track,
  skillGroupsToAdd,
  cache,
}: {
  track: TrackDetailsSkillsFragment;
  skillGroupsToAdd: SkillGroupTrackDetailsFragment[];
  cache: Cache;
}) => {
  if (track.skillMatrix?.skillGroups) {
    track.skillMatrix.skillGroups = [...track.skillMatrix.skillGroups, ...skillGroupsToAdd];
  }

  cache.writeFragment(TrackDetailsSkillsFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const editSkillGroupsInsideTrack = ({
  track,
  cache,
  editSkillGroups,
}: {
  track: TrackDetailsSkillsFragment;
  cache: Cache;
  editSkillGroups: EditSkillGroupInTrackInput[];
}) => {
  if (track.skillMatrix?.skillGroups) {
    track.skillMatrix.skillGroups = track.skillMatrix.skillGroups.map((group) => {
      const updatedGroup = editSkillGroups.find(({ skillGroupId }) => skillGroupId === group._id);

      if (!updatedGroup) {
        return group;
      }

      return {
        ...group,
        name: updatedGroup.name,
      };
    });
  }

  cache.writeFragment(TrackDetailsSkillsFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const removeSkillGroupsFromTrack = ({
  track,
  skillGroupsToRemove,
  cache,
}: {
  track: TrackDetailsSkillsFragment;
  skillGroupsToRemove: string[];
  cache: Cache;
}) => {
  if (track.skillMatrix?.skillGroups) {
    track.skillMatrix.skillGroups = track.skillMatrix.skillGroups.filter(
      (group) => !skillGroupsToRemove.includes(group._id ?? ''),
    );
  }

  cache.writeFragment(TrackDetailsSkillsFragmentDoc, { ...track, __typename: 'TrackType' });
};

export const updateSkillGroupsInsideTrack = ({
  track,
  cache,
  skillGroups,
  result,
}: {
  track: TrackDetailsSkillsFragment;
  cache: Cache;
  skillGroups: UpdateTrackSkillGroupsInput;
  result: UpdateTrackDetailsMutation;
}) => {
  if (skillGroups?.toCreateSkillGroups) {
    const skillGroupsToAdd = result.updateTrack.createdSkillGroups;
    if (!skillGroupsToAdd) {
      return;
    }
    addSkillGroupsToTrack({ skillGroupsToAdd, cache, track });
  } else if (skillGroups.toEditSkillGroups) {
    const { toEditSkillGroups: editSkillGroups } = skillGroups;
    editSkillGroupsInsideTrack({ editSkillGroups, cache, track });
  } else if (skillGroups.remove?.length) {
    const { remove: skillGroupsToRemove } = skillGroups;

    removeSkillGroupsFromTrack({ skillGroupsToRemove, cache, track });
  }
};

const updateTrackDetails: UpdateResolver<
  UpdateTrackDetailsMutation,
  UpdateTrackDetailsMutationVariables
> = (result, args, cache, info) => {
  const input = args.data;

  if (input.mindsets) {
    const { trackId, mindsets } = input;

    const track = cache.readFragment<TrackDetailsMindsetFragment>(TrackDetailsMindsetFragmentDoc, {
      id: trackId,
    });
    if (!track) {
      return;
    }

    updateMindsetsInsideTrack({ cache, track, mindsets, result });
  } else if (input.metrics) {
    const { trackId, metrics } = input;

    const track = cache.readFragment<TrackDetailsActivitiesFragment>(
      TrackDetailsActivitiesFragmentDoc,
      {
        id: trackId,
        __typename: 'TrackType',
      },
    );
    if (!track) {
      return;
    }

    updateMetricInsideTrack({ cache, track, metrics, result, args, info });
  } else if (input.skills) {
    const { trackId, skills } = input;

    const track = cache.readFragment<TrackDetailsSkillsFragment>(TrackDetailsSkillsFragmentDoc, {
      id: trackId,
      __typename: 'TrackType',
    });
    if (!track) {
      return null;
    }

    updateSkillsInsideTrack({ track, result, cache, skills });
  } else if (input.skillGroups) {
    const { trackId, skillGroups } = input;

    const track = cache.readFragment<TrackDetailsSkillsFragment>(TrackDetailsSkillsFragmentDoc, {
      id: trackId,
      __typename: 'TrackType',
    });
    if (!track) {
      return null;
    }

    updateSkillGroupsInsideTrack({ track, cache, result, skillGroups });
  } else if (input.syncConfig) {
    invalidateQuery(['track', 'department'])(result, args, cache, info);
  } else if (input.name) {
    /**
     * Cache will automatically update from result
     */
  } else {
    invalidateQuery(['tracks', 'track'])(result, args, cache, info);
  }
};

export default updateTrackDetails;
