import { PraisesFilters } from '@/types/schema';
import { IconType } from '@/utils/icon';

import { OnPraisesAction } from './hooks/usePraises/actions';

export enum PraisesTabEnum {
  All = 'all',
  ForMe = 'me',
  GivenByMe = 'given',
}

export enum PraiseCardActions {
  Edit = 'EDIT',
  Delete = 'DELETE',
  ConfirmDelete = 'CONFIRM_DELETE',
}

export interface PraisesTabProps {
  onAction: OnPraisesAction;
}

export type PraiseCardColorConfig = {
  backgroundColor: string;
  titleColor: string;
  descriptionColor: string;
  secondaryBackgroundColor: string;
  secondaryTextColor: string;
  secondaryIconColor: string;
  footerTextColor: string;
  footerIconColor: string;
  mentionTextColor: string;
  mentionBackgroundColor: string;
};

export type PraisesTabsConfig = {
  key: PraisesTabEnum;
  labelKey: string;
  getFilters?: (userId: string) => PraisesFilters;
  emptyStateProps: {
    titleKey: string;
    descriptionKey: string;
  };
  Icon: IconType;
};
