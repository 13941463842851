import { useMemo } from 'react';

import useEvent from '@lyearn/core/hooks/useEvent';

import { FilterState, SortState } from './types';
import { useDataFromQueryParams, UseDataFromQueryParamsProps } from './utils';

type Props = UseDataFromQueryParamsProps & {
  initialFilters?: UseDataFromQueryParamsProps['defaultFilters'];
  initialSearch?: UseDataFromQueryParamsProps['defaultSearch'];
  initialSortBy?: UseDataFromQueryParamsProps['defaultSortBy'];
};

function useFilterManager({
  defaultSortBy,
  defaultFilters = {},
  defaultSearch = undefined,
  initialSortBy,
  initialFilters,
  initialSearch,
  updateType,
  queryParamPrefix,
}: Props) {
  const { sort, filter, search, setQuery } = useDataFromQueryParams({
    defaultSortBy: initialSortBy || defaultSortBy,
    defaultFilters: initialFilters || defaultFilters,
    defaultSearch: initialSearch || defaultSearch,
    updateType,
    queryParamPrefix,
  });

  // When using Filter Manager with useFilterManagerWithPersistance, we provide initial values from localStorage at that time there are no query params
  // so when we call, clearFilters nothing happens, hence we are setting them to defaultFilters on clearFilters and similarly for others.
  // Issue Reference - https://lyearn.slack.com/archives/C0402A7L1Q9/p1669174623528609
  // We have moved it out of memo and have used useEvent to keep the same reference for function -> to reduce recomputation of useMemo
  const clearSearch = useEvent(() => {
    setQuery({ search: defaultSearch });
  });

  const clearFilters = useEvent(() => {
    setQuery({ filter: defaultFilters });
  });

  const clearSort = useEvent(() => {
    setQuery({ sort: defaultSortBy });
  });

  const clearSearchAndFilters = useEvent(() => {
    setQuery({ filter: defaultFilters, search: defaultSearch });
  });

  const methods = useMemo(
    () => ({
      setSort(sortState: SortState) {
        setQuery({ sort: sortState.length ? sortState : undefined });
      },
      setFilter(filterState: FilterState) {
        setQuery({ filter: filterState });
      },
      setSearch(searchState: string) {
        setQuery({ search: searchState === '' ? defaultSearch : searchState });
      },
      clearSearch,
      clearFilters,
      clearSort,
      clearSearchAndFilters,
    }),
    [clearFilters, clearSearch, clearSearchAndFilters, clearSort, defaultSearch, setQuery],
  );

  const state = useMemo(
    () => ({
      sort,
      filter,
      search,
      defaultFilters,
      defaultSearch,
    }),
    [defaultFilters, defaultSearch, filter, search, sort],
  );

  return {
    state,
    methods,
  };
}
export type UseFilterManagerType = ReturnType<typeof useFilterManager>;
export default useFilterManager;
