// ref: https://github.com/amannn/next-query-params/blob/b415978bbcc17196600aadf211d7a4df5d928a93/packages/next-query-params/src/NextQueryParamProvider.tsx
import React, { ComponentProps, memo, useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { QueryParamProvider } from 'use-query-params';

type Props = Omit<
  ComponentProps<typeof QueryParamProvider>,
  'ReactRouterRoute' | 'reachHistory' | 'history' | 'location'
>;

function NextQueryParamProvider({ children, ...rest }: Props) {
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  const pathname = match ? match[0] : router.asPath;

  const location = useMemo(() => {
    if (typeof window !== 'undefined') {
      // For SSG, no query parameters are available on the server side,
      // since they can't be known at build time. Therefore, to avoid
      // markup mismatches, we need a two-part render in this case that
      // patches the client with the updated query parameters lazily.
      // Note that for SSR `router.isReady` will be `true` immediately
      // and therefore there's no two-part render in this case.
      if (router.isReady) {
        return window.location;
      } else {
        return { search: '' } as Location;
      }
    } else {
      // On the server side we only need a subset of the available
      // properties of `Location`. The other ones are only necessary
      // for interactive features on the client.
      return { search: router.asPath.replace(/[^?]+/u, '') } as Location;
    }
  }, [router.asPath, router.isReady]);

  const history: React.ComponentProps<typeof QueryParamProvider>['history'] = useMemo(
    () => ({
      push: (location) => {
        return router.push(
          { pathname: router.pathname, query: { ...router.query, ...(location as any).query } },
          { search: location.search, pathname },
          { scroll: false },
        );
      },
      replace: (location) => {
        return router.replace(
          { pathname: router.pathname, query: { ...router.query, ...(location as any).query } },
          { search: location.search, pathname },
          { scroll: false },
        );
      },
      location,
    }),
    [location, pathname, router],
  );

  return (
    <QueryParamProvider {...rest} history={history as any} location={location}>
      {children}
    </QueryParamProvider>
  );
}

export default memo(NextQueryParamProvider);
