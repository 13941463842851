import { css } from '@emotion/css';

import { svgSizeStyles } from '@/utils/styles';

export const HEADER_HEIGHT = 6;
export const MOBILE_HEADER_HEIGHT = 6.4;
const HEADER_BORDER_WIDTH = 0.1;
export const MAX_HEADER_HEIGHT = HEADER_HEIGHT + HEADER_BORDER_WIDTH;

export const headerOnBg = (showNavigationTabs?: boolean) => css`
  ::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
    z-index: -1;
    opacity: 0;
    background: #fff;
    box-shadow: ${showNavigationTabs ? 'var(--shadow-01)' : 'none'};
    transition: opacity 0.3s ease-in-out;
  }
  &.animate::before {
    opacity: 1;
  }
`;

export const navIconButton = svgSizeStyles();
