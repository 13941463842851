import { useState } from 'react';

import { useLearnerProfileJobQuery } from '@/modules/learner/views/LearnerProfile/graphql/queries/generated/learnerProfileJob';
import { useLearnerJobSubscription } from '@/modules/learner/views/LearnerProfile/graphql/subscription/generated/learnerJob';
import { Maybe } from '@/types/schema';

interface Props {
  syncJobId?: Maybe<string>;
}

const useIntegrationJobSyncStatus = ({ syncJobId }: Props) => {
  const [jobId, setJobId] = useState<string | null | undefined>(syncJobId);

  const [{ data: subscriptionData }] = useLearnerJobSubscription({
    variables: { input: { jobScheduleId: jobId! } },
    pause: !jobId,
  });

  const [{ data: jobData }] = useLearnerProfileJobQuery({
    variables: { filters: { jobScheduleId: jobId! } },
    pause: !jobId,
  });

  const jobStatus = jobId ? subscriptionData?.job?.status ?? jobData?.job?.status : undefined;

  return { jobId, jobStatus, setJobId };
};

export default useIntegrationJobSyncStatus;
