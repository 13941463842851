import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  DepartmentDetailsFragment,
  DepartmentDetailsFragmentDoc,
} from '../../../departments/views/DepartmentDetails/graphql/queries/generated/departmentDetails';
import {
  DeleteTracksMutation,
  DeleteTracksMutationVariables,
} from '../mutations/generated/deleteTracks';

const deleteTrackUpdater: UpdateResolver<DeleteTracksMutation, DeleteTracksMutationVariables> = (
  result,
  args,
  cache,
  info,
) => {
  const departmentId = args.data.filters.departmentIds?.[0];

  if (departmentId) {
    const department = cache.readFragment<DepartmentDetailsFragment>(DepartmentDetailsFragmentDoc, {
      id: departmentId,
      __typename: 'DepartmentType',
    });

    if (department) {
      cache.writeFragment(DepartmentDetailsFragmentDoc, {
        __typename: 'DepartmentType',
        ...department,
        tracks: department.tracks?.filter(
          (track) => !args.data.filters.trackIds?.includes(track.id),
        ),
      });
    } else {
      invalidateQuery('department')(result, args, cache, info);
    }
  } else {
    invalidateQuery('department')(result, args, cache, info);
  }
};

export default deleteTrackUpdater;
