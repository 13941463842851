import { css } from '@emotion/css';

import { svgSizeStyles } from '@/utils/styles';

const ICON_SIZE = '3.2rem';
const ICON_SIZE_WITH_DESCRIPTION = '5.2rem';

export const modalStyles = css`
  width: 52rem;
  max-height: 68rem;
  min-height: 52rem;

  &.full-height {
    min-height: 68rem;
  }

  @media (max-height: 700px) {
    max-height: 90%;
    &.full-height {
      min-height: 90%;
    }
  }
`;

export const fullScreenModalStyles = css`
  width: calc(100vw - 4.8rem);
  max-width: unset;
  max-height: calc(100vh - 6.4rem);
  min-height: calc(100vh - 6.4rem);
`;

export const extraWideModalStyles = css`
  width: 104.4rem;
  max-width: unset;
  max-height: calc(100vh - 15.6rem);
  min-height: calc(100vh - 15.6rem);
`;

export const wideModalStyles = css`
  width: 65.6rem;
  max-width: unset;
  max-height: 68rem;
  min-height: 68rem;
`;

export const headerContainer = css`
  .icon-container {
    &.large-icon {
      width: ${ICON_SIZE_WITH_DESCRIPTION};
      height: ${ICON_SIZE_WITH_DESCRIPTION};
      > svg {
        width: ${ICON_SIZE_WITH_DESCRIPTION};
        height: ${ICON_SIZE_WITH_DESCRIPTION};
      }
    }

    width: ${ICON_SIZE};
    height: ${ICON_SIZE};
    > svg {
      width: ${ICON_SIZE};
      height: ${ICON_SIZE};
    }
  }
`;

export const backButtonStyles = css`
  padding: 0;

  ${svgSizeStyles()}
`;
