import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/skill-matrix';
const ADMIN_SKILL_MATRIX_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

export const skillMatrixAdminRoutes = {
  SkillMatrixManager: () => `${ADMIN_SKILL_MATRIX_PARENT_ROUTE}`,
  SkillsManager: () => `${ADMIN_SKILL_MATRIX_PARENT_ROUTE}/skills`,
  SkillMatrixArchived: () => `${ADMIN_SKILL_MATRIX_PARENT_ROUTE}/archived`,
  SkillMatrixEditorEdit: (skillMatrixId: string) =>
    `${ADMIN_SKILL_MATRIX_PARENT_ROUTE}/${skillMatrixId}/edit`,
  SkillMatrixEditorView: (skillMatrixId: string) =>
    `${ADMIN_SKILL_MATRIX_PARENT_ROUTE}/${skillMatrixId}/view`,
};

const skillMatrixLearnerRoutes = {};

const routes = {
  ...skillMatrixAdminRoutes,
  ...skillMatrixLearnerRoutes,
};

export const SKILL_MATRIX_ADMIN_ROUTE_KEYS = {
  SkillMatrixManager: 'SkillMatrixManager',
  SkillsManager: 'SkillsManager',
  SkillMatrixArchived: 'SkillMatrixArchived',
  SkillMatrixEditorEdit: 'SkillMatrixEditorEdit',
  SkillMatrixEditorView: 'SkillMatrixEditorView',
};

export const SKILL_MATRIX_LEARNER_ROUTE_KEYS = {};

export const SKILL_MATRIX_ROUTE_KEYS = {
  ...SKILL_MATRIX_ADMIN_ROUTE_KEYS,
  ...SKILL_MATRIX_LEARNER_ROUTE_KEYS,
};

export default routes;
