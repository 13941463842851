import { HabitsViewEnum } from '@/modules/habits/views/HabitsManager/types';

const adminRoutes = {};

const learnerRoutes = {
  Habits: () => `/habits`,
  HabitsManager: (tabId: HabitsViewEnum) => `/habits/${tabId}`,
};

export { adminRoutes, learnerRoutes };
export default learnerRoutes;
