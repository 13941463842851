import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/assignment';
const ADMIN_ASSIGNMENT_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const assignmentAdminRoutes = {
  AssignmentCreator: () => `${ADMIN_ASSIGNMENT_PARENT_ROUTE}/create`,
  AssignmentEditor: (assignmentId: string) =>
    `${ADMIN_ASSIGNMENT_PARENT_ROUTE}/${assignmentId}/edit`,
  AssignmentDetails: (assignmentId: string) =>
    `${ADMIN_ASSIGNMENT_PARENT_ROUTE}/${assignmentId}/details`,
  AssignmentDuplicate: (assignmentId: string) =>
    `${ADMIN_ASSIGNMENT_PARENT_ROUTE}/${assignmentId}/duplicate`,
};

const assignmentLearnerRoutes = {};

const routes = {
  ...assignmentAdminRoutes,
  ...assignmentLearnerRoutes,
};

export const ASSIGNMENT_ADMIN_ROUTE_KEYS = {
  AssignmentCreator: 'AssignmentCreator',
  AssignmentEditor: 'AssignmentEditor',
  AssignmentDetails: 'AssignmentDetails',
  AssignmentDuplicate: 'AssignmentDuplicate',
};

export const ASSIGNMENT_LEARNER_ROUTE_KEYS = {};

export const ASSIGNMENT_ROUTE_KEYS = {
  ...ASSIGNMENT_ADMIN_ROUTE_KEYS,
  ...ASSIGNMENT_LEARNER_ROUTE_KEYS,
};

export default routes;
