import React from 'react';

import { DateFormat, formatDateWithYear } from '@lyearn/core/utils/date/formatDate';
import { useTranslation } from '@lyearn/i18n';
import { BriefcaseOutline, Calendar2Outline } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Avatar, Skeleton, Stack, Tooltip, Typography } from '@lyearn/ui';
import { Link } from '@/components/Link';
import Loader from '@/components/Loader';
import UserEntity from '@/entity/User';
import routes from '@/pages/corporate/routes';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';
import { UserStatusEnum } from '@/types/schema';

import { useUserInfo } from '../../context';
import AvatarOverlay from './components/AvatarOverlay';
import { UserTooltipInfoFragment } from './graphql/fragment/generated/UserTooltipInfo';
import useUserTooltip from './hooks/useUserTooltip';
import { containerStyles, TOOLTIP_CLASSES } from './styles';
import { UserInfoItemProps, UserInfoTooltipProps } from './types';

const UserInfoItem = ({ fetching, Icon, label }: UserInfoItemProps) => {
  if (fetching) {
    return (
      <Stack alignItems="center" gapX={8}>
        <Icon className="h-20 w-20" fillColorToken="icon-secondary" />
        <Skeleton className="rounded-8" height="10px" variant="rectangular" width="132px" />
      </Stack>
    );
  }

  if (!label) return null;

  return (
    <Stack alignItems="center" gapX={8}>
      <Icon className="h-20 w-20" fillColorToken="icon-secondary" />
      <Typography color="text-text-secondary" numberOfLines={1} variant="body-short-02">
        {label}
      </Typography>
    </Stack>
  );
};

const UserInfo = () => {
  const { user: userInfo } = useUserInfo();
  const { t } = useTranslation('EmployeeProfile');
  const isAdmin = useAdminRoute();

  const {
    error,
    userDetails: { user, isPartialData },
  } = useUserTooltip({
    userId: userInfo?.id,
  });

  if (!user || error) return null;

  const tooltipUser = isPartialData ? undefined : (user as UserTooltipInfoFragment);

  const orgRole = tooltipUser?.orgRole?.name;
  const joinedOn =
    tooltipUser?.createdAt && tooltipUser?.status !== UserStatusEnum.Invited
      ? t('EmployeeProfile:Joined', {
          date: formatDateWithYear(tooltipUser?.createdAt, DateFormat.Date),
        })
      : undefined;

  return (
    <>
      <Link to={isAdmin ? routes.AdminUserProfile(user.id) : routes.UserProfile(user.id)}>
        <AvatarOverlay className="mb-12">
          <Avatar
            disabled={UserEntity.isDeactivated(tooltipUser?.status)}
            size={52}
            src={user.profilePicture ?? ''}
          />
        </AvatarOverlay>
      </Link>
      <Link to={isAdmin ? routes.AdminUserProfile(user.id) : routes.UserProfile(user.id)}>
        <Typography
          className="mb-2 hover:text-text-secondary"
          numberOfLines={1}
          variant="heading-xs">
          {user.name}
        </Typography>
      </Link>
      {user.uniqueId ? (
        <Typography color="text-text-secondary" numberOfLines={1} variant="body-short-02">
          {user.uniqueId}
        </Typography>
      ) : null}
      <Stack className="mt-16" direction="col" gapY={12}>
        <UserInfoItem Icon={Calendar2Outline} fetching={isPartialData} label={joinedOn} />
        <UserInfoItem Icon={BriefcaseOutline} fetching={isPartialData} label={orgRole} />
      </Stack>
    </>
  );
};

const UserInfoTooltip = ({ children, isUserInfoTooltipEnabled }: UserInfoTooltipProps) => {
  if (!isUserInfoTooltipEnabled) return <>{children}</>;

  const SuspensedUserInfoTooltip = (
    <Stack
      className={cn('overflow-hidden p-20 pt-16 pe-32', containerStyles)}
      direction="col"
      onClick={(ev) => {
        ev.stopPropagation();
        ev.preventDefault();
      }}>
      <React.Suspense fallback={<Loader />}>
        <UserInfo />
      </React.Suspense>
    </Stack>
  );

  return (
    <Tooltip
      classes={TOOLTIP_CLASSES}
      enterDelay={100}
      title={SuspensedUserInfoTooltip}
      variant="popover">
      {children}
    </Tooltip>
  );
};

export default UserInfoTooltip;
