import React from 'react';
import {
  initReactI18next,
  Trans as BaseTrans,
  useTranslation as useTranslationOriginal,
  UseTranslationOptions,
  UseTranslationResponse,
} from 'react-i18next';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import i18n, { InitOptions, Namespace, TFunction } from 'i18next';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';

import { useTranslationOverride as useTranslation } from './hooks/useTranslation';
import LazyImportPlugin, { loadedNamespaces } from './LazyImportPlugin';
import MissingKeyError from './MissingKeyError';

export * from 'react-i18next';

export type { Namespace, TFunction, UseTranslationOptions, UseTranslationResponse };
export { BaseTrans };
export { useTranslation };
export { default as Trans } from './Trans';

const preloadNamespaces = 'Common';
const isAlreadyLogged = new Map<string, boolean>();

// Please refer: https://www.i18next.com/overview/typescript#argument-of-type-defaulttfuncreturn-is-not-assignable-to-parameter-of-type-xyz
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

function loadDateFns(locale: string) {
  if (['hi', 'es', 'ar', 'mr', 'gu', 'kn', 'ta', 'te', 'bn', 'ml'].includes(locale)) {
    // explicitly importing only one locale to improve build time by skipping generating chunk for all locale
    return {
      es: import(/* webpackChunkName: "date-fns/es" */ `date-fns/locale/es/index.js`),
      ar: import(/* webpackChunkName: "date-fns/ar" */ `date-fns/locale/ar/index.js`),
      hi: import(/* webpackChunkName: "date-fns/hi" */ `date-fns/locale/hi/index.js`),
      mr: import(/* webpackChunkName: "date-fns/hi" */ `date-fns/locale/hi/index.js`),
      gu: import(/* webpackChunkName: "date-fns/gu" */ `date-fns/locale/gu/index.js`),
      kn: import(/* webpackChunkName: "date-fns/kn" */ `date-fns/locale/kn/index.js`),
      ta: import(/* webpackChunkName: "date-fns/ta" */ `date-fns/locale/ta/index.js`),
      te: import(/* webpackChunkName: "date-fns/te" */ `date-fns/locale/te/index.js`),
      bn: import(/* webpackChunkName: "date-fns/bn" */ `date-fns/locale/bn/index.js`),
      ml: import(/* webpackChunkName: "date-fns/hi" */ `date-fns/locale/hi/index.js`),
    }
      [locale]!.then((dateFnsLocale) => {
        // @ts-ignore
        setDefaultOptions({ locale: dateFnsLocale });
      })
      .catch((err) => {
        ErrorReporter.error(`Error loading date-fns locale ${locale}. ${err.message}`);
      });
  }

  if (locale && locale !== 'en-US') {
    ErrorReporter.error(`Need to add date-fns ${locale} import.`);
  }

  // use default locale if locale is not provided
  return Promise.resolve();
}

function initializeI18nBuilder(options?: InitOptions) {
  return i18n
    .use(LazyImportPlugin)
    .use(initReactI18next)
    .init({
      returnNull: false,
      lng: 'en-US',
      fallbackLng: 'en-US',
      load: 'currentOnly',
      compatibilityJSON: 'v3',
      interpolation: {
        escapeValue: false,
      },
      ns: preloadNamespaces,
      defaultNS: 'Common',
      fallbackNS: 'Common',
      saveMissing: true,
      missingKeyHandler: function (lng, ns, key, defaultValue) {
        // if defaultValue includes space, then it's a sentence, not a key
        if (key !== defaultValue || defaultValue.includes(' ')) {
          return;
        }
        console.log('loadedNamespaces', JSON.stringify(Array.from(loadedNamespaces)));
        // eslint-disable-next-line no-process-env
        if (process.env.NODE_ENV === 'development') {
          // adding setTimeout to throw error after react rendering and not interpreted as suspense promise
          setTimeout(() => {
            throw new MissingKeyError(
              JSON.stringify({
                language: lng,
                namespace: ns,
                key: key,
                namespacesToLoad: i18n.store.options.ns,
                usedNamespaces: (i18n as any)?.reportNamespaces?.getUsedNamespaces(),
              }),
            );
          });
        } else {
          /**
           * Check based on Set /i18n/LazyImportPlugin/index.ts
           * Report MissingKey Error only when the Namespace is loaded but a key is Missing
           * since the Namespace could only be mssing due to Network issue (mostly)
           * */
          if (loadedNamespaces.has(`${lng} - ${ns}`)) {
            const errorMessage = JSON.stringify({
              language: lng,
              namespace: ns,
              key: key,
              namespacesToLoad: i18n.store.options.ns,
              usedNamespaces: i18n?.reportNamespaces?.getUsedNamespaces(),
            });

            if (!isAlreadyLogged.has(errorMessage)) {
              ErrorReporter.error(new MissingKeyError(errorMessage));
              isAlreadyLogged.set(errorMessage, true);
            }
          }
        }
      },
      ...options,
    })
    .then(() =>
      Promise.all([
        i18n.loadNamespaces(preloadNamespaces),
        i18n.resolvedLanguage ? loadDateFns(i18n.resolvedLanguage) : Promise.resolve(),
      ]),
    )
    .then(() => i18n);
}

export function useChangeLanguage(lng?: string) {
  const { i18n: currentI18n } = useTranslationOriginal('', { useSuspense: false });
  React.useEffect(() => {
    if (lng && lng !== currentI18n.language) {
      currentI18n.changeLanguage(lng);
      if (document.documentElement) {
        document.documentElement.setAttribute('lang', lng);
        document.documentElement.setAttribute('dir', currentI18n.dir(lng));
      }
      loadDateFns(lng);
    }
  }, [currentI18n, lng]);
}

export default initializeI18nBuilder;
