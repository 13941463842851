import React from 'react';

import { Avatar } from '@lyearn/ui';

import { useUserInfo } from '../context';
import UserInfoTooltip from '../layouts/UserInfoTooltip';
import AvatarOverlay from '../layouts/UserInfoTooltip/components/AvatarOverlay';
import { ProfilePictureProps } from '../types';

const CLASSES = { border: 'border border-solid border-divider' };

function ProfilePicture({
  className,
  size,
  classes = CLASSES,
  isUserInfoTooltipEnabled,
  disabled,
}: ProfilePictureProps) {
  const { user } = useUserInfo();

  if (!user) {
    return null;
  }

  const AvatarComponent = (
    <Avatar
      className={className}
      classes={classes}
      disabled={disabled}
      name="Avatar"
      size={size}
      src={user.profilePicture || ''}
    />
  );

  if (isUserInfoTooltipEnabled) {
    return (
      <UserInfoTooltip isUserInfoTooltipEnabled={isUserInfoTooltipEnabled}>
        <AvatarOverlay>{AvatarComponent}</AvatarOverlay>
      </UserInfoTooltip>
    );
  }

  return <>{AvatarComponent}</>;
}
export default React.memo(ProfilePicture);
