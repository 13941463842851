// urql tries to find key for each fragment to normalize it and save in cache. if some fragments cant be cached then return null
// https://formidable.com/open-source/urql/docs/graphcache/normalized-caching/#custom-keys-and-non-keyable-entities

import {
  ContentEntity,
  DataCheckAttributeTypeEnum,
  MissingParticipantsDataCheckCount,
  UserSurveyQuestionType,
} from '@/types/schema';

const getContentKey = (content: ContentEntity) => content.id + (content.versionStatus || '');

// the id field resolved in MissingParticipantsDataCheckCount in NOT MONGO ID. Its uuid which will change on every call
const getMissingParticipantKey = (data: MissingParticipantsDataCheckCount) => {
  if (data.type === DataCheckAttributeTypeEnum.Custom) {
    return data.tagId;
  }

  return data.name;
};

const cacheKeys = {
  DeprecatedMediaType: () => null,
  DeprecatedResolutionType: () => null,
  UserClassroomPermissionType: () => null,
  UserClassroomEventPermissionType: () => null,
  ClassroomUserType: () => null,
  FormattedResourceMediaType: () => null,
  AvailableMediaFormatType: () => null,
  UserConnection: () => null,
  ReactionType: () => null,
  UserChannelPermissionType: () => null,
  UserResourcePermissionType: () => null,
  EnrolmentSettingsType: () => null,
  ResourceGradeSettingsType: () => null,
  SubmissionSettingsType: () => null,
  SubmissionFeedbackStickers: () => null,
  EntityMediaType: () => null,
  PostConnections: () => null,
  ResourceConnections: () => null,
  PermittedOnType: () => null,
  InvitationCodeType: () => null,
  SubmissionType: () => null,
  UserEventPermissionType: () => null,
  ContentTodoType: () => null,
  PageComponentType: () => null,
  PageType: () => null,
  PaginatedEventsPayload: () => null,
  ParentDetails: () => null,
  TagSettingsType: () => null,
  EntityTagType: () => null,
  DepartmentConnections: () => null,
  PaginatedTagsPayload: () => null,
  UserCollectionGroupType: () => null,
  ContentCollectionGroupType: () => null,
  UserWorkspacePermissionType: () => null,
  SkillConnections: () => null,
  RoleMetadata: () => null,
  ContentLockSettings: () => null,
  DripSettingsType: () => null,
  CourseSettings: () => null,
  EnrolmentInfoType: () => null,
  VisibilitySettingsType: () => null,
  SharedSpaceUserRoleType: () => null,
  SharedSpaceGroupRoleType: () => null,
  EntityWorkspaceMapping: () => null,
  ScormPackageInfo: () => null,
  FeedbackQuestionType: () => null,
  ScormProperties: () => null,
  PageType__Next: () => null,
  QuizSettings: () => null,
  RegistrationSettings: () => null,
  TaskInstruction: () => null,
  TaskContentType: getContentKey,
  ScormContentType: getContentKey,
  QuizContentType: getContentKey,
  PathContentType: getContentKey,
  ExternalContentType: getContentKey,
  ExerciseType: getContentKey,
  SurveyContentType: getContentKey,
  PathSectionType: () => null,
  MTFComponent: () => null,
  Option: () => null,
  QuestionSettings: () => null,
  Rubric: () => null,
  OrgRoleConnections: () => null,
  UpdateOrgRolePayload: () => null,
  MetricSyncConfig: () => null,
  ManualMetricConfigType: () => null,
  ManualMetricColumnConfig: () => null,
  IconSettings: () => null,
  SkillMatrixConfig: () => null,
  SkillMasteryConfig: () => null,
  NotificationTaskRequirementAudit: () => null,
  UserPreferencesType: () => null,
  PaginatedNotificationTemplatesPayload: () => null,
  LinkType: () => null,
  ExerciseInstructionType: () => null,
  ReviewCycleTodoType: () => null,
  ReviewerMetricsType: () => null,
  ReviewerConfig: () => null,
  ExternalEventProviderInfoType: () => null,
  FormComponentType: () => null,
  FacetConfig: () => null,
  SelectFacetOption: () => null,
  UserPropertiesType: () => null,
  ShareConfig: () => null,
  WorkspaceDetails: () => null,
  MentionSearchPayload: () => null,
  PaginatedActionItemsPayload: () => null,
  ActionItemMetadata: () => null,
  PaginatedFilesPayload: () => null,
  PaginatedOKRsPayload: () => null,
  IntegrationSettingsType: () => null,
  JiraIntegrationProjectStatusesConfig: () => null,
  JiraIntegrationSettingsType: () => null,
  IntegrationConfigsType: () => null,
  IntegrationConnectionStrategyType: () => null,
  IntegrationProviderPayload: () => null,
  IntegrationProviderInfoType: () => null,
  ImageGridDisplayComponentType: () => null,
  TextSectionDisplayComponentType: () => null,
  ListSectionDisplayComponentType: () => null,
  ListSectionItemType: () => null,
  ObjectiveCategoryConfig: () => null,
  DueDateConfig: () => null,
  UserOKRPermissionType: () => null,
  InstructionType: () => null,
  UserViewFieldSchema: () => null,
  ProviderConnections: () => null,
  PaginatedGroupedActionItemsPayload: () => null,
  PaginatedGroupedActionItemEdge: () => null,
  GroupedActionItemEdge: () => null,
  ActionItemEdge: () => null,
  ActionItemPayload: () => null,
  PaginatedStatusesPayload: () => null,
  ActionItemIntegrationConfig: () => null,
  DateValueType: () => null,
  LookUpActionItemValuesPayload: () => null,
  ActionItemStatusCount: () => null,
  OKRGroupCount: () => null,
  PaginatedMeetingsPayload: () => null,
  FeatureNudge: () => null,
  PaginatedMeetingInstancesPayload: () => null,
  ScheduleInfo: () => null,
  ActionItemCountsByCategoryAndStatusPayload: () => null,
  ActionItemCountByCategoryAndStatus: () => null,
  FeatureFlagsPayload: () => null,
  FeatureFlagType: () => null,
  MentionType: () => null,
  UserOKRCyclePermissionType: () => null,
  PaginatedTracksPayload: () => null,
  PaginatedMetricsPayload: () => null,
  ListItemType: () => null,
  WidgetInteractionEventActionType: () => null,
  WidgetInteractionActionType: () => null,
  MicrosoftIntegrationSettingsType: () => null,
  FieldMappingType: () => null,
  QuestionConnections: () => null,
  TagControlRuleType: () => null,
  CertificateTemplateModelRequirement: () => null,
  CertificateTemplateConnections: () => null,
  TagControlRuleConstraint: () => null,
  TagControlType: () => null,
  TagControlEntityConfig: () => null,
  TagControlConfigType: () => null,
  PaginatedReviewCyclesPayload: () => null,
  BlockSearchConnections: () => null,
  UserSearchActivityConnections: () => null,
  GlobalSearchPayload: () => null,
  MissingParticipantsDataCheckCount: getMissingParticipantKey,
  PaginatedMissingParticipantsDataCheckCountsPayload: () => null,
  ReviewCyclePayload: () => null,
  ReviewCycleEndSettingsType: () => null,
  ImageHotspotResponse: () => null,
  UserSurveyQuestionSettingsConfig: () => null,
  UserSurveyQuestionConfig: () => null,
  KeyResultOutcome: () => null,
  JiraAssigneeInfo: () => null,
  OKRProgressMeasurementConfig: () => null,
  PaginatedJiraIssuesPayload: () => null,
  UserSurveyQuestionType: (question: Pick<UserSurveyQuestionType, 'id' | 'questionId'>) =>
    question.id || question.questionId,
  ManageUserSurveyQuestionsPayload: () => null,
  ProgressInfoType: () => null,
  PaginatedJobsPayload: () => null,
  EventLocation: () => null,
  EventWorkConfig: () => null,
  CompletionSettingsType: () => null,
  JobInputPropsType: () => null,
  PerformanceMetricsWidgetType: () => null,
  MultiBannerPageComponentType: () => null,
  QuizQuestionsConfigPayload: () => null,
  GlobalSearchWidgetType: () => null,
  TodosWidgetType: () => null,
  ContentsWidgetType: () => null,
  ContinueLearningWidgetType: () => null,
  EventsWidgetType: () => null,
  CompletedLearningsWidgetType: () => null,
  PaginatedJourneyPerformancesPayload: () => null,
  UserJourneyPerformanceLevelMeta: () => null,
  JourneyPhaseType: () => null,
  JourneyType: () => null,
  JourneyPerformanceType: () => null,
  JourneyStepType: () => null,
  JourneyPerformanceQuestionResponseType: () => null,
  JourneyStepActionSettingsType: () => null,
  JourneyStepSettingsType: () => null,
  UserType: () => null,
  JourneyPersonaViewPerformanceType: () => null,
  JourneyStepActionEntitiesType: () => null,
  PaginatedJourneyStepsPayload: () => null,
  PaginatedJourneysPayload: () => null,
  DripSettingsV2Type: () => null,
  BatchConfigType: () => null,
  ParticipantConfigType: () => null,
  JourneyStepAssignmentConfigsType: () => null,
  PaginatedAnnouncementsPayload: () => null,
  PaginatedUserAnnouncementsPayload: () => null,
  ContentPerformanceWidgetType: () => null,
  TrendingContentsWidgetType: () => null,
};

export default cacheKeys;
