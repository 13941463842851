import { useCallback } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import useRest from '@/helper/hooks/useRest';
import useDefaultErrorSnackbar from '@/hooks/useDefaultErrorSnackbar';
import useNavigation from '@/hooks/useNavigation';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import routes from '@/pages/corporate/routes';

interface Params {
  action: 'START_MASQUERADE' | 'STOP_MASQUERADE';
  masqueradeAsId?: string;
}

const useMasquerade = ({ action, masqueradeAsId }: Params) => {
  const navigation = useNavigation();
  const { setLoginAndRefetch } = useUserContext();
  const { showDefaultErrorSnackbar } = useDefaultErrorSnackbar();

  const [, , , execute] = useRest('/api/auth/masquerade', {
    method: 'POST',
    body: JSON.stringify({
      action,
      masqueradeAs: masqueradeAsId,
    }),
    headers: {
      'content-type': 'application/json; charset=utf-8',
    },
  });

  const onMasqueradeStart = useCallback(async () => {
    const response = await execute();

    if (!masqueradeAsId) {
      return;
    }

    try {
      if (response?.success) {
        await setLoginAndRefetch(response);
        window.location.href = routes.Home();
      }
    } catch (e) {
      ErrorReporter.error(e);
      showDefaultErrorSnackbar();
    }
  }, [execute, masqueradeAsId, setLoginAndRefetch, showDefaultErrorSnackbar]);

  const onMasqueradeStop = useCallback(async () => {
    const response = await execute();

    try {
      if (response?.success) {
        await setLoginAndRefetch(response);
        await navigation.push(routes.Learners(), { appendQuery: false });
      }
    } catch (e) {
      ErrorReporter.error(e);
      showDefaultErrorSnackbar();
    }
  }, [execute, navigation, setLoginAndRefetch, showDefaultErrorSnackbar]);

  return { onMasqueradeStart, onMasqueradeStop };
};

export default useMasquerade;
