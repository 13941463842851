// libs
import React, { Dispatch, SetStateAction } from 'react';

import useToggle from '@lyearn/core/hooks/useToggle';
import { TypographyWithTooltip } from '@lyearn/molecules';
import { cn } from '@lyearn/style';
// components
import { Box, Stack } from '@lyearn/ui';
import { Link } from '@/components/Link';
import * as SidebarAccordion from '@/components/Panel/SidebarAccordion';
import useNavigation from '@/hooks/useNavigation';

// hooks
import { SidebarItemProps, SideBarMode } from '../../hooks/useSidebar';
import { sidebarItemStyles } from './style';

export const SIDEBAR_ITEM_ANIMATION = {
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  initial: { height: 0, opacity: 0 },
  transition: {
    layout: {
      type: 'spring',
      bounce: 0.4,
    },
  },
};

export interface RenderSidebarItemProps {
  to?: string;
  onClick?: () => void;
  isActive: boolean;
  icon?: JSX.Element;
  text: string;
  className?: string; // use this for margin purpose
  overrides?: {
    root?: string;
    icon?: string;
    text?: string;
  };
  mode?: SideBarMode;
  action?: SidebarItemProps['action'];
  isChild?: boolean;
  isExpandable?: boolean;
  showItems?: boolean;
  setShowItems?: Dispatch<SetStateAction<boolean>>;
}

export const renderSidebarItem = ({
  to,
  isActive,
  onClick,
  icon,
  text,
  className,
  overrides,
  action,
  mode,
  isChild,
  isExpandable,
  showItems,
  setShowItems,
}: RenderSidebarItemProps) => {
  const TextElem = (
    <TypographyWithTooltip
      disableInteractive
      className={overrides?.text || 'truncate'}
      color={isActive ? 'text-text-primary' : 'text-text-secondary'}
      numberOfLines={1}
      variant={mode === 'learner' || !isActive ? 'body-short-02' : 'label-02'}>
      {text}
    </TypographyWithTooltip>
  );

  const InnerElem = (
    <Stack
      alignItems="center"
      className={cn('z-10 w-full cursor-pointer truncate', isChild ? 'px-0' : 'px-12')}
      justifyContent="between">
      <Stack alignItems="center" className="truncate">
        <Box
          className={
            overrides?.icon ||
            cn(
              'me-10',
              isActive ? 'text-icon-active' : 'text-icon-secondary',
              isChild ? 'w-6' : 'w-20',
            )
          }>
          {icon}
        </Box>
        {TextElem}
      </Stack>
      {isExpandable && (
        <SidebarAccordion.Chevron iconColor="icon-placeholder" isExpanded={showItems} />
      )}
      {action}
    </Stack>
  );

  const classes =
    overrides?.root ||
    cn(
      sidebarItemStyles,
      className,
      'group flex-shrink-0 flex active:bg-interactive-secondary-hover rounded-8 relative h-40 overflow-hidden truncate',
      {
        'bg-interactive-secondary-hover': mode === 'learner' && isActive,
        'mt-2': mode === 'learner',
      },
    );

  if (to) {
    return (
      <Link className={classes} to={to}>
        {InnerElem}
      </Link>
    );
  }

  return (
    <Stack
      className={cn('w-full', classes)}
      component={onClick ? 'button' : 'div'}
      onClick={onClick ? () => onClick() : undefined}>
      {InnerElem}
    </Stack>
  );
};

const SidebarItem = ({
  sidebarItem,
  mode,
  isChild,
  className,
}: {
  sidebarItem: SidebarItemProps;
  mode?: SideBarMode;
  isChild?: boolean;
  className?: string;
}) => {
  const { to, icon, text, children, action, routes = [to] } = sidebarItem;
  const navigation = useNavigation();
  const isActive = routes.some((route) => route && navigation.asPath.includes(route));
  const [showItems, { toggle: setShowItems }] = useToggle(false);

  return (
    <>
      {renderSidebarItem({
        to,
        icon,
        text,
        isActive,
        action,
        mode,
        isChild,
        isExpandable: !!children?.length,
        showItems,
        className,
        setShowItems,
        onClick: !!children?.length
          ? () => {
              setShowItems?.(!showItems);
            }
          : undefined,
      })}
      {children?.length ? (
        <SidebarAccordion.LazyMotion isExpanded={showItems}>
          <SidebarAccordion.Div className="!gap-0">
            <Stack className="h-4 flex-shrink-0" />
            <Stack
              className="duration-50 linear overflow-hidden border-l border-border pl-8 transition ml-[1.7rem] flex-shrink-0"
              direction="col">
              <SidebarAccordion.LazyMotion isExpanded={showItems}>
                <SidebarAccordion.Div className="!gap-0">
                  {children.map((children) => (
                    <SidebarItem
                      key={children.id}
                      isChild
                      className="duration-50 linear transition"
                      mode={mode}
                      sidebarItem={children}
                    />
                  ))}
                </SidebarAccordion.Div>
              </SidebarAccordion.LazyMotion>
            </Stack>
            <Stack className="h-4" />
          </SidebarAccordion.Div>
        </SidebarAccordion.LazyMotion>
      ) : null}
    </>
  );
};

export default React.memo(SidebarItem);
