import { uniq } from 'lodash';

import { jsonStringify } from '@lyearn/core/utils/json';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import { useUpdateUserMutation } from '@/modules/onboarding/graphql/mutations/generated/updateUser';

import { OnboardingNudgeType } from '../../types';

const useCompletedOnboardingNudges = () => {
  const user = useUserContext();
  const [{ fetching: disabled }, updateUser] = useUpdateUserMutation();

  const onUpdateOnboardingNudges = async (completedNudge: OnboardingNudgeType) => {
    const completedOnboardingNudges = user.meta.completedOnboardingNudges || [];
    const { data: meta } = jsonStringify({
      ...user.meta,
      completedOnboardingNudges: uniq([...completedOnboardingNudges, completedNudge]),
    });

    await updateUser({ input: { userId: user.id, meta } }).then(() => user.refetchUser());
  };

  return { disabled, onUpdateOnboardingNudges };
};

export default useCompletedOnboardingNudges;
