import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import { UserFragment, UserFragmentDoc } from '@/graphql/fragments/generated/User';

import {
  ActionItemWithProviderTagsFragment,
  ActionItemWithProviderTagsFragmentDoc,
} from '../fragments/generated/ActionItemWithProviderTags';
import {
  UpdateActionItemMutation,
  UpdateActionItemMutationVariables,
} from '../mutations/generated/updateActionItem';

const updateActionItem: OptimisticMutationResolver = (variables, cache, info) => {
  const data = (variables as UpdateActionItemMutationVariables).data;
  const { actionItemId, ...rest } = data;
  const actionItem = cache.readFragment(ActionItemWithProviderTagsFragmentDoc, {
    id: actionItemId,
  }) as ActionItemWithProviderTagsFragment | null;
  let newAssignedUser = actionItem?.assignedTo as UserFragment | null;
  if (rest.assignedToId) {
    newAssignedUser = cache.readFragment(UserFragmentDoc, {
      id: rest.assignedToId,
    }) as UserFragment | null;
  }

  if (!actionItem || (rest.assignedToId && !newAssignedUser)) {
    return null;
  }

  const returnValue: UpdateActionItemMutation['updateActionItem'] = {
    __typename: 'ActionItemPayload',
    actionItem: {
      ...actionItem,
      title: rest.title ?? actionItem.title,
      assignedToId: rest.assignedToId ?? actionItem.assignedToId,
      status: rest.status ?? actionItem.status,
      dueDate: rest.dueDate ? new Date(rest.dueDate).toUTCString() : actionItem.dueDate,
      fileId: rest.fileId ?? actionItem.fileId,
      priority: rest.priority ?? actionItem.priority,
      updatedAt: new Date().toISOString(),
      assignedTo: newAssignedUser ? { ...newAssignedUser, __typename: 'UserType' } : null,
      __typename: 'ActionItemType',
    },
  };

  return returnValue;
};

export default updateActionItem;
