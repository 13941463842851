import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/check-in';
const ADMIN_CHECKIN_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const checkinAdminRoutes = {
  CheckinEditor: () => `${ADMIN_CHECKIN_PARENT_ROUTE}/create`,
  AdminCheckInDetails: (contentId: string) => `${ADMIN_CHECKIN_PARENT_ROUTE}/${contentId}/details`,
};

const checkinLearnerRoutes = {
  CheckInDetails: (contentId: string) => `${PARENT_ROUTE}/${contentId}/details`,
};

const routes = {
  ...checkinAdminRoutes,
  ...checkinLearnerRoutes,
};

export const CHECKIN_ADMIN_ROUTE_KEYS = {
  AdminCheckInDetails: 'AdminCheckInDetails',
  CheckinEditor: 'CheckinEditor',
};

export const CHECKIN_LEARNER_ROUTE_KEYS = {
  CheckInDetails: 'CheckInDetails',
};

export const CHECKIN_ROUTE_KEYS = {
  ...CHECKIN_ADMIN_ROUTE_KEYS,
  ...CHECKIN_LEARNER_ROUTE_KEYS,
};

export default routes;
