import { cn } from '@lyearn/style';
import { MotionDiv } from '@/components/Motion';

import { SIDEBAR_ANIMATION } from '../../Sidebar/styles';
import { SidebarAccordianDivProps } from '../types';

const SidebarAccordianDiv: React.FC<React.PropsWithChildren<SidebarAccordianDivProps>> = ({
  className,
  children,
}) => {
  return (
    <MotionDiv
      animate={SIDEBAR_ANIMATION.animate}
      className={cn('w-full gap-4 flex flex-col overflow-hidden flex-shrink-0', className)}
      exit={SIDEBAR_ANIMATION.exit}
      initial={SIDEBAR_ANIMATION.initial}
      role="label"
      transition={SIDEBAR_ANIMATION.transition}>
      {children}
    </MotionDiv>
  );
};

export default SidebarAccordianDiv;
