import { SeverityLevel } from '@sentry/browser/types';
import { safeJoin } from '@sentry/utils';
import envConfig from 'config';

const returnBreadcrumb = (level: SeverityLevel, addBreadcrumb: any) => {
  return (...args: any[]) => {
    // ref: https://github.com/getsentry/sentry-javascript/blob/5543808adf70280b0154a4e7c1d446f2b171c07d/packages/browser/src/integrations/breadcrumbs.ts#L197
    addBreadcrumb(
      {
        category: 'console',
        message: safeJoin(args, ' '),
        level: level,
        data: {
          arguments: args,
          logger: 'console',
        },
      },
      { input: args, level },
    );
  };
};

const disableConsole = async (isLoggingEnabled: boolean) => {
  if (envConfig.env === 'development' || (envConfig.env === 'production' && isLoggingEnabled)) {
    return;
  }

  const addBreadcrumb = await import(/* webpackChunkName: "sentry-browser" */ '@sentry/browser')
    .then((p) => p.addBreadcrumb)
    .catch((e) => {
      //not logging on sentry since it is external library
      console.error('Could not load @sentry/browser', e);
    });

  if (!addBreadcrumb) {
    return;
  }

  window.console.log = returnBreadcrumb('info', addBreadcrumb);
  window.console.error = returnBreadcrumb('error', addBreadcrumb);
  window.console.debug = returnBreadcrumb('debug', addBreadcrumb);
  window.console.warn = returnBreadcrumb('warning', addBreadcrumb);
};

export default disableConsole;
