import { useCallback, useEffect, useState } from 'react';

import { useTranslation } from '@lyearn/i18n';
import { SyncFill } from '@lyearn/icons';
import { Divider, LinkButton, Stack, Typography } from '@lyearn/ui';
import useNavigation from '@/hooks/useNavigation';
import useParams from '@/hooks/useParams';
import routes from '@/pages/corporate/routes';
import { JobEntityStatusEnum } from '@/types/schema';
import getLinkProps from '@/utils/getLinkProps';

import useIntegrationJobSyncStatus from '../../hooks/useIntegrationJobSyncStatus';

interface Props {
  integrationProviderId: string;
  syncJobId: string;
  onJobComplete: VoidFunction;
}

const TYPOGRAPHY = { variant: 'label-02' } as const;
const COMPLETED_JOB_STATUS = [JobEntityStatusEnum.Completed, JobEntityStatusEnum.Failed];

const IntegrationSyncToastContent = (props: Props) => {
  const { integrationProviderId, syncJobId, onJobComplete } = props;
  const { t } = useTranslation();
  const { jobStatus, setJobId } = useIntegrationJobSyncStatus({ syncJobId });

  const onDismiss = useCallback(() => {
    setJobId(null);
  }, [setJobId]);

  useEffect(() => {
    if (jobStatus && COMPLETED_JOB_STATUS.includes(jobStatus)) {
      onDismiss();
      onJobComplete();
    }
  }, [jobStatus, onDismiss, onJobComplete, setJobId]);

  if (!jobStatus || COMPLETED_JOB_STATUS.includes(jobStatus)) {
    return null;
  }

  return (
    /* prettier-ignore */
    <Stack className="start-20 fixed bottom-20 z-[99] rounded-12 border border-border bg-bg-primary shadow-01">
      <Stack className="p-20 pe-24" gapX={12}>
        <SyncFill className="mt-[0.3rem]" fillColorToken="icon-secondary" height={20} width={20} />
        <Typography
          className="whitespace-pre-line"
          color="text-text-primary"
          variant="body-short-02">
          {t('Admin:IntegrationSync.Description')}
        </Typography>
      </Stack>
      <Stack
        className="w-[11rem] border-border border-s"
        direction="col"
        gapY={8}
        justifyContent="center">
        <LinkButton
          typography={TYPOGRAPHY}
          onClick={onDismiss}
          {...getLinkProps(routes.IntegrationsDetails(integrationProviderId))}>
          {t('Admin:IntegrationSync.GotoSync')}
        </LinkButton>
        <Divider />
        <LinkButton
          typography={TYPOGRAPHY}
          onClick={onDismiss}>
          {t('Common:Actions.Dismiss')}
        </LinkButton>
      </Stack>
    </Stack>
  );
};

type Params = Pick<Props, 'integrationProviderId' | 'syncJobId'>;

const IntegrationSyncToast = () => {
  useTranslation(['Admin', 'Common']);
  const [params, setParams] = useState<Params | null>(null);

  const { pathname, replace } = useNavigation();
  const { integrationProviderId, syncJobId, ...query } = useParams<Partial<Params>>();

  useEffect(() => {
    if (integrationProviderId && syncJobId && !params) {
      setParams({ integrationProviderId, syncJobId });
      replace(pathname, { appendQuery: false, query });
    }
  }, [integrationProviderId, params, pathname, query, replace, syncJobId]);

  const onJobComplete = useCallback(() => {
    setParams(null);
  }, []);

  if (!params) {
    return null;
  }

  return <IntegrationSyncToastContent {...params} onJobComplete={onJobComplete} />;
};

export default IntegrationSyncToast;
