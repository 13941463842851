import _once from 'lodash/once';
import { LinkProps as NextLinkProps } from 'next/link';
import isURL from 'validator/lib/isURL';

export const sendToParent = (message: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  try {
    window.parent?.postMessage(message, '*');
  } catch (e) {
    console.error(e);
  }
};

function inIframeFn() {
  try {
    return window.self !== window.top;
  } catch (e) {
    /**
     * iframe is less permissive so some properties can be inaccessible like localStorage.
       window.top can throw error in iframe depending on user setting
     */
    return true;
  }
}

export const inIframe = _once(inIframeFn);

export const getLinkTarget = (link: NextLinkProps['href'], target?: string) => {
  let linkTarget = target;
  // check and change only if target is set
  if (linkTarget && inIframe()) {
    let isSameHost = true;

    // should be only false for valid link of different host and true for relative url and objects like {pathname, query}
    // isURL is heavy operation then link.include, so it is last in check
    if (typeof link === 'string' && !link.includes(window.location.host) && isURL(link)) {
      isSameHost = false;
    }

    linkTarget = isSameHost ? '_self' : target;
  }
  return linkTarget;
};
