import { useCallback, useEffect } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { post } from '@/helper/request';
import { UserProfile } from '@/modules/auth/types';

const PLATFORM_VISIT_CALL_PATH = '/api/track/platformVisit';

const useTrackPlatformVisit = (user: UserProfile) => {
  const platformVisitCallback = useCallback(async () => {
    //call only if tab is in focus and we have user id i.e. user is logged in
    // document.hasFocus() is not reliable in iframe since parent can be in focus
    if (user.id && document.visibilityState === 'visible') {
      await post(PLATFORM_VISIT_CALL_PATH, { url: window.location.href }).then((res) => {
        if (res?.success === false) {
          ErrorReporter.error(res.message);
        }
      });
    }
  }, [user.id]);

  useEffect(() => {
    platformVisitCallback();
  }, [platformVisitCallback]);
};

export default useTrackPlatformVisit;
