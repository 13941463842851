import React, { useCallback, useMemo, useState } from 'react';
import NextImage from 'next/image';

import useToggle from '@lyearn/core/hooks/useToggle';
import { Box } from '@lyearn/ui';

import { loaderSvg, toBase64 } from '../utils';
import { useBaseImageContext } from './Provider';
import { BaseImageProps } from './types';

const shouldOptimize = require('@/consts/image').shouldOptimize as (src?: string) => boolean;

let hasShownWarning = false;

const defaultBlurDataURL = `data:image/svg+xml;base64,${toBase64(loaderSvg)}`;

const Image: React.FC<BaseImageProps> = (props) => {
  const {
    // disable blur for small images
    // ref https://github.com/vercel/next.js/blob/72478c5c80d152ad99ce179b4ef5cfac055b98d5/packages/next/client/image.tsx#L540
    showBlurPlaceholder = !props.width || (typeof props.width === 'number' && props.width > 40),
    blurDataURL,
    src,
    style,
    objectFit,
    ...rest
  } = props;
  const [isError, setError] = useState<boolean>(false);
  const { loading } = useBaseImageContext();
  const [isLoaded, { on }] = useToggle(false);

  const blurPlaceholderProps =
    showBlurPlaceholder && !isLoaded
      ? {
          placeholder: 'blur' as const,
          blurDataURL: blurDataURL ?? defaultBlurDataURL,
        }
      : { placeholder: 'empty' as const };

  const unoptimized = useMemo(() => !shouldOptimize(src), [src]);

  const onError = useCallback(() => {
    setError(true);
  }, []);

  if (style && !hasShownWarning) {
    console.warn(`style attribute isn't supported in web, but in native only`);
    hasShownWarning = true;
  }

  if (!src || isError) {
    return <Box className="h-full w-full bg-s-info-light" />;
  }

  return (
    <NextImage
      loading={loading}
      unoptimized={unoptimized}
      onError={onError}
      {...blurPlaceholderProps}
      src={src}
      onLoadingComplete={on}
      {...rest}
      style={{
        maxWidth: '100%',
        objectFit: (objectFit ?? 'cover') as React.CSSProperties['objectFit'],
        width: rest.width,
        height: rest.height,
      }}
    />
  );
};

export default Image;
