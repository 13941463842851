import { useCallback, useReducer, useState } from 'react';

import { ActionItemFragment } from '@/modules/tasks/features/actionItems/graphql/fragments/generated/ActionItem';
import { CreateActionItemInput } from '@/types/schema';

import { FileFragment } from '../../graphql/fragments/generated/file';
import { ActionDialogsEnum, InViewDialogState } from '../../types';
import getInViewFile from '../../utils/getInViewFile';
import useFile from '../useFile';
import useFiles from '../useFiles';

export enum DashboardActions {
  ADD_FILE_OR_FOLDER = 'AddFileOrFolder',
  ADD_NEW_TASK = 'AddNewTask',
  EDIT_TASK = 'EditNewTask',
  EDIT_FILE = 'EditFile',
  DELETE_FILE = 'DeleteFile',
  ON_CLOSE = 'OnClose',
  DELETE_TASK = 'DeleteTask',
}

type AddFileOrFolderAction = {
  type: DashboardActions.ADD_FILE_OR_FOLDER;
  payload: {
    actionType: ActionDialogsEnum.CreateFolder | ActionDialogsEnum.CreateList;
  };
};

type AddNewTaskAction = {
  type: DashboardActions.ADD_NEW_TASK;
  payload?: {
    task?: CreateActionItemInput;
  };
};

type EditTaskAction = {
  type: DashboardActions.EDIT_TASK;
  payload: {
    task: ActionItemFragment;
  };
};

type EditFileAction = {
  type: DashboardActions.EDIT_FILE;
  payload: {
    file: NonNullable<ReturnType<typeof useFiles>['files']>[0];
  };
};

type DeleteFileAction = {
  type: DashboardActions.DELETE_FILE;
  payload: {
    file: NonNullable<ReturnType<typeof useFiles>['files']>[0];
  };
};

type DeleteTaskAction = {
  type: DashboardActions.DELETE_TASK;
  payload: {
    task: ActionItemFragment;
  };
};

type OnCloseAction = {
  type: DashboardActions.ON_CLOSE;
};

type Action =
  | AddFileOrFolderAction
  | AddNewTaskAction
  | EditTaskAction
  | EditFileAction
  | DeleteFileAction
  | OnCloseAction
  | DeleteTaskAction;

type State = InViewDialogState | undefined;

const DASHBOARD_INITIAL_STATE: State = undefined;

const reducer =
  (inViewFile?: FileFragment) =>
  (state: State, action: Action): State => {
    switch (action.type) {
      case DashboardActions.ADD_FILE_OR_FOLDER: {
        return {
          dialog: action.payload.actionType,
        };
      }
      case DashboardActions.ADD_NEW_TASK: {
        return {
          dialog: ActionDialogsEnum.AddNewTask,
          file: inViewFile,
          task: action.payload?.task,
        };
      }
      case DashboardActions.DELETE_FILE: {
        return {
          dialog: ActionDialogsEnum.Delete,
          file: action.payload.file,
        };
      }
      case DashboardActions.EDIT_FILE: {
        return {
          dialog: ActionDialogsEnum.EditList,
          file: action.payload.file,
        };
      }
      case DashboardActions.EDIT_TASK: {
        return {
          dialog: ActionDialogsEnum.EditTask,
          task: action.payload.task,
        };
      }
      case DashboardActions.ON_CLOSE: {
        return undefined;
      }
      case DashboardActions.DELETE_TASK: {
        return {
          dialog: ActionDialogsEnum.DeleteTask,
          task: action.payload.task,
        };
      }
      default:
        return state;
    }
  };

function useDashboardActions({ inViewFile }: { inViewFile?: ReturnType<typeof useFile>['file'] }) {
  const resolvedInViewFile = getInViewFile({
    inViewFile,
  });
  const [lastActedEntityId, setLastActedEntityId] = useState<string | undefined>();

  const [inViewDialogState, dispatch] = useReducer(
    reducer(resolvedInViewFile as FileFragment),
    DASHBOARD_INITIAL_STATE,
  );

  const onClose = useCallback(() => {
    dispatch({
      type: DashboardActions.ON_CLOSE,
    });
    setLastActedEntityId(undefined);
  }, [dispatch]);

  return {
    inViewDialogState,
    dispatch,
    onClose,
    setLastActedEntityId,
    lastActedEntityId,
  };
}

export default useDashboardActions;
