import { LearnerProfileDetailsFragment } from '@/modules/learner/views/LearnerProfile/graphql/fragments/generated/LearnerProfileDetails';
import { OnLearnerProfileAction } from '@/modules/learner/views/LearnerProfile/hooks/useLearnerProfile/actions';
import { ReportMetadataType } from '@/modules/report/hooks/useReportMetadata/types';
import { ReportEditorChartWidgetTypeFragment } from '@/modules/report/views/ReportEditor/graphql/fragments/generated/ReportEditorChartWidgetType';
import { WidgetLayoutType } from '@/types/schema';

export enum EmployeeProfilePages {
  Overview = 'overview',
  Activities = 'activities',
  Skills = 'skills',
  Mindsets = 'mindsets',
  Goals = 'goals',
  Team = 'team',
  About = 'about',
}

export type EmployeeProfileWidgetProps = {
  user: LearnerProfileDetailsFragment;
  isCurrentUser: boolean;
};

export type EmployeeProfileWidgetPropsWithMetadata = EmployeeProfileWidgetProps & {
  reportAttributes: ReportMetadataType;
};

export type EmployeeProfileWidgetPropsWithOnAction = EmployeeProfileWidgetProps & {
  onAction: OnLearnerProfileAction;
};

export type EmployeeProfileDetailsProps = {
  userId: string;
  type: EmployeeProfilePages;
};

export interface UserProfileWidgetProps {
  chart: ReportEditorChartWidgetTypeFragment;
  reportAttributes: ReportMetadataType;
  user: LearnerProfileDetailsFragment;
  widgetLayout: WidgetLayoutType;
  setToCenter?: boolean;
  isDefaultRestricted?: boolean;
}
