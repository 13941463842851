import { ExchangeInput, Operation } from 'urql';
import { filter, fromPromise, map, merge, mergeMap, pipe, share, Source, takeUntil } from 'wonka';

function runAfterInteraction() {
  return new Promise((resolve, reject) => {
    resolve(null);
  });
}

const interactionManagerExchange = ({ forward }: ExchangeInput) => {
  return (ops$: Source<Operation>) => {
    const sharedOps$ = pipe(ops$, share);
    const withToken$ = pipe(
      sharedOps$,
      // Filter by non-teardowns
      filter((operation) => operation.kind !== 'teardown'),
      mergeMap((operation) => {
        const { key } = operation;
        // Listen for cancellation events for this operation
        const teardown$ = pipe(
          sharedOps$,
          filter((op) => op.kind === 'teardown' && op.key === key),
        );
        return pipe(
          fromPromise(runAfterInteraction()),
          // Don't bother to forward the operation, if it has been cancelled
          // while we were refreshing
          takeUntil(teardown$),
          map(() => {
            return operation;
          }),
        );
      }),
    );

    // We don't need to do anything for teardown operations
    const withoutToken$ = pipe(
      sharedOps$,
      filter((operation) => operation.kind === 'teardown'),
    );

    return pipe(merge([withToken$, withoutToken$]), forward);
  };
};

export default interactionManagerExchange;
