import dateFnsFormat from 'date-fns/format';
import isThisYear from 'date-fns/isThisYear';

import { DateLike } from './types';

export enum DateFormat {
  /**
   * "4:32 PM"
   */
  Time = 'TIME',

  /**
   * "Wed"
   */
  Day = 'DAY',

  /**
   * "22 Feb"
   *
   * "22 February"
   *
   * "22 Feb 2023"
   *
   * "22 February 2023"
   */
  Date = 'DATE',

  /**
   * "Wed, 4:32 PM"
   */
  DayTime = 'DAY_TIME',

  /**
   * "Wed, 22 Feb"
   *
   * "Wed, 22 February"
   *
   * "Wed, 22 Feb 2023"
   *
   * "Wed, 22 February 2023"
   */
  DayDate = 'DAY_DATE',

  /**
   * "22 Feb, 4:32 PM"
   *
   * "22 February, 4:32 PM"
   *
   * "22 Feb 2023, 4:32 PM"
   *
   * "22 February 2023, 4:32 PM"
   */
  DateTime = 'DATE_TIME',

  /**
   * "Wed, 22 Feb, 4:32 PM"
   *
   * "Wed, 22 February, 4:32 PM"
   *
   * "Wed, 22 Feb 2023, 4:32 PM"
   *
   * "Wed, 22 February 2023, 4:32 PM"
   */
  DayDateTime = 'DAY_DATE_TIME',
}

const FORMAT_DAY = 'EEE';
const FORMAT_MONTH = 'MMM';
const FORMAT_MONTH_FULL = 'MMMM';
const FORMAT_DATE = 'd';
const FORMAT_YEAR = 'yyyy';
const FORMAT_TIME = 'h:mm a';

interface Options {
  includeYear?: boolean;
  showFullMonth?: boolean;
}

// IDEA: use date-fns/toDate, date-fns/parseISO
export function toDate(dateLike: DateLike) {
  if (typeof dateLike === 'string') {
    const _date = new Date(dateLike);
    if (isNaN(_date.valueOf())) {
      throw new Error(`invalid date string "${dateLike}" pased to toDate`);
    }

    return _date;
  }

  return dateLike;
}

export const formatDate = (
  date: DateLike,
  format: DateFormat,
  options: Options = { includeYear: false, showFullMonth: true },
) => {
  let dateFormat: string;
  date = toDate(date);
  const shouldIncludeYear = options.includeYear || !isThisYear(date);
  const dateWithMonthFormat = options?.showFullMonth
    ? `${FORMAT_DATE} ${FORMAT_MONTH_FULL}`
    : `${FORMAT_DATE} ${FORMAT_MONTH}`;
  const dateWithYearFormat = shouldIncludeYear
    ? `${dateWithMonthFormat} ${FORMAT_YEAR}`
    : dateWithMonthFormat;

  switch (format) {
    case DateFormat.Time: {
      dateFormat = FORMAT_TIME;
      break;
    }
    case DateFormat.Day: {
      dateFormat = FORMAT_DAY;
      break;
    }
    case DateFormat.Date: {
      dateFormat = dateWithYearFormat;
      break;
    }
    case DateFormat.DayTime: {
      dateFormat = `${FORMAT_DAY}, ${FORMAT_TIME}`;
      break;
    }
    case DateFormat.DayDate: {
      dateFormat = `${FORMAT_DAY}, ${dateWithYearFormat}`;
      break;
    }
    case DateFormat.DateTime: {
      dateFormat = `${dateWithYearFormat}, ${FORMAT_TIME}`;
      break;
    }
    case DateFormat.DayDateTime: {
      dateFormat = `${FORMAT_DAY}, ${dateWithYearFormat}, ${FORMAT_TIME}`;
      break;
    }
    default: {
      throw new Error(`Invalid format enum value: ${format}`);
    }
  }

  return dateFnsFormat(date, dateFormat);
};

export const formatDateWithYear = (
  date: DateLike,
  format: DateFormat,
  options: Omit<Options, 'includeYear'> = { showFullMonth: true },
) => {
  return formatDate(date, format, { ...options, includeYear: true });
};
