import React from 'react';

import dynamic from '@/utils/dynamic';

const Emoji = dynamic(
  () =>
    import(
      /* webpackChunkName: 'Emoji' */
      './Emoji'
    ),
);

const SuspendedEmoji = (props: any) => {
  return (
    <React.Suspense fallback={null}>
      <Emoji {...props} tooltip />
    </React.Suspense>
  );
};

export default SuspendedEmoji;
