import { OptimisticMutationResolver } from '@urql/exchange-graphcache';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import { OkrTypeEnum, UpdatedChildOkrOrder } from '@/types/schema';

import {
  AlignmentKeyResultDetailsFragment,
  AlignmentKeyResultDetailsFragmentDoc,
  AlignmentObjectiveDetailsFragment,
  AlignmentObjectiveDetailsFragmentDoc,
  KeyResultOkrDetailsFragmentDoc,
  ObjectiveOkrDetailsFragment,
  ObjectiveOkrDetailsFragmentDoc,
} from '../../views/OKRDetails/graphql/fragments/generated/OkrDetails';
import {
  UpdateChildOkRsOrderMutation,
  UpdateChildOkRsOrderMutationVariables,
} from '../mutations/generated/updateChildOKRsOrder';

type ChildOKRType = AlignmentKeyResultDetailsFragment | AlignmentObjectiveDetailsFragment;

const sortChildOKRs = (
  childOKRs: ChildOKRType[],
  updatedChildOKROrders: UpdatedChildOkrOrder[],
) => {
  const updatedChildOKROrderById = keyBy(updatedChildOKROrders, 'okrId');
  const updatedChildOKRs = childOKRs.map((childOKR) => {
    const childOrder =
      updatedChildOKROrderById[childOKR.id]?.childOrder ?? childOKR.childOrder ?? 0;

    return { ...childOKR, childOrder };
  });

  return sortBy(updatedChildOKRs, (childOKR) => {
    if (childOKR.type === OkrTypeEnum.KeyResult) {
      return childOKR.childOrder;
    }

    return childOKR.childOrder + updatedChildOKRs.length + 1;
  });
};

const updateChildOKRsOrder: OptimisticMutationResolver = (variables, cache) => {
  const updatedChildOKROrders = (variables as UpdateChildOkRsOrderMutationVariables).data
    .updatedChildOKROrders;

  const childOKRId = updatedChildOKROrders[0].okrId;
  const childOKR =
    cache.readFragment(AlignmentKeyResultDetailsFragmentDoc, { id: childOKRId }) ||
    cache.readFragment(AlignmentObjectiveDetailsFragmentDoc, { id: childOKRId });

  if (!childOKR) {
    return null;
  }

  const parentOKRId = (variables as UpdateChildOkRsOrderMutationVariables).data.parentOKRId;
  const parentOKR =
    cache.readFragment(KeyResultOkrDetailsFragmentDoc, { id: parentOKRId }) ||
    cache.readFragment(ObjectiveOkrDetailsFragmentDoc, { id: parentOKRId });

  if (!parentOKR) {
    return null;
  }

  const { id, type, childOKRs } = parentOKR as ObjectiveOkrDetailsFragment;

  const response: UpdateChildOkRsOrderMutation['updateChildOKRsOrder'] = {
    success: true,
    parentOKR: {
      id,
      childOKRs: childOKRs ? sortChildOKRs(childOKRs, updatedChildOKROrders) : [],
      __typename: type === OkrTypeEnum.Objective ? 'ObjectiveType' : 'KeyResultType',
    },
    __typename: 'UpdateChildOKRsOrderPayload',
  };

  return response;
};

export default updateChildOKRsOrder;
