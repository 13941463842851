// libs
import React from 'react';

import { TFunction } from '@lyearn/i18n';
import { Typography } from '@lyearn/ui';
import { Blocks, Features } from '@/components/PageEditor/types';
import routes from '@/pages/corporate/routes';

import { MeetingViewsEnum } from '../../type';

export enum SidePanelTabs {
  Details = 'details',
  Recap = 'recap',
  Notes = 'notes',
}

type Props = {
  t: TFunction;
  meetingId: string;
  mainTabId: MeetingViewsEnum;
  meetingInstanceId: string;
};

export const getTMSidebarTab = ({ meetingId, meetingInstanceId, mainTabId, t }: Props) => [
  {
    name: SidePanelTabs.Details,
    value: SidePanelTabs.Details,
    label: <Typography variant="heading-xxs">{t('Common:Details')}</Typography>,
    to: routes.teamMeetingEditor(meetingId, meetingInstanceId, mainTabId, SidePanelTabs.Details),
  },
  {
    name: SidePanelTabs.Recap,
    value: SidePanelTabs.Recap,
    label: <Typography variant="heading-xxs">{t('1on1s:Recap')}</Typography>,
    to: routes.teamMeetingEditor(meetingId, meetingInstanceId, mainTabId, SidePanelTabs.Recap),
  },
  {
    name: SidePanelTabs.Notes,
    value: SidePanelTabs.Notes,
    label: <Typography variant="heading-xxs">{t('Common:Notes')}</Typography>,
    to: routes.teamMeetingEditor(meetingId, meetingInstanceId, mainTabId, SidePanelTabs.Notes),
  },
];

export const editorParams = { rootType: Blocks.SESSION_PAGE, featureType: Features.MEETING };
