import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

export const PARENT_ROUTE = '/session';
const ADMIN_EVENT_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const eventAdminRoutes = {
  AdminEventCreator: () => `${ADMIN_EVENT_PARENT_ROUTE}/create`,
  AdminEventEditor: (eventId: string) => `${ADMIN_EVENT_PARENT_ROUTE}/${eventId}/edit`,
  AdminEventDuplicator: (eventId: string) => `${ADMIN_EVENT_PARENT_ROUTE}/${eventId}/duplicate`,
  AdminOfflineEventCreator: () => `${ADMIN_EVENT_PARENT_ROUTE}/offline/create`,
  AdminExternalEventCreator: () => `${ADMIN_EVENT_PARENT_ROUTE}/external/create`,
  AdminEventDetails: (eventId: string) => `${ADMIN_EVENT_PARENT_ROUTE}/${eventId}/details`,
  AdminEventAttendees: (eventId: string) => `${ADMIN_EVENT_PARENT_ROUTE}/${eventId}/attendees`,
  AdminEventReport: (eventId: string) => `${ADMIN_EVENT_PARENT_ROUTE}/${eventId}/reports`,
};

const eventLearnerRoutes = {
  EventCreator: () => `${PARENT_ROUTE}/create`,
  EventEditor: (eventId: string) => `${PARENT_ROUTE}/${eventId}/edit`,
  EventDuplicator: (eventId: string) => `${PARENT_ROUTE}/${eventId}/duplicate`,
  OfflineEventCreator: () => `${PARENT_ROUTE}/offline/create`,
  ExternalEventCreator: () => `${PARENT_ROUTE}/external/create`,
  EventDetails: (eventId: string) => `${PARENT_ROUTE}/${eventId}/details`,
  EventDetailsPreview: (eventId: string) => `${PARENT_ROUTE}/${eventId}/preview`,
  EventAttendees: (eventId: string) => `${PARENT_ROUTE}/${eventId}/attendees`,
  LiveSession: (eventId: string) => `${PARENT_ROUTE}/${eventId}`,
  DiscoverEvents: () => `${PARENT_ROUTE}`,
  DiscoverAllEvent: () => `${PARENT_ROUTE}/all`,
  DiscoverMyEvent: () => `${PARENT_ROUTE}/my`,
  DiscoverCreatedByMeEvents: () => `${PARENT_ROUTE}/me`,
  RecordingEvent: (eventId: string) => `/recorder/conference/${eventId}`,
  StreamingEvent: (eventId: string) => `/streamer/conference/${eventId}`,
};

const routes = {
  ...eventAdminRoutes,
  ...eventLearnerRoutes,
};

export default routes;
