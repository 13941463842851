import { SidePanelTabs } from '@/modules/team-meetings/views/TMEditor/components/SidePanel/utils';
import { MeetingViewsEnum } from '@/modules/team-meetings/views/TMEditor/type';

const adminRoutes = {};

const BASE_PATH = '/team-meetings';

const learnerRoutes = {
  teamMeetingsDashboard: () => BASE_PATH,
  teamMeetingDetails: (id: string) => `${BASE_PATH}/${id}/details`,
  teamMeetingEditor: (
    id: string,
    meetingInstanceId: string,
    mainTabId: MeetingViewsEnum = MeetingViewsEnum.Agendas,
    subTabId: SidePanelTabs = SidePanelTabs.Details,
  ) => `${BASE_PATH}/${id}/${meetingInstanceId}/${mainTabId}/${subTabId}`,
};

export { adminRoutes, learnerRoutes };
export default learnerRoutes;
