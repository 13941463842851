// libs
import React from 'react';

import { useTranslation } from '@lyearn/i18n';
import { LyearnLogo } from '@lyearn/icons';
import { cn } from '@lyearn/style';
// components
import { Stack } from '@lyearn/ui';
import { Image } from '@/components/Image';
import UserMenu from '@/components/UserMenu';
import { AppConfig } from '@/hooks/useAppConfig';

const userMenuOverrides = {
  avatarClassname: 'border border-solid border-interactive-secondary-hover',
  avatarSize: 28,
};

function UserInfoWithWorkspace({
  props,
  className,
}: {
  props: AppConfig['site'];
  className?: string;
}) {
  const { t } = useTranslation();
  const { logoUrl, appUrl, appUrlTarget, name } = props;

  return (
    <Stack
      alignItems="center"
      className={cn(' sticky top-0 z-50 mb-6 bg-white px-20 pb-16 pt-16', className)}
      justifyContent="between">
      <a href={appUrl || '/'} rel="noreferrer" target={appUrlTarget}>
        {logoUrl ? (
          <Image
            useImg
            alt={t('Common:LogoOfImage', { imageAlt: name })}
            className="h-24"
            src={logoUrl}
          />
        ) : (
          <LyearnLogo className="h-24" fill="currentColor" />
        )}
      </a>
      <UserMenu overrides={userMenuOverrides} />
    </Stack>
  );
}

export default React.memo(UserInfoWithWorkspace);
