import { Dispatch, SetStateAction } from 'react';
import { useSessionStorage } from 'react-use';

function useCurrentTabStorage<T>(key: string, initialValue?: T) {
  // let tabID = '';

  // if (typeof window !== 'undefined') {
  //   if (!window.name) {
  //     window.name = new Date().getTime().toString(); // Using timestamp as a unique ID
  //   }

  //   tabID = window.name;
  // }

  // const resolvedKey = `${tabID}_${key}`;
  const [state, set] = useSessionStorage<T>(key, initialValue);

  return { state, set: set as Dispatch<SetStateAction<T | undefined>> };
}

export default useCurrentTabStorage;
