import React from 'react';

import { SidebarItems, SideBarMode } from '@/modules/corporate/modules/admin/hooks/useSidebar';

import Section from './Section';

const SidebarItem = ({
  sidebarItems,
  mode,
  className,
}: {
  sidebarItems: SidebarItems;
  mode?: SideBarMode;
  className?: string;
}) => {
  return (
    <>
      {sidebarItems.map((section) => (
        <Section key={section.id} className={className} mode={mode} section={section} />
      ))}
    </>
  );
};

export default React.memo(SidebarItem);
