import React, { ComponentType, createElement, forwardRef } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import retryPromise from '@lyearn/core/utils/network/retryPromise';

type PreloadableComponent<T extends ComponentType<any>> = T & {
  preload: () => Promise<void>;
};

export default function dynamic<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
): PreloadableComponent<T> {
  const factoryWrapper = (): Promise<{ default: T }> => {
    return new Promise<{ default: T }>((resolve, reject) => {
      retryPromise<{ default: T }>(factory, 3)
        .then(resolve)
        .catch((reason) => {
          console.error('Error while dynamic import');
          ErrorReporter.error(reason);
          reject(reason);
        });
    });
  };
  const LazyComponent = React.lazy(factoryWrapper);
  let factoryPromise: Promise<void> | undefined;
  let LoadedComponent: T | undefined;

  const Component = forwardRef(function Loadable(props, ref) {
    return createElement(
      LoadedComponent ?? LazyComponent,
      Object.assign(ref ? { ref } : {}, props) as any,
    );
  }) as any as PreloadableComponent<T>;

  Component.preload = () => {
    if (!factoryPromise) {
      factoryPromise = factoryWrapper().then((module) => {
        LoadedComponent = module.default;
      });
    }

    return factoryPromise;
  };

  return Component;
}
