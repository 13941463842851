// libs
import React from 'react';

// components
import { Typography } from '@lyearn/ui';

// context
import { useUserInfo } from '../context';
// types
import { RoleProps } from '../types';

function Role({ variant = 'body-long-01', color = 'text-text-secondary', className }: RoleProps) {
  const { user } = useUserInfo();

  if (!('orgRole' in user) || !user?.orgRole?.name) {
    return null;
  }

  return (
    <Typography className={className} color={color} variant={variant}>
      {user.orgRole.name}
    </Typography>
  );
}

export default React.memo(Role);
