export const toBase64 = (str: string) =>
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : window.btoa(str);

export const loaderSvg = `
<svg aria-labelledby="nu38kza-aria" role="img" height="100%" width="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title id="nu38kza-aria">Loading...</title>
  <rect
    role="presentation"
    x="0"
    y="0"
    width="100%"
    height="100%"
    clip-path="url(#nu38kza-diff)"
    style="fill: url('#nu38kza-animated-diff')"
  ></rect>
  <defs role="presentation">
    <clipPath id="nu38kza-diff">
      <rect fill="#F2F2F2" height="100%" width="100%"></rect>
    </clipPath>
    <linearGradient id="nu38kza-animated-diff">
      <stop offset="0%" stop-color="#f2f2f4" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-2; -2; 1"
          keyTimes="0; 0.25; 1"
          dur="1.2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="50%" stop-color="#ececef" stop-opacity="1">
        <animate
          attributeName="offset"
          values="-1; -1; 2"
          keyTimes="0; 0.25; 1"
          dur="1.2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
      <stop offset="100%" stop-color="#f2f2f4" stop-opacity="1">
        <animate
          attributeName="offset"
          values="0; 0; 3"
          keyTimes="0; 0.25; 1"
          dur="1.2s"
          repeatCount="indefinite"
        ></animate>
      </stop>
    </linearGradient>
  </defs>
</svg>
`;
