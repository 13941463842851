import useNavigation from '@/hooks/useNavigation';
import routes from '@/pages/corporate/routes';

const useAdminRoute = () => {
  const navigation = useNavigation();

  const isAdminRoute = navigation.asPath.startsWith(routes.Admin());

  return Boolean(isAdminRoute);
};

const useRoleBasedNavigation = ({
  learner,
  admin,
  condition = () => true,
}: {
  learner: string;
  admin: string;
  condition?: () => boolean;
}) => {
  const isAdminRoute = useAdminRoute();
  if (isAdminRoute && condition()) {
    return admin;
  }

  return learner;
};

export { useAdminRoute, useRoleBasedNavigation };
