import React from 'react';

import Responsive from '@/components/Responsive';

import { DefaultHeaderProps } from '../types';
import DefaultHeaderDesktop from './index.desktop';
import DefaultHeaderMobile from './index.mobile';

const DefaultHeaderContainer = (props: DefaultHeaderProps) => {
  return (
    <Responsive>
      <Responsive.Default>
        <DefaultHeaderDesktop {...props} />
      </Responsive.Default>
      <Responsive.Mobile>
        <DefaultHeaderMobile {...props} />
      </Responsive.Mobile>
    </Responsive>
  );
};

export default DefaultHeaderContainer;
