import { useCallback } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { post } from '@/helper/request';
import { UserProfile } from '@/modules/auth/types';

import useWindowFocusInterval from './useWindowFocusInterval';

//5 minute accuracy
const LAST_ACTIVE_CALL_INTERVAL = 1000 * 60 * 5;
const LAST_ACTIVE_CALL_PATH = '/api/track/lastActive';

const useLastActiveAt = (user: UserProfile) => {
  const lastActiveCallback = useCallback(async () => {
    //call only if tab is in focus and we have user id i.e. user is logged in
    // document.hasFocus() is not reliable in iframe since parent can be in focus
    if (user.id && document.visibilityState === 'visible') {
      await post(LAST_ACTIVE_CALL_PATH, { url: window.location.href }).then((res) => {
        if (res?.success === false) {
          ErrorReporter.error(res.message);
        }
      });
    }
  }, [user.id]);

  useWindowFocusInterval(lastActiveCallback, LAST_ACTIVE_CALL_INTERVAL, true);
};

export default useLastActiveAt;
