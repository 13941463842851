import React from 'react';

import { Box } from '@lyearn/ui';

const AppContainer: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Box className={'flex min-h-screen flex-col bg-bg-primary text-text-primary'}>{children}</Box>
  );
};

export default AppContainer;
