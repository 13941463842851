import { useEffect } from 'react';

import { createContext } from '@lyearn/core/utils/context';

import { FeatureNudgeType } from '../types';

interface FeatureNudgeContextType {
  setFeatureNudge: React.Dispatch<React.SetStateAction<FeatureNudgeType | undefined>>;
}

const [FeatureNudgeContextProvider, useFeatureNudgeContext] =
  createContext<FeatureNudgeContextType>('FeatureNudgeContext');

export const useHelpMenuFeatureNudge = (featureNudge: FeatureNudgeType) => {
  const { setFeatureNudge } = useFeatureNudgeContext();

  useEffect(() => {
    setFeatureNudge(featureNudge);
  }, [featureNudge, setFeatureNudge]);

  useEffect(() => {
    return () => {
      setFeatureNudge(undefined);
    };
  }, [setFeatureNudge]);
};

export { useFeatureNudgeContext };
export default FeatureNudgeContextProvider;
