import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/external-content';
const ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const externalContentAdminRoutes = {
  ExternalContentCreator: () => `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/create`,
  ExternalContentEditor: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/edit`,
  ExternalContentDuplicate: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/duplicate`,
  AdminExternalContentDetails: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/details`,
  ExternalContentReport: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/reports`,
  AdminExternalContentPreviewDevices: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/preview-devices`,
  AdminExternalContentPreview: (contentId: string) =>
    `${ADMIN_EXTERNAL_CONTENT_PARENT_ROUTE}/${contentId}/preview`,
};

const externalContentLearnerRoutes = {
  ExternalContentDetails: (contentId: string) => `${PARENT_ROUTE}/${contentId}/details`,
};

const routes = {
  ...externalContentAdminRoutes,
  ...externalContentLearnerRoutes,
};

export const EXTERNAL_CONTENT_ADMIN_ROUTE_KEYS = {
  AdminExternalContentDetails: 'AdminExternalContentDetails',
  ExternalContentCreator: 'ExternalContentCreator',
  ExternalContentEditor: 'ExternalContentEditor',
  ExternalContentReport: 'ExternalContentReport',
};

export const EXTERNAL_CONTENT_LEARNER_ROUTE_KEYS = {
  ExternalContentDetails: 'ExternalContentDetails',
};

export const EXTERNAL_CONTENT_ROUTE_KEYS = {
  ...EXTERNAL_CONTENT_ADMIN_ROUTE_KEYS,
  ...EXTERNAL_CONTENT_LEARNER_ROUTE_KEYS,
};

export default routes;
