import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const JOURNEY = 'journey';
const JOURNEY_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}/${JOURNEY}`;

const adminRoutes = {
  JourneyManager: () => `${JOURNEY_PARENT_ROUTE}`,
  JourneyBuilder: (journeyId: string) => `${JOURNEY_PARENT_ROUTE}/${journeyId}/build`,
  AdminJourneyDetails: (journeyId: string) => `${JOURNEY_PARENT_ROUTE}/${journeyId}/details`,
  JourneyReport: (journeyId: string) => `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports`,
  // JourneyEmployeesReport: (journeyId: string) =>
  // `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports/employees`,
  // JourneyContentReport: (journeyId: string) =>
  //   `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports/content`,
  // JourneySessionsReport: (journeyId: string) =>
  //   `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports/sessions`,
  // JourneyReviewsReport: (journeyId: string) =>
  //   `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports/reviews`,
  // JourneyFormsReport: (journeyId: string) => `${JOURNEY_PARENT_ROUTE}/${journeyId}/reports/forms`,
};

const learnerRoutes = {
  JourneyListing: () => `${JOURNEY}`,
  JourneyConsumption: (journeyId: string) => `${JOURNEY}/${journeyId}/details`,
};

export { adminRoutes, learnerRoutes };
