import getEnabledItems from '@lyearn/core/utils/array/getEnabledItems';
import { TFunction } from '@lyearn/i18n';
import { ClockOutline, EditOutline, NoteOutline, PeopleOutline } from '@lyearn/icons';
import { CounterPill } from '@lyearn/ui';

export enum FeedbackTabs {
  MyFeedback = 'my_feedback',
  GivenByMe = 'given_by_me',
  MyTeam = 'my_team',
  Requests = 'requests',
}

export const DEFAULT_FEEDBACK_TAB = FeedbackTabs.MyFeedback;

export default function getTabItems({
  t,
  isManager,
  totalCount,
}: {
  t: TFunction;
  isManager: boolean;
  totalCount?: number;
}) {
  return getEnabledItems([
    {
      id: FeedbackTabs.MyFeedback,
      label: t('Feedback:FeedbackWall.Tabs.MyFeedback'),
      Icon: NoteOutline,
      enabled: true,
    },
    {
      id: FeedbackTabs.MyTeam,
      label: t('Feedback:FeedbackWall.Tabs.TeamFeedback'),
      Icon: PeopleOutline,
      enabled: isManager,
    },
    {
      id: FeedbackTabs.GivenByMe,
      label: t('Feedback:FeedbackWall.Tabs.GivenByMe'),
      Icon: EditOutline,
      enabled: true,
    },
    {
      id: FeedbackTabs.Requests,
      label: t('Feedback:FeedbackWall.Tabs.Requests'),
      Icon: ClockOutline,
      endAdorment: totalCount !== undefined ? <CounterPill label={totalCount} /> : null,
      enabled: true,
    },
  ]);
}
