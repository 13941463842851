import React, { useEffect } from 'react';

import { jsonParse } from '@lyearn/core/utils/json';
import useNavigation from '@/hooks/useNavigation';
import useParams from '@/hooks/useParams';

import routes from '../routes';
import { pageEnum, propsType } from './types';

// ref: https://dev.to/mapleleaf/indexing-objects-in-typescript-1cgi
function hasKey<O extends {}>(obj: O, key: PropertyKey): key is keyof O {
  return key in obj;
}

const PageResolver = ({ children }: { children: React.ReactNode }) => {
  const navigation = useNavigation();
  const { page, props: propsParams, ...restQuery } = useParams() as Record<string, string>;
  const isCorrectPage = page && hasKey(pageEnum, page);

  useEffect(() => {
    if (!isCorrectPage) return;

    const { data: props } = jsonParse<propsType>(propsParams);

    if (routes[pageEnum[page]].length === 1 && !props?.id) {
      throw new Error("Misconfigured 'page' or 'props' parameter");
    }

    const path = routes[pageEnum[page]](props?.id!);
    const { state, ...newQuery } = restQuery;
    navigation.replace(path, { appendQuery: false, query: newQuery });
  }, [isCorrectPage, navigation, page, propsParams, restQuery]);

  if (!isCorrectPage) return <>{children}</>;

  return null;
};

export default PageResolver;
