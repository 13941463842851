import { css } from '@emotion/css';

export const sidebarItemLeftPadding = (level: number, defaultPadding = 1.2) => css`
  padding-left: ${defaultPadding + level * 2 + (level ? 0.8 : 0)}rem;
`;

export const SIDEBAR_ANIMATION = {
  animate: { height: 'auto', opacity: 1 },
  exit: { height: 0, opacity: 0 },
  initial: { height: 0, opacity: 0 },
  transition: {
    layout: {
      type: 'spring',
      bounce: 0.4,
    },
  },
};
