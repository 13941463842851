// libs
import React from 'react';

// components
import { Typography } from '@lyearn/ui';

// context
import { useUserInfo } from '../context';
// types
import { UniqueIdProps } from '../types';

function UniqueId({
  variant = 'body-short-03',
  color = 'text-text-secondary',
  className,
}: UniqueIdProps) {
  const { user } = useUserInfo();

  if (!user?.uniqueId) {
    return null;
  }

  return (
    <Typography className={className} color={color} variant={variant}>
      {user.uniqueId}
    </Typography>
  );
}

export default React.memo(UniqueId);
