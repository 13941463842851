import _noop from 'lodash/noop';

import { Properties } from '../types';
import { useAnalyticsReturn } from './types';

function identify(userId: string, traits?: Properties): void {
  if (window.analytics) {
    window.analytics.identify(userId, traits);
  }
  if (window.rudderanalytics) {
    window.rudderanalytics.identify(userId, traits);
  }
}

function track(event: string, properties?: Properties): void {
  if (window.analytics) {
    window.analytics.track(event, properties);
  }
  if (window.rudderanalytics) {
    window.rudderanalytics.track(event, properties);
  }
}

function page(category?: string, name?: string, properties?: Properties): void {
  if (window.analytics) {
    window.analytics.page(category, name, properties);
  }
  if (window.rudderanalytics) {
    window.rudderanalytics.page(category, name, properties);
  }
}

export default function useAnalytics(): useAnalyticsReturn {
  return {
    identify: identify || _noop,
    track: track || _noop,
    trackPage: page || _noop,
  };
}

export function useRudderAnalytics(): useAnalyticsReturn {
  const rudderanalytics = window.rudderanalytics || {};

  return {
    identify: rudderanalytics.identify || _noop,
    track: rudderanalytics.track || _noop,
    trackPage: rudderanalytics.page || _noop,
  };
}

export function useSegmentAnalytics(): useAnalyticsReturn {
  const analytics = window.analytics || {};

  return {
    identify: analytics.identify || _noop,
    track: analytics.track || _noop,
    trackPage: analytics.page || _noop,
  };
}
