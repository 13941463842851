import { useCallback } from 'react';

import { useTranslation } from '@lyearn/i18n';
import { position, useSnackbar } from '@lyearn/ui';

const useDefaultErrorSnackbar = () => {
  const { t } = useTranslation();
  const { showErrorSnackbar } = useSnackbar();

  const showDefaultErrorSnackbar = useCallback(
    (position?: position) =>
      showErrorSnackbar({
        message: t('Common:Error.SomethingWentWrong'),
        position: position,
      }),
    [showErrorSnackbar, t],
  );

  return { showDefaultErrorSnackbar };
};

export default useDefaultErrorSnackbar;
