import React, { useEffect } from 'react';

import { useUserContext } from '@/modules/auth/hooks/useUser';

const GoogleAnalyticsTriggers: React.FC<{ GA_TRACKING_ID: string }> = ({ GA_TRACKING_ID }) => {
  const user = useUserContext();

  useEffect(() => {
    const gtag = (window as any).gtag;
    if (gtag) {
      gtag('config', GA_TRACKING_ID, { user_id: user.id });
    }
  }, [GA_TRACKING_ID, user.id]);

  return null;
};

export default GoogleAnalyticsTriggers;
