import { useEffect } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
// import config from 'config';
// import { useAddNotificationTokenMutation } from '@/hooks/useNotification/mutations/generated/addNotificationToken';
// import { useUserContext } from '@/modules/auth/hooks/useUser';
// import { firebase, isMessagingSupported } from '@/modules/session/utils/getFirebase';
// import { NotificationTokenType } from '@/types/schema';

export default function useNotification() {
  // we are only registering service worker on production as recommended
  // const user = useUserContext();
  // const [, addNotificationToken] = useAddNotificationTokenMutation();
  // useEffect(() => {
  //   if (!user.id) {
  //     // if user logout unregister the service worker
  //     unregister();
  //   } else {
  //     register(user.id, addNotificationToken);
  //   }
  // }, [addNotificationToken, user]);
  useEffect(() => {
    unregister();
  }, []);
}

// function register(userId: string, addNotificationToken: Function) {
//   if (config.env === 'production' && 'serviceWorker' in navigator) {
//     if (isMessagingSupported()) {
//       const messaging = firebase.messaging();
//       navigator.serviceWorker.register('/sw.js').then(async function (register) {
//         messaging
//           .requestPermission()
//           .then(function getFirebaseToken() {
//             return messaging.getToken({
//               serviceWorkerRegistration: register,
//               vapidKey: config.firebase_web_notification_token,
//             });
//           })
//           .then(function registerWithBackend(tokenId: string) {
//             addNotificationToken({
//               token: {
//                 tokenId,
//                 deviceId: tokenId,
//                 type: NotificationTokenType.Web,
//               },
//               userId,
//             });
//           })
//           .catch(function (err: Error) {
//             ErrorReporter.error(err);
//           });
//       });
//       messaging.onMessage(function () {});
//     }
//   }
// }

function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      })
      .catch((error) => {
        ErrorReporter.error(error);
      });
  }
}
