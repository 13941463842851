import { Dispatch } from 'react';

import { createContext } from '@lyearn/core/utils/context';

import { ResourceTypeEnum } from '../../../types/schema';
import { BreadcrumbModuleEnum } from '../hooks/useBreadcrumb';

export type BreadcrumbResourceType =
  | ResourceTypeEnum
  | 'LIBRARY'
  | 'ALL_CONTENT'
  | 'MOST_POPULAR'
  | 'NEWLY_ADDED'
  | BreadcrumbModuleEnum
  | undefined;

export type BreadcrumbModuleType =
  | {
      type: BreadcrumbResourceType;
    }
  | {
      type: 'PROFILE';
      meta: {
        profilePicture?: string | null;
      };
    };

export type Breadcrumb = {
  pageTitle?: string | null;
  id: string;
  path?: string;
  module?: BreadcrumbModuleType;
  query?: Record<string, string | string[]>;
};

export type BreadcrumbHistoryValue = Breadcrumb & {
  lastActiveId?: string;
};

export type BreadcrumbHistory = Record<string, BreadcrumbHistoryValue>;

type BreadcrumbContextType = {
  breadcrumbs: Breadcrumb[];
  setBreadcrumb: (breadcrumbs: Breadcrumb[]) => void;
  onBreadcrumbClick: (breadcrumb: Breadcrumb) => void;
  breadcrumbHistory: BreadcrumbHistory;
  setBreadcrumbHistory: Dispatch<any>;
  clean: () => void;
  computeAndSetBreadcrumb: (pageId?: string) => void;
  setBreadcrumbUrl: (nextValue?: string[]) => void;
  breadcrumbUrl: string[] | null | undefined;
};

const [BreadcrumbContextProvider, useBreadcrumbContext] =
  createContext<BreadcrumbContextType>('BreadcrumbContext');

export { BreadcrumbContextProvider, useBreadcrumbContext };
