import { css } from '@emotion/css';

export const logoutItemStyles = css`
  &.MuiListItem-root:hover,
  &.MuiListItem-root:active {
    background-color: var(--bg-error-light);

    .MenuItem-label {
      color: var(--text-error-dark);
    }
  }
`;
