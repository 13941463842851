import React, { useMemo } from 'react';

import { Stack } from '@lyearn/ui';

import { UserContext } from '../context';
import { UserProps } from '../types';

// 'items-center' in className is overriden by Stack alignItems prop, so use alignItems instead
const User: React.FC<UserProps> = ({ alignItems, onClick, user, children, className }) => {
  const value = useMemo(() => ({ user }), [user]);

  return (
    <UserContext.Provider value={value}>
      <Stack alignItems={alignItems} className={className} onClick={onClick}>
        {children}
      </Stack>
    </UserContext.Provider>
  );
};

export default User;
