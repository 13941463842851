import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import isArray from 'lodash/isArray';

import { JobEntityStatusEnum } from '@/types/schema';

import { SyncJiraTaskJobSubscription } from '../generated/syncJiraTaskJob';

const inValidateTasks = (cache: Cache) => {
  const listOperationName = ['actionItems', 'groupedActionItems', 'okr'];
  cache
    .inspectFields('Query')
    .filter((q) => {
      if (isArray(listOperationName)) return listOperationName.includes(q.fieldName);
      return q.fieldName === listOperationName;
    })
    .forEach((query) => cache.invalidate('Query', query.fieldName, query.arguments!));
};

export function getOKRJobUpdater(): UpdateResolver {
  return (result, args, cache) => {
    const okrSyncJobResponse = result as SyncJiraTaskJobSubscription;

    if (okrSyncJobResponse.job.status === JobEntityStatusEnum.Completed) {
      inValidateTasks(cache);
    }
  };
}

// export function getOKRJobQueryUpdater(): UpdateResolver {
//   return (result, args, cache) => {
//     const okrSyncJobQueryResponse = result as OkrJobTaskSyncQuery;
//
//     if (okrSyncJobQueryResponse.job?.status === JobEntityStatusEnum.Completed) {
//       inValidateTasks(cache);
//     }
//   };
// }
