import { Client } from 'urql';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { jsonParse } from '@lyearn/core/utils/json';
import { UserProfile } from '@/modules/auth/types';

import { UserProfileDocument } from '../graphql/queries/generated/user';

export const fetchUser = (client: Client, userId: string) => {
  try {
    return client
      .query(UserProfileDocument, { userId }, { requestPolicy: 'network-only' })
      .toPromise()
      .then((result) => {
        const serverUser = result?.data?.user;

        if (!serverUser) {
          throw new Error(`Error getting userId: ${userId}`);
        }
        if (result?.error) {
          console.log('Server User Fetching Errors', result?.error);
        }

        const { data: userMeta } = jsonParse<UserProfile['meta']>(serverUser.meta);
        return { ...serverUser, meta: userMeta ?? {} } as UserProfile;
      });
  } catch (error) {
    __IS_BROWSER__ && ErrorReporter.error(error);
    return Promise.reject(error);
  }
};
