import config from 'config';

import GoogleAnalyticsTriggers from './google/GoogleAnalyticsTriggers';
import RudderAnalyticsTriggers from './rudderstack/RudderAnalyticsTriggers';
import SegmentAnalyticsTriggers from './segment/SegmentAnalyticsTriggers';

export default function AnalyticsTriggers() {
  if (config.env === 'development') {
    return null;
  }

  return (
    <>
      {!config.google.analytics_id ? null : (
        <GoogleAnalyticsTriggers GA_TRACKING_ID={config.google.analytics_id} />
      )}
      {!config.segment_analytics_id ? null : <SegmentAnalyticsTriggers />}
      {!config.rudderstack.write_key || !config.rudderstack.data_plane_url ? null : (
        <RudderAnalyticsTriggers />
      )}
    </>
  );
}
