import { SidePanelTabs } from './components/SidePanel/utils';

// better name for both @iamsaumya
export type TMEditorProps = {
  id: string;
  meetingInstanceId: string;
  mainTabId: MeetingViewsEnum;
  subTabId: SidePanelTabs;
};

export enum MeetingViewsEnum {
  Tasks = 'tasks',
  OKRs = 'okrs',
  Agendas = 'agendas',
  Insights = 'insights',
}
