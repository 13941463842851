import React from 'react';

import { useTranslation } from '@lyearn/i18n';

import Admin from './components/Admin';
import useSidebar from './hooks/useSidebar';

interface AdminContainerProps {
  children: React.ReactNode;
}

function AdminContainer(props: AdminContainerProps) {
  const { t } = useTranslation('Admin');
  const sidebarItems = useSidebar(t);

  return <Admin sidebarItems={sidebarItems}>{props.children}</Admin>;
}

export default AdminContainer;
