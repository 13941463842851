import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import { mergeDeep } from '@lyearn/core/utils/immutable';
import { UpdateNotificationTemplateInput } from '@/types/schema';

import { NotificationTemplateFragmentDoc } from '../fragment/generated/notificationTemplate';

const updateNotificationTemplate: OptimisticMutationResolver = (variables, cache) => {
  const input = variables!.input as UpdateNotificationTemplateInput;
  const notificationTemplate = cache.readFragment(NotificationTemplateFragmentDoc, {
    id: input.notificationTemplateId,
  });

  return {
    success: true,
    notificationTemplate: mergeDeep(notificationTemplate, {
      __typename: 'NotificationTemplate', // TODO: figure a better way to this than hardcoding
      id: input.notificationTemplateId,
      tasksRequired: input.tasksRequired,
    }),
    __typename: 'UpdateNotificationTemplatePayload',
  };
};

export default updateNotificationTemplate;
