import Head from 'next/head';

import { useAppConfig } from '@/hooks/useAppConfig';
import { Maybe } from '@/types/schema';

const DocumentTitle = ({ title }: { title?: Maybe<string> }) => {
  const { site: appConfig } = useAppConfig();
  const defaultTitle = appConfig.name;
  const adaptedTitle = title ? `${title} / ${defaultTitle}` : defaultTitle;
  return (
    <Head>
      <title key="title">{adaptedTitle}</title>
    </Head>
  );
};

export default DocumentTitle;
