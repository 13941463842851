import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  TrackDepartmentDetailsFragment,
  TrackDepartmentDetailsFragmentDoc,
} from '../../../departments/views/DepartmentDetails/graphql/queries/generated/departmentDetails';
import {
  CreateOrgRoleMutation,
  CreateOrgRoleMutationVariables,
} from '../mutations/generated/createOrgRole';

const createOrgRoleUpdater: UpdateResolver<
  CreateOrgRoleMutation,
  CreateOrgRoleMutationVariables
> = (result, args, cache, info) => {
  const trackId = args.data.trackId;
  const orgRole = result?.createOrgRole?.orgRole;

  if (trackId) {
    const track = cache.readFragment<TrackDepartmentDetailsFragment>(
      TrackDepartmentDetailsFragmentDoc,
      { id: trackId, __typename: 'TrackType' },
    );
    if (track) {
      cache.writeFragment(TrackDepartmentDetailsFragmentDoc, {
        __typename: 'TrackType',
        ...track,
        orgRoles: [...(track.orgRoles || []), orgRole],
      });
    } else {
      invalidateQuery(['orgRoles', 'tracks', 'track'])(result, args, cache, info);
    }
  } else {
    invalidateQuery(['orgRoles', 'tracks', 'track'])(result, args, cache, info);
  }
};

export default createOrgRoleUpdater;
