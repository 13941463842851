import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';

const HOUR = 60 * 60 * 1000;
let hasNewVersion = false;

export const newVersionFound = () => {
  hasNewVersion = true;
};

if (__IS_BROWSER__) {
  //  6hr reset, after event is emitted on demand
  setInterval(newVersionFound, 6 * HOUR);
}

const useReloadOnUpdate = () => {
  const router = useRouter();

  useEffect(() => {
    const onRouteChangeStart = (url: string) => {
      if (hasNewVersion) {
        window.location.href = url;
      }
    };

    router.events.on('routeChangeStart', onRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
    };
  }, [router]);
};

export default useReloadOnUpdate;
