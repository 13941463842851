import { createContext } from '@lyearn/core/utils/context';

import { FeatureFlags } from './types';

export type FeatureFlagsMap = Record<FeatureFlags, boolean>;
export type FeatureFlagsContextType = { flags: FeatureFlagsMap };

const [FeatureFlagsContextProvider, useFeatureFlagsContext] =
  createContext<FeatureFlagsContextType>('FeatureFlagsContext');

export const useFeatureFlags = () => {
  const { flags } = useFeatureFlagsContext();

  if (!flags) {
    throw new Error('[FeatureFlagsContext] Not initialised');
  }

  return flags;
};

export { FeatureFlags, FeatureFlagsContextProvider };
