import { BackendModule } from 'i18next';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import retryPromise from '@lyearn/core/utils/network/retryPromise';

export const loadedNamespaces = new Set<string>();

const LazyImportPlugin: BackendModule = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {
    /* use services and options */
  },
  read: function (language, namespace, callback) {
    /* return resources */

    const importPromise = () =>
      import(/* webpackChunkName: "i18n/[request]" */ `../${language}/${namespace}.json`);

    retryPromise(importPromise, 3)
      .then((obj) => {
        callback(null, obj);
        loadedNamespaces.add(language + ' - ' + namespace);
      })
      .catch((err) => {
        ErrorReporter.error(err);
        callback(err, null);
      });
  },

  // only used in backends acting as cache layer
  save: function (language, namespace, data) {
    // store the translations
  },

  create: function (languages, namespace, key, fallbackValue) {
    /* save the missing translation */
  },
};

export default LazyImportPlugin;
