import { QuestionApplicableOnEnum } from '@/types/schema';

export enum QuestionManagerTabEnum {
  Active = 'active',
  Inactive = 'inactive',
}

export interface QuestionManagerProps {
  activeTab: QuestionManagerTabEnum;
  applicableOn: QuestionApplicableOnEnum;
}
