import { UpdateResolver } from '@urql/exchange-graphcache';

import { inspectCache, invalidateQuery, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  RevokeUserPublicApiCredentialsMutation,
  RevokeUserPublicApiCredentialsMutationVariables,
} from '../mutations/generated/revokeUserPublicAPICredentials';
import {
  UserPublicApiCredentialsDocument,
  UserPublicApiCredentialsQuery,
  UserPublicApiCredentialsQueryVariables,
} from '../queries/generated/userPublicAPICredentials';

const revokeUserPublicAPICredentialsUpdater: UpdateResolver<
  RevokeUserPublicApiCredentialsMutation,
  RevokeUserPublicApiCredentialsMutationVariables
> = (result, args, cache, info) => {
  const userPublicAPICredentialsId = args.input.filters.userPublicAPICredentialsId;

  if (!userPublicAPICredentialsId) {
    invalidateQuery('userPublicAPICredentials')(result, args, cache, info);
  } else {
    inspectCache(cache, 'Query', 'userPublicAPICredentials', (field) => {
      updateCache<UserPublicApiCredentialsQuery, UserPublicApiCredentialsQueryVariables>(
        cache,
        UserPublicApiCredentialsDocument,
        field.arguments as UserPublicApiCredentialsQueryVariables,
        (data) => {
          const edges = data?.userPublicAPICredentials.edges;

          if (edges) {
            data.userPublicAPICredentials.edges = edges.filter(
              ({ node }) => node.id !== userPublicAPICredentialsId,
            );
          }

          return data;
        },
      );
    });
  }
};

export default revokeUserPublicAPICredentialsUpdater;
