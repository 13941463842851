import concat from 'lodash/concat';

import { stopEventPropagation } from '@lyearn/core/utils/events';
import { TFunction } from '@lyearn/i18n';
import { EditOutline, ListFill, MoreHorizontalFill, TagFill, Trash2Outline } from '@lyearn/icons';
import { ActionMenu, ActionMenuItemType } from '@lyearn/molecules';
import { cn } from '@lyearn/style';
import { Stack } from '@lyearn/ui';
import { IconSettings } from '@/components/IconSettings';
import { SidebarItem } from '@/components/Panel/Sidebar/types';
import routes from '@/pages/corporate/routes/tasks';
import { FileCategoryEnum, IconSettings as IconSettingsType } from '@/types/schema';

import useDashboardActions, { DashboardActions } from '../../hooks/useDashboardActions';
import useFiles from '../../hooks/useFiles';

export const getIcon = (iconSettings?: IconSettingsType | null, size = 20, className = '') => {
  if (iconSettings) {
    return (
      <IconSettings Icon={TagFill} className={className} iconSettings={iconSettings} size={size} />
    );
  }

  return <ListFill className={cn('flex-shrink-0', className)} height={size} width={size} />;
};

const getFileActionItems = (t: TFunction) =>
  [
    {
      id: DashboardActions.EDIT_FILE,
      label: t('Common:Actions.Edit'),
      EndIcon: EditOutline,
    },
    {
      id: DashboardActions.DELETE_FILE,
      label: t('Common:Actions.Delete'),
      EndIcon: Trash2Outline,
      appearance: 'danger',
    },
  ] as ActionMenuItemType[];

const getActionMenu = ({
  t,
  file,
  dispatch,
}: {
  t: TFunction;
  file?: NonNullable<ReturnType<typeof useFiles>['files']>[0];
  dispatch: ReturnType<typeof useDashboardActions>['dispatch'];
}) => {
  if (!file) {
    return null;
  }

  if (file.category === FileCategoryEnum.File) {
    return (
      <Stack onClick={stopEventPropagation}>
        <ActionMenu<DashboardActions.EDIT_FILE | DashboardActions.DELETE_FILE>
          items={getFileActionItems(t)}
          onAction={(actionType) =>
            dispatch({
              type: actionType,
              payload: {
                file,
              },
            })
          }>
          {({ open }) => (
            <Stack
              alignItems="center"
              className={cn('ps-16 group-hover:block', {
                hidden: !open,
              })}>
              <MoreHorizontalFill className="h-20 w-20 rounded-4 fill-current text-icon-secondary hover:bg-interactive-secondary hover:text-icon-primary" />
            </Stack>
          )}
        </ActionMenu>
      </Stack>
    );
  }

  return null;
};

export default function getSidebarItems({
  t,
  files,
  systemGeneratedFiles,
  dispatch,
  fetching,
}: {
  t: TFunction;
  files?: ReturnType<typeof useFiles>['files'];
  systemGeneratedFiles?: ReturnType<typeof useFiles>['systemGeneratedFiles'];
  dispatch: ReturnType<typeof useDashboardActions>['dispatch'];
  fetching?: boolean;
}) {
  const systemGeneratedFileItems: SidebarItem[] = [
    {
      id: 'SYSTEM_GENERATED',
      type: 'section',
      fetching: fetching,
      items: systemGeneratedFiles?.length
        ? systemGeneratedFiles.map((file) => ({
            type: 'link',
            id: file.id,
            label: file.name,
            Icon: ListFill,
            iconSettings: file.iconSettings ? file.iconSettings : undefined,
            route: routes.TasksDashboard(file.id),
          }))
        : [],
    },
  ];

  // todo: change after BE supports parent
  const fileItems: SidebarItem[] = files?.length
    ? [
        {
          id: 'FILES',
          type: 'section',
          label: t('Task:List.Lists'),
          isExpandable: true,
          items: files.map((file) => ({
            type: 'link',
            id: file.id,
            label: file.name,
            Icon: ListFill,
            iconSettings: file.iconSettings ? file.iconSettings : undefined,
            route: routes.TasksDashboard(file.id),
            endAdorment: getActionMenu({ t, file, dispatch }),
          })),
        },
      ]
    : [];

  const sidebarItems = concat(systemGeneratedFileItems, fileItems);

  return sidebarItems;
}
