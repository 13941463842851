import { useEffect } from 'react';
import config from 'config';

import useRest from '@/helper/hooks/useRest';

export const CDN_COOKIE_EXPIRATION_NAME = 'cdnCookieExpirationTime';

interface ServerResponseType {
  expirationTime: number;
  success: boolean;
}

export const isCookieAboutToExpire = () => {
  const expirationTimeLocal = Number(localStorage.getItem(CDN_COOKIE_EXPIRATION_NAME));
  const upperBoundTime = Date.now() + 86400 * 1000; // one day from now
  const isCookieAboutToExpire = expirationTimeLocal < upperBoundTime;
  return isCookieAboutToExpire;
};

const useCdnCookie = () => {
  const fetchOptions = {
    method: 'GET',
    credentials: 'include',
  };

  const [fetchStatus, error, data, fetchCookie] = useRest(
    config.cdn_cookie_signer_url,
    fetchOptions,
  );

  useEffect(() => {
    const { expirationTime } = (data ?? {}) as ServerResponseType;
    if (expirationTime) {
      localStorage.setItem(CDN_COOKIE_EXPIRATION_NAME, expirationTime.toString());
    }
  }, [data]);
  return {
    fetchStatus,
    response: (data ?? {}) as ServerResponseType,
    fetchCookie,
  };
};

export default useCdnCookie;
