import React, { forwardRef, ReactNode } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

import { getLinkTarget } from '@lyearn/core/utils/iframe';

interface PressableStateCallbackType {
  readonly pressed: boolean;
}

export interface LinkProps extends Omit<NextLinkProps, 'href'> {
  to?: NextLinkProps['href'];
  href?: NextLinkProps['href'];
  children?: ReactNode | undefined | ((config: PressableStateCallbackType) => ReactNode);
  className?: string;
  target?: string;
  onClick?: React.MouseEventHandler;
  showHighlight?: boolean;
}

const DEFAULT_PRESSABLE_STATE: PressableStateCallbackType = { pressed: false };
const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { to, href, replace, children, className, target, ...rest } = props;
  const link = (to || href)!;

  let linkTarget = getLinkTarget(link, target);

  // next link crashes if link is undefined
  if (!link) {
    return <>{typeof children === 'function' ? children(DEFAULT_PRESSABLE_STATE) : children}</>;
  }

  return (
    <NextLink
      ref={ref}
      className={className}
      href={link}
      replace={replace}
      target={linkTarget}
      {...rest}>
      {typeof children === 'function' ? children(DEFAULT_PRESSABLE_STATE) : children}
    </NextLink>
  );
});

export default Link;
