import React from 'react';
import _concat from 'lodash/concat';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';

import { stopEventPropagation } from '@lyearn/core/utils/events';
import { List, MenuDivider, MenuItem, MenuLabel } from '@lyearn/ui';

import { ActionMenuItemType } from '../../types';
import ConfirmationItem from '../ActionMenuConfirmationItem';
import { ActionMenuListItemsProps } from '../ActionMenuListItems/types';

const getIsMenuItem = ({ type }: ActionMenuItemType) => !type || type === 'item';

function MenuListItems<T extends string = string>(props: ActionMenuListItemsProps<T>) {
  const {
    autoFocusItem = false,
    className = 'p-0 space-y-2',
    items,
    onAction,
    menuItemClassName,
  } = props;
  const initialFocusIndex = autoFocusItem ? items.findIndex(getIsMenuItem) : -1;

  return (
    <List className={className}>
      {items.map((item, index) => {
        const autoFocus = initialFocusIndex === index;

        switch (item.type) {
          case 'divider': {
            return <MenuDivider key={`MenuDivider-${index}`} {...item} />;
          }
          case 'label': {
            return <MenuLabel key={`MenuLabel-${index}`} className="block" {...item} />;
          }
          case 'confirmation': {
            return (
              <ConfirmationItem
                key={item.id}
                autoFocus={autoFocus}
                item={item}
                onAction={onAction as any}
              />
            );
          }
          default: {
            const { eventHandler, ...rest } = item;
            return (
              <MenuItem
                key={item.id}
                autoFocus={autoFocus}
                className={menuItemClassName}
                data-action={item.id}
                {...rest}
                onClick={(e) => {
                  stopEventPropagation(e);
                  onAction?.(item.id as T, item);
                }}
              />
            );
          }
        }
      })}
    </List>
  );
}

export default React.memo(MenuListItems) as React.FC<ActionMenuListItemsProps>;
