import { format } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

export const getCurrentTimeZoneValue = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getZonedTimeToUtc = (timezone: string, date: string) =>
  zonedTimeToUtc(date, timezone).toISOString();

export const getUtcToZonedTime = (timezone: string, date: string | Date) =>
  utcToZonedTime(date, timezone).toISOString();

export const getTimeWithoutZone = (date: string) => format(new Date(date), 'yyyy-MM-dd HH:mm:ss');
