import React from 'react';

import { BreadcrumbContextProvider } from '../../context';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

const BreadcrumbContext: React.FC<React.PropsWithChildren> = (props) => {
  const {
    breadcrumbs,
    setBreadcrumb,
    onBreadcrumbClick,
    breadcrumbHistory,
    setBreadcrumbHistory,
    clean,
    computeAndSetBreadcrumb,
    setBreadcrumbUrl,
    breadcrumbUrl,
  } = useBreadcrumb();

  return (
    <BreadcrumbContextProvider
      breadcrumbHistory={breadcrumbHistory}
      breadcrumbUrl={breadcrumbUrl}
      breadcrumbs={breadcrumbs}
      clean={clean}
      computeAndSetBreadcrumb={computeAndSetBreadcrumb}
      setBreadcrumb={setBreadcrumb}
      setBreadcrumbHistory={setBreadcrumbHistory}
      setBreadcrumbUrl={setBreadcrumbUrl}
      onBreadcrumbClick={onBreadcrumbClick}>
      {props.children}
    </BreadcrumbContextProvider>
  );
};

export default BreadcrumbContext;
