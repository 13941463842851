import { css } from '@emotion/css';

export const maxW264 = css`
  max-width: 26.4rem;
`;

export const requestIdStyles = css`
  cursor: pointer;

  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2.2rem;
`;
