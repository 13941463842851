import { useCallback } from 'react';
import { usePrevious, useUnmount } from 'react-use';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import {
  decodeJson,
  encodeJson,
  StringParam,
  UrlUpdateType,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { FilterState, FilterValue, SortState } from './types';

export const isSortByCreatedAt = (sort: SortState) => {
  const selectedSortBy = sort?.length ? sort[0] : {};

  return selectedSortBy.field === 'createdAt';
};

const IGNORED_FILTER_KEYS = ['userRecents', 'userTypes', 'moreFilters']; // these keys dont play role in UI, they are used to fetch data from api

export const isFilterApplied = (filter: FilterState) => {
  const filteredFilters = omit(filter, IGNORED_FILTER_KEYS) as FilterValue;

  return Boolean(Object.values(filteredFilters).find(hasFilterValue));
};

// todo refactor: use config of varoius types of filters
export const hasFilterValue = (filterValue: FilterValue): number => {
  if (typeof filterValue === 'boolean') {
    return 1;
  }

  if (Array.isArray(filterValue)) {
    return filterValue.length ? 1 : 0;
  }

  // check applied filter count for nested tags -> only for tagFilter
  if (typeof filterValue === 'object' && 'tagValues' in filterValue) {
    return Object.keys((filterValue as any)['tagValues']).length;
  }

  return !isEmpty(filterValue) ? 1 : 0;
};

export const isEmptyFilter = (filter: FilterState) => {
  const filterValues = Object.values(filter);
  for (const value of filterValues) {
    if (hasFilterValue(value)) {
      return false;
    }
  }
  return true;
};

export const getAppliedFilterCount = (filter: FilterState) => {
  const filteredFilters = omit(filter, IGNORED_FILTER_KEYS) as FilterValue;

  let filterCount = 0;
  Object.values(filteredFilters).forEach((value) => {
    filterCount += hasFilterValue(value);
  });

  return filterCount;
};

const SortStateParam = {
  encode: (sort: SortState) => encodeJson(sort),
  decode: (sortStr: string | (string | null)[] | null | undefined) => decodeJson(sortStr),
};

const FilterStateParam = {
  encode: (filter: FilterState) => encodeJson(filter),
  decode: (filterStr: string | (string | null)[] | null | undefined) => decodeJson(filterStr),
};

export const getQueryParamKey = (key: string, prefix?: string) =>
  prefix ? `${prefix}.${key}` : key;

export interface UseDataFromQueryParamsProps {
  defaultSortBy?: SortState;
  defaultFilters?: FilterState;
  defaultSearch?: string;
  updateType?: UrlUpdateType;
  queryParamPrefix?: string;
}

export type UseDataFromQueryParamsAndPersistanceProps = UseDataFromQueryParamsProps & {
  persistanceId: string;
};

export const useDataFromQueryParams = ({
  defaultSortBy,
  defaultFilters = {},
  defaultSearch = '',
  updateType,
  queryParamPrefix,
}: UseDataFromQueryParamsProps) => {
  const prevURL = usePrevious(window.location.pathname);

  const [queryParams, setQueryParams] = useQueryParams({
    [getQueryParamKey('sort', queryParamPrefix)]: withDefault(SortStateParam, defaultSortBy),
    [getQueryParamKey('filter', queryParamPrefix)]: withDefault(FilterStateParam, defaultFilters),
    [getQueryParamKey('search', queryParamPrefix)]: withDefault(StringParam, defaultSearch),
  });

  const setQuery: typeof setQueryParams = useCallback(
    (nextQueryParams: any) => {
      let adaptedQueryParams = nextQueryParams;

      if (queryParamPrefix) {
        adaptedQueryParams = Object.keys(nextQueryParams).reduce<any>((accumulator, key) => {
          accumulator[getQueryParamKey(key, queryParamPrefix)] = nextQueryParams[key];

          return accumulator;
        }, {});
      }

      setQueryParams(adaptedQueryParams, updateType);
    },
    [queryParamPrefix, setQueryParams, updateType],
  );

  useUnmount(() => {
    /**
     * We want to setQuery only if pathname remains same
     * If we will setQuery when pathname changes, then pathname change will be aborted
     */
    if (queryParamPrefix && prevURL === window.location.pathname) {
      /**
       * setTimeout used to prevent sideEffect when push and replace are called together
       */
      setTimeout(() => setQuery({ sort: undefined, filter: undefined, search: undefined }));
    }
  });

  return {
    sort: queryParams[getQueryParamKey('sort', queryParamPrefix)],
    filter: queryParams[getQueryParamKey('filter', queryParamPrefix)],
    search: queryParams[getQueryParamKey('search', queryParamPrefix)],
    setQuery,
  };
};
