import { ExchangeInput, Operation } from 'urql';
import { map, pipe, Source } from 'wonka';

import { isAuthErrorCheck } from './authExchange';

export const filterUnAuthExchange = ({ forward }: ExchangeInput) => {
  return (ops$: Source<Operation>) => {
    return pipe(
      forward(ops$),
      map((op) => {
        const isAuthError = isAuthErrorCheck(op.error);
        if (isAuthError) {
          // this is to prevent triggering ErrorBoundary as we have already handled the UnAuth error in authExchange
          op.error = undefined;
        }
        return op;
      }),
    );
  };
};
