import {
  ActionItemFragment,
  DashboardActionItemFragment,
} from '@/modules/tasks/features/actionItems/graphql/fragments/generated/ActionItem';
import {
  CreateActionItemInput,
  GroupedActionItemEdge,
  PaginatedActionItemsPayload,
  VisibilitySettingsInput,
} from '@/types/schema';

import useFiles from '../hooks/useFiles';

export enum ItemTypeEnum {
  File = 'FILE',
  Folder = 'FOLDER',
}

export interface GroupedActionItem {
  groupByKey: GroupedActionItemEdge['groupByKey'];
  totalCount: PaginatedActionItemsPayload['totalCount'];
  pageInfo: PaginatedActionItemsPayload['pageInfo'];
  actionItems: DashboardActionItemFragment[];
}

export enum FileActions {
  Edit = 'EDIT',
  Delete = 'DELETE',
  ManageSyncedTasks = 'MANAGE_SYNCED_TASKS',
  CustomiseColumns = 'CUSTOMISE_COLUMNS',
}

export interface FolderItem {
  id: string;
  name: string;
  visibilitySettings: VisibilitySettingsInput;
  type: ItemTypeEnum;
  count: number;
}

export enum ActionDialogsEnum {
  AddNewTask = 'ADD_NEW_TASK',
  EditTask = 'EDIT_TASK',
  CreateFolder = 'CREATE_FOLDER',
  CreateList = 'CREATE_LIST',
  EditList = 'EDIT_LIST',
  Delete = 'DELETE',
  DeleteTask = 'DELETE_TASK',
}

export type InViewDialogState = {
  dialog: ActionDialogsEnum;
  file?: NonNullable<ReturnType<typeof useFiles>['files']>[0];
  task?: ActionItemFragment | CreateActionItemInput;
};

export enum DashboardDialogsEnum {
  ManageSyncedTasks = 'MANAGE_SYNCED_TASKS',
  CustomiseColumns = 'CUSTOMISE_COLUMNS',
}
