import { UpdateResolver } from '@urql/exchange-graphcache';
import keyBy from 'lodash/keyBy';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  QuizEditorCacheUpdaterFragment,
  QuizEditorCacheUpdaterFragmentDoc,
} from '../fragments/generated/QuizEditorCacheUpdater';
import {
  UpdateQuizQuestionsMutation,
  UpdateQuizQuestionsMutationVariables,
} from '../mutations/generated/updateQuizQuestions';
import { getQuizEditorCacheKey } from './utils';

const updateQuizQuestionsUpdater: UpdateResolver<
  UpdateQuizQuestionsMutation,
  UpdateQuizQuestionsMutationVariables
> = (result, args, cache, info) => {
  const quiz = cache.readFragment<QuizEditorCacheUpdaterFragment>(
    QuizEditorCacheUpdaterFragmentDoc,
    { id: getQuizEditorCacheKey(args.data.quizId), __typename: 'QuizContentType' },
  );

  if (!quiz || !result.updateQuizQuestions?.success) {
    invalidateQuery(['content'])(result, args, cache, info);
    return;
  }

  if (!args.data.questionConfigs.length) {
    return;
  }

  const questionConfigById = keyBy(args.data.questionConfigs, 'questionId');

  const quizQuestions = quiz.quizQuestions.map((quizQuestion) => {
    const questionConfig = questionConfigById[quizQuestion.questionId];

    if (!questionConfig) {
      return quizQuestion;
    }

    return { ...quizQuestion, order: questionConfig.order };
  });

  cache.writeFragment(QuizEditorCacheUpdaterFragmentDoc, {
    ...quiz,
    quizQuestions,
    __typename: 'QuizContentType',
  });
};

export default updateQuizQuestionsUpdater;
