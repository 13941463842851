// libs
import { useState } from 'react';
import React from 'react';
import { useScroll } from 'react-use';

import { cn } from '@lyearn/style';
// components
import { Stack } from '@lyearn/ui';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';

// hooks
import { SidebarItems } from '../../hooks/useSidebar';
import UserInfoWithWorkspace from '../UserInfoWithWorkspace';
import MenuItems from './SidebarItems';
// styles
import { sidebarBorderStyles, sidebarStyles } from './style';

const Sidebar = ({ sidebarItems }: { sidebarItems: SidebarItems }) => {
  const { [FeatureFlags.ShowUserProfile]: showUserProfile } = useFeatureFlags();
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const { y } = useScroll({
    current: ref,
  });

  return (
    <Stack
      ref={setRef}
      className={cn(
        sidebarStyles,
        'sticky top-0 z-50 h-screen flex-shrink-0 self-start overflow-y-auto border-divider border-e',
        !showUserProfile && 'pt-50',
      )}
      component="nav"
      direction="col">
      {showUserProfile ? (
        <UserInfoWithWorkspace className={y > 0 ? cn(sidebarBorderStyles, 'shadow-01') : ''} />
      ) : null}
      <MenuItems className="px-8" sidebarItems={sidebarItems} />
    </Stack>
  );
};

export default React.memo(Sidebar);
