import { useCallback } from 'react';

import { useTranslation } from '@lyearn/i18n';
import { CopyFill } from '@lyearn/icons';
import { SnackbarFunctionProps, useSnackbar } from '@lyearn/ui';

import useCopyToClipboardBase from './useCopyToClipboardBase';

const useCopyToClipboard = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const [, onCopy] = useCopyToClipboardBase();

  const onCopyWithSnackbar = useCallback(
    (copyText: string, snackbarProps: SnackbarFunctionProps) => {
      onCopy(copyText);
      showSnackbar({ iconStatus: 'info', StartIcon: CopyFill, ...snackbarProps });
    },
    [onCopy, showSnackbar],
  );

  const onCopyUrl = useCallback(
    (url: string) => {
      onCopyWithSnackbar(url, { message: t('Common:LinkCopiedToClipboard') });
    },
    [onCopyWithSnackbar, t],
  );

  const onCopyRoute = useCallback(
    (route: string, query?: Record<string, string>) => {
      const url = new URL(route, window.origin);

      if (query) {
        Object.entries(query).forEach(([key, value]) => {
          url.searchParams.append(key, value);
        });
      }

      onCopyUrl(url.toString());
    },
    [onCopyUrl],
  );

  return { onCopy, onCopyRoute, onCopyWithSnackbar, onCopyUrl };
};

export default useCopyToClipboard;
