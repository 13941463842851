import { UpdateResolver } from '@urql/exchange-graphcache';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';

import { CreatePraiseMutation } from '../mutations/generated/createPraise';
import { PraisesDocument, PraisesQuery, PraisesQueryVariables } from '../queries/generated/praises';

export function createPraiseMutation(): UpdateResolver {
  return (result, _, cache) => {
    const praise = (result as CreatePraiseMutation).createPraise?.praise;
    if (!praise) {
      return;
    }

    inspectCache(cache, 'Query', 'praises', (field) => {
      const args = field.arguments as PraisesQueryVariables;
      updateCache<PraisesQuery, PraisesQueryVariables>(cache, PraisesDocument, args, (data) => {
        if (data?.praises?.edges) {
          const updatedEdges = [
            {
              node: praise,
              __typename: 'PraiseEdge',
            },
          ] as any[];

          for (let i = 0; i < data.praises.edges.length; ++i) {
            updatedEdges.push(data.praises.edges[i]);
          }

          data.praises.edges = updatedEdges;
        }
        return data;
      });
    });
  };
}
