import React from 'react';

import { AnimatePresence, domAnimation, LazyMotion } from '@/components/Motion';

import { SidebarAccordianLazyMotionProps } from '../types';

const SidebarAccordianLazyMotion: React.FC<
  React.PropsWithChildren<SidebarAccordianLazyMotionProps>
> = ({ children, isExpanded, initial = false }) => {
  return (
    <LazyMotion features={domAnimation}>
      <AnimatePresence initial={initial} mode="sync">
        {isExpanded ? children : null}
      </AnimatePresence>
    </LazyMotion>
  );
};

export default SidebarAccordianLazyMotion;
