const PARENT_ROUTE = '/tasks';

const learnerRoutes = {
  Tasks: () => `${PARENT_ROUTE}`,

  TasksDashboard: (fileId: string) => `${PARENT_ROUTE}/${fileId}`,
};

const routes = { ...learnerRoutes };

export default routes;
