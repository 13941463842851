import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import {
  PostFragment,
  PostFragmentDoc,
} from '@/features/messages/graphql/fragments/generated/Post';
import {
  GetPostsDocument,
  GetPostsQuery,
  GetPostsQueryVariables,
} from '@/features/messages/graphql/queries/generated/Posts';
import { ChannelPostsSubscription } from '@/features/messages/graphql/subscriptions/generated/ChannelPosts';
import currentUser from '@/helper/currentUser';
import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import { ChannelPostsSubscriptionInput, ChannelPostsSubscriptionOperation } from '@/types/schema';

import { InspectPostCacheFunction, UpdatePostCacheFunction } from './types';
import { updateUnreadCountOnPostCreate, updateUnreadCountOnPostDelete } from './unreadCountUtils';
import {
  adaptHiddenPosts,
  adaptOnPostCreate,
  adaptOnPostDelete,
  adaptPinnedPosts,
  getQueryDetails,
} from './utils';

const inspectPostCache: InspectPostCacheFunction = (cache, post, onMatch) => {
  inspectCache(cache, 'Query', 'posts', (field) => {
    const queryVariables = field.arguments as GetPostsQueryVariables;

    const subscriptionVariables: ChannelPostsSubscriptionInput = {
      types: [post.type],
      channelIds: [post.currentWorkspaceChannelId],
      operations: [
        ChannelPostsSubscriptionOperation.PostUpdated,
        ChannelPostsSubscriptionOperation.PostDeleted,
        ChannelPostsSubscriptionOperation.PostReactionUpdated,
        ChannelPostsSubscriptionOperation.PostCreated,
      ],
    };

    if (post.parentId) {
      subscriptionVariables.parentId = post.parentId;
    }

    const { match, isPinnedQuery, showHiddenPosts } = getQueryDetails(
      queryVariables,
      subscriptionVariables,
    );
    if (match) {
      onMatch({ isPinnedQuery, queryVariables, showHiddenPosts });
    }
  });
};

const updatePostsCache: UpdatePostCacheFunction = (cache, post, queryVariables, updater) => {
  const currentUserId = currentUser.id()!;

  updateCache<GetPostsQuery, GetPostsQueryVariables>(
    cache,
    GetPostsDocument,
    queryVariables,
    (data) => updater({ cache, data, post, currentUserId }),
  );
};

export const onPostCreate = (cache: Cache, post: PostFragment) => {
  cache.writeFragment(PostFragmentDoc, post);
  const currentUserId = currentUser.id()!;

  inspectPostCache(cache, post, ({ isPinnedQuery, queryVariables }) => {
    if (!isPinnedQuery) {
      updatePostsCache(cache, post, queryVariables, adaptOnPostCreate);
    }
  });

  updateUnreadCountOnPostCreate({ cache, post, currentUserId });
};

export function getChannelPostsUpdater(): UpdateResolver {
  return (result, _args, cache) => {
    const response = result as ChannelPostsSubscription;
    const post = response.channelPosts.post;
    const currentUserId = currentUser.id()!;

    switch (response.channelPosts.operation) {
      case ChannelPostsSubscriptionOperation.PostReactionUpdated: {
        cache.writeFragment(PostFragmentDoc, post);
        break;
      }
      case ChannelPostsSubscriptionOperation.PostUpdated: {
        cache.writeFragment(PostFragmentDoc, post);
        inspectPostCache(cache, post, ({ isPinnedQuery, queryVariables, showHiddenPosts }) => {
          if (isPinnedQuery) {
            updatePostsCache(cache, post, queryVariables, adaptPinnedPosts);
          }
          if (!showHiddenPosts) {
            updatePostsCache(cache, post, queryVariables, adaptHiddenPosts);
          }
        });
        break;
      }
      case ChannelPostsSubscriptionOperation.PostCreated: {
        if (post.createdById !== currentUserId) {
          onPostCreate(cache, post);
        }
        break;
      }
      case ChannelPostsSubscriptionOperation.PostDeleted: {
        cache.writeFragment(PostFragmentDoc, { ...post, channelId: null });
        inspectPostCache(cache, post, ({ queryVariables }) => {
          updatePostsCache(cache, post, queryVariables, adaptOnPostDelete);
        });
        updateUnreadCountOnPostDelete({ cache, post, currentUserId });
        break;
      }
    }
  };
}
