import { jsonParse } from '@lyearn/core/utils/json';

import useFile from '../../hooks/useFile';

export default function getInViewFile({
  inViewFile,
}: {
  inViewFile?: ReturnType<typeof useFile>['file'];
}) {
  if (inViewFile?.systemGenerated) {
    return (jsonParse(inViewFile.meta || '{}') as any).data.type === 'Uncategorized'
      ? inViewFile
      : undefined;
  }

  return inViewFile;
}
