import { GetNotificationQuery } from '@/components/Header/components/NotificationTray/graphql/queries/generated/getNotifications';
import { BaseUserNotification, UserNotificationStatusEnum } from '@/types/schema';

import { GetNotificationCountQuery } from '../../queries/generated/getNotificationCount';

export const addUserNotifications = ({
  data,
  userNotification,
}: {
  data: GetNotificationQuery | null;
  userNotification: BaseUserNotification | null;
}) => {
  // @ts-ignore todo pending from backend
  // if (userNotification.type === 'DATA_NOTIFICATION') {
  //   switch (userNotification?.message) {
  //     case 'WEB_APP_NEW_VERSION_AVAILABLE': {
  //       newVersionFound();
  //       break;
  //     }
  //   }
  //   return data;
  // }

  if (data?.userNotifications.edges && userNotification) {
    // Using for-loop instead of unshift - https://jsbench.me/cgjfc79bgx/1
    const edges: GetNotificationQuery['userNotifications']['edges'] = [
      { node: userNotification, __typename: 'UserNotificationPayloadEdge' },
    ];

    for (let index = 0; index < data.userNotifications.edges.length; index++) {
      edges.push(data.userNotifications.edges[index]);
    }

    data.userNotifications.edges = edges;
    data.userNotifications.totalCount++;
  }

  return data;
};

export const updateUserNotifications = ({ data }: { data: GetNotificationQuery | null }) => {
  const edges = data?.userNotifications.edges as GetNotificationQuery['userNotifications']['edges'];
  if (edges) {
    data!.userNotifications.edges = edges.map((edge) => {
      (edge.node as BaseUserNotification).status = UserNotificationStatusEnum.Read;
      return edge;
    });
  }

  return data;
};

export const updateUser = ({
  data,
  unreadNotificationsCount,
  unresolvedNotificationsCount,
}: {
  data: GetNotificationCountQuery | null;
  unreadNotificationsCount: number;
  unresolvedNotificationsCount: number;
}) => {
  if (data?.user) {
    const user = data.user;
    data.user = {
      ...user,
      unresolvedNotificationsCount,
      unreadNotificationsCount,
    };
  }

  return data;
};
