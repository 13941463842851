import React, { useEffect, useMemo } from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import Platform from '@lyearn/core/utils/Platform';
import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Box, Button, Stack, Typography } from '@lyearn/ui';

import { onRefresh } from '../utils';
import { ErrorDetails, PageErrorProps } from './types';

const ERROR_DETAILS_MAP: ErrorDetails[] = [
  {
    errorCondition: () => !window?.navigator?.onLine,
    headingKey: 'Common:Error.NoInternetError.heading',
    descriptionKey: 'Common:Error.NoInternetError.description',
  },
  {
    errorCondition: () => true,
    headingKey: 'Common:Error.DefaultError.heading',
    descriptionKey: 'Common:Error.DefaultError.description',
  },
];

const PageErrorContent = ({ error, className }: PageErrorProps) => {
  const { t } = useTranslation('Common');
  const { isMobile } = useScreen();

  useEffect(() => {
    ErrorReporter.error(error);
  }, [error]);

  const currentError = useMemo(
    () => ERROR_DETAILS_MAP.find((errorDetail) => errorDetail.errorCondition(error)),
    [error],
  );

  return (
    <Box
      className={cn(
        'absolute inset-0 flex items-center justify-center bg-bg-primary xs:px-12',
        className,
      )}>
      <Stack alignItems="center" className="max-w-576" direction="col" justifyContent="center">
        <Typography className="xs:display-l text-center" variant="display-xxl">
          {t(currentError?.headingKey!)}
        </Typography>
        <Typography
          className="mt-16 text-center xs:mt-8 xs:px-24"
          color="text-text-primary"
          variant="body-short-01">
          {t(currentError?.descriptionKey!)}
        </Typography>
        {Platform.select({
          web: (
            <Button
              appearance="primary"
              className="mt-48 xs:mt-24"
              shape="pill"
              size={isMobile ? 'medium' : 'large'}
              variant="outlined"
              onClick={onRefresh}>
              {t('Common:Error.Reload')}
            </Button>
          ),
          default: null,
        })}
      </Stack>
    </Box>
  );
};

/*
  Sentry fallback on ErrorBoundary is calling React component as function which is wrong, hence do not put any hooks here
*/
const PageError = (props: PageErrorProps) => {
  return <PageErrorContent {...props} />;
};

export default PageError;
