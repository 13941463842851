import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/track';
const ADMIN_TAGS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const skillReviewAdminRoutes = {
  TrackDetails: (trackId: string) => `${ADMIN_TAGS_PARENT_ROUTE}/${trackId}/details`,
};

export default skillReviewAdminRoutes;
