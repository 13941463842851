import React, { useLayoutEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import _merge from 'lodash/merge';
import Head from 'next/head';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

import useScreen from '@lyearn/core/hooks/useScreen';
import { themeConfig as LyearnThemeConfig } from '@lyearn/style';
import { Direction, generateGlobalCSS } from '@lyearn/style';
import { LyearnMobileThemeConfig } from '@lyearn/style';
import { Box, createMuiTheme, ThemeProvider } from '@lyearn/ui';
import useAppColorScheme from '@/hooks/useAppColorScheme';

import darkTheme from './darkTheme';

const STYLE_CLASSNAME = 'ly-theme-style';
export const DIALOG_CONTAINER_ID = 'ly-dialog-container';

const getHTMLStyle = (css: string) => {
  const style = document.createElement('style');
  style.setAttribute('class', STYLE_CLASSNAME);
  style.innerHTML = css;

  return style;
};

export type ThemeConfigType = Parameters<typeof generateGlobalCSS>[0];

export const useTheme = (themeConfig: ThemeConfigType) => {
  useLayoutEffect(() => {
    const rootCss = generateGlobalCSS(themeConfig);
    const darkThemeCss = generateGlobalCSS(darkTheme).replace(':root', '.darkMode');
    const rootStyles = getHTMLStyle(rootCss);
    const rootDarkModeStyles = getHTMLStyle(darkThemeCss);

    document.head.append(rootStyles);
    document.head.append(rootDarkModeStyles);

    return () => {
      rootStyles.remove();
      rootDarkModeStyles.remove();
    };
  }, [themeConfig]);
};

export const useLyearnTheme = () => {
  const { isMobile } = useScreen();

  useTheme(isMobile ? LyearnMobileThemeConfig : LyearnThemeConfig);
};

const Provider: React.FC<
  React.PropsWithChildren<{ themeConfig?: ThemeConfigType; isDarkModeAllowed?: boolean }>
> = ({ children, themeConfig, isDarkModeAllowed }) => {
  const { isMobile } = useScreen();
  const { appliedTheme } = useAppColorScheme({ isDarkModeAllowed });

  const mergedConfig = useMemo(
    () => _merge({}, isMobile ? LyearnMobileThemeConfig : LyearnThemeConfig, themeConfig),
    [isMobile, themeConfig],
  );

  const tooltipContainerRef = React.useRef(null);

  const tooltipContainer = React.useCallback(() => {
    return tooltipContainerRef.current;
  }, []);

  const cacheRtl = useMemo(() => {
    // Create rtl cache

    return document && document.dir === 'rtl'
      ? createCache({
          key: 'muirtl',
          // @ts-ignore
          stylisPlugins: [prefixer, rtlPlugin],
          prepend: true,
        })
      : createCache({
          key: 'mui',
          stylisPlugins: [],
          prepend: true,
        });
  }, []);

  const { muiTheme, darkMuiTheme } = React.useMemo(() => {
    const muiTheme = createMuiTheme({
      direction: (document.dir as Direction) ?? ('rtl' as Direction),
      palette: {
        primary: {
          main: '#000',
        },
      },
      components: {
        MuiButtonBase: {
          defaultProps: {
            disableRipple: true,
          },
        },
        MuiIconButton: {
          defaultProps: {
            className: 'bg-none',
          },
        },
        MuiTooltip: {
          defaultProps: {
            PopperProps: {
              container: tooltipContainer,
            },
          },
        },
        MuiDialog: {
          defaultProps: {
            container: tooltipContainer,
          },
        },
        MuiPopover: {
          defaultProps: {
            container: tooltipContainer,
          },
        },
        MuiPopper: {
          defaultProps: {
            container: tooltipContainer,
          },
        },
        MuiDrawer: {
          defaultProps: {
            container: tooltipContainer,
          },
        },
      },
    });

    const darkMuiTheme = createMuiTheme(muiTheme, {
      palette: {
        primary: {
          main: '#fff',
        },
        text: {
          primary: '#fff',
          secondary: '#ccc',
        },
        background: {
          paper: '#222222',
          default: '#222222',
        },
      },
    });
    return { muiTheme, darkMuiTheme };
  }, [tooltipContainer]);

  useTheme(mergedConfig);

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={appliedTheme === 'dark' ? darkMuiTheme : muiTheme}>
        <>
          <Head>
            <meta content={appliedTheme} name="color-scheme" />
          </Head>
          {children}
          {
            // This box is used in the patch, update the patch @mui+base+5.0.0-alpha.114.patch if you update the id
          }
          {createPortal(<Box ref={tooltipContainerRef} id={DIALOG_CONTAINER_ID} />, document.body)}
        </>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Provider;
