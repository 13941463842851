import config from 'config';

import { post } from '@/helper/request';

const logoutQuery = async () => {
  try {
    return await post('/api/auth/logout', { host: config.host_domain });
  } catch (e) {
    console.error('Error Executing logout', e);
  }
};
export default logoutQuery;
