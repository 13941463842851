import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/survey';
const ADMIN_SURVEY_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const adminRoutes = {
  SurveyCreator: () => `${ADMIN_SURVEY_PARENT_ROUTE}/create`,
  SurveyEditor: (contentId: string) => `${ADMIN_SURVEY_PARENT_ROUTE}/${contentId}/edit`,
  AdminSurveyDetails: (contentId: string) => `${ADMIN_SURVEY_PARENT_ROUTE}/${contentId}/details`,
  SurveyPreview: (contentId: string) => `${ADMIN_SURVEY_PARENT_ROUTE}/${contentId}/preview`,
  SurveyPreviewDevices: (contentId: string) =>
    `${ADMIN_SURVEY_PARENT_ROUTE}/${contentId}/preview-devices`,
};

const learnerRoutes = {
  SurveyDetails: (contentId: string) => `${PARENT_ROUTE}/${contentId}/details`,
};

const routes = { ...adminRoutes, ...learnerRoutes };

const ADMIN_ROUTE_KEYS = {
  SurveyCreator: 'SurveyCreator',
  SurveyEditor: 'SurveyEditor',
  AdminSurveyDetails: 'AdminSurveyDetails',
};

const LEARNER_ROUTE_KEYS = {
  SurveyDetails: 'SurveyDetails',
};

export const SURVEY_ROUTE_KEYS = {
  ...ADMIN_ROUTE_KEYS,
  ...LEARNER_ROUTE_KEYS,
};

export default routes;
