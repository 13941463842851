import useEvent from '@lyearn/core/hooks/useEvent';
import useToggle from '@lyearn/core/hooks/useToggle';
import { stopEventPropagation } from '@lyearn/core/utils/events';
import { MenuItem } from '@lyearn/ui';

import { ActionMenuConfirmationItemProps, ActionMenuProps } from '../../types';

interface Props {
  autoFocus?: boolean;
  className?: string;
  item: ActionMenuConfirmationItemProps;
  onAction?: ActionMenuProps['onAction'];
}

const ActionMenuConfirmationItem = (props: Props) => {
  const { autoFocus, className, item, onAction } = props;
  const [showConfirmation, { toggle }] = useToggle(false);

  const onItemClick = useEvent((e) => {
    stopEventPropagation(e);
    if (showConfirmation) {
      onAction?.(item.id, item);
    } else {
      toggle();
    }
  });

  const { confirmLabel, eventHandler, ...rest } = item;

  return (
    <MenuItem
      key={item.id + '-key'}
      autoFocus={autoFocus}
      className={className}
      data-action={item.id}
      {...rest}
      label={showConfirmation ? confirmLabel : item.label}
      onClick={onItemClick}
    />
  );
};

export default ActionMenuConfirmationItem;
