import { PraisesTabEnum } from '@/modules/praises/views/PraiseWall/types';
import { TeamProfilePages } from '@/modules/user/views/TeamProfile/types';
import { EmployeeProfilePages } from '@/modules/user/views/UserProfile/types';
import { ActionTypes } from '@/modules/user/views/UserProfile/views/UserProfileActionDetails/types';

import oneOnOneRoutes from './1on1s';
import actionPlanRoutes from './action-plan';
import adminRoutes from './admin';
import announcementRoutes from './announcements';
import assignmentRoutes from './assignment';
import contentRoutes from './contents';
import departmentRoutes from './department';
import designations from './designations';
import devRoutes from './dev';
import eventRoutes from './event';
import feedbackRoutes from './feedback';
import groupRoutes from './group';
import habitsRoutes from './habits';
import integrationsRoutes from './integrations';
import {
  adminRoutes as journeyAdminRoutes,
  learnerRoutes as journeyLearnerRoutes,
} from './journey';
import learnerRoutes from './learner';
import lyearnConnectRoutes from './lyearn-connect';
import meetingRoutes from './meetings';
import metricRoutes from './metrics';
import orgRoles from './org-roles';
import reviewRoutes from './review';
import scorecardRoutes from './scorecard';
import skillRoutes from './skill-matrix';
import staffRoleRoutes from './staff-roles';
import tagRoutes from './tags';
import taskRoutes from './tasks';
import trackRoutes from './track';
import userRoutes from './user';
import userSurveyRoutes from './user-survey';

const routes = {
  Database: (databaseId: string) => `/databases/${databaseId}`,

  WidgetDetails: (widgetId: string) => `/widget/${widgetId}`,
  QuizPlayer: (contentId: string, action: string) => `/quiz/player/${contentId}/${action}`,

  CommunityDetails: (communityId: string) => `/community/${communityId}`,
  CommunityChannelDetails: (communityId: string, channelId: string) =>
    `/community/${communityId}/spaces/${channelId}`,

  // skills-review
  SkillReview: (reviewCycleId: string) => `/review-cycle/${reviewCycleId}/review`,
  SkillUserResults: (reviewCycleId: string) => `/review-cycle/${reviewCycleId}/results`,
  SkillUserShareResults: (reviewCycleId: string) => `/review-cycle/${reviewCycleId}/share-results`,
  SkillUserShareResultsUser: (reviewCycleId: string, userId: string) =>
    `/review-cycle/${reviewCycleId}/share-results/user/${userId}`,
  SkillUserManageReviews: (reviewCycleId: string) =>
    `/review-cycle/${reviewCycleId}/manage-reviews`,
  SkillUserReview: (reviewCycleId: string, userId: string) =>
    `/review-cycle/${reviewCycleId}/review/user/${userId}`,
  SkillPeerSelection: (reviewCycleId: string) => `/review-cycle/${reviewCycleId}/peers`,
  SkillPeerNominationRequests: (reviewCycleId: string) =>
    `/review-cycle/${reviewCycleId}/peer-requests`,
  SkillPeerSelectionForUser: (reviewCycleId: string, userId: string) =>
    `/review-cycle/${reviewCycleId}/peers/user/${userId}`,
  SkillSubmissionPreview: (
    reviewCycleId: string,
    userId: string,
    reviewerId: string,
    reviewType: string,
  ) =>
    `/review-cycle/${reviewCycleId}/preview/user/${userId}/reviewer/${reviewerId}/review/${reviewType}`,

  // recorder
  Recorder: () => '/record',

  //praises
  Praises: (tabId: PraisesTabEnum) => `/praises/${tabId}`,

  //All Sessions
  AllSessions: () => `/session/all`,

  // User Profile
  UserProfile: (id: string, type = EmployeeProfilePages.Overview) => `/user-profile/${id}/${type}`,
  UserActivityDetails: (userId: string, metricId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Activities}/${metricId}`,
  LearnerActivityDetails: (userId: string, metricId: string) =>
    `/learners/${userId}/${EmployeeProfilePages.Activities}/${metricId}`,
  UserProfileSkillDetailPage: (userId: string, skillId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Skills}/${skillId}`,
  UserProfileSkillsRecommendedLearning: (userId: string, skillId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Skills}/${skillId}/learnings`,
  UserProfileSkillsAllActions: (userId: string, actionType: ActionTypes, skillId?: string) =>
    skillId
      ? `/user-profile/${userId}/${EmployeeProfilePages.Skills}/${skillId}/actions/${actionType}`
      : `/user-profile/${userId}/${EmployeeProfilePages.Skills}/actions/${actionType}`,
  UserProfileMindsetDetailPage: (userId: string, mindsetId: string) =>
    `/user-profile/${userId}/${EmployeeProfilePages.Mindsets}/${mindsetId}`,

  // Team
  TeamProfile: (id: string, type = TeamProfilePages.Overview) => `/team-profile/${id}/${type}`,

  //not-supported
  NotSupported: () => '/not-supported',

  //templates
  OKRsTemplates: () => '/templates/okrs',
  ReviewCycleTemplates: () => '/admin/templates/review-cycle',

  //academy-setup
  AcademySetup: () => '/academy-setup',

  ...devRoutes,
  ...adminRoutes,
  ...learnerRoutes,
  ...departmentRoutes,
  ...trackRoutes,
  ...userRoutes,
  ...contentRoutes,
  ...eventRoutes,
  ...assignmentRoutes,
  ...scorecardRoutes,
  ...tagRoutes,
  ...groupRoutes,
  ...staffRoleRoutes,
  ...lyearnConnectRoutes,
  ...skillRoutes,
  ...orgRoles,
  ...designations,
  ...integrationsRoutes,
  ...reviewRoutes,
  ...oneOnOneRoutes,
  ...meetingRoutes,
  ...feedbackRoutes,
  ...taskRoutes,
  ...metricRoutes,
  ...userSurveyRoutes,
  ...actionPlanRoutes,
  ...habitsRoutes,
  ...journeyAdminRoutes,
  ...journeyLearnerRoutes,
  ...announcementRoutes,
};

export default routes;
