import { FilterGroup, FilterOption } from '@/components/Filter/types';
import { ListItemProps } from '@/components/SortMenu/types';
import { OrderEnum } from '@/types/schema';

import { DateFilterListItem } from '../DateFilter/constants';
import { FilterBodyListConfig, FilterBodyUIConfig } from '../FilterNew/components/Body/types';
import { FilterBodyConfig, ListVariantsType } from '../FilterNew/types';
import { TagsQueryParams } from './configs/tags/types';
import { NewEntityPropsType } from './EntityFilter/types';
import { AppliedFilterItemAppearance } from './EntityFilterManager/types';
import { SortMenuProps } from './SortMenu/types';

export enum FilterManager {
  Sort = 'SORT',
  SingleSelectFilter = 'SINGLE_SELECT_FILTER',
  Filter = 'FILTER',
  DateRange = 'DATE_RANGE',
  Custom = 'CUSTOM',
  Divider = 'DIVIDER',
  Entity = 'ENTITY',
  Nested = 'NESTED',
  FeaturedTag = 'FEATURED_TAG',
}

export enum SortEnums {
  Relevance = 'Relavance',
  LastEdited = 'LAST_EDITED',
  Alphabetically = 'ALPHABETICALLY',
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
  Upcoming = 'UPCOMING',
  Score = 'SCORE',
  Status = 'STATUS',
  DueDate = 'DUE_DATE',
  EndDate = 'END_DATE',
}

interface Sort {
  field: string;
  order: OrderEnum;
}

export interface SortItems extends ListItemProps {
  sort: Sort[];
}

export interface SingleSelectItem extends ListItemProps {
  value: string;
}

export interface BasicFilterItem {
  id: string;
  defaultPlaceholder: string;
  isEnabled: boolean;
  isNonEditable?: boolean;
  appearance?: AppliedFilterItemAppearance;
  hideOperator?: boolean;
}

export interface SortItem extends Pick<BasicFilterItem, 'isEnabled'> {
  type: FilterManager.Sort;
  defaultSortById?: SortMenuProps['defaultSortById'];
  items: SortMenuProps['items'];
}

export interface FilterItem extends BasicFilterItem {
  type: FilterManager.Filter;
  groups: FilterGroup[];
  listVariant?: ListVariantsType;
}

export interface SingleSelectFilterItem extends BasicFilterItem {
  type: FilterManager.SingleSelectFilter;
  items: SingleSelectItem[];
  clearable?: boolean;
  showDefaultPlaceholder?: boolean;
  defaultSelectedValue?: string;
}

export interface DateRangeItem extends BasicFilterItem {
  type: FilterManager.DateRange;
  options?: DateFilterListItem[];
}

interface EntityItemInterface extends BasicFilterItem {
  type: FilterManager.Entity;
  listConfig: FilterBodyListConfig;
  uiConfig?: FilterBodyUIConfig;
}

export type EntityItem = EntityItemInterface & NewEntityPropsType;

export type PillConfig = {
  id: string;
  Component: (
    props: CustomFilterRenderProps & {
      filterIdToOpen: string;
      setFilterIdToOpen: (id: string) => void;
    },
  ) => JSX.Element;
  isPillSelected: (filter: FilterState, id: string) => boolean;
};

export interface NestedItem<T = {}> extends FilterBodyConfig<T>, BasicFilterItem {
  type: FilterManager.Nested;
  pillsConfig: PillConfig[];
}

export interface CustomFilterRenderProps {
  filter: FilterState;
  setFilter: (filter: any) => void;
}

export interface CustomFilterItem {
  type: FilterManager.Custom;
  renderer: (props: CustomFilterRenderProps) => JSX.Element;
  isEnabled: boolean;
}

export interface FeaturedTagItem extends BasicFilterItem {
  type: FilterManager.FeaturedTag;
  queryParams: TagsQueryParams;
}

export interface DividerItem {
  type: FilterManager.Divider;
  isEnabled?: boolean;
}

export type FilterValue = string[] | boolean | object | FilterOption[] | string;
export type SortState = Record<string, string>[];
export type FilterState = Record<string, FilterValue>;

export type SortAndFilterItem =
  | CustomFilterItem
  | DateRangeItem
  | FilterItem
  | SingleSelectFilterItem
  | SortItem
  | DividerItem
  | EntityItem
  | FeaturedTagItem
  | NestedItem;

export interface SortAndFilterProps {
  sortAndFilterItems: SortAndFilterItem[];
  className?: string;
  sort: SortState;
  filter: FilterState;
  externalComponents?: JSX.Element;
  setSort: (sort: any) => void;
  setFilter: (filter: any) => void;
}
