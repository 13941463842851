import { useEffect, useMemo, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';

import routes from '@/pages/corporate/routes';

import { NavigationOptions, useNavigationReturn } from './type';

export default function useNavigation(): useNavigationReturn {
  const router = useRouter();
  const hasRouteChangeError = useRef(false);

  useEffect(() => {
    const setHasRouteChangeError = () => {
      hasRouteChangeError.current = true;
    };

    // routeChangeError error is thrown from usePromptNavigation, when user blocks the navigation
    router.events.on('routeChangeError', setHasRouteChangeError);

    return () => {
      router.events.off('routeChangeError', setHasRouteChangeError);
    };
  }, [router.events]);

  return useMemo(() => {
    const getQuery = ({ setFrom = false, query, appendQuery = true }: NavigationOptions) => ({
      ...(appendQuery ? router.query : undefined),
      ...(setFrom ? { redirectTo: router.asPath } : undefined),
      ...query,
    });

    return {
      route: router.route,
      pathname: router.pathname,
      query: router.query,
      asPath: router.asPath,
      events: router.events,
      prefetch: router.prefetch,

      push: (path?: string, navigationOptions: NavigationOptions = {}) => {
        let pathname = path ?? router.pathname;
        let query = getQuery(navigationOptions);
        // get query from pathname. e.g. /admin/clients?clientId=123
        // queryparams should be removed from pathname and passed as query
        const pathUrl = new URL(pathname, window.location.origin);
        if (pathUrl.search.length) {
          pathname = pathUrl.pathname;
          query = { ...Object.fromEntries(pathUrl.searchParams.entries()), ...query };
        }
        return router.push({ pathname, query });
      },
      replace(path?: string, navigationOptions: NavigationOptions = {}) {
        return router.replace({ pathname: path, query: getQuery(navigationOptions) });
      },
      back: (_fallbackPath?: string) => {
        // browser StartPage is counted as first item in the browser's history and current page as second.
        const canGoBack = window.history.length > 2;
        hasRouteChangeError.current = false;

        const fallbackPath =
          typeof _fallbackPath === 'string'
            ? _fallbackPath
            : router.pathname.startsWith(routes.Admin())
            ? routes.Admin()
            : routes.Home();

        if (canGoBack) {
          // for some cases like inside iframe, history.length might be unreliable
          // so for after going back we check if url does not change, then go to fallback page
          router.back();
          const currentUrl = window.location.href;
          setTimeout(() => {
            // will not push the fallback path if there is a route change error
            // else the prompt will be shown twice
            if (currentUrl === window.location.href && !hasRouteChangeError.current) {
              router.push(fallbackPath);
            }
          }, 200);
          return;
        }

        return router.push(fallbackPath);
      },
      canGoBack: () => {
        throw new Error('not supported in web');
      },
      reset(path: string, options: NavigationOptions = {}) {
        return this.replace(path, options);
      },
    };
  }, [router]);
}
