export type propsType = { id?: string };

// Must be the same key that is used their routes object
export enum pageEnum {
  // course
  CourseViewer = 'CourseViewer',
  CourseDetails = 'CourseDetails',
  AdminCourseDetails = 'AdminCourseDetails',
  // session
  EventDetails = 'EventDetails',
  AdminEventDetails = 'AdminEventDetails',
  //path
  PathDetails = 'PathDetails',
  AdminPathDetails = 'AdminPathDetails',
  // article
  ArticleViewer = 'ArticleViewer',
  AdminArticleDetails = 'ArticleBuilder',
  // quiz
  QuizDetails = 'QuizDetails',
  AdminQuizDetails = 'AdminQuizDetails',
  // task
  TaskDetails = 'TaskDetails',
  AdminTaskDetails = 'AdminTaskDetails',
  // check-in
  CheckInDetails = 'CheckInDetails',
  AdminCheckInDetails = 'AdminCheckInDetails',
  // external content
  ExternalContentDetails = 'ExternalContentDetails',
  AdminExternalContentDetails = 'AdminExternalContentDetails',
  // exercise
  ExerciseDetails = 'ExerciseDetails',
  AdminExerciseDetails = 'AdminExerciseDetails',
  // SurveyDetails
  SurveyDetails = 'SurveyDetails',
  AdminSurveyDetails = 'SurveyDetails',

  // pages
  Home = 'Home',
  Admin = 'Admin',
  DiscoverAllEvent = 'DiscoverAllEvent',
}
