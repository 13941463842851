import { endOfToday, endOfWeek, startOfToday } from 'date-fns';
import _snakeCase from 'lodash/snakeCase';

import { AssigneeActionItemFilterEnum, StatusCategoryEnum, TagTypeEnum } from '@/types/schema';
import { getCurrentTimeZoneValue } from '@/utils/timezone';

import { ActionItemFragment } from '../../../../features/actionItems/graphql/fragments/generated/ActionItem';
import {
  getTagGroupById,
  isGroupedByTag,
  TAG_GROUP_ID_PREFIX,
} from '../../components/TaskGroupByFilter/utils';

const NOT_COMPLETED_STATUS_CATEGORIES = [
  StatusCategoryEnum.NotStarted,
  StatusCategoryEnum.InProgress,
];
const COMPLETED_STATUS_CATEGORIES = [StatusCategoryEnum.Completed];

const getPaginationFilters = (
  groupKey: string,
  groupBy: string,
  actionItem: ActionItemFragment,
) => {
  if (isGroupedByTag(groupBy)) {
    if (groupKey === 'UnTagged') {
      const tagId = groupBy.replace(TAG_GROUP_ID_PREFIX, '');

      return { notInTagIds: [tagId] };
    }

    const appliedTag = actionItem.tags?.find(
      (tag) => tag && getTagGroupById(tag.tagId) === groupBy,
    );

    switch (appliedTag?.type) {
      case TagTypeEnum.SingleSelect:
      case TagTypeEnum.MultiSelect: {
        const optionIdValues = appliedTag.options
          ?.filter(({ name }) => name === groupKey)
          .map((option) => option.id);

        if (optionIdValues?.length) {
          return {
            tagFilters: [{ tagId: appliedTag.tagId, type: appliedTag.type, optionIdValues }],
          };
        }

        return {};
      }
      case TagTypeEnum.Number: {
        return {
          tagFilters: [{ tagId: appliedTag.tagId, type: appliedTag.type, numberValue: groupKey }],
        };
      }
      case TagTypeEnum.Text: {
        return {
          tagFilters: [{ tagId: appliedTag.tagId, type: appliedTag.type, textValue: groupKey }],
        };
      }
      case TagTypeEnum.User: {
        return {
          tagFilters: [
            { tagId: appliedTag.tagId, type: appliedTag.type, userIdValues: [groupKey] },
          ],
        };
      }
      default: {
        return {};
      }
    }
  }

  switch (groupBy) {
    case 'status':
      return { [groupBy]: [groupKey] };
    case 'priority':
      return { priorities: [_snakeCase(groupKey).toUpperCase()] };
    case 'assignedToId':
      if (groupKey === 'UnAssigned') {
        return { assigneeActionItemStatus: AssigneeActionItemFilterEnum.UnAssignedItems };
      }

      return { assignedToIds: [groupKey] };
    case 'dueDate': {
      switch (groupKey) {
        case 'Done':
          return { categories: COMPLETED_STATUS_CATEGORIES };
        case 'No Due Date':
          return { dueDate: null, categories: NOT_COMPLETED_STATUS_CATEGORIES };
        case 'Overdue':
          return {
            dueDate: {
              absolute: { endTime: startOfToday().toISOString() },
              timezone: getCurrentTimeZoneValue(),
            },
            categories: NOT_COMPLETED_STATUS_CATEGORIES,
          };
        case 'Today':
          return {
            dueDate: {
              absolute: {
                startTime: startOfToday().toISOString(),
                endTime: endOfToday().toISOString(),
              },
              timezone: getCurrentTimeZoneValue(),
            },
            categories: NOT_COMPLETED_STATUS_CATEGORIES,
          };
        case 'This week':
          return {
            dueDate: {
              absolute: {
                startTime: endOfToday().toISOString(),
                endTime: endOfWeek(new Date()).toISOString(),
              },
              timezone: getCurrentTimeZoneValue(),
            },
            categories: NOT_COMPLETED_STATUS_CATEGORIES,
          };
        case 'Future':
          return {
            dueDate: {
              absolute: { startTime: endOfWeek(new Date()).toISOString() },
              timezone: getCurrentTimeZoneValue(),
            },
            categories: NOT_COMPLETED_STATUS_CATEGORIES,
          };
        default:
          return {};
      }
    }
    default: {
      return {};
    }
  }
};

export default getPaginationFilters;
