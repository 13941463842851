import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/task';
const ADMIN_TASK_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const taskAdminRoutes = {
  TaskCreator: () => `${ADMIN_TASK_PARENT_ROUTE}/create`,
  TaskEditor: (taskId: string) => `${ADMIN_TASK_PARENT_ROUTE}/${taskId}/edit`,
  TaskDuplicate: (taskId: string) => `${ADMIN_TASK_PARENT_ROUTE}/${taskId}/duplicate`,
  AdminTaskDetails: (taskId: string) => `${ADMIN_TASK_PARENT_ROUTE}/${taskId}/details`,
};

const taskLearnerRoutes = {
  TaskDetails: (taskId: string) => `${PARENT_ROUTE}/${taskId}/details`,
};

const routes = {
  ...taskAdminRoutes,
  ...taskLearnerRoutes,
};

export const TASK_ADMIN_ROUTE_KEYS = {
  TaskCreator: 'TaskCreator',
  TaskEditor: 'TaskEditor',
  AdminTaskDetails: 'AdminTaskDetails',
};

export const TASK_LEARNER_ROUTE_KEYS = {
  TaskDetails: 'TaskDetails',
};

export const TASK_ROUTE_KEYS = {
  ...TASK_ADMIN_ROUTE_KEYS,
  ...TASK_LEARNER_ROUTE_KEYS,
};

export default routes;
