import { UpdateResolver } from '@urql/exchange-graphcache';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import { CoursePerformanceType } from '@/types/schema';

import {
  FetchContentPerformancesDocument,
  FetchContentPerformancesQuery,
  FetchContentPerformancesQueryVariables,
} from '../../queries/generated/contentPerformance';
import { ContentPerformanceSubscription } from '../generated/contentPerformance';

const addPerformanceNode = ({
  data,
  performance,
}: {
  data: FetchContentPerformancesQuery | null;
  performance: CoursePerformanceType;
}) => {
  const { entityId } = performance;
  if (!data) {
    return data;
  }

  //set edges if empty
  data.contentPerformances.edges = data.contentPerformances.edges || [];

  const edges = data.contentPerformances.edges;

  // performance node does not exist
  if (!edges.find(({ node }) => (node as CoursePerformanceType).entityId === entityId)) {
    edges.push({ node: performance, __typename: 'ContentPerformanceEdge' });
  }

  return data;
};

export function getPerformanceUpdater(): UpdateResolver {
  return (result, args, cache) => {
    const response = result as ContentPerformanceSubscription;
    const performance = response.syncPerformance as CoursePerformanceType;
    inspectCache(cache, 'Query', 'contentPerformances', (field) => {
      const queryVariables = field.arguments as FetchContentPerformancesQueryVariables;
      if (
        !performance ||
        !(
          queryVariables.filters.userIds?.includes(performance.userId) &&
          queryVariables.filters.entityIds?.includes(performance.entityId)
        )
      ) {
        return null;
      }

      updateCache<FetchContentPerformancesQuery, FetchContentPerformancesQueryVariables>(
        cache,
        FetchContentPerformancesDocument,
        queryVariables,
        (data) => addPerformanceNode({ data, performance }),
      );
    });
  };
}
