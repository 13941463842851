import React, { useCallback } from 'react';

import { usePopoverContext } from '@lyearn/ui';

import MenuListItems from '../MenuListItems';
import { ActionMenuListItemsProps } from './types';

export const ACTION_MENU_LIST_ITEMS_CLASSNAME = 'px-12 space-y-2';

function ActionMenuListItems(props: ActionMenuListItemsProps) {
  const { items, onAction, autoFocusItem = true } = props;

  return (
    <MenuListItems
      autoFocusItem={autoFocusItem}
      className={ACTION_MENU_LIST_ITEMS_CLASSNAME}
      items={items}
      onAction={onAction}
    />
  );
}

export default ActionMenuListItems;
