export default function retryPromise<T>(
  lazyImport: () => Promise<T>,
  maxAttempt: number,
  currentAttempt = 1,
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    lazyImport()
      .then(resolve)
      .catch((error) => {
        if (maxAttempt === 1) {
          reject(error);
          return;
        }
        setTimeout(
          () =>
            retryPromise(lazyImport, maxAttempt - 1, currentAttempt + 1)
              .then(resolve)
              .catch(reject),
          currentAttempt * 500,
        );
      });
  });
}
