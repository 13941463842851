export enum ScreenTypeEnum {
  Desktop = 'DESKTOP',
  Tablet = 'TABLET',
  Mobile = 'MOBILE',
}

export type UseScreenReturnType = {
  screen: ScreenTypeEnum;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isLandScapeMode?: boolean;
  rotateScreenToLandscape?: () => void;
  rotateScreenToPortrait?: () => void;
};
