import React from 'react';
import { useTimeoutFn } from 'react-use';

import useToggle from '@lyearn/core/hooks/useToggle';
import Platform from '@lyearn/core/utils/Platform';
import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Box, Loader as BaseLoader, Stack, Typography } from '@lyearn/ui';
import { JustifyContent } from '@lyearn/ui/src/Stack/types';

import useLoaderLabel from './useLoaderLabel';

const DEFAULT_LOADER_SIZE = Platform.select({ web: 24, default: 36 });
const LOADER_DIRECTION = Platform.select({ web: 'row', default: 'col' });

export interface LoaderProps {
  defaultLabel?: string;
  size?: number;
  className?: string;
  arcColorToken?: React.ComponentProps<typeof BaseLoader>['arcColorToken'];
  labelColor?: React.ComponentProps<typeof Typography>['color'];
  style?: React.ComponentProps<typeof Stack>['style'];
  showLabel?: boolean;
  delay?: number;
  justifyContent?: JustifyContent;
}

const Loader: React.FC<LoaderProps> = React.memo((props) => {
  const {
    defaultLabel,
    size,
    className,
    style,
    arcColorToken,
    labelColor,
    showLabel = true,
    delay,
    justifyContent = 'center',
  } = props;
  const label = useLoaderLabel();
  // useSuspense set to false to turn off the use of React Suspense as this component is used as fallback for Suspense Boundary
  const { t, ready } = useTranslation(undefined, { useSuspense: false });
  const [hide, { off }] = useToggle(!!delay);
  useTimeoutFn(off, delay);

  if (!ready) {
    return null;
  }

  return (
    <Stack
      alignItems="center"
      className={cn('h-full w-full flex-1', className, { 'opacity-0': hide })}
      direction={LOADER_DIRECTION}
      gapX={12}
      justifyContent={justifyContent}
      style={style}>
      <Box className="native:mb-12">
        <BaseLoader
          arcColorToken={arcColorToken || 'icon-active'}
          circleColorToken="icon-placeholder"
          size={size ?? DEFAULT_LOADER_SIZE}
        />
      </Box>
      {showLabel && (
        <Typography color={labelColor || 'text-text-secondary'} variant="body-short-02">
          {defaultLabel || t(label)}
        </Typography>
      )}
    </Stack>
  );
});

export default Loader;
