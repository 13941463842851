import React from 'react';

import { ChevronRightFill } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Stack } from '@lyearn/ui';

import { SidebarAccordionChevronProps } from '../types';

export default function SidebarAccordionChevron({
  isExpanded = false,
  onClick,
  className,
  iconColor,
  iconClassName,
  ariaLabel,
}: SidebarAccordionChevronProps) {
  return (
    <Stack
      aria-label={ariaLabel}
      className={cn('transform duration-200', { 'rotate-90': isExpanded }, className)}
      onClick={onClick}>
      <ChevronRightFill
        className={cn('w-20 h-20 ', { isExpanded: 'rotate-90' }, iconClassName)}
        fillColorToken={iconColor ?? 'icon-secondary'}
      />
    </Stack>
  );
}
