import React, { useState } from 'react';
import config from 'config';

import { ErrorBoundary } from '@/components/Error';
import { useAppConfig } from '@/hooks/useAppConfig';
import useNavigation from '@/hooks/useNavigation';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import { useAdminRoute } from '@/pages/corporate/routes/hooks/useAdminRoutes';
import dynamic from '@/utils/dynamic';

import FloatingFeatureNudge from './components/FloatingFeatureNudge';
import { HELP_MENU_BLACKLISTED_ROUTES } from './consts';
import FeatureNudgeContextProvider from './context';
import { FeatureNudgeType } from './types';

const LiveChat = dynamic(
  () => import(/* webpackChunkName: "live-chat" */ '@/components/HelpMenu/components/LiveChat'),
);

const HelpMenuProvider = ({ children }: React.PropsWithChildren) => {
  const [featureNudge, setFeatureNudge] = useState<FeatureNudgeType | undefined>(undefined);

  const { pathname } = useNavigation();
  const { site: siteConfig } = useAppConfig();
  const user = useUserContext();
  const isAdmin = useAdminRoute();

  const isRouteBlacklisted = HELP_MENU_BLACKLISTED_ROUTES.has(pathname);

  const floatingFeatureNudge = React.useMemo(() => {
    if (
      isAdmin ||
      !featureNudge?.nudgeType ||
      user.meta?.completedOnboardingNudges?.includes(featureNudge.nudgeType)
    ) {
      return null;
    }
    return featureNudge;
  }, [featureNudge, isAdmin, user.meta?.completedOnboardingNudges]);

  return (
    <FeatureNudgeContextProvider setFeatureNudge={setFeatureNudge}>
      {children}
      {!isRouteBlacklisted && config.env === 'production' ? (
        <ErrorBoundary>
          {siteConfig.liveChat ? (
            <LiveChat config={siteConfig.liveChat} featureNudge={featureNudge} user={user} />
          ) : null}
        </ErrorBoundary>
      ) : null}
      {floatingFeatureNudge ? <FloatingFeatureNudge featureNudge={floatingFeatureNudge} /> : null}
    </FeatureNudgeContextProvider>
  );
};

export default HelpMenuProvider;
