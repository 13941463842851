import config from 'config';

import { post } from '@/helper/request';
import { setPublicCookies } from '@/modules/auth/utils';

const publicAuthQuery = async (host: string = config.host_domain) => {
  try {
    return await post(`${config.http_protocol}://${host}/api/auth/publicAuthentication`, {
      host,
    }).then((response) => {
      response.publicToken && setPublicCookies(response.publicToken);
      return response;
    });
  } catch (e) {
    console.error('Error Executing publicAuthQuery', e);
  }
};
export default publicAuthQuery;
