import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/designations';
const ADMIN_DESIGNATIONS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const DesignationAdminRoutes = {
  DesignationManager: () => {
    return `${ADMIN_DESIGNATIONS_PARENT_ROUTE}`;
  },
  DesignationDetails: (orgRoleId: string) => {
    return `${ADMIN_DESIGNATIONS_PARENT_ROUTE}/${orgRoleId}/details`;
  },
};

const routes = {
  ...DesignationAdminRoutes,
};

export default routes;
