import { ActionType, ModalVariant } from '../../types';
import ModalFooter, { ModalFooterWithTertiary } from '../ModalFooter';
import WideModalFooter, { WideModalFooterWithTertiary } from '../WideModalFooter';

const ModalActions = (props: {
  onClose: () => void;
  isScrolled: boolean;
  variant?: ModalVariant;
  action: ActionType;
  showCloseBtn?: boolean;
}) => {
  const { onClose, isScrolled, variant, action, showCloseBtn } = props;

  if (variant === ModalVariant.ExtraWide) {
    if (action.TertiaryAction) {
      return (
        <WideModalFooterWithTertiary
          action={action}
          showCloseBtn={showCloseBtn}
          onClose={onClose}
        />
      );
    }

    return <WideModalFooter action={action} showCloseBtn={showCloseBtn} onClose={onClose} />;
  }

  // Separate variant if tertiary button present
  if (action.TertiaryAction) {
    return <ModalFooterWithTertiary action={action} isScrolled={isScrolled} onClose={onClose} />;
  }

  return <ModalFooter action={action} isScrolled={isScrolled} onClose={onClose} />;
};

export default ModalActions;
