import { Client } from 'urql';

import ErrorReporter, { isProd } from '@lyearn/core/utils/ErrorReporter';
import { FeatureFlagType } from '@/types/schema';

import { FEATURE_FLAGS_QUERY_INPUT } from '../../consts';
import { FeatureFlagsDocument } from '../../graphql/queries/generated/featureFlags';

export const fetchFeatureFlags = async (client: Client) => {
  try {
    return client
      .query(FeatureFlagsDocument, FEATURE_FLAGS_QUERY_INPUT, { requestPolicy: 'network-only' })
      .toPromise()
      .then((result) => {
        const featureFlags = result?.data?.featureFlags?.featureFlags as
          | FeatureFlagType[]
          | undefined;

        if (result?.error) {
          const errorMessage = '[Error fetching featureFlags]';
          !__IS_BROWSER__ && console.log(errorMessage, result.error);

          throw new Error(`${errorMessage} ${JSON.stringify(result.error)}`);
        }

        return featureFlags;
      });
  } catch (error) {
    __IS_BROWSER__ && ErrorReporter.error(error);

    if (isProd && __IS_BROWSER__) {
      return Promise.resolve();
    }

    return Promise.reject(error);
  }
};
