import { useEffect } from 'react';
import { useNetworkState } from 'react-use';

import { useTranslation } from '@lyearn/i18n';
import { useSnackbar } from '@lyearn/ui';

const useUserNetworkStatus = () => {
  const { showErrorSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { online, previous: previousState } = useNetworkState();

  useEffect(() => {
    if (!online && previousState) {
      showErrorSnackbar({
        message: t('Common:Error:ListError.NoInternet.label'),
        isClosable: false,
        duration: 10000,
      });
    } else if (online && !previousState) {
      closeSnackbar();
    }
  }, [online, closeSnackbar, showErrorSnackbar, previousState, t]);
};

export default useUserNetworkStatus;
