// libs
import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Button, Stack } from '@lyearn/ui';
// components
import { Footer } from '@/components/DialogComponent';
import { typography } from '@/consts/typography';

// types
import { ActionType } from '../types';

function getFooterClassNames(isScrolled?: boolean) {
  return cn(
    'flex items-center justify-between w-full sticky bottom-0 z-50 px-24 xs:px-16 py-24 flex-shrink-0',
    {
      'border-t border-divider': isScrolled,
    },
  );
}

function PrimarySecondaryButtons({
  action,
  onClose,
  showCloseBtn = true,
}: {
  action: ActionType;
  onClose: () => void;
  showCloseBtn?: boolean;
}) {
  const { t } = useTranslation();
  const { onSubmit, text, discardText, disabled = false, isSubmitting, onSecondarySubmit } = action;
  const { isMobile } = useScreen();

  return (
    <>
      {showCloseBtn ? (
        <Button
          appearance="secondary"
          className="flex-1 bg-bg-primary me-8 xs:hidden"
          disableFocusRipple={isMobile}
          shape="pill"
          size={isMobile ? 'x-large' : 'large'}
          typography={isMobile ? typography : undefined}
          variant="outlined"
          onClick={onSecondarySubmit ?? onClose}>
          {discardText ?? t('Common:Cancel')}
        </Button>
      ) : null}
      <Button
        className="flex-1"
        disableFocusRipple={isMobile}
        disabled={disabled}
        loading={isSubmitting}
        shape="pill"
        size={isMobile ? 'x-large' : 'large'}
        typography={isMobile ? typography : undefined}
        onClick={onSubmit}>
        {text ?? t('Common:Actions.Confirm')}
      </Button>
    </>
  );
}

type ModalFooterProps = {
  action: ActionType;
  onClose: () => void;
  isScrolled?: boolean;
  showCloseBtn?: boolean;
};

export function ModalFooterWithTertiary(props: ModalFooterProps) {
  const className = getFooterClassNames(props.isScrolled);
  const { TertiaryAction, className: classNameProp } = props.action;

  return (
    <Footer className={cn(className, classNameProp)}>
      <Stack alignItems="center" className="w-full" justifyContent="between">
        {TertiaryAction}
        <Stack justifyContent="end">
          <PrimarySecondaryButtons
            action={props.action}
            showCloseBtn={props.showCloseBtn}
            onClose={props.onClose}
          />
        </Stack>
      </Stack>
    </Footer>
  );
}

function ModalFooter(props: ModalFooterProps) {
  const className = getFooterClassNames(props.isScrolled);

  return (
    <Footer className={cn(className, props.action.className)}>
      <PrimarySecondaryButtons
        action={props.action}
        showCloseBtn={props.showCloseBtn}
        onClose={props.onClose}
      />
    </Footer>
  );
}

export default React.memo(ModalFooter);
