import { useCallback, useLayoutEffect, useRef } from 'react';

/**
 * A custom hook that converts a callback to a ref to avoid triggering re-renders when passed as a
 * prop or avoid re-executing effects when passed as a dependency
 */

function useEvent<T extends (...args: any[]) => any>(callback: T | undefined): T {
  const callbackRef = useRef(callback);

  // We are using `useLayoutEffect` instead of the `useEffect` to ensure that we do the assignment before
  // any other code within this component runs (since `useLayoutEffect` is synchronous)
  // Let's say we used useEffect instead, the callbackRef.current would be undefined
  // when some other function is using function returned by this hook it might be undefined for them
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    ((...args) => {
      return callbackRef.current?.(...args);
    }) as T,
    [],
  );
}

export default useEvent;
