import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/exercise';
const ADMIN_EXERCISE_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const adminExerciseRoutes = {
  ExerciseCreator: () => `${ADMIN_EXERCISE_PARENT_ROUTE}/create`,
  ExerciseEditor: (exerciseId: string) => `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/edit`,
  ExerciseDuplicate: (exerciseId: string) =>
    `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/duplicate`,

  AdminExerciseDetails: (exerciseId: string) =>
    `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/details`,
  AdminExerciseReport: (exerciseId: string) =>
    `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/reports`,
  AdminExercisePreviewDevices: (exerciseId: string) =>
    `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/preview-devices`,
  AdminExercisePreview: (exerciseId: string) =>
    `${ADMIN_EXERCISE_PARENT_ROUTE}/${exerciseId}/preview`,
};

const learnerExerciseRoutes = {
  ExerciseDetails: (exerciseId: string) => `${PARENT_ROUTE}/${exerciseId}/details`,
  ExerciseSubmission: (submissionId: string) => `${PARENT_ROUTE}/submission/${submissionId}`,
  ExerciseSubmissionFromReviewer: (submissionId: string, reviewerId: string) =>
    `${PARENT_ROUTE}/submission/${submissionId}/reviewer/${reviewerId}`,
  ExercisePeerReview: (exerciseId: string) => `${PARENT_ROUTE}/${exerciseId}/peer-review`,
  ExerciseSubmissionReview: (submissionId: string) =>
    `${PARENT_ROUTE}/submission/${submissionId}/review`,
};

export const exerciseDetail = (exerciseId: string) => ({
  admin: adminExerciseRoutes.AdminExerciseDetails(exerciseId),
  learner: learnerExerciseRoutes.ExerciseDetails(exerciseId),
});

const routes = {
  ...adminExerciseRoutes,
  ...learnerExerciseRoutes,
};

export const ADMIN_EXERCISE_ROUTE_KEYS = {
  ExerciseCreator: 'ExerciseCreator',
  ExerciseEditor: 'ExerciseEditor',
  AdminExerciseDetails: 'AdminExerciseDetails',
  AdminExerciseReview: 'AdminExerciseReview',
};

export const LEARNER_EXERCISE_ROUTE_KEYS = {
  ExerciseDetails: 'ExerciseDetails',
  ExerciseReview: 'ExerciseReview',
};

export const EXERCISE_ROUTE_KEYS = {
  ...ADMIN_EXERCISE_ROUTE_KEYS,
  ...LEARNER_EXERCISE_ROUTE_KEYS,
};

export default routes;
