import React from 'react';

import { cn } from '@lyearn/style';
import { Stack } from '@lyearn/ui';
import * as User from '@/components/User';

import { LayoutSize, LayoutType, UserInfoLayoutProps } from './types';
import { LAYOUT } from './utils';

function UserInfoLayout<T extends LayoutType>(props: UserInfoLayoutProps<T>) {
  const {
    size = LayoutSize.Medium,
    user,
    layout = LayoutType.AvatarAndRole,
    className,
    overrides,
  } = props;
  const layoutData = overrides || LAYOUT[size];

  return (
    <User.Provider
      alignItems="center"
      className={cn('flex w-full items-center', className)}
      user={user}>
      <User.ProfilePicture className={layoutData.image} size={layoutData.imageSize} />
      <Stack className="min-w-0" direction="col">
        <User.Name
          className={layoutData?.header?.className}
          variant={layoutData?.header?.typography}
        />
        {layout === LayoutType.AvatarAndRole ? (
          <User.Role
            className={layoutData?.description?.className}
            color={layoutData?.description?.color}
            variant={layoutData?.description?.typography}
          />
        ) : (
          <User.UniqueId
            className={layoutData?.description?.className}
            color={layoutData?.description?.color}
            variant={layoutData?.description?.typography}
          />
        )}
      </Stack>
    </User.Provider>
  );
}

export default React.memo(UserInfoLayout);
