//libs
import React from 'react';

//components
import { Box, Stack, Typography } from '@lyearn/ui';

//types
import { ModalHeaderProps } from './types';

const ModalHeaderMobile = (props: ModalHeaderProps) => {
  const { Icon, title, description, children } = props.header;

  return (
    <Stack className="flex w-full flex-1" direction="col">
      <Stack className="flex-row items-center justify-start px-20 pt-22 pb-20">
        {Icon ? <Icon className="h-40 w-40 flex-shrink-0 me-20" /> : null}
        <Box className="flex-1 flex-col justify-start">
          <Typography variant="heading-s">{title}</Typography>
          <Typography className="text-text-secondary" variant="body-short-02">
            {description}
          </Typography>
        </Box>
      </Stack>
      <Stack className="w-full px-20" direction="col">
        {children}
      </Stack>
    </Stack>
  );
};

export default ModalHeaderMobile;
