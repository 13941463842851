// libs
import { createContext, useContext } from 'react';

// types
import { UserContextProps } from '../types';

export const UserContext = createContext<UserContextProps | null>(null);

export const useUserInfo = () => {
  const value = useContext(UserContext);

  if (!value) {
    throw new Error('[UserInfoContext] Not initialised');
  }

  return value;
};
