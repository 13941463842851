//https://github.com/paulnguyen-mn/use-scroll-progress/blob/master/src/hooks/useScrollProgress.js

import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export type ScrollDirection = 'up' | 'down';

// This is the difference of content height between scroll height in the
// Instructor and normal user in the course micro app viewer in the session.
// We are not showing the bottom navigation options to normal users.
const HEIGHT_DIFF = 186;

const getWindowScrollHeight = () => {
  // Refs: https://javascript.info/size-and-scroll-window
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.body.clientHeight,
    document.documentElement.clientHeight,
  );
};

interface Scroll {
  position: number;
  top: number;
  direction: ScrollDirection | null;
}

const useScrollProgress = (
  containerEl?: HTMLDivElement | null,
  navigationContainer?: HTMLDivElement | null,
  onScrollPosition?: (position: number) => void,
) => {
  const [scroll, setScroll] = useState<Scroll>({ position: 0, top: 0, direction: null });

  useEffect(() => {
    const scrollContainerEl = containerEl ? containerEl : window;
    if (!scrollContainerEl) return;

    const calculateScrollDistance = () => {
      const { scrollTop, scrollHeight, clientHeight } = containerEl
        ? {
            scrollTop: containerEl.scrollTop,
            scrollHeight: containerEl.scrollHeight,
            clientHeight: containerEl.clientHeight,
          }
        : {
            scrollTop: window.pageYOffset,
            scrollHeight: getWindowScrollHeight(),
            clientHeight: document.documentElement.clientHeight,
          };
      const totalDocScrollLength = scrollHeight - clientHeight;

      const scrollPosition = Math.floor(
        totalDocScrollLength ? (scrollTop / totalDocScrollLength) * 100 : 0,
      );

      setScroll((scroll) => ({
        position: scrollPosition,
        top: scrollTop,
        direction: scroll.top > scrollTop ? 'up' : 'down',
      }));
      onScrollPosition?.(
        totalDocScrollLength
          ? (scrollTop / (scrollHeight - (navigationContainer?.clientHeight || HEIGHT_DIFF))) * 100
          : 0,
      );
    };

    const handleScrollChange = () => {
      requestAnimationFrame(calculateScrollDistance);
    };

    const throttleScroll = throttle(handleScrollChange, 100);
    scrollContainerEl.addEventListener('scroll', throttleScroll);

    return () => {
      scrollContainerEl.removeEventListener('scroll', throttleScroll);
    };
  }, [containerEl, navigationContainer?.clientHeight, onScrollPosition]);

  return { scrollProgress: scroll.position, direction: scroll.direction };
};

export default useScrollProgress;
