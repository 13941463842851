import articleRoutes, { ARTICLE_ROUTE_KEYS } from './articles';
import checkinRoutes, { CHECKIN_ROUTE_KEYS } from './checkin';
import courseRoutes, { COURSE_ROUTE_KEYS } from './course';
import exerciseRoutes, { EXERCISE_ROUTE_KEYS } from './exercise';
import externalContentRoutes, { EXTERNAL_CONTENT_ROUTE_KEYS } from './external';
import pathRoutes, { PATH_ROUTE_KEYS } from './path';
import quizRoutes, { QUIZ_ROUTE_KEYS } from './quiz';
import scormRoutes, { SCORM_ROUTE_KEYS } from './scorm';
import surveyRoutes, { SURVEY_ROUTE_KEYS } from './survey';
import taskRoutes, { TASK_ROUTE_KEYS } from './task';

const routes = {
  ...articleRoutes,
  ...courseRoutes,
  ...checkinRoutes,
  ...exerciseRoutes,
  ...pathRoutes,
  ...taskRoutes,
  ...quizRoutes,
  ...surveyRoutes,
  ...externalContentRoutes,
  ...scormRoutes,
};

export const CONTENT_ROUTES = {
  ...ARTICLE_ROUTE_KEYS,
  ...COURSE_ROUTE_KEYS,
  ...CHECKIN_ROUTE_KEYS,
  ...EXERCISE_ROUTE_KEYS,
  ...PATH_ROUTE_KEYS,
  ...TASK_ROUTE_KEYS,
  ...QUIZ_ROUTE_KEYS,
  ...SURVEY_ROUTE_KEYS,
  ...EXTERNAL_CONTENT_ROUTE_KEYS,
  ...SCORM_ROUTE_KEYS,
};

export default routes;
