import { FeedbackTabs } from '@/modules/corporate/modules/feedback/views/FeedbackWall/utils';

const adminRoutes = {};

const learnerRoutes = {
  Feedback: (tab: FeedbackTabs) => `/feedback/${tab}`,
};

export { adminRoutes, learnerRoutes };
export default learnerRoutes;
