import { ReviewCycleManagerTabsEnum } from '@/modules/reviews/views/ReviewManager/hooks/useReviewCycleManager';
import { ReportsTab } from '@/modules/reviews/views/ReviewReports/consts';

import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/reviews';
const ADMIN_REVIEW_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const reviewAdminRoutes = {
  // manager
  ReviewManager: (tab: string = ReviewCycleManagerTabsEnum.Active) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/all/${tab}`,
  ReviewManagerOrganisational: (tab: string = ReviewCycleManagerTabsEnum.Active) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/organisational/${tab}`,
  ReviewManagerProject: (tab: string = ReviewCycleManagerTabsEnum.Active) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/project/${tab}`,
  ReviewManagerRuleBased: (tab: string = ReviewCycleManagerTabsEnum.Active) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/rule-based/${tab}`,

  ReviewQuestionBank: () => `${ADMIN_PARENT_ROUTE}/reviews/question-bank`,
  AutomatedReviewActionPlan: () => `${ADMIN_REVIEW_PARENT_ROUTE}/action-plan`,

  // creator
  ReviewCreator: () => `${ADMIN_REVIEW_PARENT_ROUTE}/create`,
  OrganisationalReviewCreator: () => `${ADMIN_REVIEW_PARENT_ROUTE}/create/type/organisational`,
  ProjectReviewCreator: () => `${ADMIN_REVIEW_PARENT_ROUTE}/create/type/project`,
  RuleBasedReviewCreator: () => `${ADMIN_REVIEW_PARENT_ROUTE}/create/type/rule-based`,
  ReviewCreatorWithTemplate: (templateId: string) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/create/template/${templateId}`,
  ReviewEditor: (reviewCycleId: string) => `${ADMIN_REVIEW_PARENT_ROUTE}/${reviewCycleId}/edit`,

  // details
  ReviewDetails: (reviewCycleId: string) => `${ADMIN_REVIEW_PARENT_ROUTE}/${reviewCycleId}/details`,
  ReviewReportRoot: (reviewCycleId: string) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/${reviewCycleId}/reports`,
  ReviewReport: (reviewCycleId: string, tab: ReportsTab = ReportsTab.Summary) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/${reviewCycleId}/reports/${tab}`,

  RevieweeAdminResult: (reviewCycleId: string, revieweeId: string, tab: string) =>
    `${ADMIN_REVIEW_PARENT_ROUTE}/${reviewCycleId}/reviewee/${revieweeId}/result/${tab}`,
};

const reviewLearnerRoutes = {
  //list
  ReviewsListMy: () => '/review-cycles/my',
  ReviewsListTeam: () => '/review-cycles/team',
  ReviewsListActionPlan: () => '/review-cycles/action-plan',

  // details
  RevieweeResult: (reviewCycleId: string, revieweeId: string, tab: string) =>
    `/review-cycle/${reviewCycleId}/${revieweeId}/results/${tab}`,
};

const routes = {
  ...reviewAdminRoutes,
  ...reviewLearnerRoutes,
};

export const REVIEW_ADMIN_ROUTE_KEYS = {
  ReviewManager: 'ReviewManager',
  ReviewManagerOrganisational: 'ReviewManagerOrganisational',
  ReviewManagerProject: 'ReviewManagerProject',
  ReviewManagerRuleBased: 'ReviewManagerRuleBased',
  ReviewManagerTemplate: 'ReviewManagerTemplate',
  AutomatedReviewActionPlan: 'AutomatedReviewActionPlan',
  ReviewCreator: 'ReviewCreator',
  ReviewEditor: 'ReviewEditor',
  ReviewDetails: 'ReviewDetails',
};

export const REVIEW_LEARNER_ROUTE_KEYS = {};

export const REVIEW_ROUTE_KEYS = {
  ...REVIEW_ADMIN_ROUTE_KEYS,
  ...REVIEW_LEARNER_ROUTE_KEYS,
};

export default routes;
