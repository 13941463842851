// libs
import React from 'react';

import { useTranslation } from '@lyearn/i18n';
import { cn } from '@lyearn/style';
import { Button, Stack } from '@lyearn/ui';
// components
import { Footer } from '@/components/DialogComponent';

// types
import { ActionType } from '../types';

const DEFAULT_CLASSNAME =
  'flex flex-row items-center w-full sticky bottom-0 z-50 px-24 py-24 flex-shrink-0 justify-end';

function PrimarySecondaryButtons({
  action,
  onClose,
  showCloseBtn = true,
}: {
  action: ActionType;
  onClose: () => void;
  showCloseBtn?: boolean;
}) {
  const { t } = useTranslation();
  const {
    onSubmit,
    text,
    discardText,
    disabled = false,
    appearance = 'primary',
    isSubmitting = false,
    endIcon,
    classes,
    onSecondarySubmit,
  } = action;

  return (
    <>
      {showCloseBtn ? (
        <Button
          appearance="secondary"
          className={cn('native:px-0 bg-bg-primary me-8 xs:flex-1', classes?.discard)}
          size="large"
          variant="outlined"
          onClick={onSecondarySubmit ?? onClose}>
          {discardText ?? t('Common:Cancel')}
        </Button>
      ) : null}
      <Button
        appearance={appearance}
        className={cn('native:px-0 xs:flex-1', classes?.action)}
        disabled={disabled}
        endIcon={endIcon}
        loading={isSubmitting}
        size="large"
        onClick={onSubmit}>
        {text ?? t('Common:Actions.Confirm')}
      </Button>
    </>
  );
}

export function WideModalFooterWithTertiary(props: {
  action: ActionType;
  onClose: () => void;
  showCloseBtn?: boolean;
}) {
  const { action, onClose, showCloseBtn } = props;
  const { className = DEFAULT_CLASSNAME, TertiaryAction } = action;

  return (
    <Footer className={className}>
      <Stack alignItems="center" className="w-full" justifyContent="between">
        {TertiaryAction}
        <Stack className="flex-1" justifyContent="end">
          <PrimarySecondaryButtons action={action} showCloseBtn={showCloseBtn} onClose={onClose} />
        </Stack>
      </Stack>
    </Footer>
  );
}

function WideModalFooter(props: {
  action: ActionType;
  onClose: () => void;
  showCloseBtn?: boolean;
}) {
  const { action, onClose, showCloseBtn } = props;
  const { className } = action;

  return (
    <Footer className={cn(DEFAULT_CLASSNAME, className)}>
      <PrimarySecondaryButtons action={action} showCloseBtn={showCloseBtn} onClose={onClose} />
    </Footer>
  );
}

export default WideModalFooter;
