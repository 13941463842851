import { LayoutValues } from '@/components/User/layouts/UserInfoLayout/utils';

import { IUser } from '../../types';

export enum LayoutSize {
  Large = 'LARGE',
  Medium = 'Medium',
}

export enum LayoutType {
  AvatarAndRole = 'AVATAR_AND_ROLE',
  AvatarAndEmail = 'AVATAR_AND_EMAIL',
}

export interface UserInfoLayoutProps<T extends LayoutType = LayoutType.AvatarAndRole> {
  user: IUser<T>;
  layout?: T;
  size?: LayoutSize;
  className?: string;
  overrides?: LayoutValues;
}
