import { TFunction } from '@lyearn/i18n';
import { PersonManagerOutline, SyncOutline } from '@lyearn/icons';
import {
  getAlphabeticallySortItem,
  getDefaultSortValue,
  getLastEditedSortItem,
  getNewestSortItem,
  getOldestSortItem,
} from '@/components/FilterManager/SortMenu/utils';
import { FilterManager, SortAndFilterItem } from '@/components/FilterManager/types';
import { ReviewCycleStatusEnum, ReviewCycleTypeEnum, ReviewTypeEnum } from '@/types/schema';

export const SKILL_REVIEW_SORT_ITEMS = [
  getLastEditedSortItem(),
  getAlphabeticallySortItem(),
  getNewestSortItem(),
  getOldestSortItem(),
];

export const SKILL_REVIEW_SORT_DEFAULT_VALUE = getDefaultSortValue(SKILL_REVIEW_SORT_ITEMS);

export const getSortAndFilterItems = (
  t: TFunction,
  disableTypeFilter: boolean,
  disableStatusFilter: boolean,
): SortAndFilterItem[] => {
  return [
    {
      type: FilterManager.Sort,
      isEnabled: true,
      items: SKILL_REVIEW_SORT_ITEMS,
    },
    {
      type: FilterManager.Filter,
      id: 'status',
      defaultPlaceholder: t('Reviews:Manager.Stage'),
      isEnabled: !disableStatusFilter,
      groups: [
        {
          id: 'status',
          options: [
            {
              value: ReviewCycleStatusEnum.Draft,
              label: t('Reviews:Status.Draft'),
            },
            {
              value: ReviewCycleStatusEnum.NotStarted,
              label: t('Reviews:Status.Upcoming'),
            },
            {
              value: ReviewCycleStatusEnum.PeerSelection,
              label: t('Reviews:Status.Active'),
            },
          ],
        },
      ],
    },
    {
      type: FilterManager.Filter,
      id: 'reviewers',
      defaultPlaceholder: t('Reviews:Manager.Reviewers.Reviewers'),
      isEnabled: true,
      groups: [
        {
          id: 'reviewersOptions',
          options: [
            {
              Icon: SyncOutline,
              value: ReviewTypeEnum.Self,
              label: t('Reviews:Manager.Reviewers.SelfReview'),
            },
            {
              Icon: PersonManagerOutline,
              value: ReviewTypeEnum.Manager,
              label: t('Reviews:Manager.Reviewers.ManagerReview'),
            },
          ],
        },
      ],
    },
    {
      type: FilterManager.Filter,
      id: 'type',
      defaultPlaceholder: t('Reviews:Manager.Type'),
      isEnabled: !disableTypeFilter,
      groups: [
        {
          id: 'type',
          options: [
            {
              value: ReviewCycleTypeEnum.Organizational,
              label: t('Reviews:Manager.Organizational'),
            },
            {
              value: ReviewCycleTypeEnum.Project,
              label: t('Reviews:Manager.Project'),
            },
            {
              value: ReviewCycleTypeEnum.Automated,
              label: t('Reviews:Manager.RuleBased'),
            },
          ],
        },
      ],
    },
  ];
};
