import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/integrations';
const ADMIN_INTEGRATION_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const integrationsAdminRoutes = {
  IntegrationsManager: () => `${ADMIN_INTEGRATION_PARENT_ROUTE}`,
  IntegrationsDetails: (integrationId: string) =>
    `${ADMIN_INTEGRATION_PARENT_ROUTE}/${integrationId}/details`,
  IntegrationsSettings: (integrationId: string) =>
    `${ADMIN_INTEGRATION_PARENT_ROUTE}/${integrationId}/settings`,
};

const routes = {
  ...integrationsAdminRoutes,
};

export const INTEGRATIONS_ADMIN_ROUTE_KEYS = {
  IntegrationsManager: 'IntegrationsManager',
  IntegrationsDetails: 'IntegrationsDetails',
  IntegrationsSettings: 'IntegrationsSettings',
};

export const INTEGRATIONS_LEARNER_ROUTE_KEYS = {};

export const INTEGRATIONS_ROUTE_KEYS = {
  ...INTEGRATIONS_ADMIN_ROUTE_KEYS,
  ...INTEGRATIONS_LEARNER_ROUTE_KEYS,
};

export default routes;
