import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync } from 'react-use';
import config from 'config';
import { useClient } from 'urql';

import { createContext } from '@lyearn/core/utils/context';
import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import Loader from '@/components/Loader';
import useCdnCookie, { isCookieAboutToExpire } from '@/hooks/useCdnCookie';
import { LoginToken, UserProfile } from '@/modules/auth/types';

import currentUser from '../../../helper/currentUser';
import { getHeadersToSetCdnCookie, logout as logoutCookies, setLoginCookies } from '../utils';
import { openReplayTrackerInit } from '../utils/openReplay/init';
import publicAuthQuery from '../utils/publicAuth';
import { fetchUser } from './useFetchUser';

export type UserContextType = {
  user?: UserProfile;
  setLoginAndRefetch: (
    loginToken: LoginToken,
    options?: Partial<{ allowMemoryCookie: boolean }>,
  ) => Promise<void>;
  refetchUser: () => Promise<void>;
  logout: () => Promise<void>;
};

export type UseUserContextType = Omit<UserContextType, 'user'> & UserProfile;

const [UserContext, useUserContextHook] = createContext<UserContextType>('UserContext');

export const useUserContext = () => {
  const { user, setLoginAndRefetch, refetchUser, logout } = useUserContextHook();
  return useMemo(
    () =>
      ({
        ...user,
        setLoginAndRefetch,
        refetchUser,
        logout,
      }) as UseUserContextType,
    [user, setLoginAndRefetch, refetchUser, logout],
  );
};

export const UserContextProvider: React.FC<{
  children?: React.ReactNode;
  preloadedUser?: UserProfile;
}> = ({ children, preloadedUser }) => {
  const [user, setUser] = useState<UserProfile | undefined>(preloadedUser);
  const { fetchStatus, response, fetchCookie } = useCdnCookie();
  const client = useClient();
  const cdnCookieSignerHost = config.cdn_cookie_signer_host;
  const refetchUser = useCallback(async () => {
    const updatedUser = await fetchUser(client, currentUser.id()!);
    if (updatedUser?.id) {
      setUser(updatedUser);
    }
  }, [client]);

  useEffect(() => {
    if (user) {
      ErrorReporter.setUser({ ...user, email: user?.emailId ?? user?.uniqueId });
      if (user.meta.enableOpenReplay) {
        openReplayTrackerInit(user);
      }
    }
  }, [user]);
  const fetchTokenAndSetCdnCookie = async () => {
    const headers = await getHeadersToSetCdnCookie();
    fetchCookie(headers);
  };
  useEffect(() => {
    if (user && cdnCookieSignerHost) {
      if (isCookieAboutToExpire()) {
        fetchTokenAndSetCdnCookie();
      }
    }
  }, [user]);
  const { loading: loadingUser } = useAsync(
    () => (currentUser.id() ? (user ? Promise.resolve() : refetchUser()) : publicAuthQuery()),
    [],
  );

  const logout = useCallback(async () => {
    await logoutCookies();
  }, []);

  const setLoginAndRefetch = useCallback(
    async (...args: Parameters<typeof setLoginCookies>) => {
      await setLoginCookies(...args);
      return refetchUser();
    },
    [refetchUser],
  );

  if (loadingUser) {
    return <Loader />;
  }

  return (
    <UserContext
      logout={logout}
      refetchUser={refetchUser}
      setLoginAndRefetch={setLoginAndRefetch}
      user={user}>
      {children}
    </UserContext>
  );
};
