import React from 'react';
import { SkipNavContent } from '@reach/skip-nav';

import { Box } from '@lyearn/ui';

interface ContentProps {
  children: React.ReactNode;
}

function Content(props: ContentProps) {
  return (
    <>
      <SkipNavContent />
      <Box className="flex-1" component="main">
        {props.children}
      </Box>
    </>
  );
}

export default Content;
