import { useCallback, useMemo } from 'react';

import { CheckMark } from '@lyearn/icons';
import { ActionMenuItemProps } from '@lyearn/molecules';

import { useUserContext } from '../../../../modules/auth/hooks/useUser';
import { useFetchConfig } from '../../../ConfigsProvider';
import { LANGUAGE_I18N_MAP } from '../../consts';
import { useSetUserLanguageMutation } from '../../graphql/mutations/generated/changeUserLanguage';
import { useUserLanguagePreferenceQuery } from '../../graphql/queries/generated/userLanguagePreference';

const useLanguageMenu = ({ onAction: onActionProp }: { onAction?: (language: string) => void }) => {
  const [, executeSetUserLanguage] = useSetUserLanguageMutation();
  const { id: userId } = useUserContext();

  const { languageConfig } = useFetchConfig(['languageConfig']);
  const [{ data }] = useUserLanguagePreferenceQuery({
    variables: { filters: { userId }, tagId: languageConfig?.languageTagId! },
    pause: !languageConfig?.languageTagId,
    requestPolicy: 'cache-and-network',
  });

  const selectedLanguageId = data?.user?.tag?.optionIdValues?.length
    ? data?.user?.tag?.optionIdValues?.[0]
    : undefined;

  const items = useMemo<ActionMenuItemProps[]>(
    () =>
      languageConfig?.languageTag?.options?.map(({ id, name }) => ({
        id,
        label: name,
        EndIcon: selectedLanguageId === id ? CheckMark : undefined,
      })) ?? [],
    [languageConfig?.languageTag?.options, selectedLanguageId],
  );

  const onAction = useCallback(
    (languageId: string) => {
      if (languageConfig?.languageTagId) {
        executeSetUserLanguage({
          input: { languageTagId: languageConfig.languageTagId, optionId: languageId },
        }).then(() => {
          const languageName = items.find((item) => item.id === languageId)?.label ?? 'English';
          localStorage.setItem('language', LANGUAGE_I18N_MAP[languageName]);
          window.location.reload();
          onActionProp?.(languageId);
        });
      }
    },
    [executeSetUserLanguage, items, languageConfig, onActionProp],
  );

  return { onAction, items };
};

export default useLanguageMenu;
