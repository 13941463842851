import { JobStateFragment } from '@/modules/corporate/modules/bulk-import/graphql/fragments/generated/JobState';
import { JobEntityStatusEnum, JobPurposeEnum } from '@/types/schema';

export function modifyJobStatus(job: JobStateFragment | undefined) {
  if (job) {
    if (
      job.purpose == JobPurposeEnum.BulkUnAssignLearnerPreprocess &&
      job.status == JobEntityStatusEnum.Completed
    ) {
      const { meta } = job;
      const status =
        meta?.bulkUnAssignLearnerMetadata?.totalLearnersCount ==
        meta?.bulkUnAssignLearnerMetadata?.unAssignableLearnersCount
          ? JobEntityStatusEnum.Completed
          : JobEntityStatusEnum.Failed;
      const modifiedJob = { ...job, status: status };
      return modifiedJob;
    }
  }
  return job;
}
