import { Cache, UpdateResolver } from '@urql/exchange-graphcache';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import { MeetingSubscriptionOperationEnum, MeetingSubscriptionPayload } from '@/types/schema';

import { MeetingFragment } from '../../fragment/generated/Meeting';
import {
  MeetingDocument,
  MeetingQuery,
  MeetingQueryVariables,
} from '../../queries/generated/meeting';

const handleUpdateMeeting = (cache: Cache, meeting?: MeetingFragment | null) => {
  if (!meeting) return;

  inspectCache(cache, 'Query', 'meeting', (field) => {
    const args = field.arguments as MeetingQueryVariables;
    updateCache<MeetingQuery, MeetingQueryVariables>(
      cache,
      MeetingDocument,
      {
        ...args,
        filters: {
          meetingId: meeting.id,
        },
      },
      (data) => {
        const meetingItem = data?.meeting?.meeting;
        if (!meetingItem) return data;

        const updatedMeetingItem: MeetingQuery = {
          ...data,
          meeting: {
            ...data.meeting,
            meeting: {
              ...data.meeting.meeting,
              ...meeting,
            },
          },
        };

        return updatedMeetingItem;
      },
    );
  });
};

export function getMeetingUpdater(): UpdateResolver {
  return (result, args, cache, info) => {
    const meetingPayload = result.meetings as MeetingSubscriptionPayload;
    const operationType = meetingPayload.operation;
    const meetingItem = meetingPayload.meeting;

    switch (operationType) {
      case MeetingSubscriptionOperationEnum.TemplateUpdated:
        handleUpdateMeeting(cache, meetingItem as MeetingFragment);
        break;
    }
  };
}
