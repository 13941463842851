import { Breadcrumb, BreadcrumbHistory } from '../context';

export const getBreadcrumbsFromUrl = ({
  breadcrumbUrl,
  breadcrumbHistory,
  pageId,
}: {
  breadcrumbUrl: (string | null)[] | null | undefined;
  breadcrumbHistory: BreadcrumbHistory;
  pageId: string;
}): Breadcrumb[] => {
  const blQuery: string[] = [];

  const isBreadcrumbUrlValid = !breadcrumbUrl?.some((url) => !(url && breadcrumbHistory[url]));

  if (!isBreadcrumbUrlValid) {
    return [];
  }

  const breadcrumbs: Breadcrumb[] =
    breadcrumbUrl?.map((url) => {
      const breadcrumb = breadcrumbHistory[url!];

      const breadcrumbData = {
        id: breadcrumb.id,
        pageTitle: breadcrumb.pageTitle,
        path: breadcrumb.path,
        query: { ...breadcrumb.query, bl: [...blQuery] },
        module: breadcrumb.module,
      };

      blQuery.push(breadcrumb.id);

      return breadcrumbData;
    }) ?? [];

  const currentBreadcrumb = breadcrumbHistory[pageId];

  return [
    ...breadcrumbs,
    {
      id: currentBreadcrumb.id,
      pageTitle: currentBreadcrumb.pageTitle,
      path: currentBreadcrumb.path,
      query: currentBreadcrumb.query,
      module: currentBreadcrumb.module,
    },
  ];
};
