import uniq from 'lodash/uniq';
import { errorExchange } from 'urql';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { jsonStringify } from '@lyearn/core/utils/json';

import { getOpNameFromOperation, getRequestIdFromOperation } from '../../utils/urql';

const IGNORED_BACKEND_ERROR = [
  /JiraAPIClient.getPaginatedIssues/, // know in backend to handle via rate limiting
  /can not create meeting as meeting already exists between participants/,
  /uniqueNameValidation failed/,
  /User already exist with emailId/,
  /Department with name .+ only already exists/,
  /Duplicate api key creation request for userId and key name/,
  /is not allowed to create group/,
];

const urqlErrorExchange = () =>
  errorExchange({
    onError: (error, operation) => {
      const requestId = getRequestIdFromOperation(operation);
      const opName = getOpNameFromOperation(operation);

      // don't report for ssr as they would be flaky due to expired tokens and error will be reported on client
      if (!__IS_BROWSER__) {
        return;
      }

      if (error?.networkError) {
        // @ts-ignore
        const errorEventTarget = error.networkError?.target as EventTarget | undefined;
        const isWSError = errorEventTarget?.constructor?.name === 'WebSocket';
        const errorType = isWSError ? 'WebSocket error' : 'Network error';
        // don't log cancelled requests, request failed due to flaky network and ws errors
        // ref https://github.com/vercel/next.js/blob/4697b1591d7920d4718a5c9a75f3cea3024bc304/packages/next/src/shared/lib/router/router.ts#L588
        if (
          isWSError ||
          error.networkError?.message?.match(
            /(Load failed)|(Failed to fetch)|(NetworkError when attempting to fetch resource)|(cancelled)|(cancelado)/,
          )
        ) {
          return;
        }
        console.log('Network Error:', requestId, opName, jsonStringify(operation.variables).data);
        console.error(isWSError ? errorEventTarget : error.networkError);
        ErrorReporter.error(new Error(`${errorType} in Urql client ${requestId} ${opName}`), {
          tags: {
            requestId,
            opName,
          },
        });
      } else {
        const graphqlErrorsMessages = uniq(error.graphQLErrors?.map((e) => e.message)).join('\n');
        console.log('Graphql Error:', requestId, opName, jsonStringify(operation.variables).data);
        console.error(graphqlErrorsMessages);
        // Logging more details of error without any message
        // https://lyearn.sentry.io/issues/4191646514/events/17b339f404674b268730a513d1eba7b4/
        if (graphqlErrorsMessages?.trim().length === 0) {
          console.log('No Error', error);
        }
        if (!IGNORED_BACKEND_ERROR.find((regex) => graphqlErrorsMessages.match(regex))) {
          ErrorReporter.error(new Error(graphqlErrorsMessages), {
            tags: {
              requestId,
              opName,
            },
          });
        }
      }
    },
  });

export default urqlErrorExchange;
