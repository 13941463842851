import { OptimisticMutationConfig } from '@urql/exchange-graphcache';

import updateAgendaItem from '@/modules/1on1s/components/Agendas/graphql/optimistic/updateAgendaItem';
import updateActionPlan from '@/modules/action-plan/views/ActionPlanDetails/graphql/optimistic/updateActionPlanDetails';
import updateNotificationTemplate from '@/modules/corporate/modules/notifications/graphql/optimistic/updateNotificationTemplate';
import updateChildOKRsOrder from '@/modules/goals/modules/okr/graphql/optimistic/updateChildOKRsOrder';
import updateOKRDetails from '@/modules/goals/modules/okr/graphql/optimistic/updateOKRDetails';
import updateActionItem from '@/modules/tasks/features/actionItems/graphql/optimistic/updateActionItem';
import manageUserSurveyQuestions from '@/modules/user-survey/views/UserSurveyEditor/graphql/optimistic/manageUserSurveyQuestions';
// import updateOrgRole from '@/modules/tracks/views/TrackDetails/graphql/optimisitic/updateOrgRole';

const optimistic: OptimisticMutationConfig = {
  updateNotificationTemplate,
  updateActionItem,
  updateChildOKRsOrder,
  updateOKRDetails,
  updateAgendaItem,
  updateActionPlan,
  manageUserSurveyQuestions,
  // updateOrgRole,
};

export default optimistic;
