import React from 'react';

import { useAppConfig } from '@/hooks/useAppConfig';

import UserInfoWithWorkspace from './UserInfoWithWorkspace';

function UserInfoWithWorkspaceContainer({ className }: { className?: string }) {
  const { site } = useAppConfig();

  return <UserInfoWithWorkspace className={className} props={{ ...site }} />;
}

export default React.memo(UserInfoWithWorkspaceContainer);
