// libs
import { useEffect } from 'react';
import random from 'lodash/random';

const OPTION_LABEL = [
  'Loader.Message1',
  'Loader.Message2',
  'Loader.Message3',
  'Loader.Message4',
  'Loader.Message5',
  'Loader.Message6',
  'Loader.Message7',
  'Loader.Message8',
  'Loader.Message9',
  'Loader.Message10',
  'Loader.Message11',
  'Loader.Message12',
];

const getRandomLabel = () => OPTION_LABEL[random(OPTION_LABEL.length - 1)];

let message = getRandomLabel();
let timeoutId: number | undefined;

const TIMEOUT_DURATION = 5 * 1000;

function useLoaderLabel() {
  useEffect(() => {
    if (timeoutId) {
      clearInterval(timeoutId);
    }

    return () => {
      timeoutId = window.setTimeout(() => {
        message = getRandomLabel();
      }, TIMEOUT_DURATION);
    };
  }, []);

  return message;
}

export default useLoaderLabel;
