import React from 'react';

import { cn } from '@lyearn/style';
import { Box, Image as ImageUi } from '@lyearn/ui';

import { BaseImage } from '..';
import { ImageProps } from './types';

const disabledImageStyles = (disabled: boolean | undefined) =>
  disabled ? { tintColor: '#f2f2f2' } : undefined;

const Image = React.forwardRef<HTMLImageElement, ImageProps>((props, forwardedRef) => {
  const { className, classes, useImg, onLoad, disabled, ...rest } = props;
  const disabledStyles = React.useMemo(() => disabledImageStyles(disabled), [disabled]);

  if (useImg) {
    return (
      <ImageUi
        ref={forwardedRef}
        className={className}
        onLoad={onLoad as React.ComponentProps<typeof ImageUi>['onLoad']}
        {...(rest as any)}
      />
    );
  }

  return (
    <Box ref={forwardedRef} className={cn('relative overflow-hidden', className, classes?.root)}>
      <BaseImage
        className={cn('h-full w-full', classes?.image)}
        // native
        style={disabledStyles}
        onLoad={onLoad}
        {...rest}
      />
    </Box>
  );
});

export default Image;
