import { UpdateResolver } from '@urql/exchange-graphcache';

import { inspectCache, updateCache } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  FetchContentPerformancesDocument,
  FetchContentPerformancesQuery,
  FetchContentPerformancesQueryVariables,
} from '@/modules/corporate/graphql/queries/generated/contentPerformance';
import { CoursePerformanceType, SyncProgressInput } from '@/types/schema';

//optimistically update meta attrs of editor blocks
//solves this: https://linear.app/lyearn/issue/BUG-1295/checklist-status-is-not-getting-retained-on-switching-lesson
export function getMetaProgressUpdater(): UpdateResolver {
  return (_result, args, cache) => {
    const input = args.input as SyncProgressInput | SyncProgressInput[];

    //syncProgress mutation is used at other places than courses to send events,
    //where input is not an array, ignore updation there
    if (!Array.isArray(input)) {
      return;
    }

    const eventsWithMetaAttrMap = new Map();
    input.forEach((event) => {
      if (event.attributes?.meta) {
        eventsWithMetaAttrMap.set(event.target?.blockId, event.attributes?.meta);
      }
    });

    if (eventsWithMetaAttrMap.size) {
      inspectCache(cache, 'Query', 'contentPerformances', (field) => {
        const queryVariables = field.arguments as FetchContentPerformancesQueryVariables;

        updateCache<FetchContentPerformancesQuery, FetchContentPerformancesQueryVariables>(
          cache,
          FetchContentPerformancesDocument,
          queryVariables,
          (data) => {
            if (!data) {
              return data;
            }
            const edges = data.contentPerformances.edges;
            edges?.forEach((edge) => {
              const node = edge.node as CoursePerformanceType;
              const eventMetaArr = eventsWithMetaAttrMap.get(node?.entityId);
              if (eventMetaArr) {
                node.meta = eventMetaArr;
              }
            });
            return data;
          },
        );
      });
    }
  };
}
