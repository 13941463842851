import React, { useCallback } from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import useToggle from '@lyearn/core/hooks/useToggle';
import { MoreVertical } from '@lyearn/icons';
import { MenuListItems } from '@lyearn/molecules';
import { cn } from '@lyearn/style';
import { Avatar, DeprecatedIconButton, Popover } from '@lyearn/ui';
import { FeatureFlags, useFeatureFlags } from '@/helper/FeatureFlags';
import useLinking from '@/hooks/useLinking';
import useNavigation from '@/hooks/useNavigation';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import EditProfileModal from '@/modules/onboarding/views/EditProfile/EditProfileModal';
import routes from '@/pages/corporate/routes';

import { useAppConfig } from '../../hooks/useAppConfig';
import useAnalytics from '../Analytics/hooks/useAnalytics';
import { navIconButton } from '../Header/DefaultHeader/style';
import useLanguageMenu from '../LanguageMenu/hooks/useLanguageMenu';
import { ActionTypes, useMenuItems } from './utils';

interface UserMenuOverrides {
  actionMenu?: string;
  avatarSize?: number;
  avatarClassname?: string;
}

type UserMenuProps = {
  overrides?: UserMenuOverrides;
};

const LanguageMenu = ({
  toggleLanguageMenu,
  showLanguageMenu,
  closeLanguageMenu,
}: {
  showLanguageMenu: boolean;
  toggleLanguageMenu?: (nextValue?: any) => void;
  closeLanguageMenu: () => void;
}) => {
  const { items: languageMenuItems, onAction: onLanguageMenuAction } = useLanguageMenu({
    onAction: closeLanguageMenu,
  });
  return (
    <Popover open={showLanguageMenu} onOpenChange={toggleLanguageMenu}>
      <Popover.Content renderAs="bottom-sheet">
        <MenuListItems
          className="px-8 pb-32 space-y-4"
          items={languageMenuItems}
          onAction={onLanguageMenuAction}
        />
      </Popover.Content>
    </Popover>
  );
};

function UserMenu({ overrides }: UserMenuProps) {
  const { track } = useAnalytics();
  const { openLink } = useLinking();
  const { isMobile } = useScreen();
  const navigation = useNavigation();
  const { name, profilePicture, logout } = useUserContext();
  const {
    [FeatureFlags.ShowUserProfile]: showUserProfile,
    [FeatureFlags.EnableMultiLanguage]: enableMultiLanguage,
  } = useFeatureFlags();
  const {} = useAppConfig();
  const [showPopover, { toggle: togglePopover }] = useToggle(false);
  const [isModalVisible, { toggle: toggleModal }] = useToggle(false);
  const [
    showLanguageMenu,
    { on: openLanguageMenu, off: closeLanguageMenu, toggle: toggleLanguageMenu },
  ] = useToggle(false);

  const { userInfo, items } = useMenuItems(showUserProfile);

  const onAction = useCallback(
    async (actionType: string) => {
      togglePopover();
      switch (actionType) {
        case ActionTypes.EditProfile: {
          toggleModal();
          return;
        }
        case ActionTypes.Logout: {
          await logout();
          track('Logged Out');
          window.location.reload();
          return;
        }
        case ActionTypes.GoToAdmin: {
          openLink(routes.Admin());
          return;
        }
        case ActionTypes.GoToJourneys: {
          navigation.replace(routes.JourneyListing());
          return;
        }
        case ActionTypes.GoToLearner: {
          openLink(routes.Home());
          return;
        }
        case ActionTypes.Language: {
          openLanguageMenu();
          return;
        }
        default: {
          return;
        }
      }
    },
    [togglePopover, toggleModal, logout, track, openLink, navigation, openLanguageMenu],
  );

  return (
    <>
      <Popover open={showPopover} onOpenChange={togglePopover}>
        <Popover.Button className={cn(overrides?.actionMenu, 'cursor-pointer')}>
          {showUserProfile ? (
            <DeprecatedIconButton appearance="secondary" aria-label="User Menu" variant="ghost">
              <Avatar
                className={overrides?.avatarClassname}
                name={name}
                size={overrides?.avatarSize}
                src={profilePicture}
              />
            </DeprecatedIconButton>
          ) : (
            <DeprecatedIconButton
              appearance="secondary"
              aria-label="User Menu"
              className={cn('p-0', navIconButton)}
              size="large"
              variant="ghost">
              <MoreVertical className="h-24 w-24 text-icon-secondary" />
            </DeprecatedIconButton>
          )}
        </Popover.Button>
        <Popover.Content
          className="p-12 pt-16"
          maxWidth={31.1}
          minWidth={27.1}
          renderAs={isMobile ? 'bottom-sheet' : 'popover'}>
          {showUserProfile ? userInfo : null}
          <MenuListItems
            className="space-y-4 p-0 xs:mb-12 xs:px-8 xs:pb-32"
            items={items}
            onAction={onAction}
          />
        </Popover.Content>
      </Popover>
      {enableMultiLanguage ? (
        <LanguageMenu
          closeLanguageMenu={closeLanguageMenu}
          showLanguageMenu={showLanguageMenu}
          toggleLanguageMenu={toggleLanguageMenu}
        />
      ) : null}
      {isModalVisible ? <EditProfileModal onClose={toggleModal} /> : null}
    </>
  );
}

export default React.memo(UserMenu);
