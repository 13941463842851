import React from 'react';
import _identity from 'lodash/identity';

import Admin from './admin';

const AdminLayout = (page: React.ReactElement) => <Admin>{page}</Admin>;

const getDefaultLayout = (route: string) => {
  if (route.startsWith('/admin')) {
    return AdminLayout;
  }

  return _identity;
};

export default getDefaultLayout;
