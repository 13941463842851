import ErrorReporter from '../ErrorReporter';
import { saveAs } from './file-saver';

interface DownloadFileOptions {
  fileName?: string;
}

const downloadFile = function (
  fileUrlOrBlob: string | Blob | undefined | null,
  options?: DownloadFileOptions,
) {
  if (!fileUrlOrBlob) {
    ErrorReporter.error('FileUrl missing in download file');
    return;
  }

  saveAs(fileUrlOrBlob, options?.fileName!);
};

export default downloadFile;
