import { Resolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';

import { OkrDetailsQueryVariables } from '../../views/OKRDetails/graphql/queries/generated/okrDetails';

const KeyResultDoc = gql`
  fragment KeyResultDoc on KeyResultType {
    id
  }
`;

const ObjectiveDoc = gql`
  fragment ObjectiveDoc on ObjectiveType {
    id
  }
`;

export const okrDetailsResolver: Resolver<any, { filters: OkrDetailsQueryVariables }, any> = (
  parent,
  args,
  cache,
) => {
  const objective = cache.readFragment(ObjectiveDoc, { id: args.filters.okrId });

  if (objective) {
    return { __typename: 'ObjectiveType', id: args.filters.okrId };
  }
  const keyResult = cache.readFragment(KeyResultDoc, { id: args.filters.okrId });

  if (keyResult) {
    return { __typename: 'KeyResultType', id: args.filters.okrId };
  }

  return null;
};
