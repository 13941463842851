import { TypographyColors, TypographyVariants } from '@lyearn/ui';

import { LayoutSize } from './types';

export interface LayoutValues {
  image?: string;
  imageSize?: number;
  header?: {
    className?: string;
    typography?: TypographyVariants;
  };
  description?: {
    className?: string;
    typography?: TypographyVariants;
    color?: TypographyColors;
  };
}

export const LAYOUT: Record<LayoutSize, LayoutValues> = {
  [LayoutSize.Large]: {
    image: 'me-24',
    imageSize: 80,
    header: {
      className: 'mb-8',
      typography: 'display-xl',
    },
    description: {
      className: '',
      typography: 'body-short-01',
      color: 'text-text-secondary',
    },
  },
  [LayoutSize.Medium]: {
    image: 'me-16',
    imageSize: 44,
    header: {
      className: '',
      typography: 'body-short-01',
    },
    description: {
      className: '',
      typography: 'body-short-02',
      color: 'text-text-secondary',
    },
  },
};
