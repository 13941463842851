// libs
import React from 'react';

import { ArrowIosBack } from '@lyearn/icons';
import { cn } from '@lyearn/style';
// components
import { Avatar, Box, LinkButton, Typography } from '@lyearn/ui';

// types
import { HeaderProps } from '../types';
// style
import { backButtonStyles } from './style';

export const renderBackButton = (header: HeaderProps) => {
  const { onBack, description } = header;

  return (
    <LinkButton
      className={cn(
        'py-2 px-4',
        {
          'mb-24': !description,
        },
        backButtonStyles,
        'mt-2 items-start -ms-4',
      )}
      color={'internal'}
      onClick={onBack}>
      <ArrowIosBack className="rtl:rotate-180" />
    </LinkButton>
  );
};

export const renderIcon = (header: HeaderProps, isScrolled: boolean) => {
  const { Icon, description, avatar } = header;

  if (Icon) {
    return (
      <Box
        className={cn('icon-container mx-4 transition-all duration-300 ease-linear', {
          'large-icon': description && !isScrolled,
          'mb-24': !description,
        })}>
        <Icon className="transition-all duration-300 ease-linear" />
      </Box>
    );
  }

  if (avatar) {
    return (
      <Avatar
        className={cn(
          'icon-container mx-4 transition-all duration-300 ease-linear',
          avatar.className,
          {
            'large-icon': description && !isScrolled,
            'mb-24': !description,
          },
        )}
        name="Avatar"
        // size={size}
        src={avatar.src || ''}
      />
    );
  }
};

export const renderTitle = (header: HeaderProps) => {
  const { title, description, classes } = header;

  return (
    <Typography
      className={cn('flex ps-8 pe-48', { 'mb-4': !!description }, classes?.title)}
      variant="heading-m">
      {title}
    </Typography>
  );
};

export const renderDescription = ({
  description,
  classes,
  isScrolled,
}: {
  description?: string | React.ReactNode;
  classes?: HeaderProps['classes'];
  isScrolled?: boolean;
}) => {
  const className = classes?.description ?? 'xs:mb-20 mb-24';

  return (
    <Typography
      className={cn('transition-all duration-300 ease-linear pe-32 ps-8', {
        '-mb-2 opacity-0': isScrolled && description,
        [className]: !isScrolled || !description,
      })}
      color="text-text-secondary"
      variant="body-short-01">
      {description}
    </Typography>
  );
};
