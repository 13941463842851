import { createContext, useContext } from 'react';
import type { Uppy } from '@uppy/core';

const UppyContext = createContext<{ Uppy: Uppy | null; loading?: boolean }>({
  Uppy: null,
});

export const useUppyContext = () => {
  const value = useContext(UppyContext);

  if (!value?.Uppy && !value?.loading) {
    throw new Error('[UppyContext] Not initialised');
  }

  return value?.Uppy;
};

export default UppyContext;
