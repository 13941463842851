import { useTranslation } from '@lyearn/i18n';
import { MaskOutline } from '@lyearn/icons';
import { Button, Stack, Typography } from '@lyearn/ui';
import { useUserContext } from '@/modules/auth/hooks/useUser';

import useMasquerade from '../../hooks/useMasquerade';

const MasqueradingHeadline: React.FC = () => {
  const { t } = useTranslation();
  const user = useUserContext();

  const { onMasqueradeStop } = useMasquerade({ action: 'STOP_MASQUERADE' });

  //temporary indication while masquerading

  return (
    <Stack
      alignItems="center"
      className="fixed bottom-24 z-50 -translate-x-1/2 transform rounded-8 border border-divider bg-interactive-white py-8 shadow-02 pe-8 ps-16 start-1/2 rtl:translate-x-1/2">
      <MaskOutline className="h-20 w-20" />
      <Typography className="ms-8" color="text-text-primary" variant="body-short-02">
        {t('Common:Masquerade.MasqueradingHeadline', { name: user.name })}
      </Typography>
      <Button
        className="ms-8"
        shape="square"
        size="x-small"
        variant="ghost"
        onClick={onMasqueradeStop}>
        {t('Common:Masquerade.Stop')}
      </Button>
    </Stack>
  );

  // return (
  //   <Stack alignItems="center" className="w-full h-60 bg-focus-primary relative">
  //     <Typography
  //       className="absolute top-1/2 start-1/2 transform -translate-x-1/2 rtl:translate-x-1/2 -translate-y-1/2"
  //       color="text-text-white"
  //       variant="body-short-02">
  //       <Trans
  //         components={{
  //           label: <Typography color="text-text-white" variant="label-02" />,
  //         }}
  //         i18nKey={'Common:Masquerade.MasqueradingHeadline'}
  //         values={{
  //           name: user.name,
  //         }}
  //       />
  //     </Typography>
  //     <Button appearance="inverse" className="absolute end-24" onClick={onMasqueradeStop}>
  //       {t('Common:Masquerade.Stop')}
  //     </Button>
  //   </Stack>
  // );
};

export default MasqueradingHeadline;
