import React, { useCallback, useRef } from 'react';
import c from 'classnames';
import identity from 'lodash/identity';

import useToggle from '@lyearn/core/hooks/useToggle';
import { Tooltip, TooltipProps, Typography, TypographyType } from '@lyearn/ui';

import { truncateStyles } from './styles';

type Props = Partial<TypographyType> & {
  children: string | React.ReactNode;
  placement?: TooltipProps['placement'];
  disableInteractive?: boolean;
  renderTooltipTitle?: (node: Props['children']) => TooltipProps['title'];
  classes?: TooltipProps['classes'];
};

const getShowTooltip = (numberOfLines: number, element: HTMLElement) => {
  if (numberOfLines === 1) {
    return element.scrollWidth > element.offsetWidth;
  }

  return element.scrollHeight > element.clientHeight;
};

function TypographyWithTooltip(props: Props) {
  const {
    children,
    className,
    numberOfLines,
    placement,
    disableInteractive,
    renderTooltipTitle = identity,
    classes,
    variant,
    ...typographyProps
  } = props;

  const ref = useRef<HTMLElement>(null);
  const [open, { toggle, off }] = useToggle(false);

  const truncateProps =
    numberOfLines === 1
      ? { className: c('truncate', truncateStyles, className) }
      : { numberOfLines, className };

  const onOpen = useCallback(() => {
    if (ref.current && numberOfLines) {
      toggle(getShowTooltip(numberOfLines, ref.current));
    }
  }, [numberOfLines, toggle]);

  return (
    <Tooltip
      classes={classes}
      disableInteractive={disableInteractive}
      open={open}
      placement={placement}
      title={renderTooltipTitle(children)}
      onClose={off}
      onOpen={onOpen}>
      <Typography
        ref={ref}
        variant={variant ?? 'body-long-01'}
        {...typographyProps}
        {...truncateProps}>
        {children}
      </Typography>
    </Tooltip>
  );
}

export default TypographyWithTooltip;
