import { Resolver } from '@urql/exchange-graphcache';

import { MetricDetailsFromCacheQueryVariables } from '../queries/generated/trackDetailsActivities';

export const metricDetailsResolver: Resolver<any, MetricDetailsFromCacheQueryVariables, any> = (
  _,
  args,
) => {
  return { __typename: 'MetricSchema', id: args.filters.id };
};
