import { useCallback, useState } from 'react';

// Reference: https://github.com/chakra-ui/chakra-ui/blob/main/packages/hooks/src/use-boolean.ts
type InitialState = boolean | (() => boolean);

function useToggle(initialState: InitialState = false) {
  const [value, setValue] = useState(initialState);

  const on = useCallback(() => {
    setValue(true);
  }, []);

  const off = useCallback(() => {
    setValue(false);
  }, []);

  const toggle = useCallback((nextValue?: any) => {
    if (typeof nextValue === 'boolean') {
      // if (process.env.NODE_ENV !== 'production') {
      //   console.warn(`[useToggle] use on or off instead. arguments for toggle will be deprecated.`);
      // }
      setValue(nextValue);
    } else {
      setValue((prev) => !prev);
    }
  }, []);

  return [value, { on, off, toggle }] as const;
}

export default useToggle;
