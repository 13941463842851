import { TFunction } from '@lyearn/i18n';
import { JobStateFragment } from '@/modules/corporate/modules/bulk-import/graphql/fragments/generated/JobState';
import { JobPurposeEnum } from '@/types/schema';

export function getSnackBarTitle(job: JobStateFragment, t: TFunction) {
  const { purpose } = job;
  switch (purpose) {
    case JobPurposeEnum.BulkUnAssignLearnerPreprocess:
      const descriptionKey = 'Common:BulkUnAssign.UnAssignReadyDescription';
      return t(descriptionKey, {
        exportContent: t('Common:BulkUnAssign.Learner', {
          count: job?.meta?.bulkUnAssignLearnerMetadata?.totalLearnersCount,
        }),
      });

    default:
      return t('Common:BulkExport.ExportReady');
  }
}

export function getFailedJobSnackBarTitle(purpose: JobPurposeEnum | null | undefined) {
  switch (purpose) {
    case JobPurposeEnum.BulkUnAssignLearnerPreprocess:
      return 'Common:BulkUnAssign.FailedToUnassignLearners';
    default:
      return 'Common:BulkExport.ExportFailedDescription';
  }
}
