import React, { forwardRef, useEffect } from 'react';
import _concat from 'lodash/concat';
import _findIndex from 'lodash/findIndex';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';

import { MoreHoriz } from '@lyearn/icons';
import { DeprecatedIconButton, PopoverContentProps, usePopoverContext } from '@lyearn/ui';
import { Popover } from '@lyearn/ui';

import ActionMenuListItemsProps from './components/ActionMenuListItems';
import { ActionMenuItemProps, ActionMenuItemType, ActionMenuProps } from './types';

const getPopoverButton = () => (
  <DeprecatedIconButton appearance="secondary" variant="outlined">
    <MoreHoriz />
  </DeprecatedIconButton>
);
/**
 * The `ActionMenu` component provides a way to display a list of actions in a popover that appears when a user clicks an icon or button. It renders a button icon by default that opens a popover with a list of items when clicked. The component requires an array of `items` to be passed as a prop to display the list of actions.
 */

const ActionMenuContent = <T = string,>(
  props: ActionMenuProps<T> & {
    onClose: () => void;
  },
) => {
  const {
    items,
    disabled,
    onAction: onActionProps,
    minWidth = 17.6,
    overrides,
    useAnchorElWidth,
    renderHeader,
    onClose,
  } = props;

  const [subItemPath, setSubItemPath] = React.useState<string[] | null>(null);
  const filteredItems = React.useMemo(() => {
    const unfilteredItems: ActionMenuItemType[] = !subItemPath
      ? items
      : (_get(items, subItemPath) as ActionMenuItemType[]);
    return unfilteredItems.filter((item) => item.enabled === undefined || item.enabled);
  }, [items, subItemPath]);

  const onAction = React.useCallback(
    (actionType: T, item: ActionMenuItemProps) => {
      const isNested = !_isEmpty(item.items);
      if (isNested) {
        const index = _findIndex(items, (it) => (it as ActionMenuItemProps).id === item.id);
        setSubItemPath((path) => _concat(path || [], [`${index}`, 'items']));
        return;
      }

      onActionProps?.(actionType, item);
      if (!item.keepOpen) {
        onClose();
      }
    },
    [items, onActionProps, onClose],
  );

  return (
    <Popover.Content
      minWidth={minWidth}
      useAnchorElWidth={useAnchorElWidth}
      {...overrides?.PopoverContent}>
      {renderHeader?.()}
      <ActionMenuListItemsProps
        className={overrides?.MenuList?.className}
        items={filteredItems}
        onAction={disabled ? noop : onAction}
      />
    </Popover.Content>
  );
};

function ActionMenu<T = string>(props: ActionMenuProps<T>) {
  const {
    items,
    disabled,
    onAction: onActionProps,
    onOpenChange,
    minWidth = 17.6,
    overrides,
    children = getPopoverButton(),
    useAnchorElWidth,
    open,
    renderHeader,
    ...rest
  } = props;

  if (!items.length) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      {({ open, onClose }) => (
        <>
          <Popover.Button disabled={disabled} {...overrides?.PopoverButton} {...rest}>
            {children}
          </Popover.Button>
          {open ? <ActionMenuContent<T> {...props} onClose={onClose} /> : null}
        </>
      )}
    </Popover>
  );
}

export default ActionMenu;
