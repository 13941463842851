import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/departments';
const ADMIN_TAGS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const departmentAdminRoutes = {
  DepartmentManager: () => ADMIN_TAGS_PARENT_ROUTE,
  DepartmentDetails: (departmentId: string) => `${ADMIN_TAGS_PARENT_ROUTE}/${departmentId}/details`,
  RoleDetails: (departmentId: string, roleId: string) =>
    `${ADMIN_TAGS_PARENT_ROUTE}/${departmentId}/roles/${roleId}`,
};

export default departmentAdminRoutes;
