import React from 'react';

import { inIframe, sendToParent } from '@lyearn/core/utils/iframe';
import useNavigation from '@/hooks/useNavigation';

const useSendLocation = () => {
  const navigation = useNavigation();

  React.useEffect(() => {
    if (!inIframe()) return;

    const handleRouteChangeComplete = (pathname: string) => {
      sendToParent(JSON.stringify({ pathname, origin: window.location.origin }));
    };

    navigation.events?.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      if (inIframe()) {
        navigation.events?.off('routeChangeComplete', handleRouteChangeComplete);
      }
    };
  }, [navigation.events]);
};

export default useSendLocation;
