import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import { UserSurveyQuestionActionEnum } from '@/types/schema';

import {
  ManageUserSurveyQuestionsMutation,
  ManageUserSurveyQuestionsMutationVariables,
} from '../mutations/generated/manageUserSurveyQuestions';

const manageUserSurveyQuestionsUpdater: UpdateResolver<
  ManageUserSurveyQuestionsMutation,
  ManageUserSurveyQuestionsMutationVariables
> = (result, args, cache, info) => {
  const hasUpdateActionsOnly = args.input.questionConfigs?.every(
    (config) => config.action === UserSurveyQuestionActionEnum.Update,
  );

  if (hasUpdateActionsOnly) {
    return;
  }

  invalidateQuery('userSurvey')(result, args, cache, info);
};

export default manageUserSurveyQuestionsUpdater;
