import { useCallback } from 'react';
import { isEqual } from 'lodash';
import { useQueryParams } from 'use-query-params';

import useNavigation from '@/hooks/useNavigation';
import { ArrayParam } from '@/hooks/useQueryParam';

import { Breadcrumb, BreadcrumbHistory } from '../context';
import { getBreadcrumbsFromUrl } from '../utils';
import useCurrentTabStorage from './useCurrentTabStorage';

export const BREADCRUMB_QUERY_PARAM = 'bl';

export const enum BreadcrumbModuleEnum {
  Bookmark = 'bookmark',
  MySession = 'my-session',
  AllSession = 'all-session',
}

export const useBreadcrumb = () => {
  const navigation = useNavigation();
  const [query, setQuery] = useQueryParams({
    [BREADCRUMB_QUERY_PARAM]: ArrayParam,
  });
  const breadcrumbUrl = query[BREADCRUMB_QUERY_PARAM];
  const setBreadcrumbUrl = useCallback(
    (val: string[] | undefined) => {
      setQuery({
        [BREADCRUMB_QUERY_PARAM]: val,
      });
    },
    [setQuery],
  );

  const { state: breadcrumbs, set: setBreadcrumb } = useCurrentTabStorage<Breadcrumb[]>(
    'ly-breadcrumb',
    [],
  );

  const { state: breadcrumbHistory, set: setBreadcrumbHistory } =
    useCurrentTabStorage<BreadcrumbHistory>('ly-breadcrumb-history', {});

  const clean = useCallback(() => {
    setBreadcrumb([]);
  }, [setBreadcrumb]);

  const onBreadcrumbClick = useCallback(
    (breadcrumb: Breadcrumb) => {
      const breadcrumbIndex = breadcrumbs.findIndex((b) => isEqual(b, breadcrumb));
      const newBreadcrumb = breadcrumbs.slice(0, breadcrumbIndex + 1);
      setBreadcrumb(newBreadcrumb);

      navigation.push(breadcrumb.path, {
        appendQuery: false,
        query: breadcrumb.query,
      });
    },
    [breadcrumbs, navigation, setBreadcrumb],
  );

  const computeAndSetBreadcrumb = useCallback(
    (pageId?: string) => {
      if (!pageId) {
        return;
      }

      const lastRoute = breadcrumbUrl?.at(-1);

      if (!lastRoute) {
        setBreadcrumb([]);
        return;
      }

      if (breadcrumbHistory[pageId]) {
        const breadcrumbs = getBreadcrumbsFromUrl({
          pageId,
          breadcrumbHistory,
          breadcrumbUrl,
        });
        setBreadcrumb(breadcrumbs);
      }
    },
    [breadcrumbHistory, breadcrumbUrl, setBreadcrumb],
  );

  return {
    clean,
    breadcrumbs,
    setBreadcrumb,
    onBreadcrumbClick,
    breadcrumbHistory,
    setBreadcrumbHistory,
    computeAndSetBreadcrumb,
    setBreadcrumbUrl,
    breadcrumbUrl: breadcrumbUrl as string[] | null | undefined,
  };
};
