import React, { Suspense } from 'react';
import { useLyearnTheme } from 'theme';

import Loader from '@/components/Loader';
import WrongZone from '@/components/WrongZone';
import currentUser from '@/helper/currentUser';
import { useUserContext } from '@/modules/auth/hooks/useUser';
import AdminLoadable from '@/modules/corporate/modules/admin';
import IntegrationSyncToast from '@/modules/corporate/modules/integrations/components/IntegrationSyncToast';

interface AdminProps {
  children: React.ReactNode;
}

function Admin(props: AdminProps) {
  useLyearnTheme();

  const user = useUserContext();
  const { isStaff } = user;

  if (!isStaff || currentUser.isMasquerading()) {
    return <WrongZone />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <IntegrationSyncToast />
      <AdminLoadable>{props.children}</AdminLoadable>
    </Suspense>
  );
}

export default Admin;
