import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';

import {
  DepartmentDetailsFragment,
  DepartmentDetailsFragmentDoc,
} from '../../../departments/views/DepartmentDetails/graphql/queries/generated/departmentDetails';
import {
  CreateTrackMutation,
  CreateTrackMutationVariables,
} from '../mutations/generated/createTrack';

const createTrackUpdater: UpdateResolver<CreateTrackMutation, CreateTrackMutationVariables> = (
  result,
  args,
  cache,
  info,
) => {
  const track = result.createTrack.track;
  const departmentId = track.departmentId;

  const department = cache.readFragment<DepartmentDetailsFragment>(DepartmentDetailsFragmentDoc, {
    id: departmentId,
    __typename: 'DepartmentType',
  });

  if (department) {
    cache.writeFragment(DepartmentDetailsFragmentDoc, {
      __typename: 'DepartmentType',
      ...department,
      tracks: [...(department.tracks || []), { ...track, orgRoles: null }],
    });
  } else {
    invalidateQuery('department')(result, args, cache, info);
  }
};

export default createTrackUpdater;
