import { useTranslation } from '@lyearn/i18n';
import { useFilterManager } from '@/components/FilterManager';
import { TabsProps } from '@/components/Tabs/types';
import { ReviewCycleStatusEnum } from '@/types/schema';

import { getSortAndFilterItems, SKILL_REVIEW_SORT_DEFAULT_VALUE } from './getSortAndFilterItems';
import useReviewCycleListItems from './useReviewCycleListItems';
import { UIConfigType } from './useUIConfig';

export enum ReviewCycleManagerTabsEnum {
  Active = 'active',
  Past = 'past',
}

export const DefaultStatusFilters = [
  ReviewCycleStatusEnum.Draft,
  ReviewCycleStatusEnum.NotStarted,
  ReviewCycleStatusEnum.PeerSelection,
  ReviewCycleStatusEnum.InProgress,
  ReviewCycleStatusEnum.Active,
];

export function getActiveStatusFilter(
  statusFilters?: ReviewCycleStatusEnum[],
  defaultFilters: ReviewCycleStatusEnum[] = DefaultStatusFilters,
) {
  return statusFilters?.length
    ? statusFilters.reduce(
        (result, it) =>
          it === ReviewCycleStatusEnum.PeerSelection
            ? [...result, ReviewCycleStatusEnum.PeerSelection, ReviewCycleStatusEnum.InProgress]
            : [...result, it],
        [] as ReviewCycleStatusEnum[],
      )
    : defaultFilters;
}

const useReviewCycleManager = ({
  tab,
  config,
  disableTypeFilter,
}: {
  tab: ReviewCycleManagerTabsEnum;
  config: UIConfigType;
  disableTypeFilter: boolean;
}) => {
  const { t } = useTranslation();
  const sortAndFilterItems = getSortAndFilterItems(
    t,
    disableTypeFilter,
    tab === ReviewCycleManagerTabsEnum.Past,
  );

  const {
    state: { sort, filter, search },
    methods: { setSort, setFilter, setSearch, clearSearch, clearFilters, clearSearchAndFilters },
  } = useFilterManager({});

  const tabs: TabsProps = {
    tabs: [
      {
        value: ReviewCycleManagerTabsEnum.Active,
        label: t('Reviews:Manager.Tabs.Active'),
        to: config.tabRoutes.active,
      },
      {
        value: ReviewCycleManagerTabsEnum.Past,
        label: t('Reviews:Manager.Tabs.Past'),
        to: config.tabRoutes.past,
      },
    ],
    value: tab,
  };

  const { reviewCycles, error, fetchMore, isFetching, isFetchingMore, totalCount } =
    useReviewCycleListItems({
      sort: sort || SKILL_REVIEW_SORT_DEFAULT_VALUE,
      filters: {
        searchText: search,
        createdByIds: filter.createdByIds,
        reviewers: filter.reviewers,
        isChildReviewCycle: [false],
        type: filter.type,
        status:
          tab === ReviewCycleManagerTabsEnum.Past
            ? [ReviewCycleStatusEnum.Closed, ReviewCycleStatusEnum.Completed]
            : getActiveStatusFilter(filter.status),
        ...config.filters,
      },
    });

  return {
    tabs,
    error,
    reviewCycles,
    totalCount,
    isFetching,
    isFetchingMore,
    fetchMore,
    sortAndFilterData: {
      sortAndFilterItems,
      sort,
      filter,
      setSort,
      setFilter,
      search,
      setSearch,
      clearSearch,
      clearFilters,
    },
  };
};

export default useReviewCycleManager;
