export enum TeamProfilePages {
  Overview = 'overview',
  Activities = 'activities',
  Goals = 'goals',
  Skills = 'skills',
  Mindsets = 'mindsets',
  Team = 'team',
}

export type TeamProfileDetailsProps = {
  userId: string;
  type: TeamProfilePages;
};
