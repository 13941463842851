import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/lyearn-connect';
const ADMIN_CONNECT_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const lyearnConnectAdminRoutes = {
  LyearnConnectManager: () => `${ADMIN_CONNECT_PARENT_ROUTE}`,
  LyearnConnectContentDetails: (connectionId: string) =>
    `${ADMIN_CONNECT_PARENT_ROUTE}/${connectionId}/details/content`,
  LyearnConnectSessionDetails: (connectionId: string) =>
    `${ADMIN_CONNECT_PARENT_ROUTE}/${connectionId}/details/session`,
  LyearnConnectCollectionDetails: (connectionId: string) =>
    `${ADMIN_CONNECT_PARENT_ROUTE}/${connectionId}/details/collection`,
};

const routes = {
  ...lyearnConnectAdminRoutes,
};

export const LYEARN_CONNECT_ADMIN_ROUTE_KEYS = {
  LyearnConnectManager: 'LyearnConnectManager',
  LyearnConnectDetails: 'LyearnConnectDetails',
};

export const LYEARN_CONNECT_LEARNER_ROUTE_KEYS = {};

export const LYEARN_CONNECT_ROUTE_KEYS = {
  ...LYEARN_CONNECT_ADMIN_ROUTE_KEYS,
  ...LYEARN_CONNECT_LEARNER_ROUTE_KEYS,
};

export default routes;
