import { css } from '@emotion/css';

/*
  to disable default tooltip in safari
*/
export const truncateStyles = css`
  &::after {
    content: '';
    display: block;
  }
`;
