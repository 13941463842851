/**
 * onyl used in native mobile
 */
import React from 'react';

const KeyboardAvoidance: React.FC<React.PropsWithChildren> = (props) => {
  const { children } = props;

  return <>{children}</>;
};

export default KeyboardAvoidance;
