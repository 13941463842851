import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Client as URQLClient, Provider as URQLProvider } from 'urql';

import UppyProvider from '@/components/UppyProvider';
import useUserNetworkStatus from '@/hooks/useUserNetworkStatus';
import { UserContextProvider } from '@/modules/auth/hooks/useUser';
import { UserProfile } from '@/modules/auth/types';

const ClientProvider: React.FC<{
  children?: React.ReactNode;
  urqlClient: URQLClient;
  queryClient: QueryClient;
  user?: UserProfile;
}> = ({ children, queryClient, urqlClient, user }) => {
  useUserNetworkStatus();

  return (
    <URQLProvider value={urqlClient}>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider preloadedUser={user}>
          <UppyProvider>{children}</UppyProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </URQLProvider>
  );
};

export default ClientProvider;
