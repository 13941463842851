import { learnerRoutes as okrLearnerRoutes } from './okr';

const routes = {
  Home: () => '/home',
  Discover: (widgetId: string) => `/discover/${widgetId}`,
  Search: () => '/results',
  TeamDashboard: () => '/team',
  LearnerProfile: (learnerId: string) => `/learners/${learnerId}`,
  LearnerReports: () => '/reports',
  LearnerReportViewer: (reportId: string) => `/reports/${reportId}/view`,
  ToReview: () => '/to-review',
  ...okrLearnerRoutes,
};

export default routes;
