import { css } from '@emotion/css';

export const SIDEBAR_WIDTH = 26;

export const sidebarStyles = css`
  width: ${SIDEBAR_WIDTH}rem;
`;

export const sidebarBorderStyles = css`
  transition: opacity 0.6s ease-in-out;
`;

export const sidebarItemStyles = css`
  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-radius: 0.8rem;
    pointer-events: none;
    background-color: var(--bg-hover);
    opacity: 0;
    transform: scale(0.9);
    z-index: 1;
    transition:
      opacity 0.05s ease-in-out,
      transform 0.2s cubic-bezier(0.3, 0.76, 0.27, 1);
  }

  :hover {
    ::after {
      opacity: 1;
      transform: none;
    }
  }

  :active {
    ::after {
      opacity: 1;
      transform: none;
      background-color: var(--bg-interactive-secondary-hover);
    }
  }
`;
