import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/metrics';
const ADMIN_METRICS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const metricsAdminRoutes = {
  MetricsManager: () => `${ADMIN_METRICS_PARENT_ROUTE}`,
  MetricsDetails: (metricId: string) => `${ADMIN_METRICS_PARENT_ROUTE}/${metricId}/details`,
};

export default metricsAdminRoutes;
