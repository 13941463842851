import authRoutes from '@/pages/auth/routes';
import routes from '@/pages/corporate/routes';

export const HELP_MENU_BLACKLISTED_ROUTES = new Set([
  routes.CommunityChannelDetails('[communityId]', '[channelId]'),

  routes.LiveSession('[eventId]'),
  routes.RecordingEvent('[eventId]'),
  routes.StreamingEvent('[eventId]'),

  routes.CycleObjectives('[cycleId]'),
  routes.LearnerCycleObjectives('[cycleId]'),

  routes.QuizEditor('[contentId]'),
  routes.QuizPreview('[contentId]'),
  routes.QuizPreviewDevices('[contentId]'),

  routes.CourseBuilder('[courseId]'),
  routes.CourseViewer('[courseId]'),
  routes.CoursePreview('[courseId]'),
  routes.CourseDevicesPreview('[courseId]'),

  routes.ArticleBuilder('[articleId]'),
  routes.ArticleViewer('[articleId]'),
  routes.ArticlePreview('[articleId]'),
  routes.ArticlePreviewDevices('[articleId]'),

  routes.SurveyPreview('[contentId]'),
  routes.SurveyPreviewDevices('[contentId]'),

  routes.AdminPathPreview('[pathId]'),
  routes.AdminPathPreviewDevices('[pathId]'),

  routes.AdminExercisePreview('[exerciseId]'),
  routes.AdminExercisePreviewDevices('[exerciseId]'),

  routes.AdminExternalContentPreview('[contentId]'),
  routes.AdminExternalContentPreviewDevices('[contentId]'),

  routes.ScormViewer('[contentId]'),
  routes.AdminScormViewer('[contentId]'),

  routes.ContentCreationLyearnAI(),

  authRoutes.Login(),
  authRoutes.Signup(),
]);
