import { LearnerActionPlanTabs } from '@/modules/action-plan/views/MyActionPlans/types';

import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

export const actionPlanAdminRoutes = {
  ActionPlanEditor: (actionPlanId: string) =>
    `${ADMIN_PARENT_ROUTE}/action-plan/${actionPlanId}/edit`,
};

const actionPlanLearnerRoutes = {
  LearnerActionPlans: (tab: LearnerActionPlanTabs) => `/action-plans/${tab}`,
};

const actionPlanRoutes = {
  ...actionPlanAdminRoutes,
  ...actionPlanLearnerRoutes,
};

export default actionPlanRoutes;
