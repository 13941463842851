import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { UserProfile } from '@/modules/auth/types';

export const openReplayTrackerInit = async (serverUserData: UserProfile) => {
  const { default: tracker } = await import('./openReplay');
  const { id, emailId, name } = serverUserData;
  // Error 'Player is already active' will be fixed by OpenReplay team
  const playerIsAlreadyActiveError = 'Player is already active';
  tracker.start().catch((err) => {
    if (!err.includes(playerIsAlreadyActiveError)) ErrorReporter.error(err);
  });
  tracker.setUserID(id);
  tracker.setMetadata('emailId', emailId);
  tracker.setMetadata('name', name);
};
