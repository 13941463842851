import { captureException, captureMessage, setUser } from '@sentry/browser';

export const isProd = process.env.NODE_ENV === 'production';

const ErrorReporter = {
  info: isProd ? captureMessage : (console.info as typeof captureMessage),
  error: isProd ? captureException : (console.error as typeof captureException),
  setUser: isProd ? setUser : (console.log as typeof setUser),
};

export default ErrorReporter;
