import React from 'react';

import { cn } from '@lyearn/style';
import { LinearGradient } from '@lyearn/ui';
import { getAppliedTheme } from '@/hooks/useAppColorScheme';

interface FooterProps {
  children: React.ReactNode | string;
  className?: string;
}

function Footer(props: FooterProps) {
  const { children, className } = props;
  return (
    <LinearGradient
      className={cn('mt-auto flex flex-row items-center justify-end xs:justify-between', className)}
      css={
        getAppliedTheme() === 'light'
          ? 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 44.79%)'
          : 'linear-gradient(rgba(0, 0, 0, 0) 0%, rgb(0 0 0 / 15%) 44.79%)'
      }>
      {children}
    </LinearGradient>
  );
}

export default React.memo(Footer);
