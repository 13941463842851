import { UpdateResolver } from '@urql/exchange-graphcache';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  MeetingInstanceSubscriptionOperationEnum,
  MeetingInstanceSubscriptionPayload,
} from '@/types/schema';

export function getMeetingInstanceUpdater(): UpdateResolver {
  return (result, args, cache, info) => {
    const meetingInstancePayload = result.meetingInstances as MeetingInstanceSubscriptionPayload;
    const operationType = meetingInstancePayload.operation;
    const invalidateQueryFn = invalidateQuery('meetingInstance');

    switch (operationType) {
      case MeetingInstanceSubscriptionOperationEnum.AgendaItemCreated:
        invalidateQueryFn(result, args, cache, info);
        break;
      case MeetingInstanceSubscriptionOperationEnum.AgendaItemDeleted:
        invalidateQueryFn(result, args, cache, info);
        break;
      case MeetingInstanceSubscriptionOperationEnum.AgendaItemDuplicated:
        invalidateQueryFn(result, args, cache, info);
        break;
      case MeetingInstanceSubscriptionOperationEnum.MeetingInstanceUpdated:
        invalidateQueryFn(result, args, cache, info);
        break;
    }
  };
}
