import { ActionPlanParentEntityEnum } from '@/types/schema';

export enum LearnerActionPlanTabs {
  MyActionPlans = 'my',
  AllActionPlans = 'all',
  CanceledActionPlans = 'cancelled',
}

export interface MyActionPlansProps {
  tab: LearnerActionPlanTabs;
  parentEntityType?: ActionPlanParentEntityEnum;
  parentEntityId?: string;
}
