import { Cache, UpdateResolver } from '@urql/exchange-graphcache';
import gql from 'graphql-tag';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import {
  TrackMindsetFragment,
  TrackMindsetFragmentDoc,
} from '@/modules/org-hierarchy/modules/org-roles/views/TrackRoleDetails/graphql/queries/generated/roleDetails';
import {
  SkillDetailsFragment,
  SkillDetailsFragmentDoc,
} from '@/modules/skill-matrix/graphql/fragment/generated/SkillDetails';
import { OrgRoleType } from '@/types/schema';

import {
  UpdateOrgRoleDetailsMutation,
  UpdateOrgRoleDetailsMutationVariables,
} from '../mutations/generated/updateOrgRoleDetails';
import {
  ActivityMetricTrackDetailsFragment,
  MetricTrackDetailsFragment,
  MetricTrackDetailsFragmentDoc,
  OrgRoleActivityMetricTrackDetailsFragment,
  OrgRoleActivityMetricTrackDetailsFragmentDoc,
  TrackDetailsActivitiesFragment,
  TrackDetailsActivitiesFragmentDoc,
} from '../queries/generated/trackDetailsActivities';
import {
  SkillGroupTrackDetailsFragment,
  SkillGroupTrackDetailsFragmentDoc,
  TrackDetailsSkillsFragment,
  TrackDetailsSkillsFragmentDoc,
} from '../queries/generated/trackDetailsSkills';

export const OrgRoleTrackFragmentDoc = gql`
  fragment _ on OrgRoleType {
    id
    trackId
  }
`;

const addMindsetInsideTrack = (
  cache: Cache,
  orgRoleId: string,
  mindsetToAdd: SkillDetailsFragment,
) => {
  const orgRole = cache.readFragment<Pick<OrgRoleType, 'id' | 'trackId'>>(OrgRoleTrackFragmentDoc, {
    id: orgRoleId,
  });

  if (orgRole && orgRole.trackId) {
    const track = cache.readFragment<TrackMindsetFragment>(TrackMindsetFragmentDoc, {
      id: orgRole.trackId,
      __typename: 'TrackType',
    });

    if (track) {
      const isMindsetPresentInsideTrack = track.mindsets?.some(
        (mindset) => mindset.id === mindsetToAdd.id,
      );
      if (!isMindsetPresentInsideTrack) {
        cache.writeFragment<TrackMindsetFragment>(TrackMindsetFragmentDoc, {
          ...track,
          mindsets: [...(track.mindsets || []), { ...mindsetToAdd, __typename: 'SkillType' }],
          __typename: 'TrackType',
        });
      }
      return true;
    }
    return false;
  }
  return false;
};

const addSkillInsideSkillGroup = (
  cache: Cache,
  skillGroupId: string,
  skillToAdd: SkillDetailsFragment,
) => {
  const skillGroup = cache.readFragment<SkillGroupTrackDetailsFragment>(
    SkillGroupTrackDetailsFragmentDoc,
    {
      id: skillGroupId,
      __typename: 'SkillGroupType',
    },
  );

  if (skillGroup) {
    const isSkillPresentInsideSkillGroup = skillGroup.skills?.some(
      (skill) => skill.id === skillToAdd.id,
    );
    if (!isSkillPresentInsideSkillGroup) {
      cache.writeFragment<SkillGroupTrackDetailsFragment>(SkillGroupTrackDetailsFragmentDoc, {
        ...skillGroup,
        skills: [...(skillGroup.skills || []), { ...skillToAdd, __typename: 'SkillType' }],
        __typename: 'SkillGroupType',
      });
    }
    return true;
  }
  return false;
};

const addActivityInsideTrack = (
  cache: Cache,
  orgRoleId: string,
  metricToAdd: MetricTrackDetailsFragment,
  activityMetricToAdd: ActivityMetricTrackDetailsFragment,
) => {
  const orgRoleTrackId = cache.readFragment<Pick<OrgRoleType, 'id' | 'trackId'>>(
    OrgRoleTrackFragmentDoc,
    { id: orgRoleId },
  );
  const trackId = orgRoleTrackId?.trackId;

  if (metricToAdd && trackId && activityMetricToAdd) {
    const track = cache.readFragment<TrackDetailsActivitiesFragment>(
      TrackDetailsActivitiesFragmentDoc,
      { __typename: 'TrackType', id: trackId },
    );
    const orgRole = cache.readFragment<OrgRoleActivityMetricTrackDetailsFragment>(
      OrgRoleActivityMetricTrackDetailsFragmentDoc,
      { __typename: 'OrgRoleType', id: orgRoleId },
    );
    if (track && orgRole) {
      const isMetricPresentInsideTrack = track.metrics?.some(
        (metric) => metric.id === metricToAdd.id,
      );
      const isMetricPresentInsideOrgRole = orgRole.activityMetrics?.some(
        (metric) => metric.id === metricToAdd.id,
      );
      if (!isMetricPresentInsideTrack) {
        cache.writeFragment<TrackDetailsActivitiesFragment>(TrackDetailsActivitiesFragmentDoc, {
          ...track,
          metrics: [...(track.metrics || []), { ...metricToAdd, __typename: 'MetricSchema' }],
          __typename: 'TrackType',
        });
      }
      if (!isMetricPresentInsideOrgRole) {
        cache.writeFragment<OrgRoleActivityMetricTrackDetailsFragment>(
          OrgRoleActivityMetricTrackDetailsFragmentDoc,
          {
            ...orgRole,
            activityMetrics: [
              ...(orgRole.activityMetrics || []),
              { ...activityMetricToAdd, __typename: 'ActivityMetricType' },
            ],
            __typename: 'OrgRoleType',
          },
        );
      }
      return true;
    }
    return false;
  }
  return false;
};

const addSkillGroupInsideTrack = (
  cache: Cache,
  orgRoleId: string,
  skillGroupToAdd: SkillGroupTrackDetailsFragment,
) => {
  const orgRoleTrackId = cache.readFragment<Pick<OrgRoleType, 'id' | 'trackId'>>(
    OrgRoleTrackFragmentDoc,
    { id: orgRoleId },
  );
  const trackId = orgRoleTrackId?.trackId;
  if (trackId) {
    const track = cache.readFragment<TrackDetailsSkillsFragment>(TrackDetailsSkillsFragmentDoc, {
      id: trackId,
      __typename: 'TrackType',
    });
    if (track && track.skillMatrix) {
      cache.writeFragment<TrackDetailsSkillsFragment>(TrackDetailsSkillsFragmentDoc, {
        ...track,
        skillMatrix: {
          ...track.skillMatrix,
          skillGroups: [
            ...(track.skillMatrix.skillGroups || []),
            { ...skillGroupToAdd, __typename: 'SkillGroupType' },
          ],
          __typename: 'SkillMatrixType',
        },
        __typename: 'TrackType',
      });
      return true;
    }
    return false;
  }
};

const updateOrgRoleDetails: UpdateResolver<
  UpdateOrgRoleDetailsMutation,
  UpdateOrgRoleDetailsMutationVariables
> = (result, args, cache, info) => {
  const input = args.data;
  const orgRoleId = input.orgRoleId;

  if (
    input.mindsetIds?.remove?.length ||
    input.skillMasteries?.remove?.length ||
    input.skillMasteries?.edit?.length ||
    input.activityMetrics?.remove?.length ||
    input.activityMetrics?.edit?.length ||
    input.name ||
    input.key ||
    input.level
  ) {
    /**
     * Cache will be updated on the basis of result received from server
     * No need to cache write anything over here
     */
  } else if (input.mindsetIds) {
    if (input.mindsetIds.add?.length) {
      input.mindsetIds.add.map((mindsetId) => {
        const mindsetToAdd = cache.readFragment<SkillDetailsFragment>(SkillDetailsFragmentDoc, {
          id: mindsetId,
          __typename: 'SkillType',
        });

        if (!mindsetToAdd || !addMindsetInsideTrack(cache, orgRoleId, mindsetToAdd)) {
          // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
        }
      });
    } else if (input.mindsetIds.create?.length) {
      const mindsetToAdd = result.updateOrgRole.createdMindsets?.[0];
      if (!mindsetToAdd || !addMindsetInsideTrack(cache, orgRoleId, mindsetToAdd)) {
        // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
      }
    }
  } else if (input.skillMasteries) {
    if (input.skillMasteries.add?.length) {
      input.skillMasteries.add.map(({ skillId, skillGroupId }) => {
        const skillToAdd = cache.readFragment<SkillDetailsFragment>(SkillDetailsFragmentDoc, {
          id: skillId,
          __typename: 'SkillType',
        });

        if (
          !skillGroupId ||
          !skillToAdd ||
          !addSkillInsideSkillGroup(cache, skillGroupId, skillToAdd)
        ) {
          // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
        }
      });
    } else if (input.skillMasteries.create?.length) {
      const skillGroupId = input.skillMasteries.create[0].skillGroupId;
      const skillToAdd = result.updateOrgRole.createdSkills?.[0];
      if (
        !skillGroupId ||
        !skillToAdd ||
        !addSkillInsideSkillGroup(cache, skillGroupId, skillToAdd)
      ) {
        // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
      }
    }
  } else if (input.activityMetrics) {
    if (input.activityMetrics.add?.length) {
      input.activityMetrics.add.map((activityMetric) => {
        const activityMetricToAdd = result.updateOrgRole.orgRole?.activityMetrics?.find(
          ({ metricId }) => metricId === activityMetric.metricId,
        );
        const metricToAdd = cache.readFragment<MetricTrackDetailsFragment>(
          MetricTrackDetailsFragmentDoc,
          {
            id: activityMetric.metricId,
            __typename: 'MetricSchema',
          },
        );
        if (
          !activityMetricToAdd ||
          !metricToAdd ||
          !addActivityInsideTrack(cache, orgRoleId, metricToAdd, activityMetricToAdd)
        ) {
          // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
        }
      });
    } else if (input.activityMetrics.toCreateMetrics?.length) {
      const activityMetricToAdd = result.updateOrgRole.createdActivityMetrics?.[0];
      const metricToAdd = activityMetricToAdd?.metric;
      if (
        !activityMetricToAdd ||
        !metricToAdd ||
        !addActivityInsideTrack(cache, orgRoleId, metricToAdd, activityMetricToAdd)
      ) {
        // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
      }
    }
  } else if (input.skillGroups) {
    if (input.skillGroups.toCreateSkillGroups?.length) {
      result.updateOrgRole.createdSkillGroups?.map((skillGroupToAdd) => {
        if (!skillGroupToAdd || !addSkillGroupInsideTrack(cache, orgRoleId, skillGroupToAdd)) {
          // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
        }
      });
    } else if (input.skillGroups.toEditSkillGroups?.length) {
      input.skillGroups.toEditSkillGroups.map((skillGroupToUpdate) => {
        const skillGroup = cache.readFragment<SkillGroupTrackDetailsFragment>(
          SkillGroupTrackDetailsFragmentDoc,
          {
            id: skillGroupToUpdate.skillGroupId,
            __typename: 'SkillGroupType',
          },
        );
        if (skillGroup) {
          cache.writeFragment<SkillGroupTrackDetailsFragment>(SkillGroupTrackDetailsFragmentDoc, {
            ...skillGroup,
            name: skillGroupToUpdate.name,
            __typename: 'SkillGroupType',
          });
        } else {
          // invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
        }
      });
    }
  } else {
    invalidateQuery(['orgRole', 'orgRoles', 'tracks', 'track'])(result, args, cache, info);
  }
};

export default updateOrgRoleDetails;
