import { createContext, useContext } from 'react';

export type BaseImageContextValue = {
  loading?: 'lazy' | 'eager' | undefined;
};

const DEFAULT_CONTEXT_VALUE: BaseImageContextValue = {
  loading: undefined,
};

const BaseImageContext = createContext<BaseImageContextValue>(DEFAULT_CONTEXT_VALUE);

export function useBaseImageContext() {
  const context = useContext(BaseImageContext);
  return context;
}

const BaseImageProvider = BaseImageContext.Provider;

export default BaseImageProvider;
