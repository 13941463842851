import groupBy from 'lodash/groupBy';

import { TFunction } from '@lyearn/i18n';
import { Calendar2Outline, Flag2Outline, FlashOutline, PersonOutline } from '@lyearn/icons';
import { FilterOption } from '@/components/Filter/types';
import { getIcon } from '@/modules/tasks/views/TasksDashboard/utils/getSidebarItems';
import { UserViewFieldEntityTypeEnum } from '@/types/schema';

import { TaskGroupByFilterProps } from './types';

export const getTaskGroupByStaticOptions = (t: TFunction): FilterOption[] => [
  {
    Icon: FlashOutline,
    value: 'status',
    label: t('Common:Sort.Status'),
  },
  {
    Icon: PersonOutline,
    value: 'assignedToId',
    label: t('Common:Filter.Assignee'),
  },
  {
    Icon: Flag2Outline,
    value: 'priority',
    label: t('Common:Filter.Priority'),
  },
  {
    Icon: Calendar2Outline,
    value: 'dueDate',
    label: t('Task:Filter.DueDate'),
  },
];

export const TAG_GROUP_ID_PREFIX = 'tag:';

export const isGroupedByTag = (groupBy?: string) => groupBy?.startsWith(TAG_GROUP_ID_PREFIX);

export const isGroupedByStatus = (groupBy?: string) => groupBy === 'status';

export const getTagGroupById = (tagId: string) => `${TAG_GROUP_ID_PREFIX}${tagId}`;

export const getTaskTagGroupByOptions = (
  taskViewConfigs: TaskGroupByFilterProps['taskViewConfigs'],
) => {
  const tagsFilterOptions: FilterOption[] = (taskViewConfigs || [])
    .filter(
      ({ entityType, tagEntitySettings }) =>
        entityType === UserViewFieldEntityTypeEnum.Tag && !tagEntitySettings?.disableGrouping,
    )
    .map((config) => ({
      label: config.name,
      provider: config.provider,
      value: getTagGroupById(config.entityId!),
      Icon: () => getIcon(config.iconSettings),
    }));

  return groupBy(tagsFilterOptions, 'provider');
};
