import React from 'react';

import { inIframe, sendToParent } from '@lyearn/core/utils/iframe';
import { useUserContext } from '@/modules/auth/hooks/useUser';

const useSendUserStatus = () => {
  const { id } = useUserContext();

  React.useEffect(() => {
    if (!inIframe()) return;

    sendToParent(JSON.stringify({ isUserActive: Boolean(id), scope: 'useSendUserStatus' }));
  }, [id]);
};

export default useSendUserStatus;
