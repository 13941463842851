import React from 'react';

import { cn } from '@lyearn/style';
import { Box, Stack } from '@lyearn/ui';

import { renderBackButton, renderDescription, renderIcon, renderTitle } from '../renderers';
import { headerContainer } from '../style';
import { ModalHeaderProps } from './types';

export const ModalHeaderContainer: React.FC<
  React.PropsWithChildren<{ isScrolled?: boolean; className?: string }>
> = ({ isScrolled, className, children }) => {
  return (
    <Box
      className={cn(
        'sticky top-0 z-50 bg-bg-primary px-24 pt-28 pb-0 xs:border-b xs:border-divider',
        { 'border-b border-divider': isScrolled },
        headerContainer,
        className,
      )}>
      {children}
    </Box>
  );
};

function ModalHeader(props: ModalHeaderProps) {
  const { header, isScrolled = false } = props;
  const { children, className, onBack } = header;

  return (
    <ModalHeaderContainer className={className} isScrolled={isScrolled}>
      <Stack>
        {onBack ? renderBackButton(header) : renderIcon(header, isScrolled)}
        <Box>
          {renderTitle(header)}
          {renderDescription({
            description: header.description,
            classes: header.classes,
            isScrolled,
          })}
        </Box>
      </Stack>
      {children ? (
        <Box
          className={cn('pb-12 transition-all duration-300 ease-linear', {
            '-mt-8': isScrolled,
          })}>
          {children}
        </Box>
      ) : null}
    </ModalHeaderContainer>
  );
}

export default React.memo(ModalHeader);
