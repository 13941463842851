import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/org-roles';
const ADMIN_ORG_ROLES_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const OrgRoleAdminRoutes = {
  OrgRoleDetails: (orgRoleId: string) => {
    return `${ADMIN_ORG_ROLES_PARENT_ROUTE}/${orgRoleId}/details`;
  },
  OrgRoleEditor: (orgRoleId: string) => {
    return `${ADMIN_ORG_ROLES_PARENT_ROUTE}/${orgRoleId}/details`;
  },
  OrgRoleEditorMindset: (orgRoleId: string) => {
    return `${OrgRoleAdminRoutes.OrgRoleEditor(orgRoleId)}?tab=mindset`;
  },
  OrgRoleEditorSkills: (orgRoleId: string) => {
    return `${OrgRoleAdminRoutes.OrgRoleEditor(orgRoleId)}?tab=skills`;
  },
  OrgRoleEditorActivity: (orgRoleId: string) => {
    return `${OrgRoleAdminRoutes.OrgRoleEditor(orgRoleId)}?tab=activity`;
  },
  OrgRoleEditorUsers: (orgRoleId: string) => {
    return `${OrgRoleAdminRoutes.OrgRoleEditor(orgRoleId)}?tab=users`;
  },
};

const OrgRoleLearnerRoutes = {
  LearnerOrgRoleDetails: (orgRoleId: string) => {
    return `${PARENT_ROUTE}/${orgRoleId}/details`;
  },
};

const routes = {
  ...OrgRoleAdminRoutes,
  ...OrgRoleLearnerRoutes,
};
export default routes;
