import React from 'react';

import useScreen from '@lyearn/core/hooks/useScreen';
import { ScreenTypeEnum } from '@lyearn/core/hooks/useScreen/types';

const Mobile: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Mobile.displayName = 'Mobile';

const Tablet: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Tablet.displayName = 'Tablet';

const Desktop: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Desktop.displayName = 'Desktop';

const Default: React.FC<React.PropsWithChildren> = ({ children }) => <>{children}</>;
Default.displayName = 'Default';

interface ResponsiveComponents {
  Mobile: React.ComponentType<React.PropsWithChildren>;
  Tablet: React.ComponentType<React.PropsWithChildren>;
  Desktop: React.ComponentType<React.PropsWithChildren>;
  Default: React.ComponentType<React.PropsWithChildren>;
}

const ScreenValueToResponsiveMap = {
  [ScreenTypeEnum.Mobile]: Mobile,
  [ScreenTypeEnum.Tablet]: Tablet,
  [ScreenTypeEnum.Desktop]: Desktop,
};

const getChildForType = (children: any, componentType: React.ComponentType) => {
  if (children.length) {
    return children.find((child: any) => child.type.displayName === componentType.displayName);
  }
  if (children.type.displayName === componentType.displayName) {
    return children;
  }

  return null;
};

const Responsive: React.FC<React.PropsWithChildren<{ debugTag?: string; useSuspense?: boolean }>> &
  ResponsiveComponents = ({ children, debugTag, useSuspense = false }) => {
  const { screen } = useScreen();

  const responsiveComponent = getChildForType(children, ScreenValueToResponsiveMap[screen]);
  if (responsiveComponent) {
    return <>{responsiveComponent}</>;
  }

  const defaultComponent = getChildForType(children, Default);
  if (defaultComponent) {
    return useSuspense ? (
      <React.Suspense fallback={null}>{defaultComponent}</React.Suspense>
    ) : (
      <>{defaultComponent}</>
    );
  }

  throw new Error(
    `[Responsive]${
      debugTag ? `[${debugTag}]` : ''
    } Please specifiy Responsive.Default or specify all of Mobile, Desktop, Default`,
  );
};

Responsive.Mobile = Mobile;
Responsive.Tablet = Tablet;
Responsive.Desktop = Desktop;
Responsive.Default = Default;

export default Responsive;
