import config from 'config';
import { Client, ClientOptions, createClient } from 'graphql-ws';

import currentUser from '../currentUser';

export enum GraphqlWSResponseCodes {
  INVALID_ACCESS_TOKEN = 4403,
}

export enum GraphqlWSErrorMessages {
  INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
  NO_ACCESS_TOKEN = 'NO_ACCESS_TOKEN',
  NO_CONTEXT_FOUND = 'NO_CONTEXT_FOUND',
}

/**
 * Creates a graphql-ws client.
 * `lazy = true` by default (websocket is connected on the first subscription)
 * @returns {Client}
 */
export function createSubscriptionClient(): Client {
  const clientOptions: ClientOptions = {
    url: `${config.ws_origin}/api/graphql-ws?op=ws`,
    keepAlive: 60 * 1000,
    connectionParams: currentUser._aT ? () => ({ accessToken: currentUser._aT!() }) : undefined,
  };
  return createClient(clientOptions);
}
