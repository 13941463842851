// libs
import React, { useEffect } from 'react';

import { useAppConfig } from '@/hooks/useAppConfig';
import useNavigation from '@/hooks/useNavigation';
// hooks
import { useUserContext } from '@/modules/auth/hooks/useUser';

import { useSegmentAnalytics } from '../hooks/useAnalytics';

const SegmentAnalyticsTriggers: React.FC = () => {
  const user = useUserContext();
  const {
    site: { name: workspaceName },
  } = useAppConfig();
  const { track, identify, trackPage } = useSegmentAnalytics();
  const navigation = useNavigation();

  useEffect(() => {
    identify(user.id, {
      name: user.name,
      email: user.uniqueId,
      workspaceName,
    });
  }, [user.id, user.name, user.uniqueId, track, identify, workspaceName]);

  useEffect(() => {
    /* call on every route change. Do in next frame so that it happens after document title is updated */
    setTimeout(() => {
      trackPage();
    });
  }, [navigation.asPath, trackPage]);

  return null;
};

export default SegmentAnalyticsTriggers;
