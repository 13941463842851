import { Resolver } from '@urql/exchange-graphcache';

import { TaskDetailsFromCacheQueryVariables } from '../queries/generated/taskDetails';

export const taskDetailsResolver: Resolver<any, TaskDetailsFromCacheQueryVariables, any> = (
  parent,
  args,
) => {
  return {
    __typename: 'ActionItemPayload',
    actionItem: { __typename: 'ActionItemType', id: args.filters.actionItemId },
  };
};
