import React from 'react';

import { Box, Button, Typography } from '@lyearn/ui';
import { Link } from '@/components/Link';

// todo replace after Gineets PR

function WrongZone() {
  return (
    <Box className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <Typography variant="display-xxxl">This is Area 403</Typography>
      <Typography className="mb-48 mt-20" variant="body-long-01">
        There may not be aliens here, but you’re not allowed to go in
      </Typography>
      <Link to="/">
        <Button>Back to Home</Button>
      </Link>
    </Box>
  );
}

export default WrongZone;
