import { OptimisticMutationResolver } from '@urql/exchange-graphcache';

import { UserFragment, UserFragmentDoc } from '@/graphql/fragments/generated/User';
import ActionPlanEntity from '@/modules/action-plan/entity';

import {
  UpdateActionPlanDetailsMutation,
  UpdateActionPlanDetailsMutationVariables,
} from '../mutations/generated/updateActionPlanDetails';
import {
  ActionPlanDetailsFragment,
  ActionPlanDetailsFragmentDoc,
} from '../queries/generated/actionPlanDetails';

const updateActionPlanDetails: OptimisticMutationResolver = (variables, cache, info) => {
  const data = (variables as UpdateActionPlanDetailsMutationVariables).input;
  const { id, owner, dueDate, status } = data;
  const actionPlan = cache.readFragment(ActionPlanDetailsFragmentDoc, {
    id,
  }) as ActionPlanDetailsFragment;

  if (!actionPlan || (!owner && !dueDate && !status)) {
    return null;
  }

  ActionPlanEntity.assertOwnerIsUser(actionPlan.resolvedOwner);

  let newOwner: UserFragment = actionPlan.resolvedOwner;
  if (owner) {
    newOwner = cache.readFragment(UserFragmentDoc, {
      id: owner.ownerId,
    }) as UserFragment;
  }

  const returnValue: UpdateActionPlanDetailsMutation['updateActionPlan'] = {
    actionPlan: {
      ...actionPlan,
      owner: newOwner ? { ...actionPlan.owner, ownerId: newOwner.id } : actionPlan.owner,
      resolvedOwner: newOwner ? { ...newOwner, __typename: 'UserType' } : actionPlan.resolvedOwner,
      dueDate: dueDate ?? actionPlan.dueDate,
      status: status ?? actionPlan.status,
      __typename: 'ActionPlanType',
    },
    __typename: 'UpdateActionPlanPayload',
  };

  return returnValue;
};

export default updateActionPlanDetails;
