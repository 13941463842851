import { ErrorBoundary as EB, withErrorBoundary as WEB } from '@sentry/nextjs';

import ErrorState from './ErrorState';
import PageError from './PageError';

export const ErrorBoundary = EB;
export const withErrorBoundary = WEB;

export default ErrorState;
export { ErrorState, PageError };
