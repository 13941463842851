import React, { Suspense } from 'react';
import { css } from '@emotion/css';

import { cn } from '@lyearn/style';
import Loader from '@/components/Loader';
import { Modal } from '@/components/Modals';
import { ModalVariant } from '@/components/Modals/types';
import dynamic from '@/utils/dynamic';

const EditProfileLazy = dynamic(
  () => import(/* webpackChunkName: "edit-profile-component" */ './'),
);

interface EditProfileModalProps {
  onClose: () => void;
}

const borderRadius12 = css`
  border-top-left-radius: 1.2rem;
  border-top-right-radius: 1.2rem;
`;

function EditProfileModal(props: EditProfileModalProps) {
  const { onClose } = props;

  return (
    <Modal
      open
      bottomSheetClasses={{
        paper: cn('h-screen', borderRadius12),
      }}
      variant={ModalVariant.FullHeight}
      onClose={onClose}>
      <Suspense fallback={<Loader />}>
        <EditProfileLazy mode="EDIT" onClose={onClose} />
      </Suspense>
    </Modal>
  );
}

export default EditProfileModal;
