import isEqual from 'lodash/isEqual';

import {
  GetPostsQuery,
  GetPostsQueryVariables,
} from '@/features/messages/graphql/queries/generated/Posts';
import { ChannelPostsSubscriptionInput, PostsFilters } from '@/types/schema';

import { PostsAdapterFunction } from './types';
import {
  canIgnoreDeletedPost,
  getCommunityChannelFragment,
  updateUnreadCountOnPostHideOrUnhide,
} from './unreadCountUtils';

type PostFilterKey = keyof PostsFilters & keyof ChannelPostsSubscriptionInput;

type PostEdges = GetPostsQuery['posts']['edges'];

const KEYS_TO_MATCH: PostFilterKey[] = ['channelIds', 'parentId', 'types'];

const sortPostEdges = (edges: PostEdges) => {
  if (!edges) {
    return [];
  }

  edges.sort((a, b) => new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime());
};

export const getQueryDetails = (
  queryVariables: GetPostsQueryVariables | null,
  subscriptionVariables: ChannelPostsSubscriptionInput | null,
) => {
  const result = { match: false, isPinnedQuery: false, showHiddenPosts: false };
  if (queryVariables && subscriptionVariables) {
    result.match = KEYS_TO_MATCH.every((key) =>
      isEqual(queryVariables.filters[key], subscriptionVariables[key]),
    );
    result.showHiddenPosts = Boolean(queryVariables.filters.showHiddenPosts);
    result.isPinnedQuery = Boolean(queryVariables.filters.isPinned);
  }
  return result;
};

export const adaptHiddenPosts: PostsAdapterFunction = ({ cache, data, post, currentUserId }) => {
  const isCreatedByCurrentUser = post.createdById === currentUserId;

  if (isCreatedByCurrentUser || !data?.posts.edges) {
    return data;
  }

  const isPostHidden = Boolean(post.hiddenBy);
  const isPostVisibileInList = data?.posts.edges?.find(({ node }) => node.id === post.id);
  const postEdges = data.posts.edges;

  if (isPostHidden && isPostVisibileInList) {
    data.posts.edges = postEdges.filter(({ node }) => node.id !== post.id);
    data.posts.totalCount = data.posts.totalCount - 1;
    updateUnreadCountOnPostHideOrUnhide({ cache, post, currentUserId });
  } else if (!isPostHidden && !isPostVisibileInList) {
    const createdAt = postEdges[postEdges.length - 1]?.node?.createdAt;
    const isAfterOldestPost = createdAt ? new Date(post.createdAt) >= new Date(createdAt) : false;

    if (isAfterOldestPost || !data.posts.totalCount) {
      postEdges.push({ node: post, __typename: 'PostEdge' });
      sortPostEdges(postEdges);
      data.posts.totalCount = data.posts.totalCount + 1;
      updateUnreadCountOnPostHideOrUnhide({ cache, post, currentUserId });
    }
  }

  return data;
};

export const adaptPinnedPosts: PostsAdapterFunction = ({ data, post }) => {
  if (data?.posts.edges) {
    const isPostPinned = Boolean(post.pinnedBy);
    const isPostAlreadyInList = data?.posts.edges?.find(({ node }) => node.id === post.id);

    if (isPostPinned && !isPostAlreadyInList) {
      data.posts.edges.push({ node: post, __typename: 'PostEdge' });
    } else if (!isPostPinned && isPostAlreadyInList) {
      data.posts.edges = data.posts.edges.filter(({ node }) => node.id !== post.id);
    }

    sortPostEdges(data.posts.edges);
    data.posts.totalCount = data.posts.edges.length;
  }

  return data;
};

export const adaptOnPostCreate: PostsAdapterFunction = ({ data, post }) => {
  if (data?.posts.edges) {
    // using for-loop instead of unshift - https://jsbench.me/cgjfc79bgx/1
    const edges: PostEdges = [{ node: post, __typename: 'PostEdge' }];

    for (let index = 0; index < data.posts.edges.length; index++) {
      edges.push(data.posts.edges[index]);
    }

    data.posts.edges = edges;
    data.posts.totalCount++;
  }
  return data;
};

export const adaptOnPostDelete: PostsAdapterFunction = ({ cache, data, post, currentUserId }) => {
  const channel = getCommunityChannelFragment(cache, post.currentWorkspaceChannelId);

  if (!channel || canIgnoreDeletedPost({ channel, post, currentUserId })) {
    return data;
  }

  if (data?.posts.edges) {
    data.posts.totalCount--;
  }

  return data;
};
