import usePagination from '@/hooks/usePagination';
import { Maybe, ReviewCyclesFilters, SortType } from '@/types/schema';

import { useGetReviewCycleListItemsQuery } from '../../../graphql/query/generated/getReviewCycleListItems';

interface Props {
  filters: ReviewCyclesFilters;
  sort?: Maybe<SortType[]>;
  pause?: boolean;
}

const useSkillReviewListItems = ({ sort, filters, pause }: Props) => {
  const { data, isFetchingMore, error, refetch, fetchMore, isFetching, isLoading, totalCount } =
    usePagination(
      useGetReviewCycleListItemsQuery,
      {
        filters,
        sort,
      },
      { edgeKey: 'reviewCycles', pause },
    );

  return {
    isFetching,
    isFetchingMore,
    isLoading,
    error,
    reviewCycles: data,
    totalCount,
    refetch,
    fetchMore,
  };
};

export default useSkillReviewListItems;
