/**
 * ref :https://github.com/urql-graphql/urql/blob/main/exchanges/refocus/src/refocusExchange.ts
 *
 * Needed to fork this as cache only request where not ignore while window refocus
 */

import { Exchange, Operation } from '@urql/core';
import { pipe, tap } from 'wonka';

import { getOperationName } from '@/utils/urql';

import { CACHE_ONLY_OPERATION_NAMES_SET } from './requestPolicyExchange';

export interface Options {
  shouldReExecute?: (op: Operation) => boolean;
}

const defaultRefocusExchange = (options: Options): Exchange => {
  return ({ client, forward }) =>
    (ops$) => {
      if (typeof window === 'undefined') {
        return forward(ops$);
      }

      const watchedOperations = new Map<number, Operation>();
      const observedOperations = new Map<number, number>();

      window.addEventListener('visibilitychange', () => {
        if (typeof document !== 'object' || document.visibilityState === 'visible') {
          watchedOperations.forEach((op) => {
            client.reexecuteOperation(
              client.createRequestOperation('query', op, {
                ...op.context,
                requestPolicy: 'cache-and-network',
              }),
            );
          });
        }
      });

      const processIncomingOperation = (op: Operation) => {
        if (
          op.kind === 'query' &&
          !observedOperations.has(op.key) &&
          //Added below line to ignore cache only operation while refocus
          (!options.shouldReExecute || options.shouldReExecute(op))
        ) {
          observedOperations.set(op.key, 1);
          watchedOperations.set(op.key, op);
        }

        if (op.kind === 'teardown' && observedOperations.has(op.key)) {
          observedOperations.delete(op.key);
          watchedOperations.delete(op.key);
        }
      };

      return forward(pipe(ops$, tap(processIncomingOperation)));
    };
};

export const refocusExchange = () => {
  return defaultRefocusExchange({
    shouldReExecute: (op) => {
      if (op.context.skipRefocus) {
        return false;
      }

      return !CACHE_ONLY_OPERATION_NAMES_SET.has(getOperationName(op.query) ?? '');
    },
  });
};
