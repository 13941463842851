import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const GROUP_PARENT_ROUTE = '/learner-groups';
const ADMIN_GROUPS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${GROUP_PARENT_ROUTE}`;

const COLLECTION_PARENT_ROUTE = '/collections';
const ADMIN_COLLECTION_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${COLLECTION_PARENT_ROUTE}`;

const groupAdminRoutes = {
  GroupManager: () => `${ADMIN_GROUPS_PARENT_ROUTE}`,
  GroupCreator: () => `${ADMIN_GROUPS_PARENT_ROUTE}/create`,
  GroupDetails: (id: string) => `${ADMIN_GROUPS_PARENT_ROUTE}/${id}/details`,
  GroupEditor: (id: string) => `${ADMIN_GROUPS_PARENT_ROUTE}/${id}/edit`,

  CollectionManager: () => `${ADMIN_COLLECTION_PARENT_ROUTE}`,
  CollectionCreator: () => `${ADMIN_COLLECTION_PARENT_ROUTE}/create`,
  CollectionDetails: (id: string) => `${ADMIN_COLLECTION_PARENT_ROUTE}/${id}/details`,
  CollectionEditor: (id: string) => `${ADMIN_COLLECTION_PARENT_ROUTE}/${id}/edit`,
};

export default groupAdminRoutes;
