import { TFunction } from '@lyearn/i18n';

export enum AuthErrorCodes {
  ACCOUNT_NOT_FOUND = 'ACCOUNT_NOT_FOUND',
  USER_EXISTS = 'USER_EXISTS',
  CANT_BE_EMPTY = 'CANT_BE_EMPTY',
  EMAIL_CANT_BE_EMPTY = 'EMAIL_CANT_BE_EMPTY',
  PASSWORD_CANT_BE_EMPTY = 'PASSWORD_CANT_BE_EMPTY',
  WEAK_PASSWORD = 'WEAK_PASSWORD',
  ENTER_VALID_EMAIL = 'ENTER_VALID_EMAIL',
  PASSWORD_NOT_SET = 'PASSWORD_NOT_SET',
  INVALID_EMAIL = 'INVALID_EMAIL',
  MUST_BE_8_CHAR = 'MUST_BE_8_CHAR',
  NO_NUMBER = 'NO_NUMBER',
  NO_UPPER_CASE = 'NO_UPPER_CASE',
  NO_LOWER_CASE = 'NO_LOWER_CASE',
  NO_SPECIAL_CHAR = 'NO_SPECIAL_CHAR',
  PASSWORD_MISMATCH = 'PASSWORD_MISMATCH',
  INVALID_DATA = 'INVALID_DATA',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  TOKEN_INVALID = 'TOKEN_INVALID',
  USER_ARCHIVED = 'USER_ARCHIVED',
  TOO_MANY_REQUEST = 'TOO_MANY_REQUEST',
  INCORRECT_SUBWORKSPACE = 'INCORRECT_SUBWORKSPACE',
  INVITE_EMAIL_DOES_NOT_MATCH = 'INVITE_EMAIL_DOES_NOT_MATCH',
  ACADEMY_NOT_SETUP = 'ACADEMY_NOT_SETUP',
}

export const getErrorMessage =
  (t: TFunction) =>
  (errorCode?: string, defaultMessage?: string): string | typeof defaultMessage => {
    const error = errorCode || defaultMessage;

    switch (error) {
      case AuthErrorCodes.INCORRECT_SUBWORKSPACE:
      case AuthErrorCodes.ACCOUNT_NOT_FOUND:
        return t('Auth:error.accountNotFound');
      case AuthErrorCodes.USER_EXISTS:
        return t('Auth:error.userExist');
      case AuthErrorCodes.CANT_BE_EMPTY:
        return t('Auth:error.cantBeEmpty');
      case AuthErrorCodes.PASSWORD_CANT_BE_EMPTY:
        return t('Auth:error.passwordcantBeEmpty');
      case AuthErrorCodes.ENTER_VALID_EMAIL:
        return t('Auth:error.pleaseEnterValidEmail');
      case AuthErrorCodes.PASSWORD_NOT_SET:
        return t('Auth:error.passwordNotSet');
      case AuthErrorCodes.INVALID_EMAIL:
        return t('Auth:error.invalidEmail');
      case AuthErrorCodes.MUST_BE_8_CHAR:
        return t('Auth:error.mustBe8Char');
      case AuthErrorCodes.PASSWORD_MISMATCH:
        return t('Auth:error.passwordDoesntMatch');
      case AuthErrorCodes.INVALID_DATA:
        return t('Auth:error.invalidData');
      case AuthErrorCodes.EMAIL_NOT_VERIFIED:
        return t('Auth:error.emailNotVerified');
      case AuthErrorCodes.TOKEN_INVALID:
        return t('Auth:error.tokenInvalid');
      case AuthErrorCodes.USER_ARCHIVED:
        return t('Auth:error.UserArchived');
      case AuthErrorCodes.NO_NUMBER:
        return t('Auth:error.atLeastOneNumber');
      case AuthErrorCodes.NO_UPPER_CASE:
        return t('Auth:error.noUpperCase');
      case AuthErrorCodes.NO_LOWER_CASE:
        return t('Auth:error.noLowerCase');
      case AuthErrorCodes.NO_SPECIAL_CHAR:
        return t('Auth:error.noSpecialChar');
      case AuthErrorCodes.EMAIL_CANT_BE_EMPTY:
        return t('Auth:error.emailcantBeEmpty');
      case AuthErrorCodes.WEAK_PASSWORD:
        return t('Auth:error.weakPasswordStrength');
      case AuthErrorCodes.TOO_MANY_REQUEST:
        return t('Auth:error.tooManyRequest');
      case AuthErrorCodes.ACADEMY_NOT_SETUP:
        return t('Auth:AcademyNotSetupTitle');
      default:
        return defaultMessage || t('Common:SomethingWentWrong');
    }
  };
