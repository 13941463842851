import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/tags';
const ADMIN_TAGS_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const tagsAdminRoutes = {
  TagEditor: (tagId: string) => `${ADMIN_TAGS_PARENT_ROUTE}/${tagId}/edit`,
  TagCreator: () => `${ADMIN_TAGS_PARENT_ROUTE}/create`,
  TagsManager: () => `${ADMIN_TAGS_PARENT_ROUTE}`,
  TagsDetails: (tagId: string) => `${ADMIN_TAGS_PARENT_ROUTE}/${tagId}/details`,
};

const tagsLearnerRoutes = {};

const routes = {
  ...tagsAdminRoutes,
  ...tagsLearnerRoutes,
};

export const TAGS_ADMIN_ROUTE_KEYS = {
  TagEditor: 'TagEditor',
  TagCreator: 'TagCreator',
  TagsManager: 'TagsManager',
  TagsDetails: 'TagsDetails',
};

export const TAGS_LEARNER_ROUTE_KEYS = {};

export const TAGS_ROUTE_KEYS = {
  ...TAGS_ADMIN_ROUTE_KEYS,
  ...TAGS_LEARNER_ROUTE_KEYS,
};

export default routes;
