import { UserSurveyManagerTabType } from '@/modules/user-survey/views/UserSurveyManager/types';

import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

export const userSurveyAdminRoutes = {
  UserSurveys: () => `${ADMIN_PARENT_ROUTE}/user-surveys`,
  UserSurveyManager: (type: string, tab: string = UserSurveyManagerTabType.Active) =>
    `${ADMIN_PARENT_ROUTE}/user-surveys/${type}/${tab}`,
  UserSurveyActionPlans: () => `${ADMIN_PARENT_ROUTE}/user-surveys/action-plans`,
  UserSurveysQuestionBank: () => `${ADMIN_PARENT_ROUTE}/user-surveys/question-bank`,
  UserSurveyCreator: (type: string) => `${ADMIN_PARENT_ROUTE}/user-surveys/${type}/create`,
  UserSurveyEditor: (userSurveyId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/edit`,
  UserSurveyAdminDetails: (userSurveyId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/details`,
  UserSurveyAdminDetailsTab: (userSurveyId: string, tab: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/details/${tab}`,
  UserSurveyThemeAdminDetails: (userSurveyId: string, themeId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/theme/${themeId}`,
  UserSurveyTemplates: (type: string) => `${ADMIN_PARENT_ROUTE}/user-surveys/${type}/templates`,
  UserSurveyPreview: (userSurveyId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/preview`,
  UserSurveyPulsePreview: (userSurveyId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/pulse-preview`,
  UserSurveyPreviewDevices: (userSurveyId: string) =>
    `${ADMIN_PARENT_ROUTE}/user-survey/${userSurveyId}/preview-devices`,
};

const userSurveyLearnerRoutes = {
  LearnerSurveyManager: () => `/surveys`,
  MySurveys: (tab?: string) => `/my-surveys/${tab ?? ''}`,
  MySurveyDetails: (userSurveyId: string) => `/my-survey/${userSurveyId}/details`,
  MySurveyDetailsTab: (userSurveyId: string, tab: string) =>
    `/my-survey/${userSurveyId}/details/${tab}`,
  UserSurveyThemeDetails: (userSurveyId: string, themeId: string) =>
    `/my-survey/${userSurveyId}/theme/${themeId}`,
  UserSurveyLearnerDetails: (userSurveyId: string) => `/user-survey/${userSurveyId}/details`,
  PublicUserSurveyDetails: (publicId: string) => `/public/user-survey/${publicId}`,
};

const userSurveyRoutes = {
  ...userSurveyAdminRoutes,
  ...userSurveyLearnerRoutes,
};

export default userSurveyRoutes;
