import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/course';
const ADMIN_COURSE_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const adminCourseRoutes = {
  CourseBuilder: (courseId: string) => `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/build`,
  CoursePreview: (courseId: string) => `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/preview`,
  CourseDevicesPreview: (courseId: string) =>
    `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/preview-devices`,
  CourseCreator: () => `${ADMIN_COURSE_PARENT_ROUTE}/create`,
  CourseReport: (courseId: string) => adminCourseRoutes.CourseLearnersReport(courseId),
  CourseLearnersReport: (courseId: string) =>
    `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/reports/learners`,
  CourseAssessmentsReport: (courseId: string) =>
    `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/reports/assessments`,
  CourseLessonsReport: (courseId: string) =>
    `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/reports/lessons`,
  CourseFeedbackReport: (courseId: string) =>
    `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/reports/feedback`,
  AdminCourseDetails: (courseId: string) => `${ADMIN_COURSE_PARENT_ROUTE}/${courseId}/details`,
};

const learnerCourseRoutes = {
  CourseViewer: (courseId: string) => `${PARENT_ROUTE}/${courseId}/view`,
  CourseDetails: (courseId: string) => `${PARENT_ROUTE}/${courseId}/details`,
  CourseReview: (courseId: string) => `${PARENT_ROUTE}/${courseId}/view/review`,
};

export const courseDetailRoute = (courseId: string) => ({
  admin: adminCourseRoutes.AdminCourseDetails(courseId),
  learner: learnerCourseRoutes.CourseDetails(courseId),
});

export const courseViewerRoute = (courseId: string) => ({
  admin: adminCourseRoutes.CourseDevicesPreview(courseId),
  learner: learnerCourseRoutes.CourseViewer(courseId),
});

const routes = {
  ...adminCourseRoutes,
  ...learnerCourseRoutes,
};

export const ADMIN_COURSE_ROUTE_KEYS = {
  CourseBuilder: 'CourseBuilder',
  CourseCreator: 'CourseCreator',
  CourseReport: 'CourseReport',
  CoursePreview: 'CoursePreview',
  CourseReview: 'CourseReview',
  CourseLearnersReport: 'CourseLearnersReport',
  CourseAssessmentsReport: 'CourseAssessmentsReport',
  AdminCourseDetails: 'AdminCourseDetails',
};

export const LEARNER_COURSE_ROUTE_KEYS = {
  CourseViewer: 'CourseViewer',
  CourseDetails: 'CourseDetails',
};

export const COURSE_ROUTE_KEYS = {
  ...ADMIN_COURSE_ROUTE_KEYS,
  ...LEARNER_COURSE_ROUTE_KEYS,
};

export default routes;
