import React from 'react';

import Responsive from '@/components/Responsive';

import ModalHeaderDesktop from './index.desktop';
import ModalHeaderMobile from './index.mobile';
import { ModalHeaderProps } from './types';

const ModalHeaderContainer = (props: ModalHeaderProps) => {
  return (
    <Responsive>
      <Responsive.Default>
        <ModalHeaderDesktop {...props} />
      </Responsive.Default>
      <Responsive.Mobile>
        <ModalHeaderMobile {...props} />
      </Responsive.Mobile>
    </Responsive>
  );
};

export default React.memo(ModalHeaderContainer);
