// if a new key is added then handle for cases where it is not stored in localStorage. See handling of `ui` in the hook
import config from 'config';

import {
  BackgroundLayoutTypeEnum,
  GlobalSearchAndLibraryConfigType,
  LivestreamProviderEnum,
  SearchableContentTypeEnum,
  VisibilitySettingsAccessTypeEnum,
} from '@/types/schema';

import { AppConfig } from '.';

export type WebinarAllowedMicroApps = (
  | 'WHITEBOARD'
  | 'SCREEN_SHARE'
  | 'NOTEPAD'
  | 'COURSE'
  | 'POLL'
  | 'VIDEO_SHARE'
)[];

export type AllowedSessionTypes = ('LIVE' | 'OFFLINE' | 'EXTERNAL')[];

// This default value should be in sync with the default values at backend service
export const DEFAULT_GLOBAL_SEARCH_AND_LIBRARY_CONFIG: GlobalSearchAndLibraryConfigType = {
  globalSearch: {
    allowedContentTypes: [
      SearchableContentTypeEnum.Article,
      SearchableContentTypeEnum.Path,
      SearchableContentTypeEnum.Scorm,
      SearchableContentTypeEnum.ExternalContent,
      SearchableContentTypeEnum.Course,
    ],
  },
  library: {
    allowedContentTypes: [
      SearchableContentTypeEnum.Article,
      SearchableContentTypeEnum.Path,
      SearchableContentTypeEnum.Scorm,
      SearchableContentTypeEnum.ExternalContent,
      SearchableContentTypeEnum.Course,
    ],
  },
};

export const DEFAULT_CONFIG: AppConfig = {
  site: {
    favicon: `${config.cdn_domain}/assets/images/favicon.png`,
    name: 'Lyearn',
    defaultLiveStreamProvider: LivestreamProviderEnum.Youtube,
    webinarAllowedMicroApps: ['SCREEN_SHARE'] as WebinarAllowedMicroApps,
    // Disabling the live session type since lightspeed is not supported
    allowedSessionTypes: [/*'LIVE', */ 'EXTERNAL', 'OFFLINE'] as AllowedSessionTypes,
    canConfigureAssignmentNotification: true,
    isTaskEnabled: true,
    canManageOrgRole: true,
    canManageNotifications: true,
    canManageAcademySetting: true,
    canManageAuthSetting: true,
    enableURQLOfflineCache: false,
    enableIntegrations: false,
    subWorkspacesEnabled: false,
    // This is to disable cloudapi conversion and cloudflare video uploads
    // in the lygo editor.
    enabledConvertibleImport: true,
    enableDomainChange: false,
    enablePageEditorPowerUp: true,
    enableAssignmentRestriction: false,
    defaultContentVisibilitySettings: { accessType: VisibilitySettingsAccessTypeEnum.Open },
    certificateSharingSettings: { disableLinkedInSharing: false, disableSocialSharing: false },
  },
  ui: {
    showUserNotification: true,
    showBookmark: true,
    allowLearnerReview: true,
    isIFrameBackAllowed: true,
    showImportCreateBtn: true,
    showCustomReporting: true,
    showGroupManager: true,
    showRoleManager: true,
    showStaff: true,
    showReportExport: true,
    tagsNotAvailableOn: [], // ['USER'] for hdfc
    // retoolAcademiesManagementUrl:
    //   'https://lyearn.retool.com/embedded/public/d017cbb2-6017-4507-b633-7bdfc2aace01',
  },
  authConfig: {
    background: {
      layout: BackgroundLayoutTypeEnum.NoBackground,
    },
    signUpAllowed: false,
    strategyMethodsListing: ['GOOGLE', 'APPLE', 'LOCAL'],
    strategyMethods: [
      {
        provider: 'GOOGLE',
        icon: `${config.cdn_domain}/assets/images/google.svg`,
        signInLabel: 'Auth:continueWithGoogle',
        signUpLabel: 'Auth:continueWithGoogle',
        webStrategyUrl: '/api/auth/login/googleAuthenticator', // todo not used in mobile
      },
      {
        provider: 'APPLE',
        icon: `${config.cdn_domain}/assets/images/apple-negative.svg`,
        signInLabel: 'Auth:continueWithApple',
        signUpLabel: 'Auth:continueWithApple',
        webStrategyUrl: '/api/auth/login/appleAuthenticator',
      },
      {
        provider: 'LOCAL',
        allowUsername: false,
        icon: `${config.cdn_domain}/assets/images/email.svg`,
        signInLabel: 'Auth:signInWithEmail',
        signUpLabel: 'Auth:signUpWithEmail',
        webStrategyUrl: '/api/auth/login/emailAuthenticator',
      },
    ],
  },
};
