export const LANGUAGE_I18N_MAP: Record<string, string> = {
  English: 'en-US',
  Hindi: 'hi',
  Marathi: 'mr',
  Tamil: 'ta',
  Telugu: 'te',
  Kannada: 'kn',
  Gujarati: 'gu',
  Spanish: 'es',
  Arabic: 'ar',
  Bengali: 'bn',
  Malayalam: 'ml',
};
