import cookie, { CookieAttributes } from 'js-cookie';

const cookieMap: Record<string, string> = {};

export function areCookiesEnabled() {
  // ref https://github.com/Modernizr/Modernizr/blob/efde16bdbca373e14bfcaabe813d3ed262f645af/feature-detects/storage/cookies.js#L27-L34
  try {
    // Create cookie
    document.cookie = 'cookietest=1';
    const ret = document.cookie.indexOf('cookietest=') !== -1;
    // Delete cookie
    document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';
    return ret;
  } catch (e) {
    return false;
  }
}

export const set = (name: string, value: string, options?: CookieAttributes) => {
  return areCookiesEnabled() ? cookie.set(name, value, options) : (cookieMap[name] = value);
};

export const get = (name: string) => {
  return areCookiesEnabled() ? cookie.get(name) : cookieMap?.[name];
};

export const getAll = () => {
  return Promise.resolve();
};

export const remove = (name: string, options?: CookieAttributes) => {
  return areCookiesEnabled() ? cookie.remove(name, options) : delete cookieMap[name];
};

export function clearAll() {
  throw new Error('Not implement in web');
}

const exports = { set, getAll, get, remove, clearAll };

export default exports;
