import React from 'react';

import { cn } from '@lyearn/style';
import { Box, Typography } from '@lyearn/ui';
import { SideBarMode, SidebarSection } from '@/modules/corporate/modules/admin/hooks/useSidebar';

import SidebarItem from './SidebarItem';

const Section = ({
  section,
  mode,
  className,
}: {
  section: SidebarSection;
  mode?: SideBarMode;
  className?: string;
}) => {
  return (
    <Box className={cn('mb-24', section?.className, className)}>
      {section.title ? (
        <Typography
          as="div"
          className="pb-8 ps-16"
          color="text-text-placeholder"
          variant="label-caps">
          {section.title}
        </Typography>
      ) : null}
      {section.children.map((sidebarItem) => (
        <SidebarItem key={sidebarItem.id} mode={mode} sidebarItem={sidebarItem} />
      ))}
    </Box>
  );
};

export default Section;
