import { forwardRef } from 'react';
import { css } from '@emotion/css';

import { cn } from '@lyearn/style';
import { Box } from '@lyearn/ui';

import { AvatarOverlayProps } from '../types';

export const overlayStyles = css`
  position: relative;
  width: min-content;
  height: min-content;

  .overlay-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--overlay-light);
    z-index: 1;
    border-radius: 50%;
    display: none;
  }

  &:hover .overlay-block {
    display: unset;
  }
`;

// spreading rest for mui
const AvatarOverlay = forwardRef<HTMLDivElement, AvatarOverlayProps>(
  ({ children, className, ...rest }, ref) => {
    return (
      <Box ref={ref} className={cn(overlayStyles, className)} {...rest}>
        <Box className="overlay-block" />
        {children}
      </Box>
    );
  },
);

export default AvatarOverlay;
