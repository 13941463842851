import { devtoolsExchange } from '@urql/devtools';
import { cacheExchange, offlineExchange } from '@urql/exchange-graphcache';
import { retryExchange } from '@urql/exchange-retry';
import config from 'config';
import { createClient, fetchExchange, subscriptionExchange } from 'urql';

import Platform from '@lyearn/core/utils/Platform';
import schema from '@/types/schema-json.json';

import cacheKeys from './cache/keys';
import cacheMutations from './cache/mutations';
import optimistic from './cache/optimistic';
import resolvers from './cache/resolvers';
import cacheSubscription from './cache/subscription';
import authExchange from './authExchange';
import errorExchange from './errorExchange';
import { filterUnAuthExchange } from './filterUnAuthExchange';
import interactionManagerExchange from './interactionManager';
import { refocusExchange } from './refocusExchange';
import { requestPolicyExchange } from './requestPolicyExchange';
import getStorage from './storage';
import { createSubscriptionClient } from './subscriptionClient';

const NO_CONTENT_MESSAGE = '[Network] No Content';

type GetClientType = {
  enableURQLOfflineCache?: boolean;
};

export const getExchanges = (
  ssrExchange?: typeof fetchExchange,
  enableURQLOfflineCache?: boolean,
) => {
  const subscriptionClient = __IS_BROWSER__ ? createSubscriptionClient() : null;
  const _cacheExchange = enableURQLOfflineCache ? offlineExchange : cacheExchange;

  return [
    devtoolsExchange,
    refocusExchange(),
    requestPolicyExchange(),
    _cacheExchange({
      //@ts-ignore
      storage: enableURQLOfflineCache ? getStorage() : undefined,
      resolvers,
      // @ts-ignore
      keys: cacheKeys,
      updates: { Mutation: cacheMutations, Subscription: cacheSubscription },
      // @ts-ignore
      schema,
      optimistic,
    }),
    filterUnAuthExchange,
    retryExchange({
      retryIf: (err) => !!(err.message === NO_CONTENT_MESSAGE || err.networkError),
    }),
    Platform.select({ native: interactionManagerExchange }),
    errorExchange(),
    authExchange(),
    fetchExchange,
    subscriptionClient
      ? subscriptionExchange({
          forwardSubscription: (operation) => ({
            subscribe: (sink) => {
              // @ts-ignore
              const dispose = subscriptionClient.subscribe(operation, sink);

              return {
                unsubscribe: dispose,
              };
            },
          }),
        })
      : undefined,
  ].filter(Boolean);
};

const getClient = ({ enableURQLOfflineCache }: GetClientType) => {
  return createClient({
    url: `${config.origin}/api/graphql`,
    exchanges: getExchanges(undefined, enableURQLOfflineCache),
  });
};

export default getClient;
