import getEnabledItems from '@lyearn/core/utils/array/getEnabledItems';
import { Trans } from '@lyearn/i18n';
import {
  ArrowDownward,
  ArrowUp,
  AZ,
  Calendar2Outline,
  EditOutline,
  SparkleOutline,
} from '@lyearn/icons';
import { SortMenuItemType } from '@/components/FilterManager/SortMenu/types';
import { SortEnums } from '@/components/FilterManager/types';
import { OrderEnum } from '@/types/schema';

/**
 *! Stop wait a minute.
 *! These values only serve as defaults, you can override everything when you define them while
 *! rendering them. Please make sure the changes done here are verified.
 */

export const DEFAULT_SORT_CONFIG = {
  [SortEnums.Alphabetically]: [{ field: 'name', order: OrderEnum.Asc }],
  [SortEnums.DueDate]: [{ field: 'dueDate', order: OrderEnum.Asc }],
  [SortEnums.LastEdited]: [{ field: 'updatedAt', order: OrderEnum.Desc }],
  [SortEnums.Newest]: [{ field: 'createdAt', order: OrderEnum.Desc }],
  [SortEnums.Oldest]: [{ field: 'createdAt', order: OrderEnum.Asc }],
  [SortEnums.Relevance]: [{ field: 'relevance', order: OrderEnum.Desc }],
  [SortEnums.EndDate]: [{ field: 'endDate', order: OrderEnum.Desc }],
};

type GetSortMenuItemFunction = (params?: Partial<SortMenuItemType>) => SortMenuItemType;

export const getEndDateSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: Calendar2Outline,
  id: SortEnums.EndDate,
  label: <Trans i18nKey="Common:Sort.EndDate" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.EndDate],
  ...params,
});

export const getAlphabeticallySortItem: GetSortMenuItemFunction = (params) => ({
  Icon: AZ,
  id: SortEnums.Alphabetically,
  label: <Trans i18nKey="Common:Sort.Alphabetically" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.Alphabetically],
  ...params,
});

export const getDueDateSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: Calendar2Outline,
  id: SortEnums.DueDate,
  label: <Trans i18nKey="Common:Sort.DueDate" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.DueDate],
  ...params,
});

export const getLastEditedSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: EditOutline,
  id: SortEnums.LastEdited,
  label: <Trans i18nKey="Common:Sort.LastEdited" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.LastEdited],
  ...params,
});

export const getNewestSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: ArrowUp,
  id: SortEnums.Newest,
  label: <Trans i18nKey="Common:Sort.Newest" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.Newest],
  ...params,
});

export const getOldestSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: ArrowDownward,
  id: SortEnums.Oldest,
  label: <Trans i18nKey="Common:Sort.Oldest" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.Oldest],
  ...params,
});

export const getRelevanceSortItem: GetSortMenuItemFunction = (params) => ({
  Icon: SparkleOutline,
  id: SortEnums.Relevance,
  label: <Trans i18nKey="Common:Sort.Relevance" />,
  sort: DEFAULT_SORT_CONFIG[SortEnums.Relevance],
  ...params,
});

export const getEnabledSortItems = (items: SortMenuItemType[]) => {
  return getEnabledItems(items.map((item) => ({ ...item, enabled: item.enabled ?? true })));
};

export const getDefaultSortValue = (items: SortMenuItemType[]) => {
  const enabledItems = getEnabledSortItems(items);

  if (!enabledItems.length) {
    throw new Error('[getDefaultSortValue] No Items');
  }

  return enabledItems[0].sort;
};
