import { UpdateResolver } from '@urql/exchange-graphcache';
import gql from 'graphql-tag';

import { invalidateQuery } from '@/helper/urqlClient/cache/cacheHelpers';
import { OrgRoleType } from '@/types/schema';

import {
  TrackDepartmentDetailsFragment,
  TrackDepartmentDetailsFragmentDoc,
} from '../../../departments/views/DepartmentDetails/graphql/queries/generated/departmentDetails';
import {
  DeleteOrgRoleMutation,
  DeleteOrgRoleMutationVariables,
} from '../mutations/generated/deleteOrgRole';

const deleteOrgRoleUpdater: UpdateResolver<
  DeleteOrgRoleMutation,
  DeleteOrgRoleMutationVariables
> = (result, args, cache, info) => {
  const orgRoleId = args.data.orgRoleId;

  const orgRole = cache.readFragment<Pick<OrgRoleType, 'id' | 'trackId'>>(
    gql`
      fragment _ on OrgRoleType {
        id
        trackId
      }
    `,
    { id: orgRoleId },
  );

  if (orgRole && orgRole.trackId) {
    const track = cache.readFragment<TrackDepartmentDetailsFragment>(
      TrackDepartmentDetailsFragmentDoc,
      { id: orgRole.trackId, __typename: 'TrackType' },
    );

    if (track) {
      cache.writeFragment(TrackDepartmentDetailsFragmentDoc, {
        __typename: 'TrackType',
        ...track,
        orgRoles: track.orgRoles?.filter((orgRole) => orgRole.id !== orgRoleId),
      });
    } else {
      invalidateQuery(['orgRoles', 'tracks', 'track'])(result, args, cache, info);
    }
  } else {
    invalidateQuery(['orgRoles', 'tracks', 'track'])(result, args, cache, info);
  }
};

export default deleteOrgRoleUpdater;
