import config from 'config';

import Cookie from '@/utils/general/cookie';

const user = {
  id: () => Cookie.get('_uid'),
  isMasquerading: () => Cookie.get('notMe') === 'true',
} as {
  id: () => string | undefined;
  isMasquerading: () => boolean; // 'true' when masquerading as other user
  /** @deprecated only for websockets in vercel, env and in-memory **/
  _aT?: () => string | undefined;
  /** @deprecated only in-memory in iframe  **/
  _rT?: () => string | undefined;
};

if (config.rewrite_ws || config.allow_memory_cookie) {
  user._aT = () => Cookie.get('_at');
}

if (config.allow_memory_cookie) {
  user._rT = () => Cookie.get('_rt');
}

export default user;
