import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from '../admin';

const PARENT_ROUTE = '/scorm';
const ADMIN_SCORM_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const scormAdminRoutes = {
  ScormCreator: () => `${ADMIN_SCORM_PARENT_ROUTE}/create`,
  ScormEditor: (contentId: string) => `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/edit`,
  ScormDuplicate: (contentId: string) => `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/duplicate`,
  AdminScormDetails: (contentId: string) => `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/details`,
  ScormReport: (contentId: string) => scormAdminRoutes.ScormLearnersReport(contentId),
  ScormLearnersReport: (contentId: string) =>
    `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/reports/learners`,
  ScormFeedbackReport: (contentId: string) =>
    `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/reports/feedback`,
  AdminScormViewer: (contentId: string) => `${ADMIN_SCORM_PARENT_ROUTE}/${contentId}/view`,
};

const scormLearnerRoutes = {
  ScormDetails: (contentId: string) => `${PARENT_ROUTE}/${contentId}/details`,
  ScormViewer: (contentId: string) => `${PARENT_ROUTE}/${contentId}/view`,
};

export const scormViewerRoute = (contentId: string) => ({
  admin: scormAdminRoutes.AdminScormViewer(contentId),
  learner: scormLearnerRoutes.ScormViewer(contentId),
});

export const scormDetailsRoute = (contentId: string) => ({
  admin: scormAdminRoutes.AdminScormDetails(contentId),
  learner: scormLearnerRoutes.ScormDetails(contentId),
});

const routes = {
  ...scormAdminRoutes,
  ...scormLearnerRoutes,
};

export const SCORM_ADMIN_ROUTE_KEYS = {
  AdminScormDetails: 'AdminScormDetails',
  ScormCreator: 'ScormCreator',
  ScormEditor: 'ScormEditor',
  ScormReport: 'ScormReport',
  ScormLearnersReport: 'ScormLearnersReport',
  ScormFeedbackReport: 'ScormFeedbackReport',
};

export const SCORM_LEARNER_ROUTE_KEYS = {
  ScormDetails: 'ScormDetails',
  ScormViewer: 'ScormViewer',
};

export const SCORM_ROUTE_KEYS = {
  ...SCORM_ADMIN_ROUTE_KEYS,
  ...SCORM_LEARNER_ROUTE_KEYS,
};

export default routes;
