import { PARENT_ROUTE as ADMIN_PARENT_ROUTE } from './admin';

const PARENT_ROUTE = '/scorecards';
const ADMIN_SCORECARD_PARENT_ROUTE = `${ADMIN_PARENT_ROUTE}${PARENT_ROUTE}`;

const scorecardAdminRoutes = {
  ScorecardCreator: () => `${ADMIN_SCORECARD_PARENT_ROUTE}/create`,
  ScorecardManager: () => `${ADMIN_SCORECARD_PARENT_ROUTE}`,
  ScorecardEditor: (scorecardId: string) => `${ADMIN_SCORECARD_PARENT_ROUTE}/${scorecardId}/edit`,
  ScorecardDetails: (scorecardId: string) =>
    `${ADMIN_SCORECARD_PARENT_ROUTE}/${scorecardId}/details`,
};

const scorecardLearnerRoutes = {};

const routes = {
  ...scorecardAdminRoutes,
  ...scorecardLearnerRoutes,
};

export const SCORECARD_ADMIN_ROUTE_KEYS = {
  ScorecardCreator: 'ScorecardCreator',
  ScorecardDetails: 'ScorecardDetails',
  ScorecardManager: 'ScorecardManager',
  ScorecardEditor: 'ScorecardEditor',
};

export const SCORECARD_LEARNER_ROUTE_KEYS = {};

export const SCORECARD_ROUTE_KEYS = {
  ...SCORECARD_ADMIN_ROUTE_KEYS,
  ...SCORECARD_LEARNER_ROUTE_KEYS,
};

export default routes;
