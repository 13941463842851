import { nanoid } from 'nanoid';
import { CombinedError, ExchangeInput, makeOperation, Operation } from 'urql';
import {
  filter,
  fromPromise,
  fromValue,
  makeSubject,
  map,
  merge,
  mergeMap,
  pipe,
  share,
  Source,
} from 'wonka';

import { getOperationName } from '@/utils/urql';

import { checkForPredicateInError } from '../../utils/api/response';
import currentUser from '../currentUser';
import fetchTokens from '../fetchTokens';

const TOKEN_INVALID = 'TOKEN_INVALID';
const UNAUTHORISED_REQUEST = 'UNAUTHORISED_REQUEST';

export const isAuthErrorCheck = (error?: CombinedError) => {
  return (
    [UNAUTHORISED_REQUEST, TOKEN_INVALID].includes((error?.graphQLErrors?.[0] as any)?.errorCode) ||
    [UNAUTHORISED_REQUEST, TOKEN_INVALID].includes(
      ((error?.graphQLErrors?.[0]?.message as any)?.extensions as any)?.errorCode,
    ) ||
    checkForPredicateInError(
      (extensions) =>
        extensions?.httpStatus === 401 &&
        [UNAUTHORISED_REQUEST, TOKEN_INVALID].includes(extensions?.errorCode),
      error,
    )
  );
};

const ADMIN_BEHAVIOUR_ROUTES = ['/admin', '/public/user-survey', '/reports'];

const isAdminBehaviourRoute = (pathname: string) =>
  ADMIN_BEHAVIOUR_ROUTES.some((prefix) => pathname.startsWith(prefix));

const addTokenToOperation = (operation: Operation) => {
  const fetchOptions =
    typeof operation.context.fetchOptions === 'function'
      ? operation.context.fetchOptions()
      : operation.context.fetchOptions || {};

  const operationName = getOperationName(operation.query);
  const adminRoute =
    typeof location !== 'undefined' ? isAdminBehaviourRoute(location.pathname) : false;

  // don't modify url if operations is retried
  // @ts-ignore
  let url = fetchOptions.headers?.['x-request-id']
    ? operation.context.url
    : operation.context.url + `?op=${operationName}`;

  const headers: any = {
    ...fetchOptions.headers,
    'x-request-id': nanoid(),
    'x-operation-name': operationName,
  };

  if (!headers.accessToken && currentUser._aT) {
    headers.accessToken = currentUser._aT();
  }

  if (!adminRoute) {
    headers['X-learner'] = true;
  }

  return makeOperation(operation.kind, operation, {
    ...operation.context,
    url,
    fetchOptions: { ...fetchOptions, headers },
  });
};

const authExchange =
  () =>
  ({ forward }: ExchangeInput) => {
    return (ops$: Source<Operation>) => {
      const sharedOps$ = pipe(ops$, share);
      const { source: retry$, next: nextRetryOperation } = makeSubject<Operation>();

      const withToken$ = pipe(
        merge([sharedOps$, retry$]),
        // Filter by non-teardowns
        filter((operation) => operation.kind !== 'teardown'),
        mergeMap((operation) => fromValue(addTokenToOperation(operation))),
      );

      // We don't need to do anything for teardown operations
      const withoutToken$ = pipe(
        merge([sharedOps$, retry$]),
        filter((operation) => operation.kind === 'teardown'),
      );

      return pipe(
        merge([withToken$, withoutToken$]),
        forward,
        share,
        mergeMap((result) => {
          const isAuthError = result.error && isAuthErrorCheck(result.error);

          if (isAuthError) {
            return pipe(
              fromPromise(fetchTokens()),
              map((tokenResult) => {
                // only retry if fetchTokens was successful
                if (tokenResult) {
                  nextRetryOperation(result.operation);
                }

                return result;
              }),
            );
          }

          return fromValue(result);
        }),
        filter((result) => {
          if (__IS_BROWSER__ && result.error) {
            return !isAuthErrorCheck(result.error);
          }

          return true;
        }),
      );
    };
  };

export default authExchange;
