import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage';

// fn so that it's lazy and create the db only when it's needed
const getStorage = () =>
  __IS_BROWSER__ && typeof indexedDB !== 'undefined'
    ? makeDefaultStorage({
        idbName: 'graphcache-v3',
        maxAge: 7,
      })
    : undefined;

export default getStorage;
