import React from 'react';
import { Trans as TransI18Next } from 'react-i18next';
import { TFunction } from 'i18next';

import {
  Typography,
  TypographyColors,
  TypographyComponentAs,
  TypographyVariants,
} from '@lyearn/ui';

interface TransProps {
  i18nKey?: string;
  values?: Record<string, ReturnType<TFunction> | string | number | undefined | null>;
  color?: TypographyColors;
  variant?: TypographyVariants;
  className?: string;
  as?: TypographyComponentAs;
  components?: Record<string, JSX.Element>;
}

function Trans(props: TransProps) {
  const {
    i18nKey,
    values,
    color,
    variant = 'label-02',
    className,
    as = 'span',
    components,
  } = props;

  return (
    <TransI18Next
      components={{
        label: <Typography as={as} className={className} color={color} variant={variant} />,
        br: <br />,
        ...components,
      }}
      i18nKey={i18nKey}
      values={values}
    />
  );
}

export default React.memo(Trans);
