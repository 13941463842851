import React, { useCallback, useEffect, useMemo } from 'react';

import ErrorReporter from '@lyearn/core/utils/ErrorReporter';
import { useTranslation } from '@lyearn/i18n';
import { AlertTriangleOutline, CopyOutline } from '@lyearn/icons';
import { cn } from '@lyearn/style';
import { Button, IconButton, Stack, Typography } from '@lyearn/ui';
import useCopyToClipboard from '@/hooks/useCopyToClipboard';
import { getOpNameFromOperation, getRequestIdFromOperation } from '@/utils/urql';

import { maxW264, requestIdStyles } from './styles';
import { ErrorStateProps } from './types';

const ErrorStateContent: React.FC<ErrorStateProps> = (props) => {
  const { t } = useTranslation();
  const {
    type = 'large',
    Icon: IconProp,
    title = t('Common:ErrorStateTitle'),
    primaryAction,
    className,
    operation,
    error,
    icon,
    requestId: _requestId,
  } = props;

  const Icon = IconProp || AlertTriangleOutline;

  useEffect(() => {
    const requestId = getRequestIdFromOperation(operation);
    const opName = getOpNameFromOperation(operation);
    error && ErrorReporter.error(error, { tags: { requestId, opName } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const { onCopy } = useCopyToClipboard();

  const requestId = useMemo(
    () => _requestId ?? getRequestIdFromOperation(operation),
    [_requestId, operation],
  );

  const onCopyClicked = useCallback(() => {
    onCopy(requestId);
  }, [onCopy, requestId]);

  const renderIcon = () => {
    if (icon) {
      return icon;
    }

    return (
      <Icon
        data-testid="error-state-icon"
        fillColorToken="icon-placeholder"
        height={32}
        width={32}
      />
    );
  };

  return (
    <Stack
      alignItems="center"
      className={cn(className, maxW264, 'mx-auto flex-1')}
      direction="col"
      justifyContent="center">
      {renderIcon()}
      <Typography
        className="mt-12 text-center"
        color="text-text-placeholder"
        variant={type === 'large' ? 'label-01' : 'label-02'}>
        {title}
      </Typography>
      {requestId ? (
        <Stack alignItems="center" className="mt-8" direction="row" onClick={onCopyClicked}>
          <Typography
            className={cn('text-center', requestIdStyles)}
            color="text-text-placeholder"
            variant="body-long-01">
            <code>ID: {requestId}</code>
          </Typography>
          <IconButton Icon={CopyOutline} className="ms-4" size="xxs" />
        </Stack>
      ) : null}
      {primaryAction ? (
        <Stack alignItems="center" className="mt-24" direction="row" justifyContent="center">
          <Button
            appearance="secondary"
            shape="pill"
            size="small"
            variant="outlined"
            onClick={primaryAction.onClick}>
            {primaryAction.label}
          </Button>
        </Stack>
      ) : null}
    </Stack>
  );
};

/*
  Sentry fallback on ErrorBoundary is calling React component as function which is wrong, hence do not put any hooks here
*/
const ErrorState = (props: ErrorStateProps) => {
  return <ErrorStateContent {...props} />;
};

export default ErrorState;
