import React from 'react';

import { useTranslation } from '@lyearn/i18n';
import { CloseOutline } from '@lyearn/icons';
import { IconButton, LinkButton, Stack, Typography } from '@lyearn/ui';
import useCompletedOnboardingNudges from '@/components/HelpMenu/hooks/useCompletedOnboardingNudges';

import { FloatingFeatureNudgeProps } from './types';

function FloatingFeatureNudge(props: FloatingFeatureNudgeProps) {
  const { t } = useTranslation();
  const { featureNudge } = props;
  const { title, description, link, nudgeType: path } = featureNudge;

  const { onUpdateOnboardingNudges } = useCompletedOnboardingNudges();

  return (
    <Stack
      className="fixed bottom-20 z-50 w-[26.4rem] rounded border border-solid border-border bg-bg-primary p-20 shadow-01 start-20"
      direction="col"
      gapY={20}>
      <IconButton
        Icon={CloseOutline}
        className="absolute top-12 end-12"
        data-action="Close Nudge"
        shape="square"
        size="xs"
        variant="ghost"
        onClick={() => path && onUpdateOnboardingNudges(path)}
      />
      <Stack className="pe-12" direction="col" gapY={8}>
        <Typography color="text-text-primary" variant="heading-xxs">
          {title}
        </Typography>
        <Typography color="text-text-paragraph" variant="body-short-02">
          {description}
        </Typography>
      </Stack>
      {link ? (
        <LinkButton className="self-start" color={'external'} href={link} target="_blank">
          {t('Common:LearnMore')}
        </LinkButton>
      ) : null}
    </Stack>
  );
}

export default FloatingFeatureNudge;
