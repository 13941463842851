const authRoutes = {
  Login: () => '/login',
  Signup: () => '/signup',
  ForgotPassword: () => '/forgot-password',
  SetPassword: () => '/set-password',
  VerifyEmail: () => '/verify-email',
  EmailLogin: () => '/email-login',
  EmailSignup: () => '/email-signup',
};

export default authRoutes;
