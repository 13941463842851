import { LearnerOKRsTabEnum } from '@/modules/goals/modules/okr/views/LearnerOKRs/components/OKRs/types';

import { PARENT_ROUTE } from './admin';

const adminRoutes = {
  CycleManager: () => `${PARENT_ROUTE}/cycle`,

  CycleCreator: () => `${PARENT_ROUTE}/cycle/create`,

  CycleEditor: (cycleId: string) => `${PARENT_ROUTE}/cycle/${cycleId}/edit`,

  CycleObjectives: (cycleId: string) => `${PARENT_ROUTE}/cycle/${cycleId}/objectives`,

  CycleReports: (cycleId: string) => `${PARENT_ROUTE}/cycle/${cycleId}/reports`,

  ObjectiveCreator: () => `${PARENT_ROUTE}/objective/create`,

  ObjectiveEditor: (objectiveId: string) => `${PARENT_ROUTE}/objective/${objectiveId}/edit`,
};

const learnerRoutes = {
  OKRs: () => `/okrs`,
  OKRsTab: (tabId: LearnerOKRsTabEnum) => `/okrs/${tabId}`,

  LearnerCycleObjectives: (cycleId: string) => `/cycle/${cycleId}/objectives`,

  LearnerObjectiveCreator: () => `/objective/create`,

  LearnerObjectiveEditor: (objectiveId: string) => `/objective/${objectiveId}/edit`,
};

export { adminRoutes, learnerRoutes };
