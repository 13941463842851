import { UserSurveyStatusEnum, UserSurveyTypeEnum } from '@/types/schema';

export interface UserSurveyManagerProps {
  type: UserSurveyTypeRouteEnum;
  tab: UserSurveyManagerTabType;
}

export enum UserSurveyTypeRouteEnum {
  Engagement = 'engagement',
  Pulse = 'pulse',
  Onboarding = 'onboarding',
  Exit = 'exit',
  Feedback = 'feedback',
}

export enum UserSurveyManagerTabType {
  Active = 'active',
  Draft = 'draft',
  Closed = 'closed',
}

export const USER_SURVEY_ROUTE_CONFIG = {
  [UserSurveyTypeRouteEnum.Engagement]: UserSurveyTypeEnum.Engagement,
  [UserSurveyTypeRouteEnum.Exit]: UserSurveyTypeEnum.Exit,
  [UserSurveyTypeRouteEnum.Onboarding]: UserSurveyTypeEnum.Onboarding,
  [UserSurveyTypeRouteEnum.Pulse]: UserSurveyTypeEnum.Pulse,
  [UserSurveyTypeRouteEnum.Feedback]: UserSurveyTypeEnum.Feedback,
};

export const USER_SURVEY_ROUTE_TO_TYPE_CONFIG = {
  [UserSurveyTypeEnum.Engagement]: UserSurveyTypeRouteEnum.Engagement,
  [UserSurveyTypeEnum.Exit]: UserSurveyTypeRouteEnum.Exit,
  [UserSurveyTypeEnum.Onboarding]: UserSurveyTypeRouteEnum.Onboarding,
  [UserSurveyTypeEnum.Pulse]: UserSurveyTypeRouteEnum.Pulse,
  [UserSurveyTypeEnum.Feedback]: UserSurveyTypeRouteEnum.Feedback,
};

export const USER_SURVEY_TAB_CONFIG = {
  [UserSurveyManagerTabType.Active]: [UserSurveyStatusEnum.Active, UserSurveyStatusEnum.Processing],
  [UserSurveyManagerTabType.Closed]: [UserSurveyStatusEnum.Closed],
  [UserSurveyManagerTabType.Draft]: [UserSurveyStatusEnum.Draft],
};
